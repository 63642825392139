import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'

import useViewport from '../../hooks/useViewport'
import './ImageViewer.css'

import closeIcon from '../../assets/icons/ui/closeGrey.png'
import closeIconSquare from '../../assets/icons/ui/close-square.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

function extractProp(key: string) {
  const keySplited = key.split('/')
  return keySplited[keySplited.length - 1].replace('.jpeg', '')
}

interface thisProps { closeModal: () => void, src: string, text: string }
export default function ObsViewer(props: thisProps) {
  const { pathname } = useLocation()
  const { app } = useConfig()
  const [ photos, setPhotos] = useState<string[]>([])
  const [ loaded, setLoaded] = useState<boolean>(false)

  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      id: string
    }

    if(target.id.match(/obsBackground|closeObsIconContainer|closeObsIcon/)) {
      props.closeModal()
    }
  }

  return(
    <ModalBackground
      id='obsBackground'
      style={{zIndex: 998 }}
      onClick={hiddeModalFn}
    >
        <CloseIconContainer
          id='closeObsIconContainer'
          location={pathname}
          style={{zIndex: 998 }}
        >
          <CloseIcon
            src={ pathname.match('/publicacion') ? closeIconSquare : closeIcon}
            id='closeObsIcon'
          />
        </CloseIconContainer>
      
        <Container className='FullImageSliderContainer'>
          <ImageSection>
            <img src={props.src} />
          </ImageSection>
        </Container>

        <Counter isMobile={isMobile} >
          { props.text }
        </Counter>

    </ModalBackground>
  )
}

interface TmodalContainer { location: string }
const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
`

const CloseIconContainer = styled.div<TmodalContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  position: absolute;
  right: 10px;
  top: 15px;
  width: 12px;
  background-color: #FE7027;
  border-radius: 50px;
  ${(args) => args.location.match('/publicacion') && `
    right: 20px;
    top: 20px;
  `}
`

const CloseIcon = styled.img`
  width: 18px;
`

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > div {
    width: 100%;
  }
`
const ImageSection = styled.div`
  background-color: transparent;
  width: 90%;
  max-width: 90%;
  height: 70vh;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > img {
    width: 90%;
    max-width: 90%;
    height: 60vh;
    max-height: 60vh;
    object-fit: scale-down;
  }
`
const MobileImage = styled.div`
  & > img {
    width: 100%;
    max-width: 100%;
    height: 80vh;
    max-height: 80vh;
    object-fit: scale-down;
  }
`


interface Tcounter { isMobile: boolean }
const Counter = styled.div<Tcounter>`
  position: absolute;
  bottom: 160px;
  width: 100%;
  font-family: Barlow;
  font-size: 20px;
  color: white;
  ${(args) => args.isMobile && `
    bottom: 60px;
  `}
`

const ObsImg = styled.img`
`