import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { useGetMiPublicacionQuery } from '../../services/apiSlice/apiSlice'

import DetailsNav from './components/detailsNav'

import TitleSectionMiPublicacion from './components/titleSection'
import StatsSectionMiPublicacion from './components/statsSection'
import PhotoCarouselMiPublicacion from './components/photoCarousel'
import EquipamientoModal from './components/equipamientoModal'

import EditSectionMiPublicacion from './components/editMiPublicacion'

import HeaderMobile from './componentsMob/headerMobile'
import TitleMobile from './componentsMob/titleMobile'
import Modal from '../../components/modal/modal'

import whatsapp from '../../assets/icons/social/whatsapp.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function MiPublicacion() {
  const { publicacionId } = useParams()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ showModal, setShowModal] = useState<boolean>(true)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const { data: publicacion, isSuccess , refetch } = useGetMiPublicacionQuery(publicacionId)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const reFetch = () => refetch()

  return(
    <MiPublicacionContainer>
      <Centered
        isMobile={isMobile}
      >

        <FirstSide isMobile={isMobile}>

          { isSuccess && isMobile ? 
            <HeaderMobile {...publicacion}/>
            :
            <TitleSectionMiPublicacion {...publicacion}/>
          }

          { isSuccess && <PhotoCarouselMiPublicacion {...publicacion}/> }

          { isSuccess && isMobile && <TitleMobile {...publicacion}/> }

          { isSuccess && !isMobile && <StatsSectionMiPublicacion {...publicacion}/> }

        </FirstSide>

        <SecondSide isMobile={isMobile}>
          { isSuccess && <EditSectionMiPublicacion props={publicacion} /> }
        </SecondSide>

        <ThirdSide isMobile={isMobile}>
          { isSuccess && <DetailsNav {...publicacion} /> }
          { isSuccess && isMobile && <StatsSectionMiPublicacion {...publicacion}/> }
          { isMobile &&
            <ContacUs>
              <ContactText> Te Parece que hay un dato incorrecto, escríbenos </ContactText>
              <Link href={`https://wa.me/+59176000632`} target='_blank'>
                <ContactIcon src={whatsapp}/>
              </Link>
            </ContacUs>
          }
        </ThirdSide>

      </Centered>

      { isSuccess && !publicacion.equipamiento_edited && showModal && 
        <Modal
          closeModal={hiddeModal}
        >
          <EquipamientoModal publicacion={publicacion} closeModal={hiddeModal}/>
        </Modal>
      }
    </MiPublicacionContainer>
  )
}

interface isMobile { isMobile: boolean }
const MiPublicacionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-height: 100vh;
`

const Centered = styled.div<isMobile>`
  display: flex;
  margin: auto;
  gap: 40px;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
    gap: 20px;
  `}
`
const FirstSide = styled.div<isMobile>`
  width: 350px;
  min-width: 350px;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
    width: 100%;
    min-width: unset;
  `}
`
const SecondSide = styled.div<isMobile>`
  margin-top: 90px;
  width: 350px;
  min-width: 350px;
  ${(args) => args.isMobile && `
    width: 100%;
    min-width: unset;
    margin-top: 0px;
  `}
`
const ThirdSide = styled.div<isMobile>`
  margin-top: 50px;
  width: 410px;
  min-width: 410px;
  ${(args) => args.isMobile && `
    width: 100%;
    min-width: unset;
    margin-top: 0px;
  `}
`

  const Title = styled.p`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    margin-bottom: 0px;
  `

const ContacUs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 40px;
  background-color: #999;
  width: 100%;
`
  const ContactText = styled.div`
    font-size: 14px;
    color: white;
  `
  const ContactIcon = styled.img`
    height: 30px;
    width: 30px;
  `
const Link = styled.a`
  text-decoration: none;
`