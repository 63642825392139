import React from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `el ${date} de ${month}, ${year}`
}

export default function TitleSectionMiPublicacion(props: TpublicacionItem) {

  return(
    <TitleSectionContainer>

      <Title> { props.year } { props.marca } </Title>
      <Title> { props.modelo } </Title>
      <Published> Publicado { dateFormater(props.publishedAt) } </Published>

    </TitleSectionContainer>
  )
}

const TitleSectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
`

  const Title = styled.p`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
  `
  const Published = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: gray;
    font-family: Montserrat;
    margin-top: 5px;
  `

