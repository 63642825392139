import React from 'react'
import styled from 'styled-components'

import Footer from '../../components/footer/footer'

export default function Condiciones() {
  return(
    <CondicionesContainer>
      <Doc>
        <Titulo> Términos y Condiciones de Uso de la plataforma </Titulo>

        <TextoParrafo>
          Última actualización de los Términos y Condiciones de Uso: 17 de febrero de 2023
        </TextoParrafo>

        <TextoParrafo>
          Al acceder o usar este sitio web usted acepta los siguientes términos y cualquier otros términos y condiciones referido en estos términos o notificaciones periódicas enviadas a usted como enmienda a estos términos y condiciones.
        </TextoParrafo>

        <Seccion>
          <TituloSeccion> 1. Sobre carp10 </TituloSeccion>

          <TextoParrafo>
            Carp10.com es un sitio web que es propiedad de Caramba S.R.L., una empresa legalmente constituida y existe bajo las leyes del estado plurinacional de Bolivia (En adelante se denominará carp10.com o carp10)
          </TextoParrafo>

          <TextoParrafo>
            Carp10 publica o hace disponible información, productos y servicios, datos de vehículos, software y otro contenido suministrado por carp10 y terceras personas (“Material”).
          </TextoParrafo>

          <TextoParrafo>
            Carp10 no garantiza o avala la precisión o exactitud de la información presentada en este Material, o cualquier otra información recibida como resultado del uso de este sitio web (incluyendo sitios web de terceros enlazados). Usted es responsable de evaluar la precisión del Material y de cualquier otra información y confiar en ella bajo su propio riesgo.
          </TextoParrafo>

          <TextoParrafo>
            Al menos de que sea expresado por carp10, carp10 no provee ninguna garantía en relación a los bienes y servicios anunciados a la venta, incluyendo pero no limitado, cualquier representación o garantía de que los bienes o servicios son comercializables de calidad. Apto o seguro para el propósito intencionado, como fue descrito por el vendedor.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 2. Solo de uso personal y no-comercial </TituloSeccion>

          <TextoParrafo>
            A menos de haber firmado un acuerdo comercial con carp10 o Caramba S.R.L., el uso de este sitio web y de los sitios web enlazados a él, son solamente para tu uso personal y no-comercial. Excepto por el Material guardado en el caché de tu computadora o una copia personal del Material para tu uso personal, o donde tú te hagas responsable del ejercicio de tales actividades bajo tus derechos legales (por ejemplo, el uso de software para capturas de pantallas para accesibilidad), no debes, sin el previo consentimiento por escrito de carp10 o Caramba S.R.L., realizar o autorizar a terceras personas por ti, a realizar cualquiera de los siguiente:
          </TextoParrafo>
          

          <TextoParrafo>

            <Inciso>
              <TituloInciso>i.</TituloInciso>
              <TextoInciso>Modificar, copiar, distribuir, reproducir, publicar o licenciar cualquier Material;</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>ii.</TituloInciso>
              <TextoInciso>Usar o intentar usar cualquier Material para crear cualquier sitio web, publicación o base de datos para búsqueda;</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>iii.</TituloInciso>
              <TextoInciso>Duplicar o referenciar cualquier sitio web o Material de carp10.</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>iv.</TituloInciso>
              <TextoInciso>Usar proceso automático de cualquier tipo para consultar, acceder, cobrar, web-scrapping, minería de datos o generar o compilar cualquier documento, indexado o basado en base de datos en el Material;</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>v.</TituloInciso>
              <TextoInciso>Usar cualquier proceso manual para hacer múltiples copias del Material o para compilar cualquier documento, basado en indexación o base de datos sobre el Material;</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>vi.</TituloInciso>
              <TextoInciso>Publicar, transferir o vender cualquier Material, información o funcionalidad derivado de carp10 o de Caramba S.R.L.; o</TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso>vii.</TituloInciso>
              <TextoInciso>Llevar a cabo cualquier otra acción que esté en estos términos (u otros términos referidos en estos términos), contrarios a la buena fe, o que atente o viole cualquier ley aplicable.</TextoInciso>
            </Inciso>

          </TextoParrafo>

          <TextoParrafo>
            Si carp10 o Caramba S.R.L. considerase que usted o cualquier tercer persona natural o jurídica relacionado a ti ha actuado contrario a estos términos, entonces carp10 o Caramba S.R.L. puede inmediatamente terminar tu derecho de acceder y usar el sitio web de carp10 y reservarse el derecho de tomar cualquier acción que considere necesaria o deseable para prevenir la repetición de tal acción.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 3. Usted es responsable por el uso de carp10 </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> El Material disponible (o a través) de carp10 es proporcionado solamente como información general. El Material no es profesional ni consejo y no es sustituido como tal. El Material puede que no sea apropiado, correcto o suficiente para tus circunstancias, usted no debe confiarse en él como la única razón en hacer o no algo, y puede que no esté continuamente accesible o libre de errores o virus. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Usted se compromete a indemnizar a carp10 contra cualquier responsabilidad, perdida, queja o demanda si carp10 (o cualquiera de sus socios, representantes, empleados o agentes) sufriera cualquier perdida o daño o incurra cualquier costo en conexión por un incumplimiento de su parte (o cualquiera de sus socios, representantes, empleados o agentes) de estos términos incluyendo cualquier garantía que proporciones. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"c)"} </TituloInciso>
              <TextoInciso> Debes mantener el acceso a tu nombre de usuario y contraseña seguro y confidencial y no proveer esos detalles a terceros. Tu aceptas cualquier responsabilidad relacionada al uso no autorizado de tu nombre de usuario y contraseña de este sitio web. </TextoInciso>
            </Inciso>
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 4. Bienes y servicios proporcionados por carp10 </TituloSeccion>

          <TextoParrafo>
            Cualquier bien o servicio ofrecido por carp10 (más allá del acceso al sitio web) son sujetos a términos y condiciones adicionales. A tal punto de que cualquier inconsistencia que existiera entre estos términos y los términos y condiciones adicionales, los términos y condiciones adicionales prevalecerán al grado de cualquier inconsistencia.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 5. Bienes y servicios proporcionados por terceros </TituloSeccion>

          <TextoParrafo>
            Si los bienes y servicios son ofrecidos en carp10 por terceros (vendedores):
          </TextoParrafo>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> i. </TituloInciso>
              <TextoInciso> Los precios de los vehículos, de forma general y salvo señalamiento expreso, no contemplan los gastos de envío, de notaría o de transferencia (siempre verificar con el vendedor). </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> ii. </TituloInciso>
              <TextoInciso> Usted es responsable de realizar cualquier búsqueda relevante, consulta e investigación con respecto a los bienes y servicios incluyendo la búsqueda del registro correspondiente de los vehículos o registro de interés público (incluyendo el RUAT del vehículo). </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> iii. </TituloInciso>
              <TextoInciso> Carp10 no actúa como agente o corredor, ordenador de cualquier contrato entre usted y cualquier tercera persona, como tampoco provee cualquier garantía en relación a los bienes o servicios de cualquier tercera persona (a menos que se lo indique específicamente). </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> iv. </TituloInciso>
              <TextoInciso> Carp10 no será responsable por los términos de cualquier transacción que se realice entre usted y cualquier tercera persona, cualquier bien o servicio comprado por ti de cualquier tercera persona o resolver cualquier disputa entre usted y cualquier tercera persona. </TextoInciso>
            </Inciso>
          </TextoParrafo>

          <TextoParrafo>
            Para el propósito de estos términos, “persona” significa cualquier entidad legal, persona natural o jurídica.
          </TextoParrafo>

          <TextoParrafo>
            Carp10 puede que aumente información adicional a la proporcionada por el vendedor, por ejemplo, con la información de marca, modelo, versión y año proporcionado por el vendedor el texto del "Equipamiento" del vehículo es generado automáticamente y puede que sea incorrecto. Aunque carp10 pide al vendedor que revise esta información. Carp10 recomienda que usted verifique con el vendedor la información contenida en el anuncio sea correcta antes de que usted realice cualquier compra.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 6. La responsabilidad que tiene carp10 contigo es limitada </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> Toda derecho y/o garantía que no se encuentre expresamente señalado en la presente cláusula, se encontrará excluido como una garantía o derecho presunto, respecto a los bienes y/o servicios proporcionados por carp10.  </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Sujeto a la cláusula 6c y excepto en la medida de que la responsabilidad no puede ser excluida bajo las leyes aplicables, carp10 no se hace responsable de tus quejas que surjan o que estén en conexión con tu acceso y uso de carp10 y servicios que surgieran, incluyendo contratos agravios, indemnización, responsabilidad estricta, incumplimiento, garantía o estatuto. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"c)"} </TituloInciso>
              <TextoInciso> En la máxima medida permitida por lay, carp10 solo tendrá responsabilidad hacia usted conforme a cualquier garantía, derecho o termino contractual que surja, se cree o esté implícito por ministerio de ley y que no pueda excluirse siempre que, dicha responsabilidad de Carp10 es limitada, a opción de carp10, a (A) reemplazar o reparar bienes relevantes, (B) suministrar bienes equivalentes a los bienes relevantes (C) suministrar nuevamente los servicios relevantes (D) pagar el costo de tal reemplazos (reparar o suministrar). </TextoInciso>
            </Inciso>
            <Inciso>
              <TituloInciso> {"d)"} </TituloInciso>
              <TextoInciso> A lo que permite la ley, carp10 no se hace responsable por cualquier perdida o daño consecuente o indirecto, incluyendo perdida de uso, producción, ganancia, ingresos, negocio, datos, ahorros en contratos anticipados, retrasos o de cualquier costo financiero, incremento en costo de operaciones o pérdida económica. </TextoInciso>
            </Inciso>
            <Inciso>
              <TituloInciso> {"e)"} </TituloInciso>
              <TextoInciso> Para el propósito de esta sección, el termino carp10 significa la empresa Caramba S.R.L., sus socios, empleados, contratistas, agentes e inspectores, ya sea individual o colectivamente. </TextoInciso>
            </Inciso>
            <Inciso>
              <TituloInciso> {"f)"} </TituloInciso>
              <TextoInciso> Esta cláusula seguirá vigente ante cualquier expiración o terminación de estos términos. </TextoInciso>
            </Inciso>
            <Inciso>
              <TituloInciso> {"g)"} </TituloInciso>
              <TextoInciso> Donde usted haya comprado un producto o servicio en específico desde carp10, por favor revisar los términos adicionales de ese producto o servicio para su provisión de responsabilidad que puedan prevalecer sobre esta cláusula. </TextoInciso>
            </Inciso>
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 7. Derechos de autor, marca y licencia </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> El Material en carp10 es protegido por derechos de autor y leyes de propiedad intelectual. Todos los derechos no concebidos expresamente bajo estos términos son reservados por carp10 y Caramba S.R.L. Al menos se indique lo contrario en carp10, el Material pertenece o es licenciado a favor de Caramba S.R.L. y es sujeto a derechos de autor (Material sujeto a derechos de autor) </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Usted no debe alterar o modificar el Material sujeto a derechos de autor de ninguna manera o quitar cualquier notificación legal asociada a ella. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"c)"} </TituloInciso>
              <TextoInciso> Carp10.com, carp10 y otros nombres de productos y servicios de Caramba S.R.L. son marcas registradas o no registradas de Caramba S.R.L. Cualquier otro producto y nombre de empresas mencionadas en carp10 puede que sean marcas registradas de terceros. Nada de lo que se muestra en carp10.com o carp10 debe interpretarse como una concesión a usted de ningún derecho de propiedad intelectual, incluido el derecho de utilizar cualquier marca sin el consentimiento expreso por escrito del propietario correspondiente. </TextoInciso>
            </Inciso>
            <Inciso>
              <TituloInciso> {"d)"} </TituloInciso>
              <TextoInciso> En consideración de Caramba S.R.L. permitiéndote acceder y utilizar carp10.com, usted otorga a carp10 una licencia no exclusiva, gratuita, perpetua, irrevocable y licencia sublicenciable para reproducir, modificar, eliminar, adaptar y publicar cualquier material que usted publique, suba o transmita vía carp10.com para los fines que carp10 o Caramba S.R.L determine razonablemente necesarios de vez en cuando (sujeto a las políticas de privacidad de carp10). Usted garantiza que tiene todos los derechos necesarios sobre el contenido que contribuye y que el contenido no infringe ninguna ley o derechos de terceros, respondiendo ante y en nombre de Caramba S.R.L. en caso de presentarse algún reclamo por parte de terceros respecto al contenido que contribuye. Usted acepta que carp10 tome cualquier acción que pueda infringir sus derechos morales en relación con el material. </TextoInciso>
            </Inciso>
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 8. Tu cuenta de carp10 </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> Al crearse usted una cuenta de usuario en carp10, usted acepta recibir comunicación electrónica de carp10 o Caramba S.R.L. sobre sus productos o servicios, sin embargo, usted puede optar por no hacerlo a través del centro de preferencia de su cuenta. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Si usted se crea una cuenta de usuario con un nombre de usuario, número de teléfono y/o una foto de perfil en su cuenta de carp10, carp10 puede mostrar públicamente en tus comunicaciones de carp10, incluyendo en tus mensajes de uno a uno. </TextoInciso>
            </Inciso>
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 9. Servicio de comunicación </TituloSeccion>

          <TextoParrafo>
            Carp10 puede que le proporcione la capacidad de comunicarse con carp10, vendedores, anunciantes de terceros y otros usuarios a través de formularios en línea, respuesta de voz interactiva, solicitudes y consultas electrónicas, boletines informativos y otras formas de comunicación (“Servicios de Comunicación”).
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 10. Inspecciones vehiculares </TituloSeccion>

          <TextoParrafo>
            En algunos casos, en los que así lo determine carp10, carp10 inspeccionará físicamente el estado y condiciones de los bienes puestos a la venta antes de su publicación, ya sea a través de un tercero autorizado o carp10 propiamente. De dicha inspección, los usuarios autorizan a carp10 para que utilice el Sitio para publicar los resultados de la inspección dentro de su publicación.
          </TextoParrafo>

          <TextoParrafo>
            Carp10 ayuda a los usuarios facilitándoles las herramientas, información y medios para la toma de decisiones, pero, sobre todo, lo que más nos preocupa es la seguridad de sus usuarios. Por ello, carp10 por sí mismo o por medio de un tercero autorizado, realizará una inspección de carácter meramente informativo a efecto de brindar transparencia al Usuario y que el mismo tome una decisión informada, no obstante, carp10 no será responsable por los vicios ocultos del bien adquirido, así como de la compra o venta de los bienes. La inspección es un procedimiento estandarizado e imparcial que tiene el fin exclusivo de ayudar a los Usuarios a darles mayores elementos para la toma de decisiones y que conozcan las condiciones del bien que se vende y publica dentro del Sitio; sin embargo, será responsabilidad de cada Usuario que adquiera un bien, realizar la revisión y compraventa de los bienes o servicios que se ofrecen en el Sitio, además de que no podrá utilizarse para otros fines distintos que los autorizados en los Términos y Condiciones Generales. Los Usuarios aceptan que el procedimiento de inspección es exclusivamente informativo con el fin de ayudar a los Usuarios; sin embargo, serán libres de acudir o someterse a la inspección de cualquier tercero si así lo desean. El que un Usuario utilice o decida apoyarse con los servicios de cualquier tercero no autorizado por Carp10 para conocer o realizar cualquier reparación del bien objeto de la compraventa, será responsabilidad exclusiva del Usuario.
          </TextoParrafo>

          <TextoParrafo>
            Los Usuarios aceptan que la información de las inspecciones pueda ser publicada y replicada con fines informativos, comerciales y los que se señalan en el apartado de publicaciones sin perjuicio de los resultados de la inspección. Los Usuarios aceptan que dicha inspección será meramente de carácter informativo y que en ningún momento carp10 será responsable por alguna falla o vicio oculto posterior a la compraventa.
          </TextoParrafo>

          <TextoParrafo>
            Carp10 se reserva el derecho de cancelar cualquier solicitud de diagnóstico o inspección de un usuario ya sea debido a: (i) que no haya realizado el pago correspondiente, (ii) que no haya subido el comprobante de pago correspondiente atreves de la plataforma o (iii) no haya cumplido con los criterios del inspector para llevar a cabo la inspección.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 11. Servicios de Software </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> Cualquier software, incluyendo código HTML, que está disponible para descargar desde carp10.com (“Software”) es propiedad de Caramba S.R.L. y/o sus proveedores. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Tu uso del software está gobernado por los términos del contrato de licencia de usuario final, si lo hubiera, que acompaña o se incluye con el software (“Contrato de Licencia”). Debe aceptar los términos del acuerdo antes de utilizar el software. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"c)"} </TituloInciso>
              <TextoInciso> Si no hay ningún acuerdo de licencia, carp10 te concede una licencia personal, no transferible para usar el software para mirar o por otra parte usar carp10 en conformidad con estos términos, y para ningún otro propósito, Usted está prohibido a: </TextoInciso>
            </Inciso>

            <TextoParrafo>
              <Inciso>
                <TituloInciso> i. </TituloInciso>
                <TextoInciso> Copiar, reproducir, traducir, adaptar, variar o modificar en su totalidad o parcialmente, el Software; </TextoInciso>
              </Inciso>

              <Inciso>
                <TituloInciso> ii. </TituloInciso>
                <TextoInciso> Realizar ingeniería inversa o compilación inversa o directa o indirectamente permitir o causar una tercera persona natural o jurídica realice ingeniería inversa o compilación inversa, en su totalidad o parcial, el Software;  </TextoInciso>
              </Inciso>

              <Inciso>
                <TituloInciso> iii. </TituloInciso>
                <TextoInciso> Introducir o usar cualquier dispositivo, Software, o rutina que interfiera o intente interferir con la operación del software; o </TextoInciso>
              </Inciso>

              <Inciso>
                <TituloInciso> iv. </TituloInciso>
                <TextoInciso> Sub-licenciar, alquilar, arrendar, prestar o autorizar a ninguna persona ningún derecho de uso del Software que no sea el establecido en este párrafo. </TextoInciso>
              </Inciso>
            </TextoParrafo>

          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 12. Carp10 y su privacidad </TituloSeccion>

          <TextoParrafo>
            Al usar carp10, usted está de acuerdo con las políticas de privacidad. Las cuales establecen como carp10 recolecta, usa y expone su información personal.
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 13. Modificación de los términos </TituloSeccion>

          <TextoParrafo>
            <Inciso>
              <TituloInciso> {"a)"} </TituloInciso>
              <TextoInciso> Carp10 puede modificar o enmendar sus términos y condiciones para sus bienes y servicios de vez en cuando. Modificaciones de estos términos (y términos y condiciones vinculados a estos términos) toman efecto una vez mostrados en este sitio web o app; sin embargo, solo aplican a tu uso de carp10 luego de esa fecha. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"b)"} </TituloInciso>
              <TextoInciso> Carp10 puede que, actúe razonablemente, cese de proveerte acceso a carp10 o cualquiera de sus servicios sin ninguna notificación. </TextoInciso>
            </Inciso>

            <Inciso>
              <TituloInciso> {"c)"} </TituloInciso>
              <TextoInciso> Todos los derechos no expresamente otorgados son reservados. </TextoInciso>
            </Inciso>
          </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 14. Contacto de Carp10 </TituloSeccion>

          <TextoParrafo>
            Si tienes una pregunta, problema o necesitas contactarnos:            
          </TextoParrafo>

          <TextoParrafo> Teléfono: +(591) 76000632 </TextoParrafo>
          <TextoParrafo> Email – preguntas: info@carp10.com</TextoParrafo>

        </Seccion>

      </Doc>

      <Footer/>

    </CondicionesContainer>
  )
}

const CondicionesContainer = styled.div`
  font-family: Montserrat;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const Doc = styled.div`
  width: min(70%, 800px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Titulo = styled.p`
  font-size: 28px;
  font-weight: 500;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 20px;
  }
`

const Seccion = styled.div`
  font-size: 16px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`
const TituloSeccion = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-left: 40px;
  text-align: left;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 16px;
  }
`
const TextoParrafo = styled.p`
  text-align: left;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 12px;
  }
`
const Inciso = styled.div`
  display: flex;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 5px;
    font-size: 12px;
  }
`
const TituloInciso = styled.div`
  min-width: 30px;
`

const TextoInciso = styled.div``