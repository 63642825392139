import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { setLogEvent } from '../../components/popUp/popUpSlice'
import { setFinishedStep } from '../../services/publicar/publicarSlice'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import { setUIStep, setPrecio, setMostrarPrecio } from "../../services/publicar/publicarSlice"
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

import NavigatorComponent from './navigatorComponent'
import Modal from '../../components/modal/modal'
import AuthModal from '../../components/modal/modalAuth'

import backIcon from '../../assets/icons/ui/back_orange.png'
import { Toast, ToastMessage } from 'primereact/toast';

export default function VenderPrecio() {
  const dispatch = useAppDispatch()
  const [ showModal, setShowModal] = useState<boolean>(false)
  const toastBottomRight = useRef<Toast>(null);

  const publicar = useAppSelector((state: TrootState) => state.publicar)
  const user = useAppSelector((state: TrootState) => state.user)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  function checkFinish() {
    const data: any = { ...publicar }

    delete data.pubEnCurso
    delete data.fotos
    delete data.ui

    const undefineds = Object.entries(data).map(([prop, val]) => val === undefined )
    /*
    console.log(Object.entries(data).map(([prop, val]) => {
      if (val === undefined ) console.log(prop)
      return val === undefined 
    }))
    */
    const completeAll = !undefineds.some((item) => item === true)

    if (completeAll) {
      dispatch(setUIStep('fotos'))
    } else {
      dispatch(setLogEvent({
        type: 'error',
        message: 'No se han rellenado todos los campos, vuelve a los pasos anteriores'
      }))
    }
  }

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'pub-precio')
      dispatch(setPrecio(Number(event.target.value)))

    if (event.target.name === 'pub-mostrarPrecio') {
      
      const target = event.target as typeof event.target & { checked: boolean}
      dispatch(setMostrarPrecio(target.checked))
      if (target.checked ===false) dispatch(setPrecio(0))
    }
  }

  const showMessage = (title: string, detail: string, ref: React.RefObject<Toast>, severity: ToastMessage['severity']) => {
   
    ref.current?.show({ severity: severity, summary: title, detail: detail, life: 10000 });
};
  useEffect(() => {
    dispatch(setFinishedStep({ step: 2, finish: publicar.precio ? true : false }))
  }, [publicar.precio])
 
  return(
    <VenderPrecioContainer className='view'>

      <NavigatorComponent step={2} />

      <Title> Precio para la publicación </Title>
      {/*
      <RowToogle>
        <Toggle
          id='pub-mostrarPrecio'
          name='pub-mostrarPrecio'
          checked={publicar.mostrar_precio ? publicar.mostrar_precio : false}
          onChange={inputChange}
        />
        <LabelToogle htmlFor='pub-mostrarPrecio'>Mostrar precio en mi publicacion</LabelToogle>
      </RowToogle>
      */}
      { publicar.mostrar_precio === true ?
        <Row>
          <Label htmlFor='pub-precio'> $us. </Label>

          <InputVender
            type='number'
            id='pub-precio'
            name='pub-precio'
            placeholder="Precio"
            value={ publicar.mostrar_precio && publicar.precio !== 0 ? publicar.precio : '' }

            onChange={inputChange}
          />

        </Row>
      :
        <Consultar> Ocultar el precio y que consulten el precio conmigo </Consultar>
      }

      <Row>
        <BackButton
          onClick={() => dispatch(setUIStep('datosVehiculo'))}
        >
          <BackIcon src={backIcon} />
          Volver
        </BackButton>
        <Continue
          
          onClick={(e) => {
            if (!user.data) {
              setShowModal(true)
            } else if ((publicar.precio && publicar.precio < 5000) || publicar.precio === 0) {
              showMessage('ERROR EN EL PRECIO', 'El precio tiene que ser real y mayor a 5000 $us.', toastBottomRight, 'error')
            }
            else {
              checkFinish()
            }
          }}
        >
          Siguiente
        </Continue>
      </Row>

      { !user.data && showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <AuthModal
            hiddeModal={hiddeModal}
          />
        </Modal>
      }
        <Toast ref={toastBottomRight} position="bottom-right" />
    </VenderPrecioContainer>
  )
}

const VenderPrecioContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  margin: 25px 0px 20px 0px;
`

const InputVender = styled.input`
  font-family: Montserrat;
  font-weight: 500;
  width: 220px;
  margin: 0px 0px 0px 15px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  border-color: #00000088;
  &:focus {
    outline: none;
  }
  &:invalid {
    border-color: #00000066;
  }
  &:valid {
    color: black;
    border-color: black;
  }
`

const Label = styled.label`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
`

const LabelToogle = styled.label`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`
const RowToogle = styled(Row)`
  gap: 30px;
`

const Continue = styled.button`
  margin: 0px 0px 0px 20px;
  background-color: #fe7027;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 160px;
  cursor: pointer;
`

const BackButton = styled.div`
  color: #fe7027;
  border: 1px solid #fe7027;
  border-radius: 30px;
  padding: 0px 10px;
  font-weight: 600;
  cursor: pointer;
`
const BackIcon = styled.img`
  height: 35px;
`

const Consultar = styled.div`
  background-color: #fe702755;
  border-radius: 3px;
  border: solid 2px #fe7027;
  font-weight: 700;
  font-size: 16px;
  margin: 50px 0px 0px 0px ;
  padding: 5px 10px;
`