import React, { useEffect } from "react"
import styled from 'styled-components'
import { NavLink, useNavigate } from "react-router-dom"
import { closeSession } from "../../../services/auth/userSlice"

import { TrootState, useAppDispatch, useAppSelector } from "../../../app/store"
import { useSelector, useDispatch } from "react-redux"
import { setActiveView } from "../../../app/AppUISlice"
import { hiddeSidebar } from "../miCuentaSlice"


//import SocialMedia from "../header/socialMedia"

import logo_ligth from '../../../assets/icons/ui/logo_ligth.png'
import close_ligth from '../../../assets/icons/ui/closeGrey.png'
import menuIcon from '../../../assets/icons/ui/menu-micuenta.png'


interface thisProps { showSidebar: boolean, hiddeSidebar: () => void }
export default function SidebarMiCuentaMobile(props: thisProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showSidebar } = useSelector(
    (state: TrootState) => state.miCuenta
  )

  const user = useAppSelector((state: TrootState) => state.user)
  useEffect(() => {
    console.log('showSidebar: ', showSidebar)
  }, [props.showSidebar])

  function goHome() {
    // const view = views.find((view) => view.path === '/')
    // if (view) dispatch(setActiveView(view))
    dispatch(hiddeSidebar())
  }

  function logOut() {
    console.log('dispath close session')
    dispatch(hiddeSidebar())
    dispatch(closeSession())
    .then((result) => {
      console.log('logout: ', result)
      if (result.meta.requestStatus === 'fulfilled') {
        navigate('/')
      }
    })
  }

  return(
    <>
      <SidebarBackground
        showSideBar={showSidebar}
        className="SidebarBackground"
        onClick={ () => dispatch(hiddeSidebar()) }
        style={{ zIndex: 998 }}
      />

      <SidebarMenu
        showSideBar={showSidebar}
        className="SideBar"
        style={{ zIndex: 999 }}
      >
        <TopSide>
          <TitleSidebar className="TitleSidebar">

            <CloseSidebar
              src={menuIcon}
              onClick={ () => dispatch(hiddeSidebar()) }
            />

            <NavItem> Mis publicaciones </NavItem>
            <NavItem> Mis favoritos </NavItem>

            <HorizonatlDivisor/>

            <NavItem> Mis Inspecciones </NavItem>

            <HorizonatlDivisor/>

          </TitleSidebar>

        </TopSide>

        { user.data?.email &&

          <BottomSide>
            <NavLink to='/' onClick={() => goHome()}>
              <Logo
                src={logo_ligth}
                onClick={() => props.hiddeSidebar()}
              />
            </NavLink>

            <Email>
              { user.data.email.replace('@', '@ ') }
            </Email>

            <CloseSession
              onClick={() => logOut()}
            >
              Cerrar Sesión
            </CloseSession>
          </BottomSide>            
        }

      </SidebarMenu>
    </>
  )
}

interface TSidebarProps { showSideBar: boolean }
const SidebarBackground = styled.div<TSidebarProps>`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .2s ease-out;
  ${ props => props.showSideBar && `
    opacity: 1;
    left: 0px;
    right: 0px;
  `};
`
const SidebarMenu = styled.div<TSidebarProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -300px;
  width: 300px;
  background-color: #F0EEDD;
  background-color: #CCCCCC;
  z-index: 999;
  transition: transform .5s ease-out;
  ${ props => props.showSideBar && `
      transform: translate(300px, 0);        
  `};
`
const TopSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

  const NavItem = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    align-self: left;
    text-align: left;
    width: 200px;
    margin: 10px 0px;
  `

const BottomSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: 20px;
`;

const TitleSidebar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`
  const Logo = styled.img`
    width: 150px;
    margin-bottom: 30px;
  `
  const Email = styled.div`
    align-self: center;
    white-space: pre-line;
    text-align: center;
    width: 200px;
    margin-bottom:  20px;
  `

  const CloseSidebar = styled.img`
    width: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
  `

const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #999;
  margin: 15px auto 25px auto;
`


const CloseSession = styled.div`
  font-family: Montserrat;
  align-self: center;
  background-color: #30302C;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  width: 120px;
  padding: 5px 0px 5px 0px;
`
