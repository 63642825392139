import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'

import { setLogEvent } from '../../../components/popUp/popUpSlice'
import { useAppDispatch } from '../../../app/store'
import useViewport from '../../../hooks/useViewport'

import edit from '../../../assets/icons/mipublicacion/edit-tool.png'
import cancel from '../../../assets/icons/mipublicacion/cancel.png'
import { useUpdateMiPublicacionMutation } from '../../../services/apiSlice/apiSlice'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

const ciudades = [
  'Santa Cruz de la Sierra',
  'Warnes',
  'La Guardia'
]
interface thisprops { props: TpublicacionItem }
export default function EditSectionMiPublicacion(properties: thisprops) {
  const { props } = properties

  const dispatch = useAppDispatch()
  const [editKm, setEditKm] = useState<undefined | number>(undefined)
  const [editPrecio, setEditPrecio] = useState<undefined | number>(undefined)
  const [editCiudad, setEditCiudad] = useState<undefined | string>(undefined)
  const [editing, setEditing] = useState<boolean>(false)
  const [toSave, setToSave] = useState<boolean>(false)
  const [ updateMiPublicacion, respUpdateMiPub ] = useUpdateMiPublicacionMutation()

  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  useEffect(() => {
    if (editKm || editPrecio || editCiudad)
    setEditing(true)
    else
    setEditing(false)

    if (editKm && editKm !== props.kilometraje) setToSave(true)
    else if (editPrecio && editPrecio !== props.precio) setToSave(true)
    else if (editCiudad && editCiudad !== props.ciudad) setToSave(true)
    else setToSave(false)

  }, [editKm, editPrecio, editCiudad])

  function cancelEdit() {
    setEditKm(undefined)
    setEditPrecio(undefined)
    setEditCiudad(undefined)
  }

  async function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'mipub-km')
    setEditKm(Number(event.target.value))

    if (event.target.name === 'mipub-precio')
    setEditPrecio(Number(event.target.value))

    if (event.target.name === 'mipub-ciudad') 
    setEditCiudad(event.target.value)
  }

  function editMiPublicacion() {
    const payload = {
      precio: editPrecio,
      kilometraje: editKm,
      ciudad: editCiudad
    }

    const cleanedPayload = Object.fromEntries(Object.entries(payload).filter(([prop, val]) => {
      if (val !== undefined) return true
      else return false
    }))

    if (Object.keys(cleanedPayload).length > 0) {
      updateMiPublicacion({ payload: cleanedPayload, publicacionId: props.id_publicacion })
      .then((resp: any) => {
        console.log(resp)
        dispatch(setLogEvent({ type: 'info', message: 'Datos actualizados' }))
        cancelEdit()
        
      })
    }
  }


  return(
    <EditSectionContainer isMobile={isMobile}>

      { !isMobile &&
        <Title> Información de la publicación </Title>
      }

      <InputContainer>
        <InputTitle> Kilometraje </InputTitle>
        <Row>
          <Input
            type={'number'}
            name='mipub-km'
            placeholder={ props.kilometraje.toString()}
            disabled={editKm ? false : true}
            onChange={inputChange}
            value={editKm ? editKm.toString() : props.kilometraje }
          />

          <Edit
            onClick={() => {
              if (!editKm)
              setEditKm(props.kilometraje)
              else
              setEditKm(undefined)
            }}
          >
            <EditIcon src={editKm ? cancel : edit}/>
          </Edit>
        </Row>
      </InputContainer>

      <InputContainer>
        <InputTitle> Precio en dolares </InputTitle>

        { editPrecio && 
          <InputSubTitle>
            Precio anterior: $us { props.precio }
          </InputSubTitle>
        }

        <Row>
          <Input
            type={'number'}
            name='mipub-precio'
            placeholder={ props.precio.toString()}
            disabled={editPrecio ? false : true}
            onChange={inputChange}
            value={editPrecio ? editPrecio.toString() : props.precio}
          />

          <Edit
            onClick={() => {
              if (!editPrecio)
              setEditPrecio(props.precio)
              else
              setEditPrecio(undefined)
            }}
          >
            <EditIcon src={editPrecio ? cancel : edit}/>
          </Edit>

        </Row>
      </InputContainer>

      <InputContainer>
        <InputTitle> Ubicación del vehículo </InputTitle>
        <Row>
          <SelectPublicar
            name='mipub-ciudad'
            
            onChange={inputChange}
            disabled={editCiudad ? false : true}
            value={editCiudad ? editCiudad : props.ciudad}
          >
            <option value="" disabled> Selecione Ciudad </option>
            {
              ciudades.map((ciudad, index) => (
                <option value={ciudad} key={`vender-cc-${ciudad}`}>
                  { ciudad }
                </option>
              ))
            }
          </SelectPublicar>

          <Edit
            onClick={() => {
              if (!editCiudad)
              setEditCiudad(props.ciudad)
              else
              setEditCiudad(undefined)
            }}
          >
            <EditIcon src={editCiudad ? cancel : edit}/>
          </Edit>
        </Row>

        { editing &&
          <Botonera>
            <CancelButton
              onClick={() => cancelEdit()}
            >
              Cancelar
            </CancelButton>

            <GuardarButton
              disabled={!toSave}
              onClick={() => editMiPublicacion()}
            >
              Guardar
            </GuardarButton>
          </Botonera>
        }

      </InputContainer>

      

    </EditSectionContainer>
  )
}

interface isMobile { isMobile: boolean }
const EditSectionContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 60px 30px 40px 30px;
  border: 2px solid black;
  border-radius: 75px;
  ${(args) => args.isMobile && `
    border: none;
    padding: 0px 30px 40px 30px;
  `}
`

const Title = styled.p`
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 600;
  width: 190px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //align-items: space-between;
`

const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;
`
  const InputTitle = styled.div`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: gray;
  `
  const InputSubTitle = styled.div`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: gray;
  `
  const Input = styled.input`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 15px;
  `
  const Edit = styled.div`
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
    text-decoration: underline;
    color: #0088AA;
    font-weight: 600;
    cursor: pointer;
    margin-left: 30px;
  `
    const EditIcon = styled.img`
      height: 30px;
      margin-right: 10px;
    `

const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
  
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  border: solid 1px gray;
  border-radius: 5px;
  
  
  &:focus {
    outline: none;
  }
`

const Botonera = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  cursor: pointer;
`
const Button = styled.button`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  border: none;
`
const CancelButton = styled(Button)`
  background-color: orange;
  color: white;
`
const GuardarButton = styled(Button)`
  background-color: #71c837;
  color: white;
`