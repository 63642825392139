import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom"
import useViewport from '../hooks/useViewport'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { TrootState, useAppDispatch, useAppSelector } from './store'
import useConfig from '../hooks/useConfig'
import { fetchUserData } from '../services/auth/userSlice'
import { useGetPublicacionesQuery } from '../services/apiSlice/apiSlice'
import { loadingItems, loadPublicaciones } from '../services/publicaciones/publicacionesSlice'
import { useGoogleLogin, useGoogleOneTapLogin  } from '@react-oauth/google'
import { googleLogin } from '../services/auth/userSlice'
import { verifyUserExists } from '../services/auth/api'
import Modal from '../components/modal/modal'
import PhoneModal from '../components/modal/phoneModal'
import ReactGA from "react-ga4";



import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import Home from '../views/home/Home'
import About from '../views/about/About'
import PageNotFound from '../views/404/pageNotFound'
import SignUp from '../views/sign-up/SignUp'
import SignIn from '../views/sign-in/SignIn'
import Vender from '../views/publicar/vender'
import InfoInspeccion from '../views/inspeccion/info'
import InfoPublicidadVideo from '../views/publicidadVideo/info';
import Agendar from '../views/inspeccion/agendar'
import MiCuenta from '../views/micuenta/miCuenta'
import AutoView from '../views/publicacion/AutoView'
import MiPublicacion from '../views/miPublicacion/MiPublicacion'
import PoliticasPrivacidad from '../views/terminos/politicasPrivacidad'
import Condiciones from '../views/terminos/terminosCondiciones'
import DiagnosticoTerminosCondiciones from '../components/diiagnosticoT&C/diagnosticoT&C'

import WhatsAppButton from '../components/whatsappButton/whatsappButton'

import Header from '../components/header/header'
import Sidebar from '../components/sidebar/sidebar'
import PopUp from '../components/popUp/popUp'
import LoadingBlock from '../components/loading/loading'
import { setLogEvent } from '../components/popUp/popUpSlice'

//core

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";    
import "primeflex/primeflex.css";     
//ReactGA.initialize("G-QHXPX2CWKM");
ReactGA.initialize([
  {
    trackingId: "G-YTJERY7YWH",
    //gaOptions: {...}, // optional
    //gtagOptions: {...}, // optional
  },
  {
    trackingId: "G-Z0Q8ZCVBV5",
    //gaOptions: {...}, // optional
    //gtagOptions: {...}, // optional
  },
  {
    trackingId: "G-QHXPX2CWKM",
  },
]);
const cookies = new Cookies()

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

function App() {
  const config = useConfig()
  const location = useLocation();
  const { pathname } = useLocation()
  const [ searchParams ] = useSearchParams()
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ phone, setPhone ] = useState<string|undefined>(undefined)
  const [ credential, setCrediental ] = useState<string|undefined>(undefined)
  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const filter = useAppSelector((state: TrootState) => state.filter)
  const user = useAppSelector((state: TrootState) => state.user)

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const { data: publicaciones, error, isFetching, isLoading, refetch } = useGetPublicacionesQuery({
    search: filter.filter.search,
    tipos: filter.filter.tipos,
    minprice: filter.filter.minPrice,
    maxprice: filter.filter.maxPrice === 40000 ? undefined : filter.filter.maxPrice,
    sort: filter.sort,
    inspeccionado: filter.filter.inspeccionado,
    permutable: filter.filter.permutable
  },{
    pollingInterval: 0
  })

  useEffect(() => {
    if (isFetching)
    dispatch(loadingItems())

    if (!error && !isLoading && publicaciones) {
      dispatch(loadPublicaciones(publicaciones.items))
    }

    if (error) {
      console.log(error)
    }

  }, [isFetching , error, publicaciones])

  useEffect(() => {
    if (cookies.get(config.app.COOKIE_USER)) {
      dispatch(fetchUserData())
    }
  }, [])

  useEffect(() => {
    //console.log(pathname)
    //console.log(window.location.pathname + window.location.search)
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname });

    if (pathname === '/' && searchParams.get('hist')) {
      console.log(searchParams.get('hist'))

      ReactGA.event('social_post_click', {
        social_network: 'instagram',
        link_url: `?hist=${searchParams.get('hist')}`
      })
    }

  }, [pathname])

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      if (credentialResponse.credential) {
        const credential = credentialResponse.credential
        const segment = credential.split('.')
        //const parsed = JSON.parse(Buffer.from(segment[1], 'base64').toString('utf8'))
        const parsed = JSON.parse(atob(segment[1]))
        console.log(parsed.email)

        verifyUserExists(parsed.email)
        .then((resp: any) => {
          console.log(resp)
          if (resp.data.userExits === true) {
            googleAuth({ google_token: credential })
          } else {
            setShowModal(true)
            setCrediental(credential)
          }
        })

      }
    },
    onError: () => {
      console.log('Login Failed');
    },
  })

  useEffect(() => {
    if (phone && credential) {
      googleAuth({ google_token: credential, phone_number: phone })
    }
  }, [phone])

  function googleAuth(props: { google_token: string, phone_number?: string }) {
    dispatch(googleLogin({...props}))
    .catch((error: any) => {
      dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
    })
  }

  return (
    <AppContainer
      isMobile={isMobile}
      className="App"
    >
      { pathname !== '/micuenta' && !pathname.match('/publicacion/') && <Header/>}
      { isMobile && <Sidebar/> }
      <PopUp/>

      <Routes>
        <Route path="/" element={<Home  refetch={refetch}/>} />
        <Route path="/registro" element={<SignUp/>} />
        <Route path="/login" element={<SignIn/>} />
        <Route path="/vender" element={<Vender/>} />
        <Route path="/inspeccion" element={<InfoInspeccion/>} />
        <Route path="/publicidadVideo" element={<InfoPublicidadVideo />} />
        <Route path="/diagnosticovehicular" element={<InfoInspeccion/>} />
        <Route path="/inspeccion/agendar" element={<Agendar/>} />
        <Route path="/micuenta" element={<MiCuenta/>} />
        <Route path="/publicacion/:publicacionId" element={<AutoView/>} />
        {/* <Route path="/publicacion/:publicacionId/solicitarinspeccion" element={<Facturacion/>} /> */}
        <Route path="/micuenta/publicacion/:publicacionId" element={<MiPublicacion/>} />

        <Route path="/terminos&condiciones" element={<Condiciones/>} />
        <Route path="/politicasdeprivacidad" element={<PoliticasPrivacidad/>} />
        <Route path="/diagnostico-terminoscondiciones" element={<DiagnosticoTerminosCondiciones/>} />

        {/* <Route path="/about" element={<About/>} /> */}
        <Route path='*' element={<PageNotFound/>} />
      </Routes>

      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <PhoneModal
            hiddeModal={hiddeModal}
            setPhone={setPhone}
          />
        </Modal>
      }

      <LoadingBlock/>
      <WhatsAppButton/>
    </AppContainer>
  );
}

interface TappContainer { isMobile: boolean }
const AppContainer = styled.div<TappContainer>`
  
  
`

export default App;
