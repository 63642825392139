import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { useGetMiInspeccionQuery } from '../../services/apiSlice/apiSlice'
import { setResetInspeccion } from '../../services/pagoInspeccion/inspeccionPubliSlice'
import ReactGA from "react-ga4"

import Modal from '../../components/modal/modal'
import EvaluacionModal from './evaluacionModal'

import PagoInspeccion from '../../components/inspeccionComprador/PagoInspeccion'

import plus from '../../assets/icons/ui/plus.png'
import loadingGif from '../../assets/gifs/loading.gif'

import {
  escaneo_general,
  interior_vehiculo,
  seguridad_carretera,
  bajo_capo,
  datos_adicionales,
  estetica_vehiculo,
 } from './autoTypes'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

ReactGA.initialize([
  { trackingId: "G-YTJERY7YWH" },
  { trackingId: "G-Z0Q8ZCVBV5" },
  { trackingId: "G-QHXPX2CWKM" },
])

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `${date}/${month}/${year}`
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Evaluacion(props: TpublicacionItem) {
  const dispatch = useAppDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ showSolicitar, setShowSolicitar] = useState<boolean>(false)
  const [ inspeccionSelected, setInspeccionSelected ] = useState<string | undefined>()

  const [ evalEscaneo, setEvalEscaneo] = useState<[number, number] | undefined>(undefined)
  const [ evalInterior, setEvalInterior] = useState<[number, number] | undefined>(undefined)
  const [ evalSeguridad, setEvalSeguridad] = useState<[number, number] | undefined>(undefined)
  const [ evalCapo, setEvalCapo] = useState<[number, number] | undefined>(undefined)
  const [ evalAdicionales, setEvalAdicionales ] = useState<[number, number] | undefined>(undefined)
  const [ evalEstetica, setEvalEstetica] = useState<[number, number] | undefined>(undefined)

  const { data: inspeccion, error, isFetching, isLoading, isSuccess, isError, refetch } = useGetMiInspeccionQuery ({
    publicacionId: props.id_publicacion },{ pollingInterval: 0 })

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)
  useEffect(() => {
    
    ReactGA.event('click_mechanic_evaluation_results',{
      publicacionId: props.id_publicacion,
    })
  }, [])

  const displaySolicitarModal = () => setShowSolicitar(true)
  const hiddeSolicitarModal = () => {
    setShowSolicitar(false)
    dispatch(setResetInspeccion())
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    if (isSuccess) {
      //  ESCANEO
      const props_escaneo = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (escaneo_general.includes(prop)  && value !==null) return true
        else return false
      }))

      if (Object.keys(props_escaneo).length > 0) {
        const resultEscaneo = Object.entries(props_escaneo).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalEscaneo([resultEscaneo, Object.keys(props_escaneo).length])
        console.log('ESCANEO: ',resultEscaneo, Object.keys(props_escaneo).length)
      }

      // INTERIOR
      const props_interior = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (interior_vehiculo.includes(prop) && value !==null) return true
        else return false
      }))

      if (Object.keys(props_interior).length > 0) {
        const resultInterior = Object.entries(props_interior).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalInterior([resultInterior, Object.keys(props_interior).length])
        console.log('evalInterior:', evalInterior);
        console.log('INTERIOR: ',resultInterior, Object.keys(props_interior).length)
      }

      // SEGURIDAD
      const props_seguridad = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (seguridad_carretera.includes(prop)  && value !==null) return true
        else return false
      }))

      if (Object.keys(props_seguridad).length > 0) {
        const resultSeguridad = Object.entries(props_seguridad).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalSeguridad([resultSeguridad, Object.keys(props_seguridad).length])
        console.log('SEGURIDAD: ',resultSeguridad, Object.keys(props_seguridad).length)
      }

      // CAPO
      const props_capo = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (bajo_capo.includes(prop)  && value !==null) return true
        else return false
      }))

      if (Object.keys(props_capo).length > 0) {
        const resultCapo = Object.entries(props_capo).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalCapo([resultCapo, Object.keys(props_capo).length])
        console.log('CAPO: ',resultCapo, Object.keys(props_capo).length)
      }

      // ADICIONALES
      const props_adicionales = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (datos_adicionales.includes(prop) && value !==null) return true
        else return false
      }))

      if (Object.keys(props_adicionales).length > 0) {
        const resultAdicionales = Object.entries(props_adicionales).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalAdicionales([resultAdicionales, Object.keys(props_adicionales).length])
        console.log('ADICIONALES: ',resultAdicionales, Object.keys(props_adicionales).length)
      }

      // ESTETICA
      const props_estetica = Object.fromEntries(Object.entries(inspeccion).filter(([prop, value]) => {
        if (estetica_vehiculo.includes(prop) && value !==null) return true
        else return false
      }))

      if (Object.keys(props_estetica).length > 0) {
        const resultEstetica = Object.entries(props_estetica).map(([prop, value]) => value)
        .filter(value => value === true).length;
        setEvalEstetica([resultEstetica, Object.keys(props_estetica).length])
        console.log('ESTETICA: ',resultEstetica, Object.keys(props_estetica).length)
      }
    }

  }, [inspeccion])


  return(
    <EvaluacionContainer>
      
      { isLoading &&
        <Loading>
          <LoadinImg src={loadingGif}/>
        </Loading>
      }

      { isSuccess && 
        <TopSide>
          <ResultadosEvaluacion>
            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Escaneo de sensores </Title>
              <Value>
                { evalEscaneo && (evalEscaneo[0] / evalEscaneo[1] * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('escaneo') }}
                />
              </Value>
            </Data>

            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Interior del vehículo </Title>
              <Value>
                { evalInterior && ((evalInterior[0] / evalInterior[1]) * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('interior') }}
                />
              </Value>
            </Data>

            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Seguridad en carretera </Title>
              <Value>
                { evalSeguridad && ((evalSeguridad[0] / evalSeguridad[1]) * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('seguridad') }}
                />
              </Value>
            </Data>

            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Bajo el capó </Title>
              <Value>
                { evalCapo && ((evalCapo[0] / evalCapo[1]) * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('capo') }}
                />
              </Value>
            </Data>

            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Datos Adicionales </Title>
              <Value>
                { evalAdicionales && ((evalAdicionales[0] / evalAdicionales[1]) * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('adicionales') }}
                />
              </Value>
            </Data>

            <Data isMobile={isMobile}>
              <Title isMobile={isMobile}> Estética del vehículo </Title>
              <Value>
                { evalEstetica && ((evalEstetica[0] / evalEstetica[1]) * 10).toFixed(0) }<Slash>/</Slash>10
                <DetalleIcon src={plus}
                  onClick={() => { setShowModal(true); setInspeccionSelected('estetica') }}
                />
              </Value>
            </Data>
          </ResultadosEvaluacion>

          <FechaEvaluacion>
            <strong style={{marginRight: '10px'}}>
              Vehículo verificado por Carp10 el:
            </strong>
            { dateFormater(inspeccion.createdAt) }
          </FechaEvaluacion>

        </TopSide>
      }

      { isError &&
        <TopSide>
          <NoInspeccionContainer>

            <NoInspeccionText
              isMobile={isMobile}
            >
              Este vehículo no ha sido diagnosticado por carp10.
            </NoInspeccionText>

            <SolicitarInspeccionButton
              onClick={() => {
                setShowSolicitar(true)
              }}
            >
              Solicitar Diagnostico
            </SolicitarInspeccionButton>

          </NoInspeccionContainer>
        </TopSide>
      }

      { showModal && inspeccionSelected &&
        <Modal
          closeModal={hiddeModal}
        >
          <EvaluacionModal
            //hiddeModal={hiddeModal}
            inspeccion={inspeccion}
            tipo={inspeccionSelected}
          />
        </Modal>
      }


      { showSolicitar &&
        <Modal
          closeModal={hiddeSolicitarModal}
        >
          <PagoInspeccion
            publicacionData={ props }
            closeModal={hiddeSolicitarModal}
            //hiddeModal={hiddeModal}
          />
        </Modal>
      }

      {/* { showSolicitar && solicitarInspeccion.pagarInspeccion === true &&
        <Modal
          closeModal={hiddeSolicitarModal}
        >
          <SonidoMotor
            { ...props }
            //hiddeModal={hiddeModal}
          />
        </Modal>
      } */}

    </EvaluacionContainer>
  )
}

const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #b9b9b9;
  margin: 20px auto 15px auto;
`

const EvaluacionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
`

interface isMobile { isMobile: boolean }
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`
const TopSide = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding: 30px 10px 0px 10px;
`

const ResultadosEvaluacion = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
const FechaEvaluacion = styled.div`
  margin-top: 10px;
  align-self: flex-start;
`
  const Data = styled.div<isMobile>`
    font-family: Montserrat;
    color: gray;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin-bottom: 15px;
    width: 30%;
    ${(args) => args.isMobile && `
      width: 50%;
    `}
  `
  const Title = styled.div<isMobile>`
  
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    ${(args) => args.isMobile && `
      font-size: 11px;
    `}
  `
  const Value = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 34px;
    text-align: left;
    font-weight: 700;
    margin-top: 8px;
    color: black;
  `
  const DetalleIcon = styled.img`
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
  `
const DocTitle = styled.div`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
  color: gray;
`
const Slash = styled.span`
  font-weight: 500;
  margin: 0px 3px 0px 3px;
`
const NoInspeccionContainer = styled.div`
  margin: auto;
  background-color: #DEE3DB;
  padding: 20px 20px;
  border-radius: 15px;
  border: 2px solid black;
`
const NoInspeccionText = styled.div<isMobile>`
  font-family: Montserrat;
  width: 350px;
  font-weight: 500;
  ${(args) => args.isMobile && `
    width: 250px;
  `}
`
const SolicitarInspeccionButton = styled.button`
  margin-top: 10px;
  border: none;
  background-color: #FE7027;
  color: white;
  border-radius: 20px;
  padding: 2px 20px;
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
`
  const LoadinImg = styled.img`
    width: 50px;
    padding: 5px 10px;
    margin: 5px 0px;
  `