import React, { useState } from 'react';
import styled from 'styled-components'
import { TsignUpStep } from './SignUp';
import { NavLink, useNavigate } from "react-router-dom"
import useConfig from "../../hooks/useConfig"

import check from '../../assets/icons/ui/check.png'

interface TsignUpSuccess { changeRegisterStep: (step: TsignUpStep) => void }
export default function SignUpSuccess(props: TsignUpSuccess) {
  const config = useConfig();
  const navigate = useNavigate();
  const [ message, setMessage ] = useState({ type: 'none', message: '' });

  return (
    <SignUpSuccessContainer>
      <CheckBack>
        <Check src={check}/>
      </CheckBack>

      <Title>Exito!</Title>

      <SubTitle>
        Cuenta creada exitosamente
      </SubTitle>

      <NavLink to='/login' style={{width: '100%'}}>
        <GoToLoginButton>
          Go To Login
        </GoToLoginButton>
      </NavLink>

    </SignUpSuccessContainer>
  );

};

const SignUpSuccessContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`

const CheckBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #AEB5C1;
  width: 150px;
  height: 150px;
  border-radius: 100px;
`
  const Check = styled.img`
    width: 100px;
  `
const GoToLoginButton = styled.button`
  background-color: #fe7027;
  width: 70%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  font-family: Montserrat;
  border: none;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 50px;
`  
const Title = styled.p`
  font-weight: 600;
  font-size:  25px;
  color: white;
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 20px auto 0px auto;
`
const SubTitle = styled.p`
  color: white;
  font-family: Montserrat;
  font-size:  15px;
  font-weight: 600;
  text-align: left;
  max-width: 350px;
  margin: 10px auto;
`
