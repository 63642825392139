export const vehicles = {
    "Audi": ["A1", "A3", "A4", "A5", "A6", "A7", "A8", "allroad", "Cabriolet", "e-tron", "e-tron GT", "Fox", "Q2", "Q3", "Q3 Sportback", "Q5", "Q5 Sportback", "Q7", "Q8", "Quattro", "R8", "RS e-tron GT", "RS Q3", "RS Q8", "RS2", "RS5", "RS6", "RS7", "S1", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "SQ2", "SQ5", "SQ7", "SQ8", "Super 90", "TT", "TT RS", "TTS", "V8"],
    "BMW": ["1 Series", "1 Series M", "1600", "1602", "1800", "2 Series", "2000", "2002", "2500", "2800", "3 Series", "3.0", "3.3L", "4 Series", "5 Series", "6 Series", "7 Series", "8 Series", "Alpina", "i4", "i8", "Isetta", "iX", "iX3", "M", "M2", "M3", "M4", "M5", "M6", "M8", "X1", "X2", "X3", "X3 M", "X4", "X4 M", "X5", "X5 M", "X6", "X6 M", "X7", "Z3", "Z3 M", "Z4", "Z4 M", "Z8"],
    "Chevrolet": ["Beat", "Blazer", "Camaro", "Captiva", "Cruze", "Onix", "Onix Plus", "Onix RS", "Suburban", "Tahoe", "Tracker", ],
    "Dodge": [
        "Avenger", "Caliber", "Challenger", "Charger", "Coronet", "Custom", "Custom Royal", "D25", "D5N", "D8", "Dakota", "Dart", "DC8", "Durango", "Fargo", "Four", "Journey", "Kingsway", "Lancer", "Luxury Liner", "M37", "Model 30", "Monaco", "Nitro", "Phoenix", "Pickup", "Polara", "Ram", "Route Van", "Six", "Sportsman", "Vintage", "Viper", "W-Series"
    ],
    "Fiat": ["500", "500C", "500X", "Abarth", "Argo", "Pulse", "Uno"],
    "Ford": ["EcoSport", "Edge", "Endura", "Escape", "Escort", "Everest", "Expedition", "Explorer", "F-250", "Fiesta", "Focus", "Fusion", "Galaxy", "Ka", "Kuga", "Maverick", "Mondeo", "Mustang", "Pick Up", "Puma", "Raider", "Ranger", "S-Max", "Shelby", "Taurus", "Territory", "Tourneo Connect", "Tourneo Custom", "Transit", "Transit Custom", "XL"],
    "GAC": ["GS3", "GS4", "GS5", "GS8"],
    "JAC": ["J3", "JS8", "JS2", "JS3", "S2", "S3", "T8", "X200"],
    "GWM": [
        "Steed", "Ute", "Wingle 5", "Wingle 7", "Poer"
    ],
    "Haval": [
        "Dargo", "H2", "H6", "H8", "H9", "Jolion" ],
    "Honda": [
        "Accord", "Accord Euro", "Avancier", "City", "Civic", "CR-V", "CR-Z", "e", "Fit", "HR-V", "Insight", "Integra", "Jazz", "Legend", "MDX", "Mobilio", "N-Box", "NSX", "Odyssey", "Pilot", "S2000", "S660", "Stepwgn", "WR-V"
    ],
    "Hyundai": [
        "Accent", "Atos", "Creta", "Elantra", "Elantra LaVita", "Genesis", "Grandeur", "Grand i10", "i20", "i30", "i40", "i45", "iLoad", "iMax", "IONIQ", "IONIQ 5", "ix35", "Kona", "NEXO", "Palisade", "S Coupe", "Santa Fe", "Sonata", "Staria", "STARIA LOAD", "Terracan", "Tiburon", "Trajet", "Tucson", "Veloster", "Venue"
    ],
    "Jeep": [
        "Cherokee", "Commander", "Compass", "Gladiator", "Grand Cherokee", "Grand Wagoneer",  "Liberty", "Patriot", "Renegade", "Wagoneer", "Wrangler"
    ],
    "Kia": [
        "Carnival", "Cerato", "Ceres", "Credos", "EV6", "Grand Carnival", "Magentis", "Mentor", "Niro", "Optima", "Picanto", "Pregio", "pro_cee'd", "Rio", "Rondo", "Sedona", "Seltos", "Sorento", "Soul", "Spectra", "Sportage", "Stinger", "Stonic"
    ],
    "Lada": [
        "Niva", "Vesta", "Grantia"
    ],
    "Land Rover": [
        "110", "Defender", "Discovery", "Discovery 3", "Discovery 4", "Discovery Sport", "Freelander", "Freelander 2", "Range Rover", "Range Rover Evoque", "Range Rover Sport", "Range Rover Velar", "Range Rover Vogue", "Rover"
    ],
    "Lexus": [
        "CT", "ES", "GS", "GX", "HS", "IS", "LC", "LFA", "LS", "LX", "NX", "RC", "RX", "SC", "UX"
    ],
    "Mazda": [
        "BT 50", "Mazda 2 HB", "Mazda 2 Sedán", "Mazda 3 Sedán", "Mazda 3 HB", "CX-3", "CX-30", "CX-5", "Mazda 6", "CX-9"
    ],
    "Mercedes-Benz": [
        "A-Class", "AMG GT", "B-Class", "C-Class", "CL-Class", "CLA-Class", "CLC-Class", "CLK-Class", "CLS-Class", "E-Class", "EQA", "EQC", "G-Class", "GL-Class", "GLA-Class", "GLB-Class", "GLC-Class", "GLE-Class", "GLS-Class", "M-Class", "Marco Polo ACTIVITY", "Marco Polo HORIZON", "MB100", "MB140", "O 319", "R-Class", "S-Class", "SL-Class", "SLC-Class", "SLK-Class", "SLR McLaren", "SLS-Class", "Sprinter", "SSK", "V-Class", "Valente", "Viano", "Vito", "Vito Tourer", "X-Class"
    ],
    "MG": [
        "HS", "ZS", "ZX", "MG3","MG5", "RX5"
    ],
    "Mitsubishi": [
        "ASX", "Eclipse Cross", "L200", "Mirage", "Mirage G4","Montero", "Montero Sport", "Outlander", "Space Star", "XPander"
    ],
    "Nissan": [
        "Frontier", "Juke", "Kicks", "March", "Murano", "Pathfinder", "Patrol", "Qashqai", "Rogue", "Sentra", "Sentra NISMO", "Tiida", "Versa", "X-Trail", 
    ],
    "Peugeot": [
        "108", "2008", "208", "3008", "308", "5008", "508", "Boxer"
    ],
    "RAM": [
        "700"
    ],
    "Renault": [
        "KWID", "LOGAN", "SANDERO", "STEPWAY", "DUSTER", "DUSTER OROCH", "CAPTUR", "KOLEOS", "OROCH", "KANGOO", "SYMBOL"
    ],
    "SsangYong": [
        "Actyon", "Actyon Sports", "Chairman", "Korando", "Kyron", "Musso", "Rexton", "Stavic", "Tivoli", "Tivoli XLV"
    ],
    "Subaru": [
        "BRZ", "Forester", "Impreza", "L Series", "Legacy", "Levorg", "Liberty", "Outback", "Sambar", "Sherpa", "Sportswagon", "SVX", "Tribeca", "Vortex", "WRX", "XV"
    ],
    "Suzuki": [
        "Alto", "Baleno", "Carry", "Celerio", "Ciaz", "Dzire", "Ertiga", "Grand Vitara", "Hustler", "Ignis", "Jimny", "Kizashi", "S-Cross", "Sierra", "Spacia", "S-Presso", "Swift", "SX4", "Vitara", "XL-7"
    ],
    "Tata": [
        "Safari", "Super Ace", "Telcoline", "Xenon"
    ],
    "Toyota": [
        "4Runner", "86", "Agya", "Alphard", "Altezza", "Aristo", "Aurion", "Avalon", "Avensis Verso", "BB", "BJT", "Blade", "Blizzard", "C-HR", "Caldina", "Camry", "Camry Vienta", "Carina", "Cavalier", "Celica", "Celsior", "Century", "Chaser", "Corolla", "Corolla II", "Corona", "Corona Avante", "Corsa", "Cressida", "Cresta", "Crown", "Cynos", "Echo", "Esquire", "Estima", "FJ Cruiser", "Fortuner", "Grand Hiace", "Granvia", "Harrier", "Hiace", "Hiace SBV", "Hilux", "Hilux 4Runner", "Hilux Surf", "Highlander", "IQ", "Kluger", "Landcruiser", "Landcruiser Prado", "Lexcen", "Liteace", "Mark II", "Mark X", "Masterace", "Mirai", "MR2", "Noah", "Paseo", "Picnic", "Porte", "Prius", "Prius C", "Prius V", "Progres", "Publica", "Ractis", "RAV4", "Regius", "Rush", "Rukus", "Sai", "Sera", "Sequoia", "Sienta", "Soarer", "Spacia", "Spade", "Sprinter", "Starlet", "Stout", "Supra", "T-18", "Tacoma", "Tarago", "Tercel", "Tiara", "Townace", "Tundra", "Vanguard", "Vellfire", "Verossa", "Vienta", "Voxy", "Windom", "Yaris", "Yaris Cross"
    ],
    "Volkswagen": [
        "Beetle", "Caddy", "Caravelle", "CC", "Country", "Crafter", "Eos", "Gol", "Golf", "Iltis", "Jetta", "Karmann Ghia", "Kombi", "Kombi Transporter", "LT", "Lupo", "Microbus", "Multivan", "Passat", "Phaeton", "Polo", "Rabbit", "Scirocco", "Sharan", "Superbug", "T-Cross", "T-Roc", "Tiguan", "Touareg", "Touran", "Transporter", "up!", "Vento"
    ],
    "Volvo": [
        "XC40", "XC60", "XC90"
    ],
    
}