import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { displaySidebar } from '../../app/AppUISlice'
import useViewport from '../../hooks/useViewport'


import ViewItem from '../../components/sidebar/sidebarViewItem'

import DesktopMenu from '../desktopMenu/desktopMenu'
import Notificactions from '../notifications/notifications'
//import SocialMedia from './socialMedia'

import menuIconLigth from '../../assets/icons/ui/menu_ligth.png';
import menuIconDark from '../../assets/icons/ui/menu_dark.png';
import logo_ligth from '../../assets/icons/ui/logo_ligth.png'
import logo_dark from '../../assets/icons/ui/logo_dark.png'
import userDefault from '../../assets/icons/ui/user_default.png'
import { NavLink } from 'react-router-dom'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Header() {
  const dispatch = useDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const { views, activeView } = useSelector(
    (state: TrootState) => state.appUI
  )

  const user = useAppSelector((state: TrootState) => state.user)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  return(
    <HeaderElement
      isMobile={isMobile}
      style={{zIndex: 997}}
      className='Header'
    >
      <LeftSide>
        <NavLink to='/'>
          <Logo
            src={ activeView.theme === 'ligth' ? logo_ligth : logo_dark}
            isMobile={isSD || isMobile}
          />
        </NavLink>
      </LeftSide>

      <Center>
        {
          !isMobile &&
          <ListViews className='ListViews'>
            {
              views.map((view, idx) => (
                <ViewItem
                  key={`sideView${idx}`}
                  theme={activeView.theme}
                  view={view}
                />
              ))
            }
            {/* <SocialMedia theme={activeView.theme}/> */}
          </ListViews>
        }
      </Center>

      {
        !isMobile &&
        <RightSide>

          { user.data ?
            <>
              
              <NavLink to='/micuenta' style={{textDecoration: 'none'}}>
                <UserThumb>
                  { user.data.given_name && user.data.given_name.split(' ')[0] }
                  <UserIcon src={user.data.profile_image ? user.data.profile_image : userDefault} />
                </UserThumb>
              </NavLink>

              <Notificactions/>
            </>
          : 
            <NavLink to='/login' style={{textDecoration: 'none'}}>
              <Ingresar>
                Ingresar
              </Ingresar>
            </NavLink>
          }

          <DesktopMenu/>

        </RightSide>
      }

      {
        isMobile && <RightSideMobile
          onClick={() => { dispatch(displaySidebar()) }}
          src={ activeView.theme === 'ligth' ? menuIconLigth : menuIconDark} 
        />
      }

    </HeaderElement>
  );
};

interface TheaderElement { isMobile: boolean }
const HeaderElement = styled.div<TheaderElement>`
  background-color: #30302C;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* position: absolute;
  top: 0px;
  left: 0px;
  right: 0px; */
  height: 100px;
  height: ${(args) => args.isMobile ? '70px' : '100px' };
`;

interface Tmobile { isMobile: boolean }
const LeftSide = styled.div`
  background-color: '#000';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
  const Logo = styled.img<Tmobile>`
    width: ${(props) => props.isMobile ? `100px` : `190px` };
    margin-left: ${(props) => props.isMobile ? `30px` : `89px` };
  `

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 90px;
`;

  const ListViews = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  `

const RightSideMobile = styled.img`
  margin-right: 30px;
  width: 55px;
  cursor: pointer;
`;

const Ingresar = styled.div`
  font-family: Montserrat;
  color: white;
  background-color: #FE7027;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 12px;
  border-radius: 5px;
  text-decoration: none;
  
  &:hover {
    background-color: #FF9800;
  }
`
const UserThumb = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  
`
const UserIcon = styled.img`
  border-radius: 30pc;
  width: 30px;
`