import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TinitialState } from "./inspeccionPubliTypes";

const initialState: TinitialState = {
  pagarInspeccion: undefined,
  conSonidoMotor: undefined,
  pagoId: undefined,
  tipo: 'por agendar',
  data: {
    publicacionId: undefined,
    marca: undefined,
    modelo: undefined,
    year: undefined,
    fecha: undefined,
    hora: undefined,
    direccion: undefined,
  }
}

const inspeccionPublicacionSlice = createSlice({
  name: 'inspeccionPublicacion',
  initialState,
  reducers: {
    setPagarInspeccion(state, action: PayloadAction<{ publicacionId: string, marca: string, modelo: string }>) {
      state.pagarInspeccion = true
      state.tipo = 'por agendar'
      state.data.publicacionId = action.payload.publicacionId
      state.data.marca = action.payload.marca
      state.data.modelo = action.payload.modelo
    },
    setConSonidoMotor(state, action: PayloadAction<boolean>) {
      state.conSonidoMotor = action.payload
    },
    cancelPagoInspeccion(state) {
      state.pagarInspeccion = undefined
      state.data.publicacionId = undefined
    },
    setPagoId(state, action: PayloadAction<string>) {
      state.pagoId = action.payload
    },
    setPorAgendar(state) {
      state.tipo = 'por agendar'
    },
    setYoLoAgendo(state) {
      state.tipo = 'agendado'
    },
    setInspeccionMarca(state, action: PayloadAction<string>) {
      state.data.marca =  action.payload
    },
    setInspeccionModelo(state, action: PayloadAction<string>) {
      state.data.modelo = action.payload
    },
    setInspeccionYear(state, action: PayloadAction<number>) {
      state.data.year = action.payload
    },
    setInspeccionFecha(state, action: PayloadAction<number>) {
      state.data.fecha = action.payload
    },
    setInspeccionHora(state, action: PayloadAction<string>) {
      state.data.hora = action.payload
    },
    setInspeccionDireccion(state, action: PayloadAction<string>) {
      state.data.direccion = action.payload
    },
    setResetInspeccion(state) {
      state.pagarInspeccion = initialState.pagarInspeccion
      state.conSonidoMotor = initialState.conSonidoMotor
      state.pagoId = initialState.pagoId
      state.tipo = initialState.tipo
      state.data = { ...initialState.data }
    }
  }
})


export const {
  setPagarInspeccion,
  setConSonidoMotor,
  cancelPagoInspeccion,
  setPagoId,
  setPorAgendar,
  setYoLoAgendo,
  setInspeccionFecha,
  setInspeccionHora,
  setInspeccionDireccion,
  setInspeccionMarca,
  setInspeccionModelo,
  setInspeccionYear,
  setResetInspeccion
} = inspeccionPublicacionSlice.actions

export default inspeccionPublicacionSlice.reducer