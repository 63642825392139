import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './font-family.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from "react-router-dom"

import ConfigContext from "./context/configContext"
import  config  from "./config/config"

import { Provider } from 'react-redux'
import store from './app/store'

import ViewportProvider from './context/viewportContext'
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ConfigContext.Provider value={config}>
				<ViewportProvider>
					<Provider store={store}>
						<GoogleOAuthProvider 
							clientId="364199719348-dv24vq9n50cbbeb2p2h86jdf16dphrm7.apps.googleusercontent.com"
						>
							<App />
						</GoogleOAuthProvider>
					</Provider>
				</ViewportProvider>
			</ConfigContext.Provider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
