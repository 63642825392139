import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TpublicacionItem, Tpublicaciones } from "./publicacionesApiTypes"
import { TinisitalState } from "./types"

const initialState:TinisitalState = {
  items: [],
  state: 'initial' 
}

const publicacioneSlice = createSlice({
  name: 'publicaciones',
  initialState,
  reducers: {
    loadPublicaciones: (state, action: PayloadAction<TpublicacionItem[]>) => {
      state.items = action.payload
      state.state = 'fullfilled'
    },
    loadingItems: (state) => {
      state.state = 'loading'
    }
  }
})

export const {
  loadPublicaciones,
  loadingItems
} = publicacioneSlice.actions

export default publicacioneSlice.reducer