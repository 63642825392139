import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { useAppDispatch } from '../../app/store'
import { displayFilterBar, hiddeFilterBar } from '../../services/filterPublicaciones/filterSlice'

import Modal from '../../components/modal/modal'
import DesktopFilter from './desktopFilter'
import CleanFilters from './StickyFilTer&Sort'
import SortSelect from '../../components/selects/sortSelect'

import controlsIcon from '../../assets/icons/ui/controls.png'
import sortIcon from '../../assets/icons/ui/sort.png'


const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface TstickyFilter { viewTop: number }
export default function StickyFilter(props: TstickyFilter) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport();
  const [containerTop, setContainerTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isSD, setIsSD] = useState(false);
  const [ showModal, setShowModal] = useState<boolean>(false)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const refPosition = useRef<HTMLDivElement>(null)
  const stickyBar = useRef<HTMLDivElement>(null)

  // Scrolling hook
  useLayoutEffect(() => {
    const { current: containerCurrent } = refPosition;

    if (containerCurrent) {
      setContainerTop(containerCurrent.getBoundingClientRect().top);
    }

  }, [bodyTop])

  return(
    <>
    <StickyBar>
      <RefSticky
        ref={refPosition}
      />

      <FilterContainer
        ref={stickyBar}
        style={ containerTop <= 0 ? { position: `fixed`, top: '0px', left: '0px', right: '0px' }
          : { position: 'relative' } 
        }
      >
        <FirstRow>
          <FiltrarButton
            // onClick={() => setShowModal(true)}
            onClick={() => dispatch(displayFilterBar())}
          >
            <FiltrarIcon src={controlsIcon} />
            Filtrar
          </FiltrarButton>

          {/* <OrdenarButton>
            <OrdenarIcon src={sortIcon} />
            Ordenar por
          </OrdenarButton> */}
          <SortSelect/>

        </FirstRow>

        <CleanFilters viewTop={undefined}/>

      </FilterContainer>
    </StickyBar>

    
    { showModal &&
      <Modal
        closeModal={hiddeModal}
      >
        <DesktopFilter/>
      </Modal>
    }
    </>
  )
}

const StickyBar = styled.div`
  background-color: white;
  width: 100%;
  min-height: 61.5px;
  max-height: 61.5px;
  z-index: 997;
`
const RefSticky = styled.div``


const FilterContainer = styled.div`
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  padding: 5px 0px;
  z-index: 10;
`
const FirstRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  padding: 10px 0px;
`
  const FiltrarButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid gray;
    border-radius: 3px;
    width: 150px;
    padding: 5px 0px;
  `
  const FiltrarIcon = styled.img`
    width: 20px;
    margin-right: 5px;
  `

  const OrdenarButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid gray;
    border-radius: 3px;
    width: 150px;
    padding: 5px 0px;
  `
  const OrdenarIcon = styled.img`
    width: 20px;
    margin-right: 5px;
  `
