import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Carousel } from "react-bootstrap"
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useViewport from '../../hooks/useViewport'
import useConfig from '../../hooks/useConfig'
import { useNavigate } from 'react-router-dom'

import Modal from '../modal/modal'
import SolicitarInspeccionModal from '../inspeccionMiPublicacion/solicitarInspeccionModal'
import VendidoRibbon from '../vendidoRibbon/vendidoRibbon'


import noImage from '../../assets/icons/ui/no-image.png'
import loading2 from '../../assets/gifs/loading-car.svg'
import pin from '../../assets/icons/ui/map-pin.png'
import download from '../../assets/icons/mipublicacion/download.png'

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `${date} de ${month}, ${year}`
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function MiPublicacionCard(props: TpublicacionItem) {
  const { app } = useConfig()
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  const [ photos, setPhotos] =  useState<string[]>([])
  const [ showModal, setShowModal] = useState<boolean>(false)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const requiredPhotosUsuario = [
    'exterior_1', 'exterior_2', 'exterior_3', 'exterior_4', 
    'interior_1',
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_asientos_delanteros',
  ]

  const requiredPhotosInspector = [
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_capo_abierto',
  ]

  useEffect(() => {
    const required = props.createdBy === 'usuario' ? requiredPhotosUsuario : requiredPhotosInspector;

    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') || item.match('interior_'))) {
        const fotoKeySplit = item.split('/')
        const fotoKey = fotoKeySplit[fotoKeySplit.length - 1].replace('fotos/', '').replace(/\..*/, '')

        if (required.includes(fotoKey)) {
          return true
        } else
          return false

      } else
        return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)

    setPhotos([...fotosUrls])
  }, [])

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading2}
      visibleByDefault={true}
    />
  ), [])

  function formatPrice(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function downloadFile(fileSrc: string) {
    console.log(fileSrc)
    const image = await fetch(`${fileSrc}?mock=${Date.now()}`)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'Carp10 - Reporte Diagnostico.pdf'
    link.target="_blank"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return(
    <PublicacionCardContainer className='PublicacionCardContainer'>

      { props.vendido && <VendidoRibbon/>}

      { props.publishedAt &&
        <PublishedAt>
          <b> Publicado el: </b> { dateFormater(props.publishedAt) }
        </PublishedAt>
      }

      { props.publishedAt ?
        <Carousel>
          {photos.map((photo, index) => (
            <Carousel.Item
              key={`card-landing-${props.id_publicacion}-${index}`}
            >
              <ImageSection>
                <MyImage
                  className='imageFit'
                  alt="member image"
                  src={photo}
                  width={'100%'}
                  height={'100%'}
                />
                </ImageSection>
            </Carousel.Item>
          ))}
        </Carousel>
        :
        <TerminarPublicacion
          onClick={()=> navigate('/vender')}
        >
          Terminar de publicar
        </TerminarPublicacion>
      }

      <DataSection
        onClick={() => props.publishedAt && 
          navigate(`/micuenta/publicacion/${props.id_publicacion}`
        )}
      >
        <DataFirstRow> {props.year} {props.marca} </DataFirstRow>
        <DataSecondRow>
          { props.version } { props.kilometraje ? `| ${props.kilometraje} km` : '' }
        </DataSecondRow>
        <DataThirdRow> <Price> $us { props.precio } </Price> </DataThirdRow>
      </DataSection>

      <Divisor/>
        <Location>
          <Pin src={pin} />
          { props.ciudad }, Bolivia
        </Location>
      <Divisor/>

      { props.publishedAt && !props.inspeccionado &&
        <Solicitar
          onClick={() => displayModal()}
        >
          Solicitar diagnotico
        </Solicitar>
      }

      { props.inspeccionado &&
        <Informe
          onClick={() => downloadFile(`${app.STATIC_FILES}inspecciones/${props.id_publicacion}/reporte/reporte_diagnostico.pdf`)}
        >
          <InformeIcon src={download}/>
          Descargar reporte PDF del diagnostico
        </Informe>
      }

      { showModal &&
        <Modal closeModal={hiddeModal} >
          <SolicitarInspeccionModal publicacionData={props} closeModal={hiddeModal}/>
        </Modal>
      }

    </PublicacionCardContainer>
  )
}

const PublicacionCardContainer = styled.div`
  width: 300px;
  border: solid 2px #ccc;
  border-radius: 2px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  justify-content: flex-start;
  margin-top: 20px;
  position: relative;
`

const ImageSection = styled.div`
  background-color: #cccccc;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const DataSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 20px;
  cursor: pointer;
`
  const DataFirstRow = styled.div`
    align-self: left;
    text-align: left;
    
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
  `
  const DataSecondRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    margin-top: 0px;
  `
  const DataThirdRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 15px;
  `
  const Price = styled.div`
    color: black;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
  `

const PublishedAt = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  position: absolute;
  top: -25px;
  left: 0px;
`

const Divisor = styled.div`
  width: 100%;
  border-bottom: 2px solid #ccc;
`
const Location = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;  
  margin-right: 10px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
`
  const Pin = styled.img`
    height: 13px;
    width: 13px;
    margin-right: 10px;
  `
const Button = styled.button`
  width: 250px;
  align-self: center;
  border: none;
  height: 70px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 20px;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
`

const TerminarPublicacion = styled(Button)`
  background-color: white;
  color: #FE7027;
  border: 2px solid #FE7027;
  margin: 65px 0px;
`

const Solicitar = styled(Button)`
  background-color: #FE7027;
  color: white;
`
const Informe = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #201E1F;
  color: white;
`
  const InformeIcon = styled.img`
    height: 40px;
    margin-right: 10px;
  `