import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import styles from './Home.module.css'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'

import WelcomeSection from './WelcomeSection'
import CollectionPublicacionesSection from './CollectionsPublicaciones'
import StickyFilterMobile from './StickyFilterMobile'
import DesktopFilter from './desktopFilter'
import FilterBar from '../../components/filterCardMobile/filterCardMobile'
import StickyFiler from './StickyFilTer&Sort'
import SearchBar from './searchBar'

import Footer from '../../components/footer/footer'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

import Modal from '../../components/modal/modal'
import EquipamientoModal from '../miPublicacion/components/equipamientoModal'
import { setFinishPublicar } from '../../services/publicar/publicarSlice'
import { obtenerPublicacion } from '../../services/publicaciones/api'
import { Helmet } from 'react-helmet'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface thisProps { refetch: () => void }
export default function Home (props: thisProps) {
  const dispatch = useAppDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [viewTop, setViewTop] = useState<number>(0)
  const [ showModal, setShowModal] = useState<boolean>(true)
  const [ publicacion, setPublicacion] = useState<TpublicacionItem|undefined>(undefined)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => { 
    setShowModal(false)
    dispatch(setFinishPublicar())
  }

  const publicar = useAppSelector((state: TrootState) => state.publicar)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    if (publicar?.pubEnCurso?.publicacion_id) {
      console.log('mounting equipamioentop modal')
      props.refetch()
      obtenerPublicacion(publicar.pubEnCurso.publicacion_id)
      .then((resp) => {
        if (resp.data.equipamiento) {
          setPublicacion(resp.data)
          setShowModal(true)
        }
      })
    }
  }, [])

  // function scrollHandler(event: React.SyntheticEvent) {
  //   setViewTop(Date.now())
  // }

  return(
    <>
    <Helmet>
      <title>Carp10</title>
      <meta name="description" content="prueba" />
      <meta name="keywords" content="palabra clave 1, palabra clave 2" />
      <meta property="og:title" content="Título de la Página" />
      <meta property="og:description" content="Descripción de la página Carp10" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Título de la Página" />
      <meta name="twitter:description" content="Descripción de la página" />
      <meta name="twitter:image" content="URL_de_la_imagen.png" />
    </Helmet>
    <HomeContainer
      // onScroll={scrollHandler}
    >

      <WelcomeSection/>
      
      <SearchBar/>
      
      { isMobile ?
          <StickyFilterMobile viewTop={viewTop}/> 
        :
        <>
          <DesktopFilter/>
          <StickyFiler viewTop={viewTop}/>
        </>
      }

      <CollectionPublicacionesSection/>
      <Footer/>

      <FilterBar/>

      { showModal && publicacion &&
        <Modal
          closeModal={hiddeModal}
        >
          <EquipamientoModal publicacion={publicacion} closeModal={hiddeModal}/>
        </Modal>
      }

    </HomeContainer>
    </>
  )
}

const HomeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`