import React from 'react'
import styled from 'styled-components'

export default function DiagnosticoTerminosCondiciones() {

  return(
    <DiagnosticoTCContainter>
    <Doc>
      <Titulo> Diangostico - Terminos & Condiciones </Titulo>

      <TextoParrafo> Al solicitar o pagar un diagnostico vehicular a través de nuestra plataforma, usted acepta lo siguientes términos y condiciones. </TextoParrafo>

      <Seccion>
        <TituloSeccion>1.	Tarifas, cancelación y exclusiones:</TituloSeccion>

        <TextoParrafo>
          <Inciso>
            <TituloInciso> {"a)"} </TituloInciso>
            <TextoInciso> En consideración de los servicios de inspección proveídas a tu vehículo, incluyendo la provisión de un reporte de la inspección, acompañado de un audio o video cuando sea el caso (Reporte), aceptas pagar las tarifas aplicables según lo establecido donde el servicio esté disponible para su compra. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"b)"} </TituloInciso>
            <TextoInciso> Carp10 solicita que hagas el mayor esfuerzo de notificar, con no menos de 24 horas de anticipación, si intentas cancelar una inspección agendada. Si cancelas la inspección agendada con 24 horas de anticipación o más, se te hará la devolución del dinero. Si cancelas la inspección con menos de 24 horas, carp10 podrá cobrar una tarifa por cancelación. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"c)"} </TituloInciso>
            <TextoInciso> Las inspecciones ofrecidas por carp10, no desmantelan, ni perturban cualquier estructura, arreglo, ensamble, componente o mecanismo interno/externo del vehículo como parte del proceso de inspección. Las inspecciones realizadas por carp10 requieren que el vehículo a ser inspeccionado sea ubicado en una superficie y nivel seguro con razonable espacio para caminar alrededor del perímetro del vehículo. En el caso de que no se ninguna de estas condiciones no pueda cumplirse, carp10 se reserva el derecho de cancelar la inspección y realizar el reembolso completo del dinero si carp10 fuese notificado con al menos 24 horas de anticipación. Si alguna de estas condiciones no se puede cumplir, y se dio la notificación en menos de 24 horas, Usted  acepta que la inspección procederá según lo agendado; sin embargo, existe la posibilidad que los inspectores de carp10 no puedan completar la inspección del vehículo de acuerdo a estos términos y condiciones. </TextoInciso>
          </Inciso>
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>2. Proceso de inspección:</TituloSeccion>

        <TextoParrafo>
          Seguido del pago completo de un servicio de diagnóstico o inspección, un inspector contactará al dueño del vehículo para acordar una hora para llevar a cabo la inspección y confirmar la ubicación del vehículo.
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>3.	Reporte de la inspección:</TituloSeccion>

        <TextoParrafo>
          <Inciso>
            <TituloInciso> {"a)"} </TituloInciso>
            <TextoInciso> Cualquier evaluación hecha por nuestro inspector de un vehículo es basada en una inspección VISUAL y no-mecánica del vehículo, carp10 ni los inspectores son responsables de cualquier defecto latente que sea descubierto, donde no sea visualmente aparente durante el proceso de inspección. La inspección específicamente no cubre los ítems descritos en el Anexo 1, que forma parte indivisible del presente documento. Ninguna remoción de partes o componentes es llevada a cabo durante el proceso de inspección. Usted es consiente que, si bien los inspectores que provee carp10 utilizan métodos aceptados para la detección de fallas, estos métodos puede que no necesariamente identifiquen todas las fallas del vehículo. Los ítems y/o fallas listados en el reporte (si existiera), no tienen la intención de ser una lista exhaustiva y no debe confiarse como tal. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"b)"} </TituloInciso>
            <TextoInciso> El reporte proporcionado por el inspector de carp10 hacia ti, es realizado únicamente sobre las condiciones actuales, al momento en que es emitido, y es en base a las condiciones del vehículo al momento de la inspección, sujeto a estos términos y condiciones. Es tu responsabilidad  de conducir una inspección visual del vehículo al momento de venderlo, ya que carp10 solo puede aconsejarte sobre las condiciones del vehículo al momento de la inspección. El reporte no es un certificado de circulación ni cuenta como una “inspección técnica vehicular” realizada por la entidad policial oficial, autorizada para dicho efecto. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"c)"} </TituloInciso>
            <TextoInciso> Teniendo en cuenta que la “prueba en marcha” se encuentra incluida como parte de la inspección, es tu responsabilidad asegurarte de que el vehículo cuente con suficiente combustible para realizar esta prueba. Durante las inspecciones realizadas, carp10 no se compromete a realizar pruebas en marcha con vehículos que parezcan, determinado por el inspector y a su entera discreción, no estar condiciones para conducirlo en carretera, o si algún vehículo no tiene la documentación legal correspondiente. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"d)"} </TituloInciso>
            <TextoInciso> Sujeto a las leyes del estado plurinacional de Bolivia, las inspecciones de carp10 no proveen ninguna garantía respecto a la naturaleza o exactitud de cualquier información contenida en el reporte o la aptitud de la información contenida en el reporte para cualquier propósito. </TextoInciso>
          </Inciso>
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>4.	Tus responsabilidades:</TituloSeccion>

        <TextoParrafo>
          <Inciso>
            <TituloInciso> {"a)"} </TituloInciso>
            <TextoInciso> La inspección se llevará a cabo en la ubicación notificada por ti  a carp10. Es tu responsabilidad de llevar al vehículo al lugar donde se hará la inspección a la hora en que se agendó o de procurar de que el dueño o dueña del vehículo tenga su vehículo disponible para hacer la inspección en la ubicación y tiempo agendado. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"b)"} </TituloInciso>
            <TextoInciso> El comprador de la inspección o diagnostico vehicular es responsable de realizar búsquedas de registros públicos en relación al vehículo, por ejemplo, para determinar si el vehículo está sujeto a interés de garantía, el número de chasis, número de motor o número de placa no son auténticos, o cualquier otro asunto. A partir de las inspecciones realizadas, Carp10 no se hace responsable de verificar la veracidad del kilometraje del vehículo indicado en el odómetro del vehículo. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"c)"} </TituloInciso>
            <TextoInciso> Debes proveer al inspector de carp10 de cualquier material de información que tengas o que tengas conocimiento en relación con el vehículo objeto de la inspección. Por ejemplo, el historial de los servicios realizados al vehículo, si el vehículo no está registrado (de no estarlo, el motivo por el cual no esté registrado), cualquier reparo mecánico mayor realizado en el vehículo o si el vehículo ha sufrido algún daño estructural debido a su participación en una colisión. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"d)"} </TituloInciso>
            <TextoInciso> Las inspecciones de carp10 se basan en la divulgación completa de esta información que viene de ti y la precisión de tal información y no será responsable si el reporte es impreciso debido a cualquier información proporcionada por ti haya sido imprecisa. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"e)"} </TituloInciso>
            <TextoInciso> Entiendes que el reporte no es, y no debe ser, considerado como una recomendación de comprar o no un vehículo. La decisión de compra del vehículo es tuya teniendo en cuenta los asuntos que considere pertinentes y teniendo en cuenta sus propias circunstancias y averiguaciones.  </TextoInciso>
          </Inciso>
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>5.	Obligaciones las partes</TituloSeccion>

        <TextoParrafo>
          <Inciso>
            <TituloInciso> {"a)"} </TituloInciso>
            <TextoInciso> Además de lo establecido en esta cláusula, y en la máxima medida permitida por la ley, todos los derechos, recursos, condiciones, garantías implícitas y expresas con respecto a los bienes o servicios proporcionados por las inspecciones de carp10 quedan EXCLUIDOS POR EL PRESENTE DOCUMENTO. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"b)"} </TituloInciso>
            <TextoInciso>
              <TextoParrafo> En la máxima medida permita por la ley, las inspecciones de carp10 solo tendrán obligaciones contigo: </TextoParrafo>
              <TextoParrafo> De conformidad con cualquier garantía, derecho o termino contractual que surja, se cree o esté implícito por aplicación de la ley y que no se pueda excluir SIEMPRE QUE, en la máxima medida permitida por la ley, cualquier responsabilidad de carp10 es limitada, a opción de carp10 para (A) reemplazar o reparar los bienes relevantes, (B) suministrar bienes equivalentes a los bienes relevantes, (C) suministrar los servicios relevantes nuevamente, o (D) pagar el costo de dicho reemplazo, reparación o suministro. </TextoParrafo>
            </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"c)"} </TituloInciso>
            <TextoInciso> Carp10 no se hace responsable de las quejas que surjan o que tengan que ver con tu conexión o acceso al sitio web de carp10, el reporte y cualquier otro servicio relacionado de carp10. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"d)"} </TituloInciso>
            <TextoInciso> Para el propósito de esta sección, “carp10” se refiere tanto al sitio web carp10.com y a la empresa “Caramba S.R.L.”, sus socios, empleados, contratistas y agentes, sea individual o colectivamente. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"e)"} </TituloInciso>
            <TextoInciso> Entiendes de que cualquier falta y/o ítem identificado en el reporte (si existiera) no pretende ser una lista exhaustiva de los mismos.  </TextoInciso>
          </Inciso>
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>6.	Propiedad intelectual:</TituloSeccion>

        <TextoParrafo>
          Todos los derechos de propiedad intelectual en los servicios de carp10 con de exclusiva propiedad de “Caramba S.R.L” y no hay transferencia de derechos de propiedad intelectual en ningún material proporcionado por carp10 hacia ti como parte de la provisión de servicios hacia ti. Para el propósito de esta cláusula, “Derechos de Propiedad Intelectual” significa, en presente y futuro, todos los derechos registrados y no registrados respecto a los derechos de autor, marcas, signos distintivos, diseños o patentes. Carp10 se reserva el derecho de reportar cualquier contenido o dato contenido en él y proporcionar cualquier reporte sujeto a estos términos y condiciones. Nada en esta cláusula operará a limitar o restringir los derechos de carp10 en relación a los datos contenidos en el reporte para otro propósito que no sea proporcionarte un reporte a ti.
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>7.	Terminación:</TituloSeccion>

        <TextoParrafo>
          Cualquiera de las partes estará facultada a terminar este acuerdo por inmediata notificación escrita hacia la otra parte, si una parte incumpliera cualquier termino que no sea capaz de remediar, o si la otra parte incumpliera un término que sea capaz de remediar y la otra parte fallara en subsanar tal incumplimiento con un aviso por escrito de 14 días.
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>8.	Cuestiones razonables fuera de control de carp10:</TituloSeccion>

        <TextoParrafo>
          Carp10 no se responsabiliza por cualquier retraso en desarrollar, o cualquier fracaso en llevar a cabo una inspección a medida tal que ese retraso o fracaso resultara de un evento o circunstancia fuera del control razonable o como causa de fuerza mayor o caso fortuito.
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>9.	Privacidad:</TituloSeccion>

        <TextoParrafo>
          carp10 recolecta, guarda y revela tu información personal (como tu nombre, correo electrónico y detalles del vehículo) de acuerdo con nuestras políticas de privacidad
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>10.	Enmienda:</TituloSeccion>

        <TextoParrafo>
          carp10 puede en cualquier momento modificar o enmendar los términos y condiciones sobre los cuales los servicios de inspección o diagnostico sean proporcionados, brindándote un aviso de 30 días a través de tu correo electrónico designado o mediante la publicación de cualquier cambio en el sitio web de carp10 o en cualquier otro lugar en donde los servicios estén disponibles para la compra, brindando te a ti que puedas terminar este acuerdo con 7 días de anticipación a través de notificación escrita cualquier cambio de material impactara tu uso del servicio.
        </TextoParrafo>
      </Seccion>

      <Seccion>
        <TituloSeccion>Anexo1:</TituloSeccion>
        <TituloSeccion>Automóviles, Jeep, vagonetas y camionetas:</TituloSeccion>

        <TextoParrafo>
          <Inciso>
            <TituloInciso> {"a)"} </TituloInciso>
            <TextoInciso> Cilindros de las ruedas </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"b)"} </TituloInciso>
            <TextoInciso> Montaje del motor </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"c)"} </TituloInciso>
            <TextoInciso> Nivel de emisión de CO2 </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"d)"} </TituloInciso>
            <TextoInciso> Conversores catalíticos </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"e)"} </TituloInciso>
            <TextoInciso> Accesorios adicionales producto de las modificaciones del dueño. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"f)"} </TituloInciso>
            <TextoInciso> Sistemas de cómputo del vehículo como control crucero y cualquier otro sistema de cómputo. </TextoInciso>
          </Inciso>

          <Inciso>
            <TituloInciso> {"g)"} </TituloInciso>
            <TextoInciso> Alineamiento de las llantas. </TextoInciso>
          </Inciso>
        </TextoParrafo>
      </Seccion>
    </Doc>
    </DiagnosticoTCContainter>
  )
}

const DiagnosticoTCContainter = styled.div`
  /* font-family: Montserrat;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  overflow: auto; */

  font-family: Montserrat;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  /* font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  width: 250px;
  text-align: left; */
`

const Doc = styled.div`
  width: min(70%, 800px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
`
const Titulo = styled.p`
  font-size: 28px;
  font-weight: 500;
  //margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 22px;
  }
`

const Seccion = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`

const TituloSeccion = styled.p`
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 20px;
  }
`

const TextoParrafo = styled.p`
  font-size: 16px;
  text-align: left;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 12px;
  }
`
const Inciso = styled.div`
  display: flex;
  @media (max-width: 950px) {
    font-size: 12px;
  }
`

const TituloInciso = styled.div`
  min-width: 20px;
`
const TextoInciso = styled.div``