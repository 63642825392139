import React, { useEffect, useLayoutEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
// import { UserContext } from '../../context/userContext';
import useOnScreen from '../../hooks/useOnScreen';
import WebSocket from 'isomorphic-ws';
import defaultProfileIcon from '../../assets/icons/chat/avatar.png';
import useConfig from '../../hooks/useConfig';
import styled from 'styled-components';
import { useIsomorphicLayoutEffect } from '../../libs/use-isomorphic-layout-effect/use-isomorphic-layout-effect'

import './Chat.css'
import sendIcon from '../../assets/icons/chat/send.png'
import { TrootState, useAppSelector } from '../../app/store';

import Message from './message';

export type channelData = {
  channelId: string,
  userId: string,
  marca: string,
  id_publicacion: string,
  modelo: string,
  given_name: string,
  family_name: string,
  profile_image: string,
  id_sub: string
}

export type conversation = {
    username: string,
    message: string,
    timestamp: number,
    avatarImg?: string,
};

type chatState = {
    active: boolean,
    channelName: string,
    toSendChat: string,
    messageReceived: string | null,
    conversationObject: conversation[],
    loadedConversation: boolean,
    lastMessageLoaded: number,
    uiStyle: {
        inputRows: number,
        lastKeyPressed: string | null,
    }
};

let daysLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
let todayDate = new Date();

interface Tprops { publicacion: string }
export default function Chat(props: Tprops) {
    const { app } = useConfig()
    //const WS_URL = app.CHAT_BACKEND_URL;
    const WS_URL = "wss://ws.api.carp10.com";
    //const WS_URL = "d-udzhx0v6sb.execute-api.us-east-1.amazonaws.com";

    const [chatState, setChatState] = useState<chatState[]>([])
    const [activeChannel, setActiveChannel] = useState<number>(0);
    const [minimized, setMinimized] = useState<boolean>(false);
    const [statusChat, setStatusChat] = useState('disconnected');
    const [messageReceived, setMessageReceived] = useState('');
    const [requestOldMessages, setRequestOldMessages] = useState<number>(0);
    const [heigthBodyChat, setHeigthBodyChat] = useState<number>(0);

    const userData = useAppSelector((state: TrootState) => state.user)
    // const { userData, setRefreshUserInfo } = useContext(UserContext);

    const ws = useRef<WebSocket | null>(null);
    const inputTextChat = useRef<HTMLTextAreaElement>(null);
    const dummyTopChat = useRef<HTMLDivElement>(null);
    const dummyBottomChat = useRef<HTMLDivElement>(null);
    const bodyChatCont = useRef<HTMLDivElement>(null);
    const bodyChat = useRef<HTMLDivElement>(null);

    const isVisibleBodyChat = useOnScreen(bodyChatCont);
    const isVisibleTopChat = useOnScreen(dummyTopChat);

    function focusInput() {
        const { current } = inputTextChat;
        scrollToBottom();

        if (inputTextChat.current) {
            inputTextChat.current.focus();
        }
    }

    function scrollToBottom() {
        const { current } = dummyBottomChat;

        if (current && bodyChat.current && dummyTopChat.current && bodyChatCont.current) {
            if (dummyTopChat.current.getBoundingClientRect().top === bodyChatCont.current.getBoundingClientRect().top) {

                const scrollTo = bodyChat.current.getBoundingClientRect().height - heigthBodyChat;
                if (bodyChatCont.current) {
                    bodyChatCont.current.scrollTo(0, scrollTo);
                }

                setHeigthBodyChat(bodyChat.current.getBoundingClientRect().height);

            } else {
                current.scrollIntoView({ behavior: "auto" });
            }
        }
    }

    useIsomorphicLayoutEffect(() => {
        if (isVisibleBodyChat) {
            scrollToBottom();
        }

    }, [isVisibleBodyChat])

    useIsomorphicLayoutEffect(() => {
        if (minimized === false) {
            focusInput();
            scrollToBottom();
        }

    }, [minimized])

    useIsomorphicLayoutEffect(() => {
        scrollToBottom();
    }, [activeChannel]);

    // Attaching Listenners Event --------------------------------------------
    useEffect(() => {
        if (statusChat === 'disconnected' && !ws.current) {
            wsStablishConnection()
        }
    }, []);

    function wsStablishConnection() {
        //console.log('connecting websocket');
        ws.current = new WebSocket(WS_URL);
        //setStatusChat('connecting');

        ws.current.onopen = () => { setStatusChat('connected'); };
        ws.current.onclose = () => { setStatusChat('disconnected'); wsStablishConnection(); };
        ws.current.onmessage = (data: WebSocket.MessageEvent) => { setMessageReceived(data.data.toString()); };
        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }

    // Loading Conversation for each Channel --------------------------------
    useIsomorphicLayoutEffect(() => {
        scrollToBottom();

        if (statusChat === 'connected') {
            chatState.forEach((channel, index) => {
                if (channel.loadedConversation === false) {
                    let newStateChats = [...chatState];

                    if (ws.current) {
                        ws.current.send(JSON.stringify({
                            "action": "loadChannelMessages",
                            "channel": channel.channelName,
                            "lastMessageLoaded": 0,
                        }));
                    }

                    newStateChats[index]['loadedConversation'] = true;
                    setChatState(newStateChats);
                }
            })
        }

    }, [statusChat, chatState])

    // Loading News and yet subscribed channels
    useIsomorphicLayoutEffect(() => {
        if (userData.data) {
            const chatChannel = `${userData.data?.id_sub}.${props.publicacion}`

            let currentChats = [...chatState].map((channel) => { return channel.channelName });

            // if (JSON.stringify(userData.data.channels) !== JSON.stringify(currentChats)) {
            if (JSON.stringify([chatChannel]) !== JSON.stringify(currentChats)) {

                // const newChats = userData.data.channels.filter((channel) => { return !currentChats.includes(channel) });
                const newChats = [chatChannel].filter((channel) => { return !currentChats.includes(channel) });
                const toPushChat = newChats.map((newChat) => {
                    return {
                        active: false,
                        channelName: newChat,
                        toSendChat: '',
                        messageReceived: null,
                        conversationObject: [],
                        loadedConversation: false,
                        lastMessageLoaded: 0,
                        uiStyle: {
                            inputRows: 1,
                            lastKeyPressed: null
                        }
                    }
                });

                setChatState([...chatState, ...toPushChat]);
            }
        }

    }, [userData])

    // ---------- Mesage received event

    useIsomorphicLayoutEffect(() => {
        try {
            if (messageReceived !== '') {
                let newMessage = JSON.parse(messageReceived);
                const wichChannel = chatState.map((channel, idx) => { return channel.channelName === newMessage.channel })
                const channelIdx = wichChannel.findIndex((item) => item === true);

                let newStateChats = [...chatState];

                if (newMessage.action && newMessage.action === 'loadChannelMessages') {
                    if (newMessage.data.count !== 0) {
                        let messages = [
                            ...newStateChats[channelIdx]['conversationObject'],
                            ...newMessage.data.messages
                        ];


                        messages.sort(function (x, y) {
                            return x.timestamp - y.timestamp;
                        })

                        newStateChats[channelIdx]['conversationObject'] = messages;
                        newStateChats[channelIdx].lastMessageLoaded = messages[0].timestamp;

                        if (newMessage.data.messages.length < 15) {
                            newStateChats[channelIdx].lastMessageLoaded = 99;
                        }
                    }

                } else {
                    newStateChats[channelIdx]['conversationObject'] = [
                        ...newStateChats[channelIdx]['conversationObject'],
                        newMessage
                    ];
                }

                setChatState(newStateChats);
            }
        } catch (err) {
            console.log('Wrong format on message received', messageReceived);
        }

    }, [messageReceived])

    // ----------      Loading old messages

    useIsomorphicLayoutEffect(() => {
        if (bodyChatCont.current) {
            bodyChatCont.current.addEventListener('scroll', loadMessages);
        }

        return () => {
            if (bodyChatCont.current) {
                bodyChatCont.current.removeEventListener('scroll', loadMessages)
            }
        }

    }, [])

    function loadMessages(this: HTMLElement, ev: Event) {
        if (dummyTopChat.current && bodyChatCont.current && bodyChat.current) {
            if (dummyTopChat.current.getBoundingClientRect().top === bodyChatCont.current.getBoundingClientRect().top) {
                if (bodyChat.current.getBoundingClientRect().height > bodyChatCont.current.getBoundingClientRect().height) {
                    setRequestOldMessages(Date.now());
                }
            }
        }
    }

    useIsomorphicLayoutEffect(() => {
        if (requestOldMessages !== 0 && ws.current) {
            if (chatState[activeChannel].lastMessageLoaded !== 99) {
                ws.current.send(JSON.stringify({
                    "action": "loadChannelMessages",
                    "channel": chatState[activeChannel].channelName,
                    "lastMessageLoaded": chatState[activeChannel].lastMessageLoaded,
                }));
            }
        }
    }, [requestOldMessages]);

    // ------------- Send Message

    function sendMessage(channelIdx: number) {
        let newStateChats = [...chatState];

        if (newStateChats[channelIdx] && newStateChats[channelIdx].toSendChat && newStateChats[channelIdx].toSendChat.length) {

            if (ws.current) {
                ws.current.send(JSON.stringify({
                    "action": "sendmessage",
                    "channel": newStateChats[channelIdx].channelName,
                    "message": newStateChats[channelIdx].toSendChat,
                }));
            }

            newStateChats[channelIdx]['uiStyle'].inputRows = 1;
            newStateChats[channelIdx]['conversationObject'] = [
                ...newStateChats[channelIdx]['conversationObject'],
                {
                    username: userData.data?.id_sub ? userData.data.id_sub : '',
                    message: newStateChats[channelIdx].toSendChat.trim(),
                    avatarImg: userData.data?.profile_image ? userData.data.profile_image : defaultProfileIcon,
                    timestamp: Date.now()
                }
            ];

            newStateChats[channelIdx]['toSendChat'] = '';
            setChatState(newStateChats);
        }
    }

    // ----------------  Input UIStyles
    function inputTextChanged(event: React.SyntheticEvent, channelIdx: number) {
        let newStateChats = [...chatState];
        const target = event.target as typeof event.target & {
            value: string
        }

        const e = event as typeof event & {
            key: string,
            shiftKey: boolean
        }

        const rows = Math.ceil(target.value.length / 27);
        const linebreak = target.value.match(/\n/g)
        const emptyLine = newStateChats[channelIdx]['toSendChat'].trim().length === 0;

        let totalRows = rows;
        if (linebreak?.length && !emptyLine) {
            totalRows += linebreak.length;
        }

        const valueKey = !emptyLine || (emptyLine && !linebreak) ? target.value : ''
        newStateChats[channelIdx]['uiStyle'].inputRows = totalRows === 0 ? 1 : totalRows;
        newStateChats[channelIdx]['toSendChat'] = valueKey

        setChatState(newStateChats);
    }

    function lookForKeyPressed(event: React.SyntheticEvent, channelIdx: number) {
        let newStateChats = [...chatState];

        const e = event as typeof event & {
            key: string,
            shiftKey: boolean
        }

        if (e.key === 'Enter' && !e.shiftKey && newStateChats[channelIdx]['toSendChat'].trim().length > 0) {
            sendMessage(channelIdx)
        }


        if (e.key !== "Enter" || newStateChats[channelIdx]['toSendChat'].trim().length > 0) {
            newStateChats[channelIdx]['uiStyle'].lastKeyPressed = e.key;
            setChatState(newStateChats);
        }
    }


    function buildMessages(messages: any) {
        let dateNameListed:Array<string> = [];
        let mapedMessages = messages.map((message: any, index: number) => {
            let date = new Date(message.timestamp);
            let dateFormated = date.toLocaleString(undefined, {timeZone: 'UTC'}).split(" ")[0];
            
            if(dateNameListed.includes(dateFormated)){
                return (
                    <Message {...message}
                        key={`message-${message.username}-${message.timestamp}`}
                    />
                )
            }
            else{
                dateNameListed.push(dateFormated);
                let isToday = todayDate.toLocaleString(undefined, {timeZone: 'UTC'}).split(" ")[0] === dateFormated;
                let dayLabel = daysLabels[date.getDay()]
                return (
                    <div key={`message-${message.username}-${message.timestamp}`}>
                        <div className='channels-date-line'>
                            <div className='channels-date-line-line'/>
                            <p className='channels-date-line-day'>{isToday? "Today": dayLabel}</p>
                            <div className='channels-date-line-line'/>
                        </div>
                        <Message {...message}/>
                    </div>
                )
            }
            
        })
        return mapedMessages;
    }

  return (
    <ChatOffset>
      <ChatContainer>

        <ChatHeader className={'channels-chat-header'}
          onClick={(event) => {
            const target = event.target as Element
            const { id } = target;
            if (!id.match('channels-tab')) {
              setMinimized(!minimized)
            }
          }}
        >

          <div className='channels-chat-title'>
            <Title> Chat (Test) </Title>
            <div
              className={
                statusChat === 'disconnected' ? 'channels-chat-statusConnection channels-chat-disconnected' :
                statusChat === 'connecting' ? 'channels-chat-statusConnection channels-chat-connecting' : 'channels-chat-statusConnection channels-chat-connected'
              }
            >
            </div>
          </div>
          {/* 
          <div className='channels-chat-tabs'>

              {chatState.map((channel, index) => (
                  <div
                      key={`tab${index}`}
                      id={`channels-tab-${channel.channelName}`}
                      className={index === activeChannel ? 'channels-chat-tab channels-chat-tab-active' : 'channels-chat-tab'}
                      onClick={(event) => {
                          setActiveChannel(index);
                          focusInput();
                          if (activeChannel === index || minimized === true) {
                              setMinimized(!minimized);
                          }
                      }}

                  >
                      <p className='channels-chat-channelName-title'
                          id={`channels-tab-${channel.channelName}-name`}
                      >
                          {channel.channelName}
                      </p>
                  </div>
              ))}
          </div> */}

        </ChatHeader>

        {/* <ChatBody className={minimized ? 'channels-hidden' : 'channels-chat-body'} */}
        <ChatBody
          ref={bodyChatCont}
          className={minimized ? 'channels-hidden' : 'channels-chat-body'}
        >
          <div ref={bodyChat}>
            <div style={{ height: '10px' }} ref={dummyTopChat} ></div>

            {
              chatState.length > 0 && buildMessages(chatState[activeChannel].conversationObject)
            }

            <div style={{ height: '10px' }} ref={dummyBottomChat} ></div>
          </div>
        </ChatBody>

        <div className={minimized ? 'channels-hidden' : 'channels-chat-input'}>
          {/* <div className='channels-chat-nftbutton'>
              <p>NFT</p>
          </div> */}

          <textarea
            ref={inputTextChat}
            className='channels-chat-textbox'
            cols={25}
            // rows={inputRows}
            rows={chatState.length > 0 && chatState[activeChannel].uiStyle ? chatState[activeChannel].uiStyle.inputRows : 1}
            onChange={(event) => { inputTextChanged(event, activeChannel) }}
            onKeyDown={(event) => { lookForKeyPressed(event, activeChannel) }}
            value={chatState.length > 0 && chatState[activeChannel].toSendChat && chatState[activeChannel].toSendChat.length ? chatState[activeChannel].toSendChat : ''}
          >
          </textarea>

          <img
            className='channels-chat-send'
            src={sendIcon}
            height={20}
            onClick={() => { sendMessage(activeChannel) }}
          />
        </div>

        <div className='channels-chat-footer'>

        </div>

      </ChatContainer>
    </ChatOffset>
  );
}

const ChatOffset = styled.div`
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  bottom: 0px;
  right: 0px;
`

const ChatContainer = styled.div`
  width: 350px;
  margin-right: 5px;
  background-color: #F5F5F5;
  border-radius: 20px;
`
const ChatHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
  cursor: pointer;
  background-color: #F0F0F0;
  border-radius: 10px 20px 0px 0px;
`
  const Title = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    margin-left: 40px;
  `
const ChatBody = styled.div`
  
  
  background-color: #FBF9FF;
  background-color: #F5F5F5;
  overflow-y: scroll;
`