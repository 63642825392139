import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import Modal from '../../components/modal/modal'
import AuthModal from '../../components/modal/modalAuth'

import checkWithBox from '../../assets/icons/ui/checkWithBox.png'
import { TrootState, useAppSelector } from '../../app/store'
import { useNavigate } from 'react-router-dom'

import whatsappIcon from '../../assets/icons/social/whatsapp.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function InfoInspeccion() {
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const user = useAppSelector((state: TrootState) =>  state.user)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <InfoInspeccionContainer
      isMobile={isMobile}
      className='view InfoInspeccionContainer'
    >
      <Content>

      <Title isMobile={isMobile}>
        Diagnóstico vehicular móvil<br/>
      </Title>
      {/*
      <SubTitle>
        { '(Precio: 280 Bs. )' } <br/>
      </SubTitle>
      */}
      <SubTitle2>
        { 'Precio desde:  300 Bs.'}
      </SubTitle2>

      <BeneficiosTitle>
        Beneficios
      </BeneficiosTitle>

      <BeneficiosContainer isMobile={isMobile}>
        <Column isMobile={isMobile}>
          <strong>Un mecánico irá donde se encuentre el vehículo</strong> que quieras que diagnostiquemos.<br/><br/>
        </Column>

        <Column isMobile={isMobile}>
          Con este diagnóstico <strong>conocerás el verdadero estado del vehículo</strong> ¡Antes de decidir comprarlo!<br/><br/>
        </Column>
        {/*
        <Column isMobile={isMobile}>
          Si tu vehículo está publicado a la venta en carp10, se mostrará el <strong>resultado del diagnóstico en tu publicación</strong> (Si asi lo deseas).
        </Column>
        */}

        <Column isMobile={isMobile}>
          Luego de realizado el diagnóstico se te enviará el <strong>reporte en PDF a tu whatsapp</strong>. Para que pueda ser revisado por ti y por tu mecánico de confianza.
        </Column>
      </BeneficiosContainer>

      <BeneficiosTitle>
        Incluye
      </BeneficiosTitle>

      <IncluyeContainer isMobile={isMobile}>
        <Item> <Icon src={checkWithBox}/> Escaneo de los sensores del vehículo con Scanner profesional de alta gama</Item>
        <Item> <Icon src={checkWithBox}/> Revisión del Kilometraje real (que no haya sido modificado)</Item>
        <Item> <Icon src={checkWithBox}/> Revisión visual del motor y demás componentes bajo el capó</Item>
        <Item> <Icon src={checkWithBox}/> Revisión de los atributos del interior</Item>
        {/*<Item> <Icon src={checkWithBox}/> Prueba del vehículo en marcha </Item>*/}
        <Item> <Icon src={checkWithBox}/> Revisión de pintura</Item>
        <Item> <Icon src={checkWithBox}/> Revisión de las llantas</Item>
      </IncluyeContainer>

      <Advice isMobile={isMobile}>
        <strong>Nota: NO desmantelamos</strong>  ninguna pieza del vehículo durante el diagnóstico
      </Advice>

      <SolicitarButton
        onClick={() => {
          if (user.data)
          navigate('/inspeccion/agendar')
          else
          setShowModal(true)
        }}
      >
        Solicitar Diagnostico
      </SolicitarButton>

      <PhoneNumber href="https://wa.me/59176604123" isMobile={isMobile} target="_blank" rel="noopener noreferrer">
      <WhatsappLogo src={whatsappIcon} alt="WhatsApp" /> Mayor información: +591 76604123
      </PhoneNumber>

      { !user.data && showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <AuthModal
            hiddeModal={hiddeModal}
          />
        </Modal>
      }

      </Content>

    </InfoInspeccionContainer>
  )
}

interface isMobile { isMobile: boolean }
interface TinfoInspeccionContainer { isMobile: boolean }
const InfoInspeccionContainer = styled.div<TinfoInspeccionContainer>`
  justify-content: center;
  padding: 20px 0px 20px 0px; 
  width: 100%;
`
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
`
interface Ttitle { isMobile: boolean }
const Title = styled.div<Ttitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 26px;
  color: #FE7027;
  ${(args) => args.isMobile && `
    width: 300px;
    margin: auto;
  `}
`
const SubTitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: grey;
  margin-top: 10px;
  text-decoration: line-through;
`

const SubTitle2 = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin-top: 10px;
`

const BeneficiosTitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #FE7027;
  margin: 30px 10px 0px 10px;
`
interface TbeneficiosContainer { isMobile: boolean }
const BeneficiosContainer = styled.div<TbeneficiosContainer>`
  display: flex;
  display: ${(args) => args.isMobile ? 'flex' : 'grid'};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  grid-template-columns: [first] 300px [line2] 300px;
  grid-template-rows: [row1-start] 100px [row1-end];
  gap: ${(args) => args.isMobile ? '10px' : '50px'};

  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  white-space: pre-wrap;
  text-align: justify;
  margin: auto;
  margin-top: 20px;
  max-width: 1000px;
`
  interface Tcolumn { isMobile: boolean }
  const Column = styled.div<Tcolumn>`
    width: 300px;
    padding: 0px 10px 0px 0px;
    border-radius: 2px;
    box-shadow: 1px 2px 2px 2px grey;
  `
  const Bold = styled.span`
    font-weight: 600;
    margin-left: 0px;
  `

interface TincluyeContainer { isMobile: boolean }
const IncluyeContainer = styled.div<TincluyeContainer>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 20px auto 0px auto;
  ${(args) => args.isMobile && `
    width: 300px;
  `}
`
  const Item = styled.div`
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: 15px;
    font-weight:  500;
    text-align: left;
    margin-top: 10px;
    
  `
  const Icon = styled.img`
    height: 20px;
    margin-right: 10px;
  `
const Advice = styled.div<isMobile>`
  margin: 30px 0px 0px 0px;
  ${(args) => args.isMobile && `
    max-width: 300px;
    margin: 30px auto 0px auto;
  `}
`

const PhoneNumber = styled.a<isMobile>`
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-decoration: none; 
    display: inline-block; 

    &:hover {
        background-color: #f1f1f1;
        color: #555; 
    }

    ${(args) => args.isMobile && `
        font-size: 16px;
        padding: 8px 12px;
    `}
`;

const WhatsappLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 4px;  // o cualquier otro valor de espacio que desees
    vertical-align: middle;  // para alinear el ícono con el texto
`;

  const SolicitarButton = styled.button`
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    width: 200px;
    color: white;
    background-color: #FF925C;
    border: none;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;
    margin: 60px auto 50px auto;
    &:hover {
      background-color: #fe7027;
    }
  `