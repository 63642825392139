import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from "react-router-dom"
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { initSession } from "../../services/auth/userSlice"
import { TsignInStep } from './SignIn'
import { setUIStep } from '../../services/publicar/publicarSlice'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import { googleLogin } from '../../services/auth/userSlice'
import { verifyUserExists } from '../../services/auth/api'
import Modal from '../../components/modal/modal'
import PhoneModal from '../../components/modal/phoneModal'

import loadingGif from '../../assets/gifs/loading.gif'
import { setLogEvent } from '../../components/popUp/popUpSlice'



interface Tlogin { changeStep: (step: TsignInStep) => void }
export default function Login(props: Tlogin) {
  const dispatch = useAppDispatch()
  const { changeStep } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [ step, setStep ] = useState<string>('login')
  const [ errorMsg, setErrorMsg ] = useState<string | null>(null)
  const [ enableLogin, setEnableLogin ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const emailInput = useRef<HTMLInputElement>(null)
  const passInput = useRef<HTMLInputElement>(null)

  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ phone, setPhone ] = useState<string|undefined>(undefined)
  const [ credential, setCrediental ] = useState<string|undefined>(undefined)
  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const userState = useAppSelector((state: TrootState) => state.user)

  useEffect(() => {
    if (userState.status === 'error' && userState.errorMsg) {
      setErrorMsg(userState.errorMsg)
    } else {
      setErrorMsg(null)
    }
  }, [userState])

  useLayoutEffect(() => {
    if (step === 'login') {
      if (emailInput.current && passInput.current) {
        setErrorMsg('');
        setEnableLogin(false)
        emailInput.current.value = '';
        passInput.current.value = '';
      }
    }

  }, [step])

  function inputChange() {
    if (emailInput.current && passInput.current) {
      if (emailInput.current.value !== '' && passInput.current.value !== '')  {
        setEnableLogin(true);
      } else {
        setEnableLogin(false);
      }
    }
  }

  function initLogin(event: React.SyntheticEvent) {
    event.preventDefault()

    setLoading(true)
    if (emailInput.current && passInput.current) {
      dispatch(initSession({
        username: emailInput.current.value,
        password: passInput.current.value
      }))
      .then((result) => {
        setLoading(false)

        if (result.meta.requestStatus === 'fulfilled') {
          if (pathname === '/vender')
            dispatch(setUIStep('fotos'))
          else if (pathname.match('/micuenta')) {
            window.location.reload();
          }
          else if (pathname.match('publicacion/'))
            window.location.reload();
          else if (pathname !== '/inspeccion')
            navigate('/')

        }
      })
      .catch((err) => {
        console.log('err: ',err)
        setLoading(false)
      })
    }
  }

/*
  function googleAuth(props: { google_token: string, phone_number?: string }) {
    dispatch(googleLogin({...props}))
    .then((resp) => {
      navigate('/')
    })
    .catch((error: any) => {
      dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
    })
  }
*/
/*
  useEffect(() => {
    if (phone && credential) {
      googleAuth({ google_token: credential, phone_number: phone })
    }
  }, [phone])
*/
  return(
    <FormContainer>

      <SubTitle>
        Inicia sesión con tu cuenta
      </SubTitle>

      <Form onSubmit={initLogin}>  
      {/* <Form>  */}
        <Input
          ref={emailInput}
          type="text"
          error={errorMsg ? true : false}
          placeholder="User"
          name="email"
          onChange={() => {inputChange()}}
        />

        <Input
          ref={passInput}
          type="password"
          error={errorMsg ? true : false}
          placeholder="Password"
          name="password"
          onChange={() => {inputChange()}}
        />

        { errorMsg &&
          <ErrorMessage
            display={errorMsg ? true : false}
          >
            { errorMsg } 
          </ErrorMessage>
        }

        <ForgotPassword onClick={ () => { changeStep('resetPassword') } }>
          Olvidaste la Contraseña?
        </ForgotPassword>

        <LoginContainer>
          
          <Divisor>
            <Line/>
              o
            <Line/>
          </Divisor>
          
        {/* <GoogleLogin
            width='260'
            locale='es_SP'
            text='continue_with'
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
              if (credentialResponse.credential) {
                const credential = credentialResponse.credential
                const segment = credential.split('.')
                const parsed = JSON.parse(atob(segment[1]))
        
                verifyUserExists(parsed.email)
                .then((resp: any) => {
                  if (resp.data.userExits === true) {
                    googleAuth({ google_token: credential })
                  } else {
                    setShowModal(true)
                    setCrediental(credential)
                  }
                })
              }
            }} 
            onError={() => {
              console.log('Login Failed');
            }}
            useOneTap
          /> */}
        </LoginContainer>

        <LoginButton
          type="submit"
          ready={enableLogin}
          disabled={!enableLogin || loading ? true : false}
        >
          { loading ? '' : 'Log In' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </LoginButton>

      </Form>

      
      <SignUp>
        No tienes una cuenta? 

        <HighLighted
          onClick={() => {
            if (pathname === '/inspeccion')
            changeStep('signup')
            else
            navigate('/registro')
          }}
        >
          Registrate
        </HighLighted>

      </SignUp>

      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <PhoneModal
            hiddeModal={hiddeModal}
            setPhone={setPhone}
          />
        </Modal>
      }

    </FormContainer>
  )
}
const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const SubTitle = styled.p`
  font-size:  25px;
  font-weight: 600;
  text-align: left;
  max-width: 350px;
  margin: 10px auto;
  color: white;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
interface Tinput { error: boolean }
const Input = styled.input<Tinput>`
  width: 90%;
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin: 15px auto 0px auto;
  padding: 5px 5px 5px 15px;
  ${(args) => args.error && `
    border: 1px solid red;
  `};
  &:focus {
    outline: 1px solid black;
  }
`

interface TerrorMessage { display: boolean }
const ErrorMessage = styled.p<TerrorMessage>`
  align-self: flex-end;
  display: none;
  font-weight: 500;
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: right;
  ${(args) => args.display && `
    display: initial;
  `}
`

const ForgotPassword = styled.p`
  align-self: flex-end;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0px 30px 0px;
  cursor: pointer;
  text-align: right;
  color: white;
`
interface TloginButton { ready: boolean }
const LoginButton = styled.button<TloginButton>`
  width: 100%;
  background-color: #FF925C;
  border-radius: 5px;
  font-size: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  ${(args) => args.ready && `
    background-color: #fe7027;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `
const SignUp = styled.p`
  align-self: flex-end;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-align: right;
  text-decoration: none !important;
  color: white;
`
const HighLighted = styled.span`
  color: #fe7027;
  font-weight: 600;
  margin-left: 5px;
`

const LoginContainer = styled.div`
  align-self: center;
  margin-bottom: 40px;
`

const Divisor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  margin-bottom: 10px;
`
const Line = styled.div`
  border-bottom: 1px solid white;
  width: 20px;
  margin: 5px 10px 0px 10px;
`