import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import * as ReactDOMServer from "react-dom/server"
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setUIStep, addFotoToGroup1, addFotoToGroup2, setFinishedStep, clearFotos, updateFotos, deleteFoto } from '../../services/publicar/publicarSlice'
//import { setLogEvent } from '../../components/popUp/popUpSlice'
import { Photo } from '../../services/publicar/publicarTypes'

import { useNavigate } from 'react-router-dom'
import { finishPublicacion, uploadImageAuthorization, uploadImage } from '../../services/publicar/api'
import useViewport from '../../hooks/useViewport'

import Modal from '../../components/modal/modal'
import SuccessModal from './successModal'

import NavigatorComponent from './navigatorComponent'

import backIcon from '../../assets/icons/ui/back_orange.png'
import * as tf from '@tensorflow/tfjs';
import { DragDropContext, Droppable, DroppableProps, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const resp = {
  SD: '1200px',
  Mob: '950px',
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export interface Tfoto {
  status: 'uploaded' | 'loaded' | null,
  prop: string | undefined,
  src: null | Blob
}

type Prediction = {
  imageURL: string;
  prediction1: string;
  prediction2: string | null;
};

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

toast('¡Publicación exitosa!', {
  type: 'success',
  autoClose: 5000
});

export default function VenderFotosMultiples() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ complete, setComplete] = useState(false)
  const [ fotos, setFotos ] = useState<Tfoto[]>([]) 
  const [ currentProp, setCurrentProp ] = useState<string | undefined>(undefined)
  const [ currentSlide, setCurrentSlide ] = useState<number>(0)
  const [ uploading, setUploading ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const publicar = useAppSelector((state: TrootState) => state.publicar)
  const reduxFotos  = useAppSelector((state) => state.publicar.fotos);
  const { fotosGroup1, fotosGroup2 } = useAppSelector((state) => state.publicar.fotos);

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const [predictions, setPredictions] = useState<Photo[]>([]);
  const [model1, setModel1] = useState<tf.LayersModel | null>(null)
  const [model2, setModel2] = useState<tf.LayersModel | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null);


  const classNames1 = ['exterior', 'interior', 'capó abierto', 'llanta', 'otro'];
  const classNames2 = ['foto_frontal', 'foto_diagonal_frontal', 'foto_lateral', 'foto_diagonal_trasera', 'foto_trasera'];

  const orderModel2 = ['foto_frontal', 'foto_diagonal_frontal', 'foto_lateral', 'foto_diagonal_trasera', 'foto_trasera'];

  const [isMounted, setIsMounted] = useState(false);

  const [subiendo, setSubiendo] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);


  useEffect(() => {
    tf.loadLayersModel('https://carp10-modelos.s3.amazonaws.com/cv-model-1/cv-model-1.json').then(model => {
      setModel1(model);
      //console.log("Model 1 loaded successfully");
    });
    tf.loadLayersModel('https://carp10-modelos.s3.amazonaws.com/cv-model-exterior/cv-model-exterior.json').then(model => {
      setModel2(model);
      //console.log("Model 2 loaded successfully");
    });
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
  }, [reduxFotos.fotosGroup1, reduxFotos.fotosGroup2]);
  

  const renderImages = () => {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StrictModeDroppable droppableId="predictions" direction="horizontal">
          {(provided: DroppableProvided) => (
          <ImageHolder2 {...provided.droppableProps} ref={provided.innerRef}>
            {predictions.map((pred, index) => (
              <Draggable key={pred.imageURL} draggableId={pred.imageURL} index={index}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                  <div 
                    ref={provided.innerRef} 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ width: '150px', height: '115px', margin: '10px 0 120px 0', ...provided.draggableProps.style }}
                  >
                    <Image src={pred.imageURL} alt="" isDragging={snapshot.isDragging}/>
                      <div>
                        <p style={{margin: '0'}}>{pred.prediction1}</p>
                        {pred.prediction2 && <p style={{margin: '0'}}>{pred.prediction2}</p>}
                        <button onClick={() => deleteImage(index, pred.name && pred.name.startsWith('exterior') ? 'group1' : 'group2')}>Delete</button>
                      </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ImageHolder2>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  };

  const sortImages = () => {
    // Realiza una copia de las predicciones para no mutar el estado original
    let sortedPredictions = [...predictions];
  
    sortedPredictions.sort((a, b) => {
      if (a.prediction1 === "exterior" && b.prediction1 !== "exterior") {
        return -1;
      } else if (a.prediction1 !== "exterior" && b.prediction1 === "exterior") {
        return 1;
      } else if (a.prediction1 === "exterior" && b.prediction1 === "exterior" && a.prediction2 && b.prediction2) {
        return orderModel2.indexOf(a.prediction2) - orderModel2.indexOf(b.prediction2);
      }
      return 0;
    });
  
    // Actualiza el estado con las predicciones ordenadas
    setPredictions(sortedPredictions);
  };
  
  const deleteImage = (index: number, group: 'group1' | 'group2') => {
    let newImages = [...selectedFiles];
    newImages.splice(index, 1);
    setSelectedFiles(newImages);
  
    let newPredictions = [...predictions];
    newPredictions.splice(index, 1);
    setPredictions(newPredictions);

    dispatch(deleteFoto({ group: group, index: index }));

    //console.log('Size of fotosGroup1 after deletion:', reduxFotos.fotosGroup1.length);
    //console.log('Size of fotosGroup2 after deletion:', reduxFotos.fotosGroup2.length);
  };

  // Esta función se llamará cuando el usuario arrastre y suelte un elemento
  const handleOnDragEnd = (result: DropResult ) => {
    if (!result.destination) return;
    const items = Array.from(predictions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPredictions(items);
  };
  
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));

      const newPredictions = [];
      for (let i = 0; i < event.target.files.length; i++) {
          let file = event.target.files[i];
          const imgURL = URL.createObjectURL(file);
          const img = document.createElement('img') as HTMLImageElement;
          img.src = imgURL;
          await new Promise<void>((resolve) => {
            img.onload = () => resolve();
          });
  
          let tensorImg = tf.browser.fromPixels(img).toFloat();
          tensorImg = tf.image.resizeBilinear(tensorImg, [224, 224]);
          tensorImg = tensorImg.expandDims(0);
          tensorImg = tensorImg.div(255.0);
  
          if (model1) {
            let pred1 = model1.predict(tensorImg);
            if (pred1 instanceof Array){
                pred1 = pred1[0];
            }
            let classIndex1 = pred1.argMax(-1).dataSync()[0];
  
            let prediction2 = null;
            if (classNames1[classIndex1] === "exterior" && model2) {
              let pred2 = model2.predict(tensorImg);
              if (pred2 instanceof Array){
                pred2 = pred2[0];
              }
              let classIndex2 = pred2.argMax(-1).dataSync()[0];
              prediction2 = classNames2[classIndex2];
            }
          
            newPredictions.push({
              imageURL: imgURL,
              prediction1: classNames1[classIndex1],
              prediction2: prediction2,
              name: `${classNames1[classIndex1].replace(/ /g, "_").toLowerCase()}_${i + 1}.jpg`
            });
          }
      }
      setPredictions(newPredictions);
  
      newPredictions.forEach((prediction) => {
        const photo: Photo = {
          imageURL: prediction.imageURL,
          prediction1: prediction.prediction1,
          prediction2: prediction.prediction2,
          name: prediction.name
        };
    
        console.log('Prediction:', prediction.prediction1);
  
        if (prediction.prediction1 === 'exterior' && reduxFotos.fotosGroup1.length < 5) {
          //console.log('Size of fotosGroup1 before dispatch:', reduxFotos.fotosGroup1.length);
          dispatch(addFotoToGroup1(photo));
        } else if (prediction.prediction1 !== 'exterior' && reduxFotos.fotosGroup2.length < 15) {
          //console.log('Size of fotosGroup2 before dispatch:', reduxFotos.fotosGroup2.length);
          dispatch(addFotoToGroup2(photo));
        }
      });
    }
  };

  async function finish() {
    if (reduxFotos.fotosGroup1.length > 0 && reduxFotos.fotosGroup2.length < 20) {
      const newPhotos: Photo[] = [];
      reduxFotos.fotosGroup1.forEach((foto: Photo, index: number) => {
        const fotoCopy = { ...foto };
        fotoCopy.name = `exterior_${index + 1}.jpeg`;
        newPhotos.push(fotoCopy);
      });
  
      reduxFotos.fotosGroup2.forEach((foto: Photo, index: number) => {
        const fotoCopy = { ...foto };
        let predictionName = fotoCopy.prediction1.replace(/ /g, "_").toLowerCase();
        fotoCopy.name = `${predictionName}_${index + 1}.jpeg`;
        newPhotos.push(fotoCopy);
      });

      // Divide newPhotos en dos grupos
      const newGroup1 = newPhotos.filter(foto => foto.prediction1 === 'exterior');
      const newGroup2 = newPhotos.filter(foto => foto.prediction1 !=='exterior');
    
      dispatch(updateFotos({group1: newGroup1, group2: newGroup2}));

      if (publicar.pubEnCurso) {
        try {
          const sortedImages = [...reduxFotos.fotosGroup1, ...reduxFotos.fotosGroup2];

          let exteriorCounter = 1;
          let interiorCounter = 1;
          const filenames = sortedImages.map((img, i) => {
            if (img.prediction1 === 'exterior') {
              return `exterior_${exteriorCounter++}.jpeg`;
            } else {
              return `interior_${interiorCounter++}.jpeg`;
            }
          });
          
          //console.log('Filenames to be uploaded:', filenames);
          //console.log(`Enviando solicitud a la API publicar con publicacion_id: ${publicar.pubEnCurso.publicacion_id} y tipo: finishOferta`);

          const preSignedUrlsResponse = await uploadImageAuthorization({publicacion_id: publicar.pubEnCurso.publicacion_id, filenames});
          if (preSignedUrlsResponse .data.result !== "success" || !preSignedUrlsResponse .data.urls || preSignedUrlsResponse .data.urls.length === 0) {
            throw new Error('No se recibieron las URLs para subir las imágenes');
          }
 
          //console.log('Number of images to be uploaded:', sortedImages.length);
          //console.log('Number of preSignedUrls received:', preSignedUrlsResponse.data.urls.length);

          const uploadPromises = sortedImages.map(async (photo, i) => {
            const response = await fetch(photo.imageURL as string);
            const blob = await response.blob();
            return uploadImage(blob, preSignedUrlsResponse.data.urls[i].url);
          });
          await Promise.all(uploadPromises);

          //console.log('Number of images uploaded:', uploadPromises.length);
          toast.success('Todas las fotos se han subido con éxito');
          
          try {
            const finishPublicacionResp = await finishPublicacion({publicacion_id: publicar.pubEnCurso.publicacion_id}, filenames);
            if (finishPublicacionResp.status === 200) {
              dispatch(clearFotos());
              dispatch(setFinishedStep({step: 3, finish: true}));
              navigate('/');
              //console.log('Publicación finalizada con éxito. El estado de la aplicación debería haberse reiniciado.');
            } else {
                throw new Error('Hubo un error al terminar la publicación');
            }
          }catch(error){
            //console.error("Error al subir las fotos:", error);
            toast.error('Ha ocurrido un error al subir las fotos');
          }
        } catch (error) {
          //console.error("Error al subir las fotos:", error);
          toast.error('Ha ocurrido un error al subir las fotos');
        }
      }else{
        toast.error('No se ha iniciado correctamente la publicación. Por favor, intenta de nuevo ');
      }
    } else {
      toast.error('No se han subido todas las imagenes necesarias');
    }
  };
  
  return(
    <VenderFotosContainer className='view'>
      <Column>
        <NavigatorComponent step={3}/>
        <Title> Sube las fotos de tu vehículo </Title>
      </Column>
      <MainWrapper>
        <MainContent>
          <InputHolder>
            <UploadInput  type="file" id="file" multiple onChange={handleFileChange} />
          </InputHolder>
          <ImageHolder>
            <div>
              {predictions.length > 0 && <button onClick={sortImages}>Ordenar Imagenes</button>}
              {renderImages()}
            </div>
          </ImageHolder>
        </MainContent>
      
      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <SuccessModal/>
        </Modal>
      }
      </MainWrapper>
      <Row>
        <BackButton
          onClick={() => dispatch(setUIStep('precio'))}
        >
          <BackIcon src={backIcon} />
          Volver
        </BackButton>

        <Continue
          onClick={() => {finish()}}
        >
          Finalizar
        </Continue>
      </Row>
    </VenderFotosContainer>
  )
}

const VenderFotosContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  //justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px 20px 0px;
`

const UploadIcon = styled.img`
  height: 150px;
  @media (min-width: ${resp.Mob}){
    height: 200px;
  }
`
const FileUpload = styled.label`
  font-family: Barlow;
  font-weight: 600;
  background: #61dafb;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  width: 240px;
  margin-top: 15px;
  display: none;
`
const MainWrapper = styled.div`
  //height: 300px;
  //flex-grow: 1;
  display: flex;
  flex-flow: column;
  //align-items: center;
  //width: 100%;
`;

const MainContent = styled.div`
  // Estilos para el contenido principal
`;

const InputHolder = styled.div`
  height: 50px;
  
`;

const ImageHolder = styled.div`
  //flex-flow: 1;
`;

const ImageHolder2 = styled.div`
  display: flex;
  //grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  max-width: 750px; 
  //align-items: flex-start;
  //justify-content: space-between;
  //gap: 5px;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const Image = styled.img<{ isDragging: boolean }>`
  //flex: 1 0 auto;
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

const UploadInput = styled.input`
  // Estilos para el input de subida de archivos
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  position: relative;
  bottom: 60px; // ajusta este valor según sea necesario
`
const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const Continue = styled.button`
  margin: 0px 0px 0px 20px;
  background-color: #fe7027;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 160px;
`

const BackButton = styled.button`
  color: #fe7027;
  border: 1px solid #fe7027;
  border-radius: 30px;
  padding: 0px 10px;
  font-weight: 600;

`
const BackIcon = styled.img`
  height: 35px;
`

const Loading = styled.img`
  height: 20px;
`