import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
//import { fetchMorePublicaciones } from '../../services/publicaciones/api'
//import { loadPublicaciones } from '../../services/publicaciones/publicacionesSlice'

import PublicacionCardMobLanding from '../../components/publicacionCard/publicacionCardMobLanding'
import PublicacionCard from '../../components/publicacionCard/publicacionCard'
import { TrootState, useAppSelector } from '../../app/store'
//import { TfilterTipo } from '../../services/filterPublicaciones/types'
import { Button } from 'primereact/button'; 

import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function CollectionPublicacionesSection() {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [page, setPage] = useState(1); // Página actual
  const loadingRef = useRef(null); // Referencia al último elemento
  const dispatch = useDispatch();
  const publicaciones = useAppSelector((state: TrootState) => state.publicaciones)
//  const filters = useAppSelector((state: TrootState) => state.filter.filter); // Importa el selector para obtener los filtros

//  const loadMorePublicaciones = async (page: number, tipos: TfilterTipo[]) => {
//    try {
//      const response = await fetchMorePublicaciones(page, filters.tipos);
//      const newPublicaciones = response.data.items; // Ajusta según la estructura de tu respuesta
//      dispatch(loadPublicaciones(newPublicaciones));
//    } catch (error) {
//      console.error(error);
//    }
//  };
const gridItem = (publicacion: any) => {
  return (
  <PublicacionCard
    key={`publicacion-${publicacion.id_user}-${publicacion.id_publicacion}`}
    {...publicacion}
  />)}

  const gridItemMobile = (publicacion: any) => {
    return (
    <PublicacionCardMobLanding
              {...publicacion}
              key={`publicacion-${publicacion.id_user}-${publicacion.id_publicacion}`}
            />)}

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  {/*
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          // Aquí es donde cargas la siguiente página de datos
          setPage((prevPage) => prevPage + 1);
          loadMorePublicaciones(page, filters.tipos); // Asegúrate de que esta función maneja la paginación
        }
      },
      { threshold: 1 }
    );
    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }
    return () => observer.disconnect();
  }, [page, filters.tipos]); // Observa los cambios en la página

*/}
  return(
    <CollectionPublicacionesContainer
      className='CollectionPublicacionesContainer'
      isMobile={isMobile}
      isSD={isSD}
    >     
      <Centered
        isMobile={isMobile}
        isSD={isSD}
      >
    <ShowingLabel isMobile={isMobile} isSD={isSD}>
      { publicaciones?.items? `${publicaciones.items.length} resultados` : `Cargando`}
    </ShowingLabel>
        { publicaciones && isMobile ?
          <DataView value={publicaciones.items} itemTemplate={gridItemMobile} layout={'grid'} paginator rows={45}/> 
          :<DataView value={publicaciones.items} itemTemplate={gridItem} layout={'grid'} paginator rows={45}/> 
        }
      </Centered>
      

    </CollectionPublicacionesContainer>
  )
}

interface TcollectionsContainer { isMobile: boolean, isSD: boolean }
const CollectionPublicacionesContainer = styled.div<TcollectionsContainer>`
  background-color: #eeeeee;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;;
  flex-flow: row wrap;
  padding: 0px 0px 50px 0px;

  ${(args) => args.isMobile && `
    padding-top: 20px;
    flex-flow: column nowrap;
  `}
  ${(args) => args.isSD && `
    flex-flow: row wrap;
  `}
`
const Centered = styled.div<TcollectionsContainer>`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  min-width: 1330px;
  max-width: 1330px;
  width: 1330px;
  margin: auto;
  ${(args) => args.isSD && `
    min-width: 880px;
    max-width: 880px;
    width: 880px;
  `}
  ${(args) => args.isMobile && `
    min-width: min(430px, 100%);
    max-width:  min(430px, 100%);
    width:  min(430px, 100%);
  `}
`
const ShowingLabel = styled.label<TcollectionsContainer>`
font-family: Montserrat;
font-weight: 600;
font-size: 18px;
color: #fe7027;
border-radius: 5px;
padding: 5px 0px;
width: 80%;
text-align: start;
`