import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useGetAllChannelsQuery } from '../../../services/apiSlice/apiSlice'
import ChatSeller from '../../../components/chatSeller/ChatSeller'
import { channelDataType } from '../../../services/apiSlice/apiTypes'

export default function ChatList() {
  const [ chatSelected, setChatSelected ] = useState<channelDataType|undefined>(undefined)
  const [ channels, setChannels ] = useState<channelDataType[]>([])
  const { data: Channels, isSuccess, refetch } = useGetAllChannelsQuery()

  useEffect(() => {
    console.log(isSuccess)
    if (isSuccess)  {
      const sorted = [...Channels.items]
      .sort((a, b) => {
        if (!a.seller_unread) return 1
        if (!b.seller_unread) return -1

        if (!a.user_last_msg) return -1
        if (!b.user_last_msg) return -1
        
        if (a.user_last_msg === b.user_last_msg) return 0
        
        
        return b.user_last_msg - a.user_last_msg
      })

      setChannels(sorted)
    } 
  }, [isSuccess, Channels])

  return(
    <ChatListContainer>
      <Divisor/>
      <Title> Chats </Title>

      {
        isSuccess && channels.map((channel) => (
          <CustomerChat
            key={`${channel.userId}.${channel.channelId}`}
            unread={channel.seller_unread !== 0}
            selected={chatSelected?.channelId === channel.channelId}
            onClick={() => {
              setChatSelected(channel)
            }}
          >
            { channel.seller_unread !== 0 &&
              <UnReadMessages>
                { channel.seller_unread > 9 ? '9+' : channel.seller_unread }
              </UnReadMessages>
            }

            <CustomerData>
              <CustomerIcon src={channel.profile_image}/>
              <CustomerName> { channel.given_name } </CustomerName>
            </CustomerData>

            <PublicacionData>
              { channel.marca } - { channel.modelo }
            </PublicacionData>
          </CustomerChat>
        ))
      }

      { chatSelected &&
        <ChatSeller
          channel={chatSelected}
          key={`${chatSelected.userId}.${chatSelected.channelId}`}
          reloadChannels={refetch}
        />
      }
    </ChatListContainer>
  )
}

const ChatListContainer = styled.div`
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 50px;
`
const Divisor = styled.div`
  border-top: 2px solid #FFF;
`

const Title = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-align: left;
  padding: 20px 30px 0px 30px;
`
interface CustomerChatProps { unread: boolean, selected: boolean }
const CustomerChat = styled.div<CustomerChatProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 10px;
  padding: 10px 0px 10px 15px;
  background-color: #bbb;
  ${(args) => args.unread && `
    background-color: #FE7027;
  `}
  ${(args) => args.selected && `
    background-color: black;
  `}
`
const CustomerData = styled.div`
  display: flex;
  align-items: center;
`
  const UnReadMessages = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    width: 25px;
    height: 25px;
    border-radius: 20px;
    font-size: 11px;
  `
  const CustomerIcon = styled.img`
    height: 20px;
    margin-right: 10px;
  `
  const CustomerName = styled.div`
    text-transform: capitalize;
  `
const PublicacionData = styled.div`
  margin-left: 10px;
`