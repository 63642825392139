import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
//import { updateEquipamiento } from '../../services/micuenta/api';
import { useUpdateEquipamientoMutation } from '../../../services/apiSlice/apiSlice';

interface thisProps { publicacion: TpublicacionItem, closeModal: () => void  }
export default function EquipamientoModal(props: thisProps) {
  const [edit, setEdit] = useState(false);
  const [equipamiento, setEquipamiento] = useState<string|undefined>(undefined)

  const [ updateEquipamiento, resultUpEquipamiento ] = useUpdateEquipamientoMutation()

  useEffect(() => {
    if (props.publicacion.equipamiento)
    setEquipamiento(props.publicacion.equipamiento)
  }, [])

  function equipamientoChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setEquipamiento(event.target.value)
  }

  useEffect(() => {
    if (resultUpEquipamiento.isSuccess) {
      props.closeModal()
    }
  }, [resultUpEquipamiento])

  return(
    <EquipamientoModalContainer>
      <Title>
        Hemos generado el siguiente equipamiento según la marca, modelo y versión de tu vehículo
      </Title>

      <Title>
        Puedes editarlo si ves algun dato incorrecto
      </Title>

      <SubTitle>
        Equipamiento para un { props.publicacion.marca } { props.publicacion.modelo } modelo {props.publicacion.year} versión { props.publicacion.version }
      </SubTitle>

      <TextArea
        rows={10}
        cols={50}
        disabled={!edit}
        value={equipamiento}
        onChange={equipamientoChange}
      >
        { props.publicacion.equipamiento }
      </TextArea>

      <Botonera>

        <Edit
          onClick={() => setEdit(!edit)}
        >
          Editar
        </Edit>

        <Save
          disabled={!edit}
          onClick={() => {
            if (equipamiento)
              updateEquipamiento({
                equipamientoEdit: equipamiento, publicacionId: props.publicacion.id_publicacion
              })

            // updateEquipamiento(equipamiento, props.publicacion.id_publicacion)
            // .then((resp) => {
            //   props.closeModal()
            // })

              
          }}
        >
          Guardar cambios
        </Save>

      </Botonera>
    </EquipamientoModalContainer>
  )
}

const EquipamientoModalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px 30px;
  @media (max-width: 950px){
    padding: 20px 20px;
  }
`
const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  width: 350px;
  margin-top: 10px;
  @media (max-width: 950px){
    width: 250px;
  }
`

const SubTitle = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  width: 350px;
  margin-top: 10px;
  @media (max-width: 950px){
    width: 250px;
  }
`

const TextArea = styled.textarea`
  @media (max-width: 950px){
    width: 300px;
  }
`

const Botonera = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Buttom = styled.button`
  width: 150px;
  border: none;
  padding: 5px 0px;
  @media (max-width: 950px){
    width: 140px;
  }
`
const Edit = styled(Buttom)`
  background-color: #eeeeee;
`
const Save = styled(Buttom)`
  background-color: #90ee90;
  
`
