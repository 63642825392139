import React, { useState, useEffect, Children } from "react"
import type { FC } from 'react'



interface TLazyImage {
  src: string,
  placeHolder: string | undefined,
  children(src: string | undefined): JSX.Element, 
}

// const useProgressiveImage: FC<TLazyImage> = ({ children, src }) => {  src: string,
const ImageWithPlaceHolder = (props: TLazyImage) => {
  const { children, placeHolder, src} = props;
  const [sourceLoaded, setSourceLoaded] = useState< undefined | string>(placeHolder)

  useEffect(() => {
    const img = new Image()

    if (src) {
      img.src = src
      img.onload = () => setSourceLoaded(src)
    }
  }, [src])

  return(children(sourceLoaded))
}

export default ImageWithPlaceHolder