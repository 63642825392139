import React, { useLayoutEffect, useState } from 'react'
import { useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../app/store'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { TstepPagoInspeccion } from './PagoInspeccion'
import { setConSonidoMotor } from '../../services/pagoInspeccion/inspeccionPubliSlice'

import soundIncon from '../../assets/icons/inspeccion/sound-max.png'
import useViewport from '../../hooks/useViewport'


const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface thisProps { publicacionData: TpublicacionItem, changeStep: (step: TstepPagoInspeccion) => void }
export default function SonidoMotor(props: thisProps) {
  const { publicacionData: pub } = props
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <SonidoMotorContainer
      isMobile={isMobile}
    >
      <Title> <strong> Añade más </strong> a tu diagnostico vehicular </Title>

      <Price> Grabación del sonido del motor (Gratis)</Price>
      

      <Center>
        <Details>
          Una parte fundamental para evaluar el estado general de un vehículo y tomar una decisión informada antes de comprar,
          es escuchar el sonido del motor ya que puede revelar problemas o fallas potenciales
        </Details>

        <SoundIcon src={soundIncon} />
        <SoundCaption> Escucha cualquier sonido irregular </SoundCaption>
      </Center>

      <Bottom
        isMobile={isMobile}
      >
        <AddSonido
          onClick={() => {
            dispatch(setConSonidoMotor(true))
            props.changeStep('facturacion')
          }}
        >
          Añadir al diagnostico
        </AddSonido>

        <Decline
          onClick={() => {
            dispatch(setConSonidoMotor(false))
            props.changeStep('facturacion')
          }}
        >
          No gracias, continuar
        </Decline>
      </Bottom>

    </SonidoMotorContainer>
  )
}

interface isMobile { isMobile: boolean }
const SonidoMotorContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 1100px;
  padding: 40px 0px;
  min-height: 400px;
  ${(args) => args.isMobile && `
      width: 350px;
  `}
`

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 26px;
`

const Price = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
`
const Center = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 10px;
`
  const Details = styled.div`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    max-width: max(60%, 300px);
    text-align: justify;
  `
  const SoundIcon = styled.img`
    width: 50px;
  `
    const SoundCaption = styled.div`
      font-family: Montserrat;
      font-weight: 500;
      font-size: 13px;
    `

const Bottom = styled.div<isMobile>`
  display: flex;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
  `}
`

const Button = styled.button`
  margin-top: 10px;
  border: none;
  
  
  border-radius: 5px;
  padding: 5px 20px;
`
  const AddSonido = styled(Button)`
    background-color: #FE7027;
    color: white;
  `
  const Decline = styled(Button)`
    background-color: transparent;
    text-decoration: underline;
  `
