import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { TupdateMiPublicacion, TsimpleResponse } from './miCuentaApiTypes'


let BACKEND_URL = 'https://api.carp10.com'

export function updateMiPublicacion(payload: TupdateMiPublicacion, publicacionId: string) {

  const options = {
    method: 'PUT',
    withCredentials: true,
    data: JSON.stringify({
      ...payload,
    }),
    url: `${BACKEND_URL}/myaccount/mispublicaciones/${publicacionId}`
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}


export function updateEquipamiento(equipamientoEdit: string, publicacionId: string) {
  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'update_equipamiento',
      equipamiento_edit: equipamientoEdit,
    }),
    url: `${BACKEND_URL}/myaccount/mispublicaciones/${publicacionId}`
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}
