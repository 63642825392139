import React from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'


export default function Equipamiento(props: TpublicacionItem) {
  return(
    <EquipamientoContainer>
      {props.equipamiento && 
      <Text> { props.equipamiento.replace(/-/g, '\n-') }  </Text>
      }
    </EquipamientoContainer>
  )
}

const EquipamientoContainer = styled.div`
  padding: 10px;
  //padding-left: 30px;
  max-height: 300px;
  margin-top: 20px;
  overflow-y: auto;
  @media (max-width: 950px){
    
  }
`

const Text = styled.div`
  font-family: Montserrat;
  text-align: justify;
  white-space:pre-line;
  line-height: 15px;
  @media (max-width: 950px){
    
    max-width: 350px;
  }
`