import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { setSearch } from '../../services/filterPublicaciones/filterSlice'

import glassWhite from '../../assets/icons/ui/glass-white.png'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function SearchBar() {
  const dispatch = useAppDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [searchText, setSearchText] = useState<string>('')

  const filter = useAppSelector((state: TrootState) => state.filter)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function SearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  function Search() {
    console.log(searchText)
    dispatch(setSearch(searchText))
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      dispatch(setSearch(searchText))
    }
  }

  useEffect(() => {
    if (filter.filter.search === undefined)
    setSearchText('')
  }, [filter.filter.search])

  return(
    <SearchBarContainer>
      <SearchContainer isMobile={isMobile}>
        <SearchInput
          placeholder='Busca por marca o modelo'
          isMobile={isMobile}
          value={searchText}
          onChange={SearchChange}
          onKeyDown={handleKeyDown}
        />

        <SearchButton
          isMobile={isMobile}
          onClick={() => Search() }
        >
          
          <SearchIcon src={glassWhite}/>
        </SearchButton>
      </SearchContainer>
    </SearchBarContainer>
  )
}

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
`

interface TsearchContainer { isMobile: boolean }
const SearchContainer = styled.div<TsearchContainer>`
  display: flex;
  flex-flow: ${(args) => args.isMobile ? 'row nowrap' : 'row nowrap'};
  margin: 30px auto 0px auto;
`
  interface isMobile { isMobile: boolean }
  const SearchInput = styled.input<isMobile>`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    border: none;
    width: 500px;
    padding: 10px;
    border: 2px solid #d9d9d9;
    border-radius: 5px 0px 0px 5px;
    ${(args) => args.isMobile && `
      width: 280px;
    `}
  `
  interface TsearchButton { isMobile: boolean }
  const SearchButton = styled.button<TsearchButton>`
    background-color: #fe7027;
    border: none;
    color: white;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    border-radius: 0px 8px 8px 0px;
    height: 45px;
    width: ${ (args) => args.isMobile ? '50px' : '70px' };
    margin: ${(args) => args.isMobile ? '0px auto 0px -4px' : '0px 0px 0px -4px'};
  `
  const SearchIcon = styled.img`
    height: 20px;
  `
const Row = styled.div`
  display: flex; 
  flex-flow: row nowrap;
  width: 60%;
  margin: 0px auto;
`