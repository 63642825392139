import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import loading from '../../assets/icons/logo/logo-carp10.png'
import loadingSpinner from '../../assets/gifs/loadingOrange.gif'
import { TrootState, useAppSelector } from '../../app/store'

export default function LoadingBlock() {
  const user = useAppSelector((state: TrootState) => state.user)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (user.status === 'loading') setShow(true)
    else setShow(false)

  }, [user.status])

  return(
    <>
      { show && 
        <LoadingBlockContainer>
          <SpinnerContainer>
            <SpinnerIcon src={loading} />
            <SpinnerGif src={loadingSpinner} />
          </SpinnerContainer>
        </LoadingBlockContainer>
      }
    </>
  )
}

const LoadingBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000033;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1001;
`
const SpinnerContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: #30302C;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow:
    0 0 60px 10px #333,
    0 0 100px 15px #444,
    0 0 140px 20px #555;
`
  const SpinnerIcon = styled.img`
    position: absolute;
    width: 120px;
    height: 120px;
    left: 0px;
    top: 50%;
    transform: translate( 0%, -50%);
  `
  const SpinnerGif = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 41px;
    left: 59px;
  `