import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { TsimpleResponse } from '../auth/userApiTypes';
import { TcreatePagoResponse, TpresignedResponse  } from './apiTypes';


let BACKEND_URL = 'https://api.carp10.com'

export function pagoInspeccion(payload: any) {
  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'create',
      ...payload
    }),
    url: `${BACKEND_URL}/pago-inspeccion`
  };

  return new Promise<AxiosResponse<TcreatePagoResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function getPresignedUrl(pagoId: string, fileType: string) {
  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'uploadComprobante',
      comprobante_id: pagoId,
      //file_type: 'pdf',
      file_type: fileType,
    }),
    url: `${BACKEND_URL}/pago-inspeccion`
  };

  return new Promise<AxiosResponse<TpresignedResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function uploadComprobante(file: File|Blob, slug: string) {
  return new Promise<TsimpleResponse>((resolve, reject) => {
    fetch(slug, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: file
    })
    .then(data => {
      console.log("Presigned Url: ", 'success');
      //if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve({ result: 'success' })
    })
    .catch((error) => {
      console.log('userInfo error', error)
      reject(error)
    });
  })
}

