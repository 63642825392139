import React, { useEffect, useLayoutEffect, useState } from "react"
import styled from 'styled-components'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
import useViewport from "../../hooks/useViewport"
import {
  setUIStep,
  setMarca,
  setModelo,
  setYear,
  setVersion,
  setTraccion,
  setTransmision,
  setMotorCC,
  setKilometraje,
  setPropietariosAnteriores,
  setCiudad,
  setDeAgencia,
  setPermutable,
  setFinishedStep,
} from "../../services/publicar/publicarSlice"
import { filterOnlyAllowedProps } from "../../libs/utils"
import { datosVehiculos } from "../../services/publicar/publicarTypes"


import { vehicles } from '../../assets/data/VehiclesData2'

import NavigatorComponent from "./navigatorComponent"


const marcas = Object.keys(vehicles)

const year = [
  2010, 2011, 2012, 2013, 2014,
  2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
];

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

const ciudades = [
  'Santa Cruz de la Sierra',
  'Warnes',
  'La Guardia',
  'Trinidad'
]

const motorCCValues = [1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 3000, 3500, 3600, 4000, 5600, 6000]

type ObjectKey = keyof typeof vehicles

export default function VenderDatosVehiculos() {
  const dispatch = useAppDispatch()
  const [complete, setComplete] = useState(false)
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const publicar = useAppSelector((state: TrootState) => state.publicar)

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'pub-marca')
      dispatch(setMarca(event.target.value))

    if (event.target.name === 'pub-modelo')
      dispatch(setModelo(event.target.value))

    if (event.target.name === 'pub-ano') 
      dispatch(setYear(Number(event.target.value)))

    if (event.target.name === 'pub-version')
      dispatch(setVersion(event.target.value))

    if (event.target.name === 'pub-traccion') {
      dispatch(setTraccion(event.target.value as '4x4' | '4x2'))
    }

    if (event.target.name === 'pub-transmision') 
      dispatch(setTransmision(event.target.value as 'automatica' | 'mecanica'))

    if (event.target.name === 'pub-motor_cc') 
      dispatch(setMotorCC(Number(event.target.value)))

    if (event.target.name === 'pub-kilometraje') 
      dispatch(setKilometraje(Number(event.target.value)))

    if (event.target.name === 'pub-propietarios_anteriores') 
      dispatch(setPropietariosAnteriores(event.target.value as 'primero' | 'segundo' | 'tercero'))

    if (event.target.name === 'pub-ciudad') 
      dispatch(setCiudad(event.target.value))

    if (event.target.name === 'pub-deagencia') {
      const target = event.target as typeof event.target & { checked: boolean}
      dispatch(setDeAgencia(target.checked))
    }

    if (event.target.name === 'pub-permutar') {
      const target = event.target as typeof event.target & { checked: boolean}
      dispatch(setPermutable(target.checked))
    }
  }

  useEffect(() => {
    const filteredProps = filterOnlyAllowedProps(publicar, datosVehiculos)
    const thereIsUndefined = Object.entries(filteredProps).some(([prop, value]) => typeof value === 'undefined')

    if (!thereIsUndefined)
      setComplete(true)
    else 
      setComplete(false)
  }, [publicar])

  useEffect(() => {
    dispatch(setFinishedStep({ step: 1, finish: complete }))
  }, [complete])

  return(
    <VenderContainer className="view">

      <NavigatorComponent step={1}/>

      <Title> Ingresa los datos de tu vehículo </Title>

      <FirstRow
        isMobile={isMobile}
        isSD={isSD}
      >
        <SelectPublicar
          name="pub-marca"
          onChange={inputChange}
          value={publicar.marca ? publicar.marca : ''}
        >
          <option value="" disabled> Marca </option>
          {
            marcas.map((marca, index) => (
              <option value={marca} key={`marca${index}`}>
                { marca }
              </option>
            ))
          }
        </SelectPublicar>

        <Row>
          <SelectInlineBig 
            name='pub-modelo'
            onChange={inputChange}
            disabled={publicar.marca ? false : true}
            value={publicar.modelo ? publicar.modelo : ''}
          >
            <option value="" disabled> Modelo </option>
            {
              publicar.marca ? vehicles[publicar.marca as ObjectKey].map((vehicle, index) => (
                <option value={vehicle} key={`vehicle${vehicle}`}>
                  { vehicle }
                </option>
              ))
              :
              null
            }
          </SelectInlineBig>

          <SelectInlineSmall
            name='pub-ano'
            onChange={inputChange}
            value={publicar.year ? publicar.year : ''}
          >
            <option value='' disabled> Año </option>
            {
              year.map((year, index) => (
                <option value={year} key={`year${index}`}>
                  { year }
                </option>
              ))
            }
          </SelectInlineSmall>
        </Row>

        <InputVender
          type='text'
          name='pub-version'
          placeholder="Version"
          value={publicar.version ? publicar.version : ''}
          onChange={inputChange}
        />

        <SelectPublicar
          name='pub-transmision'
          onChange={inputChange}
          value={publicar.transmision ? publicar.transmision : ''}
        >
          <option value='' disabled>Transmision</option>
          <option value='automatica'> Automatica </option>
          <option value='mecanica'> Mecanica </option>
        </SelectPublicar>

        <SelectPublicar
          name='pub-traccion'
          onChange={inputChange}
          value={publicar.traccion ? publicar.traccion : ''}
        >
          <option value='' disabled>Traccion</option>
          <option value='4x2'> 4x2 </option>
          <option value='4x4'> 4x4 </option>
        </SelectPublicar>
        
        <SelectPublicar
          name="pub-motor_cc"
          onChange={inputChange}
          value={publicar.motor_cc ? publicar.motor_cc : ''}
        >
          <option value="" disabled> Motor (cc.) </option>
          {
            motorCCValues.map((cc, index) => (
              <option value={cc} key={`vender-cc-${cc}`}>
                { cc }
              </option>
            ))
          }
        </SelectPublicar>

        <InputVender
          type='number'
          name='pub-kilometraje'
          placeholder="Kilometraje"
          value={publicar.kilometraje ? publicar.kilometraje : ''}
          onChange={inputChange}
        />

        <SelectPublicar
          name='pub-propietarios_anteriores'
          onChange={inputChange}
          value={publicar.propietarios_anteriores ? publicar.propietarios_anteriores : ''}
        >
          <option value='' disabled>Dueños anteriores</option>
          <option value='primero'> 1er y Único dueño </option>
          <option value='segundo'> 2do dueño </option>
          <option value='tercero'> 3er dueño </option>
        </SelectPublicar>

        <SelectPublicar
          name='pub-ciudad'
          onChange={inputChange}
          value={publicar.ciudad ? publicar.ciudad : ''}
        >
          <option value="" disabled> Selecione Ciudad </option>
          {
            ciudades.map((ciudad, index) => (
              <option value={ciudad} key={`vender-cc-${ciudad}`}>
                { ciudad }
              </option>
            ))
          }
        </SelectPublicar>

        <Row>
          <Label htmlFor='pub-deagencia'>¿Sacado de agencia?</Label>
          <Toggle
            id='pub-deagencia'
            name='pub-deagencia'
            checked={publicar.de_agencia ? publicar.de_agencia : false}
            onChange={inputChange}
          />
        </Row>

        <Row>
          <Label htmlFor='pub-permutar'>¿Permutaría por otro vehículo?</Label>
          <Toggle
            id='pub-permutar'
            name='pub-permutar'
            checked={publicar.permutable ? publicar.permutable : false}
            onChange={inputChange}
          />
        </Row>        
        
      </FirstRow>

      <Continue
        isMobile={isMobile}
        onClick={() => dispatch(setUIStep('precio'))}
      >
        Siguiente
      </Continue>

      
    </VenderContainer>
  )
}

const VenderContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
`
const Title = styled.div`
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  margin: 25px 0px 20px 0px;
`
const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 500;
  width: 300px;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  border-color: #00000088;
  color: #00000088;
  &:focus {
    outline: none;
  }
  &:not(:focus):invalid {
    border-color: #00000066;
    color: #00000066;
  }
  &:valid {
    color: black;
    border-color: black;
  }
`

const SelectInlineBig = styled(SelectPublicar)`
  width: 180px;
`
const SelectInlineSmall = styled(SelectPublicar)`
  width: 90px;
`

const InputVender = styled.input`
  font-family: Montserrat;
  font-weight: 500;
  width: 300px;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  border-color: #00000088;
  &:focus {
    outline: none;
  }
  &:invalid {
    border-color: #00000066;
  }
  &:valid {
    color: black;
    border-color: black;
  }
`

const Label = styled.label`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
`

interface TfirstRow { isMobile: boolean, isSD: boolean }
const FirstRow = styled.div<TfirstRow>`
  display: flex;
  flex-flow: ${(args) => args.isMobile  ? 'column nowrap' : 'row nowrap'};
  flex-flow: column nowrap;
  gap: 20px;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

interface Tcontinue { isMobile: boolean }
const Continue = styled.button<Tcontinue>`
  
  margin: 40px 0px 0px 0px;
  background-color: #fe7027;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 160px;
`