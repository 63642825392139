import React, { useLayoutEffect, useState } from "react"
import styled from 'styled-components'
import { NavLink, useNavigate } from "react-router-dom"
import { closeSession } from "../../services/auth/userSlice"
import useViewport from "../../hooks/useViewport"
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'

import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
import { useSelector, useDispatch } from "react-redux"
import { hiddeSidebar, setActiveView } from "../../app/AppUISlice"

import ViewItem from "./sidebarViewItem";
//import SocialMedia from "../header/socialMedia"

import logo_ligth from '../../assets/icons/ui/logo_dark.png'
import close_ligth from '../../assets/icons/ui/closeGrey.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Sidebar() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const { showSidebar, views, activeView } = useSelector(
    (state: TrootState) => state.appUI
  )

  const user = useAppSelector((state: TrootState) => state.user)

  function goHome() {
    const view = views.find((view) => view.path === '/')
    if (view) dispatch(setActiveView(view))
    dispatch(hiddeSidebar())
  }

  function logOut() {
    console.log('dispath close session')
    dispatch(hiddeSidebar())
    dispatch(closeSession())
    .then((result) => {
      console.log('logout: ', result)
      if (result.meta.requestStatus === 'fulfilled') {
        navigate('/')
      }
    })
  }

  return(
    <>
      <SidebarBackground
        showSideBar={showSidebar}
        className="SidebarBackground"
        onClick={ () => dispatch(hiddeSidebar()) }
        style={{ zIndex: 998 }}
      />

      <SidebarMenu
        showSideBar={showSidebar}
        className="SideBar"
        style={{ zIndex: 999 }}
      >
        <TopSide>
          <TitleSidebar className="TitleSidebar">

            <NavLink to='/' onClick={() => goHome()}>
              <Logo src={logo_ligth}/>
            </NavLink>

            <CloseSidebar
              src={close_ligth}
              onClick={ () => dispatch(hiddeSidebar()) }
            />
          </TitleSidebar>

          <ListViews>
            {
              views.map((view, idx) => (
                <ViewItem
                  key={`sideView${idx}`}
                  view={view}
                  theme={activeView.theme}
                />
              ))
            }
            {/* <SocialMedia theme={activeView.theme}/> */}
            <ViewItem
              key={`sideView-micuenta-custom`}

              view={
                ({
                  title: 'Mi Cuenta',
                  path: '/micuenta',
                  theme: 'ligth',
                  show: isMobile && user.data ? 'ever' : 'never' })
              }

              theme={'ligth'}
            />
          </ListViews>

          <HorizonatlDivisor/>

          {/* {
            !user.data?.id_sub &&
            <LoginContainer>
              <GoogleOAuthProvider  clientId="987394169990-da58t1fvq651i988bvutef1ehc1n8b2e.apps.googleusercontent.com">
                <GoogleLogin
                  width='200'
                  locale='es_SP'
                  text='continue_with'
                  onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </LoginContainer>
          } */}

          {
            !user.data &&
              <Login>
                <NavLink to="/login" className='no-decoration'
                  onClick={ () => dispatch(hiddeSidebar()) }
                >
                  <InitLogin> Iniciar Sesión </InitLogin>
                </NavLink>

                <VerticalDivisor> | </VerticalDivisor>

                <NavLink to="/registro" className='no-decoration'
                  onClick={ () => dispatch(hiddeSidebar()) }
                >
                <SignUp> Crear Cuenta </SignUp>
                </NavLink>
              </Login>
          }



          <SolicitarInspeccion
            onClick={() => {
              navigate('/inspeccion') 
              dispatch(hiddeSidebar())
            }}
          >
            Diagnóstico Vehicular
          </SolicitarInspeccion>

        </TopSide>

        { user.data?.email &&

          <BottomSide>
            <p> { user.data.email } </p>
            <CloseSession
              onClick={() => logOut()}
            >
              Cerrar Sesión
            </CloseSession>
          </BottomSide>            
        }

      </SidebarMenu>
    </>
  )
}

interface TSidebarProps { showSideBar: boolean }
const SidebarBackground = styled.div<TSidebarProps>`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .2s ease-out;
  ${ props => props.showSideBar && `
    opacity: 1;
    left: 0px;
    right: 0px;
  `};
`
const SidebarMenu = styled.div<TSidebarProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: -300px;
  width: 300px;
  background-color: #F0EEDD;
  background-color: #E6E3C6;
  z-index: 999;
  transition: transform .5s ease-out;
  ${ props => props.showSideBar && `
      transform: translate(-300px, 0);        
  `};
`
const TopSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const BottomSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: 20px;
`;

const TitleSidebar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`
  const Logo = styled.img`
    width: 150px;
  `
  const CloseSidebar = styled.img`
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
  `

const ListViews = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`
const HorizonatlDivisor = styled.div`
  width: 100px;
  border-bottom: solid 2px #fe7027;
  margin: 15px auto 25px auto;
`

const Login = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: auto;
  color: #777777;
`
  const VerticalDivisor = styled.span`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 10px 0px 10px;
  `
  const InitLogin = styled.span`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  `
  const SignUp = styled.span`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  `

  const CloseSession = styled.div`
    font-family: Montserrat;
    align-self: center;
    background-color: #30302C;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    width: 120px;
    padding: 5px 0px 5px 0px;
  `
  const SolicitarInspeccion = styled.div`
    font-family: Montserrat;
    align-self: center;
    background-color: #FE7027;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    width: 150px;
    padding: 5px 0px 5px 0px;
    margin-top: 20px;
  `

const LoginContainer = styled.div`
  align-self: center;
  margin-bottom: 20px;
`