import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { useSubscribePubMutation } from '../../services/apiSlice/apiSlice'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'
import { TrootState, useAppSelector } from '../../app/store'
import ReactGA from "react-ga4"

import Modal from '../../components/modal/modal'
import AuthModal from '../../components/modal/modalAuth'

import chat from '../../assets/icons/ui/chat.png'
import chatIcon from '../../assets/icons/chat/chat.png'
import whatsapp from '../../assets/icons/social/whatsapp.png'

ReactGA.initialize([
  { trackingId: "G-YTJERY7YWH" },
  { trackingId: "G-Z0Q8ZCVBV5" },
  { trackingId: "G-QHXPX2CWKM" },
])

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function ContactSeller(props: TpublicacionItem) {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [message, setMessage] = useState<string>('Me interesa, ¿Dónde puedo ver el vehículo?')
  const [phone, setPhone] = useState<number|undefined>(undefined)
  const [url, setUrl] = useState<string|undefined>(undefined)
  const [ showModal, setShowModal] = useState<boolean>(false)

  const [ subscribePub, subscribePubResult ] = useSubscribePubMutation()

  const user = useAppSelector((state: TrootState) => state.user)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function changeInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'sendMessage' ) {
      console.log(event.target.value)
      setMessage(event.target.value)
    }
  }

  useEffect(() => {
    if (props.seller_phone) {
      setPhone(Number(props.seller_phone.replace('+', '')))
      setUrl(`https://carp10.com/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}`)
    }
  }, [])

  return(
    <ContactSellerContainer
      isMobile={isMobile}
    >
      <ChatContainer isMobile={isMobile} >
          <Header>
            <ChatIcon src={chat}/>
            Chatea con el vendedor
          </Header>

          <Mensaje
            type='text'
            //defaultValue='Me interesa, ¿Dónde puedo probar el vehículo?'
            name='sendMessage'
            onChange={changeInput}
            value={message}
          />

          <Botonera isMobile={isMobile}>

            { phone && url &&
              // <Link >
                <WhatsappButton
                  isMobile={isMobile}
                  href={ user.data ? `https://wa.me/${phone}?text=${encodeURI(message+'\n\n'+url)}` : undefined}
                  target='_blank'
                  onClick={(event) => {
                    if(!user.data) {
                      setShowModal(true)
                      event.preventDefault()
                    } else {
                      ReactGA.event('click_whatsapp_seller_button', {
                        publicacionId: props.id_publicacion,
                      })
                    }
                  }}
                >
                  <WhatsappIcon src={whatsapp} isMobile={isMobile}/>
                  { user.data ? phone.toString().replace('591', '') : 'Ver #nro. de telef.' }
                </WhatsappButton> 
              // </Link>
            }

            { !props.chatSubscribed  &&
              <ChatButton
                style={{ backgroundColor: '#30302C', fontWeight: '700' }}
                isMobile={isMobile}
                onClick={() => {
                  if (user.data) {
                    subscribePub({
                      publicacion: props.id_publicacion,
                      message: message,
                      avatarImg: user.data?.profile_image,
                    })

                    ReactGA.event('click_carp10_chat_button', {
                      publicacionId: props.id_publicacion,
                    })

                  } else {
                    setShowModal(true)
                  }
                }}
              >
                
                <InitChatIcon src={chatIcon} />
                
                <EnviarText>
                  <span style={{color: 'white'}}>Car</span>
                  <span style={{ color: '#FE7027' }}>p10</span>
                  <Beta>BETA</Beta>
                </EnviarText>
                
              </ChatButton>
            }

          </Botonera>
        </ChatContainer>

        { showModal &&
          <Modal
            closeModal={hiddeModal}
          >
            <AuthModal
              hiddeModal={hiddeModal}
            />
          </Modal>
        }

        {/* { props.chatSubscribed &&
          <Chat publicacion={props.id_publicacion} />
        } */}
    </ContactSellerContainer>
  )
}

interface isMobile { isMobile: boolean }
const ContactSellerContainer = styled.div<isMobile>`
  margin: auto;
  width: 100%;
  ${(args) => args.isMobile && `
    margin: 10px 0px 10px 0px;
  `}
`

const ChatContainer = styled.div<isMobile>`
  background-color: #c9c9c9;
  align-self: flex-end;
  width: 100%;
  padding: 10px 30px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  ${(args) => args.isMobile && `
    border-radius: 25px;
  `}
`
  const Header = styled.div`
    text-align: left;
    
    font-family: Montserrat;
  `
  const ChatIcon = styled.img`
    height: 30px;
    margin-right: 20px;
  `
  const Mensaje= styled.input`
    width: 100%;
    padding: 5px 10px;
  `
  const button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    padding: 10px 10px;
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    font-weight: 600;
    border-radius: 3px;
  `
const Botonera = styled.div<isMobile>`
  display: flex;
  width: 100%;
  gap: 10px;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  `}
`

  const Enviar = styled(button)<isMobile>`
    text-decoration: none;
    display: flex;
    gap: 12px;
    
    ${(args) => args.isMobile && `
      border-radius: 5px;
      align-self: center;
      padding: 10px 20px;
      font-size: 14px;
      gap: 5px;
      white-space: pre-line;
    `}
  `

  const WhatsappButton = styled.a<isMobile>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 50%;
    padding: 3px 10px;
    font-family: Montserrat, sans-serif;

    font-size: 18px;
    font-weight: 600;
    background-color: white;
    
    text-decoration: underline;
    color: #25D366;
    font-family: 'Barlow';
    letter-spacing: 1px;
    ${(args) => args.isMobile && `
      min-width: 180px;
      width: 100%;
    `}
  `
  const ChatButton = styled(Enviar)<isMobile>`
    width: 50%;
    font-size: 15px;
    ${(args) => args.isMobile && `
      min-width: 180px;
      width: 100%;
    `}
  `

  const WhatsappIcon = styled.img<isMobile>`
    height: 30px;
    margin-right: 10px;
    ${(args) => args.isMobile && `
      height: 25px;
    `}
  `
  const InitChatIcon = styled.img`
    height: 20px;
    margin-right: 5px;
  `
  
  const EnviarText = styled.div`
    
  `

    const Beta = styled.span`
      background-color: #00AA88;
      color: white;
      padding: 0px 5px;
      border-radius: 2px;
      max-width: 50px;
      margin-left: 5px;
    `