import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import closeIcon from '../../assets/icons/ui/closeGrey.png'
import closeIconSquare from '../../assets/icons/ui/close-square.png'


interface thisProps { children: JSX.Element, closeModal: () => void }
export default function Modal(props: thisProps) {
  const { pathname } = useLocation()

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return(() => {
      document.body.style.overflow = 'initial';
    })
  }, [])

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      id: string
    }

    if(target.id.match(/modalBackground|closeModalIcon|ModalContainer/)) {
      props.closeModal()
    }
  }

  return(
    <ModalBackground
      id='modalBackground'
      style={{zIndex: 998 }}
      onClick={hiddeModalFn}
    >
      <ModalContainer
        style={{zIndex: 999 }}
        id='ModalContainer'
      >
        <CloseIconContainer
          id='closeModalIconContainer'
        >
          <CloseIcon
            src={ pathname.match('/publicacion') ? closeIconSquare : closeIcon}
            id='closeModalIcon'
          />
        </CloseIconContainer>

        { props.children }

      </ModalContainer>
    </ModalBackground>
  )
}

interface TmodalContainer { location: string }
const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  margin: auto;
  border-radius: 10px;
`
const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  right: 0px;
  top: 5px;
  width: 18px;
`

const CloseIcon = styled.img`
  width: 18px;
`