import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import { updateEquipamiento } from '../../../services/micuenta/api';


export default function MiEquipamiento(props: TpublicacionItem) {
  const [edit, setEdit] = useState(false);
  const [equipamiento, setEquipamiento] = useState<string|undefined>(undefined)

  useEffect(() => {
    if (props.equipamiento)
    setEquipamiento(props.equipamiento)
  }, [props])

  function equipamientoChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    console.log(event.target.value)
    setEquipamiento(event.target.value)
  }

  return(
    <EquipamientoContainer>

      {/* {props.equipamiento && 
      <Text> { props.equipamiento.replace(/-/g, '\n-') }  </Text>
      } */}

      <TextArea
        rows={10}
        //cols={50}
        disabled={!edit}
        value={equipamiento}
        onChange={equipamientoChange}
      >
        { props.equipamiento }
      </TextArea>

      <Botonera>

        <Edit
          onClick={() => setEdit(!edit)}
        >
          Editar
        </Edit>

        <Save
          disabled={!edit}
          onClick={() => {
            if (equipamiento)
            updateEquipamiento(equipamiento, props.id_publicacion)
            .then((resp) => {
              setEdit(false)
            }) 
          }}
        >
          Guardar cambios
        </Save>

      </Botonera>
    </EquipamientoContainer>
  )
}

const EquipamientoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px 0px 0px 0px;
`

const Text = styled.div`
  font-family: Montserrat;
  text-align: justify;
  white-space: pre;
  line-height: 15px;
`

const TextArea = styled.textarea`
 width:100%;
 max-width:100%;
`

const Botonera = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Buttom = styled.button`
  width: 150px;
  border: none;
  padding: 5px 0px;
`
const Edit = styled(Buttom)`
  background-color: #eeeeee;
`
const Save = styled(Buttom)`
  background-color: #90ee90;
  
`
