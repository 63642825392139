import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface Tprops { closeModal: () => void }
export default function SuccessPago(props: Tprops) {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <AgendarSuccessContainer>
      <Content>

          <Subtitle isMobile={isMobile}>
            Perfecto! un agente revisara el comprobante que subiste y se comunicará contigo
          </Subtitle>

          <VolverButton
            onClick={() => {
              props.closeModal()
            }}
          >
            Volver al vehículo
          </VolverButton>

      </Content>
    </AgendarSuccessContainer>
  )
}

const AgendarSuccessContainer = styled.div`
  width: 100%;  
`
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 40px 0px 20px 0px;
  gap: 30px;
`

interface Tsubtitle { isMobile: boolean }
const Subtitle = styled.div<Tsubtitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #444444;
  width: 380px; 
  ${(args) => args.isMobile && `
    font-size: 15px;
    width: 280px; 
  `}
`

const VolverButton = styled.button`
  color: white;
  background-color: black;
  border-radius: 20px;
  padding: 5px 10px;
`