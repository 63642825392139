import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import fetch, { Headers, Request, Response } from 'cross-fetch';
import {
  TsimpleResponse,
  TupdateEquipamientoPayload,
  TupdateMiPublicacionPayload,
  TsubscribePayload,
  getAllChannelsResponseType,
} from './apiTypes';
//import AbortController from 'abort-controller';

global.fetch = fetch as any;
global.Headers = Headers as any;
global.Request = Request as any;
global.Response = Response as any;
//global.AbortController = AbortController;

const BACKEND_URL = 'https://api.carp10.com';

export const publicacionesApi =  createApi({
  reducerPath: 'publicacionesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}`,
    fetchFn: fetch,
  }),
  tagTypes: ['Publicacion', 'MiPublicacion'],
  endpoints: (builder) => ({

    // -------------------  PUBLICACIONES
    getPublicaciones: builder.query({
      query: (args) => {
        let url = '/publicaciones?'

        if (args.search) url += `&search=${args.search}`
        if (args.minprice) url += `&miprice=${args.minprice}`
        if (args.maxprice) url += `&maxprice=${args.maxprice}`
        if (args.inspeccionado) url += `&inspeccionado=${args.inspeccionado}`
        if (args.permutable) url += `&permutable=${args.permutable}`
        if (args.tipos?.length > 0) {
          const queryTiposArray = args.tipos.map((tipo: string) => `&tipo=${tipo}`)
          url += queryTiposArray.join('')
        } 

        if (args.sort) url += `&sortBy=${args.sort}`

        return {
          url,
          credentials: 'include',
        }
      },
      providesTags: ['Publicacion']
    }),
    getMisPublicaciones: builder.query({
      query: () => {
        return {
          url: '/myaccount/mispublicaciones',
          credentials: 'include'
        }
      },
      providesTags: ['Publicacion']
    }),
    getFavoritas: builder.query({
      query: () => {
        return {
          url: '/myaccount/misfavoritos',
          credentials: 'include'
        }
      },
      providesTags: ['Publicacion']
    }),

    getMiPublicacion: builder.query({
      query: (publicacionId) => ({
        url: `/myaccount/mispublicaciones/${publicacionId}`,
        credentials: 'include'
      }),
      providesTags: ['Publicacion']
    }),
    getMiInspeccion: builder.query({
      query: (args) => {
        let url = '/publicaciones/'
        if (args.publicacionId) url += `${args.publicacionId}/inspeccion`

        return {
          url,
          credentials: 'include'
        }
      }
    }),
    

    // -------------------  PUBLICACION

    getPublicacion: builder.query({
      query: (publicacionId) => ({
        url: `/publicaciones/${publicacionId}`,
        credentials: 'include',
      }),
      providesTags: ['Publicacion'],
    }),
    subscribePub: builder.mutation<TsimpleResponse, TsubscribePayload>({
      query: body => ({
        url: `/chatsubscribe/${body.publicacion}`,
        method: 'POST',
        credentials: 'include',
        body: {
          message: body.message,
          avatarImg: body.avatarImg,
        }
      }),
      invalidatesTags: ['Publicacion'],
    }),

    // -------------------  MI PUBLICACION

    updateEquipamiento: builder.mutation<TsimpleResponse, TupdateEquipamientoPayload>({
      query: body => ({
        url: `/myaccount/mispublicaciones/${body.publicacionId}`,
        method: 'POST',
        credentials: 'include',
        body: {
          type: 'update_equipamiento',
          equipamiento_edit: body.equipamientoEdit,
        }
      }),
      invalidatesTags: (result, error, arg) => {
        if (result && result.result === 'success') {
          return ['Publicacion']
        }
        return []
      },
    }),

    updateMiPublicacion: builder.mutation<TsimpleResponse, TupdateMiPublicacionPayload>({
      query: body => ({
        url: `/myaccount/mispublicaciones/${body.publicacionId}`,
        method: 'PUT',
        credentials: 'include',
        body: {
          ...body.payload
        }
      }),
      invalidatesTags: ['Publicacion'],
    }),

    // -------------------  MI CUENTA

    getAllChannels: builder.query<getAllChannelsResponseType, void>({
      query: () => ({
        url: `/myaccount/mensajes`,
        credentials: 'include',
      })
    }),

    // -------------------  NOTIFICACIONES

    getNotificaciones: builder.query<getAllChannelsResponseType, void>({
      query: () => ({
        url: `/myaccount/mensajes`,
        credentials: 'include',
      })
    }),

  })
})


export const {
  useGetPublicacionesQuery,
  useGetMisPublicacionesQuery,
  useGetPublicacionQuery,
  useGetMiPublicacionQuery,
  useGetMiInspeccionQuery,
  useUpdateEquipamientoMutation,
  useUpdateMiPublicacionMutation,
  useGetFavoritasQuery,
  useSubscribePubMutation,
  useGetAllChannelsQuery,
} = publicacionesApi