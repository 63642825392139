import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { conversation } from './ChatSeller'
import { channelDataType } from '../../services/apiSlice/apiTypes'
import { TrootState, useAppSelector } from '../../app/store'
import './Chat.css'

import defaultProfileIcon from '../../assets/icons/chat/avatar.png'


function timestampToHours(timestamp:number) {
  let time = new Date(timestamp);
  // return time.getHours() + ':' + time.getMinutes();
  // let stringData = time.toLocaleDateString('en-GB', {
  //     day:   'numeric',
  //     month: 'short',
  // });

  // stringData += ' at ' + time.toLocaleTimeString(undefined, {
  //     hour:   '2-digit',
  //     minute: '2-digit',
  // });

  let stringData = time.toLocaleTimeString(undefined, {
    hour:   '2-digit',
    minute: '2-digit',
  });

  return stringData
}

interface Tprops { message: conversation, channelData: channelDataType }
export default function Message(props: Tprops) {
  const { message,  channelData } = props
  const [avatarLoaded, setAvatarLoaded] = useState(false)

  const user = useAppSelector((state: TrootState) => state.user)
  useEffect(() => {
    console.log(props.message)
  }, [])

  return(
    <MessageContainer
      mine={user.data?.id_sub === props.message.username}
    >
      { user.data?.id_sub !== props.message.username &&
      <AvatarSide>
        
          <AvatarThumb
            loadedAvatar={avatarLoaded === false}
            src={ defaultProfileIcon }
          />

          { props?.message.avatarImg &&
            <AvatarThumb
              loadedAvatar={avatarLoaded === true}
              src={ props.message.avatarImg }
              onLoad={(event) => {
                setAvatarLoaded(true)
              }}
            />
          }
        </AvatarSide>
      }

      <MesssageBody>
        { user.data?.id_sub !== props.message.username &&
          <UserName>
            { message.username === user.data?.id_sub ? user.data.given_name : channelData.given_name }
          </UserName>
        }

        <MessageBubble
          mine={user.data?.id_sub === message.username}
        >
          <MessageTex
            mine={user.data?.id_sub === message.username}
          >
            { message.message }
          </MessageTex>
          <MessageTime
            mine={user.data?.id_sub === props.message.username}
          >
            { timestampToHours(props.message.timestamp) }
          </MessageTime>
        </MessageBubble>

        {/* <span className='channles-message-hour'>
          { timestampToHours(props.timestamp) }
        </span> */}

      </MesssageBody>

    </MessageContainer>
  )
}

interface TmessageContainer { mine: boolean }
const MessageContainer = styled.div<TmessageContainer>`
  display: flex;
  flex-flow: row nowrap;;
  font-size: 15px;
  margin-top: 15px;
  margin-left: 10px;
  padding: 0px 5px;
  ${(args) => args.mine && `
    flex-direction: row-reverse;
  `}
`

interface TavatarThumb { loadedAvatar: boolean }
const AvatarThumb = styled.img<TavatarThumb>`
  height: 30px;
  margin-right: 5px;
  border-radius: 15px;
  display: ${(args) => args.loadedAvatar ? 'initial' : 'none'};
`
const AvatarSide = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
const MesssageBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: left;
  align-items: flex-start;
`
  const UserName = styled.div`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    color: black;
    margin-bottom: 7px;
    margin-left: 15px;
  `

interface TmessageBubble { mine: boolean }
const MessageBubble = styled.div<TmessageBubble>`
  background-color: ${(args) => args.mine ? '#D9D9D9' : 'white'};
  padding: 10px 10px;
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-flow: column nowrap;
  max-width: 250px;
  ${(args) => args.mine && `
    margin-right: 20px;
  `}
`
  interface TmessageText { mine: boolean }
  const MessageTex = styled.div<TmessageText>`
    align-self: flex-start;
    margin: 0px 25px 0px 10px;
    line-height: 15px;
    text-align: left;
    ${(args) => args.mine && `
      margin-right: 10px;
    `}
  `
  interface TmessageTime { mine: boolean }
  const MessageTime = styled.div<TmessageTime>`
    align-self: flex-end;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    color: #555555;
    ${(args) => args.mine && `
      align-self: flex-start;
      margin-left: 10px;
      margin-top: 5px;
      margin-right: 10px;
    `}
  `