import React, { useLayoutEffect, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
//import { setInspeccionFecha, setInspeccionHora, setInspeccionDireccion } from "../../../services/inspeccion/inspeccionSlice"
import { setInspeccionFecha, setInspeccionHora, setInspeccionDireccion } from '../../services/pagoInspeccion/inspeccionPubliSlice';
import { TstepPagoInspeccion } from './solicitarInspeccionModal'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes';

import useViewport from "../../hooks/useViewport"

const horaCita = [
  '08:00 - 10:00',
  '10:00 - 12:00',
  '12:00 - 14:00',
  '14:00 - 16:00'
]

const year = [
  2010, 2011, 2012, 2013, 2014,
  2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface Tprops { publicacionData: TpublicacionItem, changeStep: (step: TstepPagoInspeccion) => void }
export default function AgendarFecha(props: Tprops) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const [startDate, setStartDate] = useState(new Date());
  const date = new Date();
  const fromDate = `${date.getMonth() + 1}/${date.getDate() + 1}/${date.getFullYear()}`;

  const inspeccion = useAppSelector((state: TrootState) => state.inspeccionPubli)

  useEffect(() => {
    console.log(startDate)
  }, [startDate])

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLTextAreaElement>) {
    if (event.target.name === 'inspeccion-hora') {
      dispatch(setInspeccionHora(event.target.value))
    }

    if (event.target.name === 'inspeccion-address') {
      dispatch(setInspeccionDireccion(event.target.value))
    }
  }

  useEffect(() => {
    dispatch(setInspeccionFecha(startDate.getTime()))
  }, [startDate])


  return(
    <DatosVehiculoContainer
      isMobile={isMobile}
    >
      <Centered isMobile={isMobile}>

        <Title> Agendar Diagnóstico </Title>

        <FirstRow
          isMobile={isMobile}
          isSD={isSD}
        >
          <Row>
            <p>Fecha:</p>
            <DatePickerStyled
              selected={startDate}
              onChange={(date:Date) => setStartDate(date)}
              locale={es}
              minDate={new Date(fromDate)}
            />
          </Row>

          <Row>
            <p>Horario:</p>
            <SelectPublicar 
              name='inspeccion-hora'
              onChange={inputChange}
              defaultValue={inspeccion.data.hora ? inspeccion.data.hora : ''}
            >
              <Option value="" disabled> Seleccione </Option>
              { horaCita.map((item, index) => (
                <Option value={item} key={`inspeccion-hora-${index}`}>
                  { item }
                </Option>
              ))}
            </SelectPublicar>
          </Row>

          <Row>
            <p>Direccion:</p>
            <TextArea
              //type='text'
              name='inspeccion-address'
              placeholder={ inspeccion.data.direccion ? inspeccion.data.direccion  : 'Dirección' }
              onChange={inputChange}
            />
          </Row>

        </FirstRow>

        <Bottom isMobile={isMobile}>
          <Volver
            onClick={() => props.changeStep('info')}
          >
            Volver
          </Volver>

          <Continue
            onClick={() => props.changeStep('facturacion') }
          >
            Solicitar Diagnostico
          </Continue>
        </Bottom>

      </Centered>
    </DatosVehiculoContainer>
  )
}

interface TdatosVehiculoContainer { isMobile: boolean }
interface isMobile { isMobile: boolean }
const DatosVehiculoContainer = styled.div<TdatosVehiculoContainer>`
  width: 100%;
`
const Centered = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width:  ${(args) => args.isMobile ? '350px' : '500px' };
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  margin: 40px 0px 20px 0px;
`

const DatePickerStyled = styled(DatePicker)`
  font-family: Montserrat;
  font-weight: 600;
  width: 200px;
  padding: 6px 5px 6px 15px;
  border: solid 1px gray;
  border-radius: 5px;
`

const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 600;
  width: 200px;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`
  const Option = styled.option`
    margin: 5px 0px 5px 0px;
    font-family: Montserrat;
  `

const TextArea = styled.textarea`
  font-family: Montserrat;
  font-weight: 600;
  width: 200px;
  margin: 5px 0px 0px 0px;
  padding: 6px 5px 6px 15px;
  border: solid 1px gray;
  border-radius: 5px;
`

const Row = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`

interface TfirstRow { isMobile: boolean, isSD: boolean }
const FirstRow = styled.div<TfirstRow>`
  display: flex;
  flex-flow: column nowrap;
  gap: ${(args) => args.isMobile  ? '20px' : '30px'};
  margin: 10px 0px 10px 0px;
`
interface Tbottom { isMobile: boolean }
const Bottom = styled.div<Tbottom>`
  margin-top: 30px;
  display: flex;
  //flex-flow: ${(args) => args.isMobile ? 'column nowrap' : 'row nowrap' };
  flex-flow: column nowrap;
  gap: 15px;
  margin-bottom: 20px;
`


const Continue = styled.button`
  cursor: pointer;
  align-self: center;
  margin: 0px 0px 0px 0px;
  background-color: #FF925C;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  height: 40px;
  width: 190px;
`
const Volver = styled.button`
  cursor: pointer;
  align-self: center;
  margin: 0px 0px 0px 0px;
  background-color: gray;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  height: 40px;
  width: 190px;
`