import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useConfig from '../../../hooks/useConfig'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import { Carousel as CarouselBoot } from "react-bootstrap"
import useViewport from '../../../hooks/useViewport'
import VendidoRibbon from '../../../components/vendidoRibbon/vendidoRibbon'

import loading2 from '../../../assets/gifs/loading-car.svg'

const order = [
  'exterior_1',
  'exterior_2',
  'exterior_3',
  'exterior_4',
  'exterior_5',
  'exterior_6',
  'exterior_7',
  'exterior_8',

  'interior_1',
  'interior_2',
  'interior_3',
  'interior_4',
  'interior_5',
  'interior_6',
  'interior_7',
  'interior_8',
  'interior_9',
  'interior_10',
  

  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_lateral',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_capo_abierto',
  
  'foto_maletero_abierto',
  'foto_volante',
  'foto_tablero_01',
  'foto_tablero_02',
  'foto_asientos_delanteros',
  'foto_asientos_traseros',
  'foto_caja_cambio',
  'foto_puerta_conductor_adentro',
  
  'foto_llanta_delantera_derecha',
  'foto_llanta_delantera_izquierda',
  'foto_llanta_trasera_derecha',
  'foto_llanta_trasera_izquierda',
  'foto_llanta_auxilio'
]

function extractProp(key: string) {
  const keySplited = key.split('/')
  return keySplited[keySplited.length - 1].replace('.jpeg', '')
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function PhotoCarouselMiPublicacion(props: TpublicacionItem) {
  const { app } = useConfig()
  const [ photos, setPhotos] = useState<string[]>([])
  const [ loadedImages, setLoadedImages] = useState<boolean>(false)

  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_'))) {
        return true
      } else return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)
    .sort((a,b) => {
      return order.indexOf(extractProp(a)) - order.indexOf(extractProp(b))
    })

    const loadAlPhotos = fotosUrls.map((picture) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() { resolve(img) }
        img.onerror = img.onabort = function() { reject(picture) }
        img.src = picture;  
      })
    });

    Promise.all(loadAlPhotos).then((values) => {
      setLoadedImages(true)
    })

    setPhotos([...fotosUrls])    
  }, [])

  return(
    <PhotoCarouselContainer className='PhotoCarouselContainer'>
      { props.vendido && <VendidoRibbon/>}
      <CarouselBoot>
        
          { loadedImages ? photos.map((photo, idx) => (
            <CarouselBoot.Item
              key={`pub-photo-${props.id_publicacion}-${idx}`}
            >
              <ImageSection
                isMobile={isMobile}
                className='wrapperImageFit'
                // onClick={() => {
                //   setShowImage(photo)
                //   setShowModal(true)
                // }}
              >
                <img src={photo} width={'100%'} height={'100%'} className='imageFit'/>
              </ImageSection>
            </CarouselBoot.Item>
          ))
          :
          <ImageSection isMobile={isMobile} className='wrapperImageFit'>
            <img src={loading2} width={'100%'} height={'100%'}/>
          </ImageSection>
        }
        </CarouselBoot>

    </PhotoCarouselContainer>
  )
}

interface isMobile { isMobile: boolean }
const PhotoCarouselContainer = styled.div`
  width: 100%;
  min-width: 100%;
  position: relative;
`

const ImageSection = styled.div<isMobile>`
  background-color: #cccccc;
  width: 100%;
  height: 250px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  ${(args) => args.isMobile && `
    height: 300px;
    min-height: 300px;
  `}
`


const ListonVendido = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  z-index: 2000;

  top: -3px;
  right: -3px;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 3px solid #1eb5ff;
    border: 3px solid #000;
    border-top-color: transparent;
    border-right-color: transparent;
  };
  &::before {
    top: 0;
    left: 0;
  };
  &::after {
    bottom: 0;
    right: 0;
  };
  & span {
    position: absolute;
    display: block;
    width: 165px;
    padding: 5px 0;
    background-color: #000;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: white;
    font-family: montserrat, sans-serif;

    left: -22px;
    top: 30px;
    transform: rotate(45deg);
    
  };
`