import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../app/store'
import { setLogEvent } from '../popUp/popUpSlice'

interface thisProps { hiddeModal: () => void, setPhone: (tel: string) => void }
export default function PhoneModal(props: thisProps) {
  const dispatch = useAppDispatch()
  const [ phone, setPhone ] = useState<string|undefined>(undefined)

  return(
    <PhoneModalContainer>
      <Title> Ingrese su número de teléfono </Title>
      <Row>
        <Country
          value='+591'
          disabled
        />
        <Phone
          type='tel'
          value={phone}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)
          }
        />
      </Row>

      <Continue
        onClick={() => {
          if (phone) {
            props.setPhone(phone)
            props.hiddeModal()
          } else {
            dispatch(setLogEvent({ type: 'error', message: 'Por favor ingrese un numero de telefono' }))
          }
        }}
      >
        Continuar
      </Continue>
    </PhoneModalContainer>
  )
}

const PhoneModalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #30302C;
  width: 500px;
  height: 300px;
`

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 23px;
  color: white;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
`

const Country = styled.input`
  width: 70px;
  padding-left: 7px;
  font-size: 20px;
`
const Phone = styled.input`
  width: 50px;
  width: 200px;
`
const Continue = styled.button`
  background-color: #fe7027;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  padding: 8px 20px;
`