import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setLogEvent } from '../popUp/popUpSlice'
import { TstepPagoInspeccion } from './solicitarInspeccionModal'
import { setYoLoAgendo, setPorAgendar, setResetInspeccion } from '../../services/pagoInspeccion/inspeccionPubliSlice'

import check from '../../assets/icons/ui/checkWithBox.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface thisProps { item: TpublicacionItem, changeStep: (value: TstepPagoInspeccion) => void }
export default function InfoSolicitarInspeccion(props: thisProps) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const inspeccion = useAppSelector((state: TrootState) => state.inspeccionPubli)

  useEffect(() => {
    dispatch(setResetInspeccion())
  }, [])

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function AgendarYoMismo(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked)
    dispatch(setYoLoAgendo())
    else
    dispatch(setPorAgendar())
  }

  return(
    <SolicitarInspeccionContainer isMobile={isMobile} >

      <MainTitle> Diagnóstico vehicular móvil </MainTitle>
      {/*<TitlePrecio> (Precio: 250 bs.) </TitlePrecio>*/}
      <TitlePrecio2> (Precio: 300 bs.) </TitlePrecio2>

      <Title> Beneficios </Title>
      <Body isMobile={isMobile} >
        <Paragraph> Vamos hasta <strong>donde esté tu movilidad </strong> para realizarle el diagnóstico con escaneo y así no te perjudiques en tus actividades (el diagnóstico toma aprox. 40 minutos). </Paragraph>
        <Paragraph> Se mostrará el <strong>resultado del diagnostico en tu publicación</strong> de carp10 (Si así lo deseas). </Paragraph>
        <Paragraph> Demuestra <strong> mayor transparencia y confianza </strong> con los interesados en tu vehículo.</Paragraph>
        <Paragraph> Luego de realizado el diagnóstico te enviamos el <strong>reporte en PDF a tu whatsapp</strong> para que puedas compartirlo con potenciales compradores.</Paragraph>
      </Body>

      <Title> Incluye </Title>

      <Featrues isMobile={isMobile}>
        <Feature>
          <CheckIcon src={check} />
          Escaneo general de sensores
        </Feature>

        <Feature>
          <CheckIcon src={check} />
          Revisión de los atributos del interior y exterior del vehículo
        </Feature>

        <Feature>
          <CheckIcon src={check} />
          Revisión del motor
        </Feature>
        {/*
        <Feature>
          <CheckIcon src={check} />
          Prueba del vehículo en marcha
        </Feature>
        */}
        <Feature>
          <CheckIcon src={check} />
          Revisión 360° y de la estética del vehículo
        </Feature>
      </Featrues>

      <Advice>
        Nota: NO se desmantela ninguna pieza del vehículo durante el diagnositco
      </Advice>

      <TitleBlack>
        Solicitar un diagnostico para el vehículo
      </TitleBlack>

      <Props>
        <Prop> <strong> Marca: </strong> { props.item.marca } </Prop>
        <Prop> <strong> Modelo: </strong> { props.item.modelo } </Prop>
        <Prop> <strong> Año: </strong> { props.item.year } </Prop>
      </Props>

      <AgendarContainer>
        <AgendarCheck
          type='checkbox'
          id='agendar-inspeccion-myself'
          onChange={AgendarYoMismo}
          checked={ inspeccion.tipo === 'agendado'}
        />
        <AgendarLabel
          htmlFor='agendar-inspeccion-myself'
        >
          {`Agendar la inspección yo mismo\n en este momento`}
        </AgendarLabel>
      </AgendarContainer>

      <InspeccionarButton
        // onClick={() => SolicitarInspeccion(props.item)}
        onClick={() => {
          if (inspeccion.tipo === 'agendado')
          props.changeStep('fecha') 
          else
          props.changeStep('facturacion')
        }} 
      >
        { inspeccion.tipo === 'por agendar' ? 'Solicitar Diagnostico' :  'Agendar Fecha' }
      </InspeccionarButton>

    </SolicitarInspeccionContainer>
  )
}

interface isMobile { isMobile: boolean }
const SolicitarInspeccionContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  width: 600px;
  max-height: 550px;
  padding: 20px 10px;
  overflow-y: auto;
  ${(args) => args.isMobile && `
    width: 350px;
  `}
`
const MainTitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 21px;
  color: #FE7027;
  margin-top: 20px;
  margin-bottom: 10px;
`

const TitlePrecio = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  margin-bottom: 10px;
  color: grey;
  text-decoration: line-through;
`

const TitlePrecio2 = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  margin-bottom: 10px;
`

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  color: #FE7027;
  margin-top: 22px;
  margin-bottom: 20px;
`

const Body = styled.div<isMobile>`
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  max-height: 250px;
  gap: 0px;
  align-items: center;
  ${(args) => args.isMobile && `
    flex-flow: row wrap;
    max-height: unset;
    justify-content: center;
  `}
`
const Paragraph = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  width: 250px;
  text-align: justify;
`
const Featrues = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 400px;
  margin: auto;
  ${(args) => args.isMobile && `
    display: unset;
    width: 300px;
    text-align: left;
  `}
`
const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
`

const Advice = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin: 20px 10px 0px 15px;
`

const CheckIcon = styled.img`
  height: 20px;
  margin-right: 10px;
  margin-top: 3px;
`

const TitleBlack = styled.div`
  display: flex;
  font-family: Montserrat;
  font-weight: 600;
  color: black;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 20px;
`
const Props = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: left;
  width: 150px;
  margin: auto;
`
const Prop = styled.div`
  font-family: Montserrat;
  text-align: left;
  margin-top: 5px;
`
const AgendarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-self: center;
  margin: 30px 0px 0px 0px;
`
  const AgendarCheck = styled.input`
    width: 15px;
    height: 15px;
    font-size: 100px;
    accent-color: gray;
  `
  const AgendarLabel = styled.label`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    color: gray;
    white-space: pre-wrap;
  `

const InspeccionarButton = styled.button`
  background-color: #FE7027;
  color: white;
  font-weight: 600;
  font-family: Montserrat;
  width: 250px;
  margin: auto;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 0px;
`