import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { Tpublicaciones, TpublicacionItem } from './publicacionesApiTypes'
//import { Tfilter, TfilterTipo } from '../filterPublicaciones/types';

let BACKEND_URL = 'https://api.carp10.com'

// export function misPublicaciones() {

//   const options = {
//     method: 'GET',
//     withCredentials: true,
//     url: `${BACKEND_URL}/myaccount/mispublicaciones`
//   };

//   return new Promise<AxiosResponse<Tpublicaciones>>((resolve, reject) => {
//     axios(options)
//     .then(function (response) {
//       resolve(response);
//     })
//     .catch(function (error) {
//       console.log(error)
//       reject(error);
//     })
//   })
// }


export function obtenerPublicacion(publicacionId: string) {

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/publicaciones/${publicacionId}`
  };

  return new Promise<AxiosResponse<TpublicacionItem>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function likePublicacion(publicacionId: string) {

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/like/${publicacionId}`
  };

  return new Promise<AxiosResponse<TpublicacionItem>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}


export function dislikePublicacion(publicacionId: string) {

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/dislike/${publicacionId}`
  };

  return new Promise<AxiosResponse<TpublicacionItem>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function clickedPublicacion(publicacionId: string) {

  const options = {
    method: 'GET',
    //withCredentials: true,
    url: `${BACKEND_URL}/click/${publicacionId}`
  };

  return new Promise<AxiosResponse<TpublicacionItem>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}
{/*
export function fetchMorePublicaciones(page: number, tipos: TfilterTipo[]) {
  const params = { page, tipos: tipos.join(',') };

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/publicaciones`,
    params: { page, tipos }
  };

  return new Promise<AxiosResponse<Tpublicaciones>>((resolve, reject) => {
    axios(options)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.log(error)
        reject(error);
      })
  });
}
*/}
