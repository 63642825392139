import React from 'react'
import styled from 'styled-components'
import { NavLink, useNavigate } from 'react-router-dom'
import ChatList from './chatList'

import logo_ligth from '../../../assets/icons/ui/logo_ligth.png'
import { TrootState, useAppSelector } from '../../../app/store'


export default function SidebarMiCuentaDesktop() {
  const navigate = useNavigate()
  const user = useAppSelector((state: TrootState) => state.user)

  return(
    <MiCuentaSidebarContainer>
      <TopSide>
  
        <Logo
          onClick={() => navigate('/')}
          src={ logo_ligth }
        />

        { user.data?.given_name &&
          <DataSection>
            <Title> Nombre y Apellido </Title>
            <Subtitle>
              { user.data?.given_name }  { user.data?.family_name }
            </Subtitle>
          </DataSection>
        }

        { user.data?.phone_number &&
          <DataSection>
            <Title> Celular </Title>
            <Subtitle>
              { user.data?.phone_number }
            </Subtitle>
          </DataSection>
        }

        <ChatList/>

      </TopSide>

      <BottomSide>

        <UserEmail> { user.data?.email } </UserEmail>

      </BottomSide>
    </MiCuentaSidebarContainer>
  )
}

const MiCuentaSidebarContainer = styled.div`
z-index: 1000;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 270px;
  background-color: #30302C;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`
const TopSide = styled.div` 
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
`;

const BottomSide = styled.div`
  margin-bottom: 50px;
`
const UserEmail = styled.div`
  color: white;
  white-space: pre-line;
  font-size: 12px;
`

const Logo = styled.img`
  width: 190px;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  color: white;
`
const Subtitle = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  color: white;
  text-align: left;
`
const DataSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 180px;
  margin-top: 10px;
`