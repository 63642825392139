import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import SidebarMiCuentaDesktop from './sidebar/sidebarMiCuentaDesktop'
import SidebarMiCuentaMobile from './sidebar/sidebarMiCuentaMobile'
import HeaderMiCuentaMobile from './header/headerMiCuentaMobile'
import HeaderMiCuentaDesktop from './header/headerMiCuentaDesktop'

import MisPublicaciones from './misPublicaciones'
import MisFavoritas from './misFavoritas'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function MiCuenta() {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  
  const hiddeSidebar = () => setShowSidebar(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <MiCuentaContainer>

      {!isMobile && <SidebarMiCuentaDesktop/> }
      {!isMobile && <HeaderMiCuentaDesktop/> }

      {isMobile && <SidebarMiCuentaMobile showSidebar={showSidebar} hiddeSidebar={hiddeSidebar}/> }
      {isMobile && <HeaderMiCuentaMobile/> }

      <MiCuentaBody isMobile={isMobile}>
        <MisPublicaciones/>
        <MisFavoritas/>
      </MiCuentaBody>

    </MiCuentaContainer>
  )
}

interface isMobile { isMobile: boolean }
const MiCuentaContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 90vh;
`

const MiCuentaBody = styled.div<isMobile>`
  width: calc(100% - 270px);
  margin-left: 270px;
  
  ${(args) => args.isMobile && `
    width: 100%;
    margin-left: unset;
    margin-top: 70px;
  `}
`