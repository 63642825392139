import React from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import { useNavigate } from 'react-router-dom'

import leftArrow from '../../../assets/icons/ui/left-arrow.png'

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `el ${date} de ${month}, ${year}`
}

export default function HeaderMobile(props: TpublicacionItem) {
  const navigate = useNavigate()

  return(
    <TitleSectionContainer>

      <Section>
        <Back
          onClick={() => navigate(-1)}
        >
          <BackIcon src={leftArrow}/>
        </Back>
      </Section>

      <Section>
        <Title> Mi auto a la venta </Title>
      </Section>

      <Section></Section>

    </TitleSectionContainer>
  )
}

const TitleSectionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 10px 0px;
`

  const Title = styled.p`
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
  `
  const Published = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: gray;
    font-family: Montserrat;
    margin-top: 5px;
  `

const Section = styled.div``

const Back = styled.div`
  margin-left: 15px;
`
const BackIcon = styled.img`
  height: 20px;
`