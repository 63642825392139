import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import { useNavigate } from 'react-router-dom'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import { closeSession } from '../../../services/auth/userSlice'
import { googleLogout } from '@react-oauth/google';

import leftArrow from '../../../assets/icons/ui/left-arrow.png'
import userDefault from '../../../assets/icons/ui/user_default.png'
import editIcon from '../../../assets/icons/mipublicacion/edit-tool.png'

import Modal from '../../../components/modal/modal'
import UserEditModal from '../../miPublicacion/components/userEditModal'
import AuthModal from '../../../components/modal/modalAuth'


export default function HeaderMiCuentaDesktop() {
  const navigate = useNavigate()
  const [active, setActive] = useState<boolean>(false)
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ showAuthModal, setShowAuthModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: TrootState) => state.user)
  const dropMenu = useRef<HTMLDivElement |null>(null)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const displayAuthModal = () => setShowAuthModal(true)
  const hiddeAuthModal = () => setShowAuthModal(false)

  function logOut() {
    dispatch(closeSession())
    .then((result) => {      
      if (result.meta.requestStatus === 'fulfilled') {
        googleLogout()
        navigate('/')
      }
    })
  }

  useLayoutEffect(() => {
    function clickBody(event: MouseEvent) {
      let target = event.target as Element
      const id = target.getAttribute('id')
  
      if (!target.closest('#carp10-user-edit')) {
        if (!target.closest('#carp10-login-dropmenu') ) {
          setActive(false)
        }
      }
    }
  
    document.body.addEventListener('click', clickBody )
  
    return () => {
      document.body.removeEventListener('click', clickBody )
    }
  }, [])

  return(
    <TitleSectionContainer>

      <div></div>

      <Links>
        <Title onClick={() => navigate('/')} >
          Comprar
        </Title>

        <Title onClick={() => navigate('/vender')} >
          Vender otro vehiculo
        </Title>
      </Links>


      <UserContainer
        id='carp10-user-edit'
        onClick={() => setActive(!active) }
      >
        <UserIcon
          id='carp10-user-edit-icon'
          //src={userDefault}
          src={user.data?.profile_image ? user.data.profile_image : userDefault}
        />
      </UserContainer>
    
      { active &&
        <DropMenu
          ref={dropMenu}
        >
          <FirstRow>
            <EditarIcon src={editIcon} />

            <CloseSession
              onClick={() => displayModal()}
            >
              Editar Perfil
            </CloseSession>
          </FirstRow>

          {/* <HorizonatlDivisor/> */}

          <SecondRow>
            <Inspecionar
              onClick={() => {
                navigate('/inspeccion');
                setActive(false);
              }}
            >
              Solicitar Inspección
            </Inspecionar>
          </SecondRow>

          <FirstRow>
            { user.data &&
              <CloseSession
                onClick={() => logOut()}
              >
                Cerrar Sesión
              </CloseSession>
            }
          </FirstRow>

        </DropMenu>
      }


      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <UserEditModal hiddeModal={hiddeModal} />
        </Modal>
      }

      { !user.data &&
        <Modal closeModal={hiddeAuthModal}>
          <AuthModal hiddeModal={hiddeAuthModal}/>
        </Modal>
      }

    </TitleSectionContainer>
  )
}

const TitleSectionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 10px 0px;
  margin-left: 270px;
  position: relative;
`
  const Title = styled.p`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 150px;
    cursor: pointer;
  `

const Links = styled.div`
  display: flex;
`

const UserDrop = styled.div`
  position: relative;
`
const UserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #666;
  border-radius: 50px;
  align-self: flex-end;
  margin-right: 50px;
  cursor: pointer;
`
  const UserIcon = styled.img`
    height: 40px;
  `

  const EditarIcon = styled.img`
    height: 25px;
  `

const DropMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 55px;
  right: 0px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  width: 230px;
  background-color: white;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.1);
  z-index: 99;
`
  const FirstRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `
  const HorizonatlDivisor = styled.div`
    width: 100px;
    border-bottom: solid 2px #fe7027;
    margin: 5px auto 5px auto;
  `
  const SecondRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 5px 0px;
  `
 
  const CloseSession = styled.div`
    cursor: pointer;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  `
  const Inspecionar = styled.span`
    cursor: pointer;
    font-family: Montserrat;
    align-self: center;
    background-color: #FE7027;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    width: 150px;
    padding: 5px 0px 5px 0px;
    margin-top: 5px;
  `