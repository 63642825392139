import React from 'react'
import styled from 'styled-components'
import { useUpdateMiPublicacionMutation } from '../../../services/apiSlice/apiSlice'

import loadingGif from '../../../assets/gifs/loading.gif'

interface props { publicacionId: string, closeModal: () => void }
export default function ModalVendido(props: props) {
  const [ updateMiPublicacion, respUpdateMiPublicacion ] = useUpdateMiPublicacionMutation()

  function MarcarVendido(vendidoEn: string) {
    updateMiPublicacion({
      publicacionId: props.publicacionId,
      payload: {
        vendido: true,
        vendido_en: vendidoEn,        
      }
    })
    .then((resp) => {
      props.closeModal()
    })
  }

  return(
    <Continer>
      <Title> ¿Como lo vendio el vehiculo? </Title>

      { !respUpdateMiPublicacion.isLoading ?
        <Options>
          <Option onClick={() => MarcarVendido("carp10")} > A traves de Carp10 </Option>
          <Option onClick={() => MarcarVendido("facebook")}> En Facebook Marketplace </Option>
          <Option onClick={() => MarcarVendido("otros")}> Otros medios </Option>
        </Options>
      :
        <Loading>
          <LoadinImg src={loadingGif}/>
        </Loading>
      }
    </Continer>
  )
}

const Continer = styled.div`
  padding: 10px 30px;
  font-family: Montserrat, sans-serif;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
`
const Options = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
`
  const Option = styled.div`
    
    width: 100%;
    text-align: left;
    background-color: #dedede;
    border-radius: 2px;
    padding: 5px 10px;
    margin: 5px 0px;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
    }
  `

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
`
  const LoadinImg = styled.img`
    width: 50px;
    padding: 5px 10px;
    margin: 5px 0px;
  `