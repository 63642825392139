import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import Modal from '../../components/modal/modal'
import AuthModal from '../../components/modal/modalAuth'

import { TrootState, useAppSelector } from '../../app/store'
import { useNavigate } from 'react-router-dom'

const responsive = {
    mobileThreshold: 950,
    SDthreshold: 1340,
  }

export default function InfoPublicidadVideo() {
    const navigate = useNavigate()
    const { bodyWidth, bodyTop } = useViewport()
    const [ showModal, setShowModal] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState(false)
    const [, setIsSD] = useState(false)

    const user = useAppSelector((state: TrootState) =>  state.user)

    const displayModal = () => setShowModal(true)
    const hiddeModal = () => setShowModal(false)

    useLayoutEffect(() => {
        if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
        else setIsMobile(false);

        if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
        else setIsSD(false)

    }, [bodyWidth, bodyTop])

    return(
      <InfoPublicidadVideoContainer
        isMobile={isMobile}
        className='view InfoPublicidadVideoContainer'
        >
        <Content>
            <Title isMobile={isMobile}>
                Video Publicitario<br/>
            </Title>
            <SubTitle2>
                { 'Precio:  915 Bs.'}
            </SubTitle2>
            
            <TextContainer>
                    <p>Aquí puedes colocar toda la información que desees acerca de la publicidad en video.</p>
                    <p>Los videos son una excelente manera de comunicar ideas complejas de forma sencilla y atractiva.</p>
            </TextContainer>


        { !user.data && showModal &&
            <Modal
            closeModal={hiddeModal}
            >
            <AuthModal
                hiddeModal={hiddeModal}
            />
            </Modal>
        }
        </Content>
      </InfoPublicidadVideoContainer>
    )
}

interface isMobile { isMobile: boolean }
interface TInfoPublicidadVideoContainer { isMobile: boolean }
const InfoPublicidadVideoContainer = styled.div<TInfoPublicidadVideoContainer>`
  ustify-content: center;
  padding: 20px 0px 20px 0px; 
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0px;
`


interface Ttitle { isMobile: boolean }
const Title = styled.div<Ttitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 26px;
  color: #FE7027;
  ${(args) => args.isMobile && `
    width: 300px;
    margin: auto;
  `}
`
const SubTitle2 = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin-top: 10px;
`

const TextContainer = styled.div`
    margin-top: 20px;
    width: 100%;
`;