import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TpublicacionesState, Tfilter, TfilterTipo, TfilterUIState } from "./types"

const initialState: TpublicacionesState = {
  ui: {
    state: 'hidden'
  },
  filter: {
    search: undefined,
    tipos: [],
    minPrice: undefined,
    maxPrice: undefined,
    inspeccionado: false,
    permutable: false
  },
  sort: undefined
}

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    displayFilterBar: (state) => {
      state.ui.state = 'visible'
    },
    hiddeFilterBar: (state) => {
      state.ui.state = 'hidden'
    },
    setSearch: (state, action: PayloadAction<string|undefined>) => {
      state.filter.search = action.payload
    },
    setFilterTipo(state, action: PayloadAction<TfilterTipo>) {
      const { payload } = action
      let currentTipos = [...state.filter.tipos]

      if (currentTipos.includes(payload)) {
        const index = currentTipos.findIndex((item) => item === payload)

        if (index > -1) {
          currentTipos.splice(index, 1)
          state.filter.tipos = [...currentTipos]
        }
      } else {
        state.filter.tipos = [...state.filter.tipos, payload]
      }
      
    },
    setFilterMinPrice(state, action: PayloadAction<number|undefined>) {
      state.filter.minPrice = action.payload
    },
    setFilterMaxPrice(state, action: PayloadAction<number|undefined>) {
      state.filter.maxPrice = action.payload
    },
    setSort(state, action: PayloadAction<string>) {
      state.sort = action.payload
    },
    setFilterConDiagnostico(state, action: PayloadAction<boolean>) {
      state.filter.inspeccionado = action.payload;
    },
    setFilterPermutable(state, action: PayloadAction<boolean>) {
      state.filter.permutable = action.payload;
    },
    cleanAllFilters: (state) => {
      state.filter = {
        search: undefined,
        tipos: [],
        minPrice: undefined,
        maxPrice: undefined,
        inspeccionado: false,
        permutable: false
      }
    }
  }
})

export const {
  setFilterTipo,
  setFilterMinPrice,
  setFilterMaxPrice,
  displayFilterBar,
  hiddeFilterBar,
  setSearch,
  setSort,
  cleanAllFilters,
  setFilterConDiagnostico,  
  setFilterPermutable
} = filterSlice.actions

export default filterSlice.reducer