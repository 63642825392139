import React from 'react';
import whatsappIcon from '../../assets/icons/social/whatsapp.png'; // Asegúrate de tener una imagen/icono de WhatsApp

const whatsappButton = () => {
  return (
    <a href="https://wa.me/59176604123" target="_blank" rel="noopener noreferrer" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
      <img src={whatsappIcon} alt="Chat en WhatsApp" style={{ width: '60px', height: '60px' }}/>
    </a>
  );
};

export default whatsappButton;
