import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { hiddeFilterBar } from '../../services/filterPublicaciones/filterSlice'
import { loadPublicaciones, loadingItems } from '../../services/publicaciones/publicacionesSlice'
import { useGetPublicacionesQuery } from '../../services/apiSlice/apiSlice'

import DesktopFilter from '../../views/home/desktopFilter'

import closeIcon from '../../assets/icons/ui/close-circle.png'

export default function FilterBar() {
  const dispatch = useAppDispatch()
  const filter = useAppSelector((state: TrootState) => state.filter)

  const publicaciones = useAppSelector((state: TrootState) => state.publicaciones)





  useEffect(() => {
    if (filter.ui.state === 'visible')
    document.body.style.overflow = 'hidden';

    if(filter.ui.state === 'hidden')
    document.body.style.overflow = 'unset';

  }, [filter.ui]);

  return(
    <FilterBarBackground
      showFilterBar={filter.ui.state === 'visible'}
    >
      <FilterBarMenu
        showFilterBar={filter.ui.state === 'visible'}
        className="FilterBar"
        style={{ zIndex: 999 }}
      >
        <Header>
          <LeftSideHeader/>
          Filtros
          <CloseIcon
            src={closeIcon}
            onClick={() => dispatch(hiddeFilterBar())}
          />
        </Header>

        <Body>

          <DesktopFilter/>
          <Showing
            onClick={() => dispatch(hiddeFilterBar())}
          >
            { publicaciones?.items? `Mostrar ${publicaciones.items.length} vehículos` : `Cargando`}
          </Showing>

        </Body>

        <Footer>
          
        </Footer>

      </FilterBarMenu>

    </FilterBarBackground>
  )
}

interface TbarBackground { showFilterBar: boolean }
const FilterBarBackground = styled.div<TbarBackground>`
  position: fixed;
  z-index: 1001;
  top: 0px;
  bottom: 0px;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity .4s ease-out;
  ${ props => props.showFilterBar && `
    opacity: 1;
    left: 0px;
    right: 0px;
  `};
`

const FilterBarMenu = styled.div<TbarBackground>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  top: 100vh;
  bottom: 0px;
  right: 0px;
  width: 100%;

  background-color: white;  
  z-index: 999;
  transition: transform .5s ease-out;
  ${ props => props.showFilterBar && `
      transform: translate(0, -100vh);
  `};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  justify-content: space-between;
  font-weight: 700;
  width: 100%;
  height: 60px;
  box-shadow: 0px 1px 8px #888888;
`
  const LeftSideHeader = styled.div`
    width: 30px;
    margin-left: 15px;
  `
  const CloseIcon = styled.img` 
    width: 30px;
    margin-right: 15px;
    cursor: pointer;
  `

const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 120px);
`

const Footer = styled.div`
  margin-bottom: 20px;
`
  const Showing = styled.button`
    font-family: Montserrat;
    background-color: white;
    color: #fe7027;
    border: solid 2px #fe7027;
    border-radius: 5px;
    padding: 5px 20px;
    width: 80%;
    margin: 20px auto 0px auto;
  `