import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel as CarouselBoot } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useConfig from '../../hooks/useConfig'
import useViewport from '../../hooks/useViewport'

import FullImageModal from '../../components/imageViewer/modalImageFullScreen'
import ImageViewer from '../../components/imageViewer/imageViewer'

import close from '../../assets/icons/ui/close-circle.png'
import logo from '../../assets/icons/ui/logo_ligth.png'
import loading2 from '../../assets/gifs/loading-car.svg'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

const requiredPhotosUsuario = [
  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_asientos_delanteros',
]

const requiredPhotosInspector = [
  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_capo_abierto',
]

const order = [
  'exterior_1',
  'exterior_2',
  'exterior_3',
  'exterior_4',
  'exterior_5',

  'interior_1',
  'interior_2',
  'interior_3',
  'interior_4',
  'interior_5',
  'interior_6',
  'interior_7',
  'interior_8',

  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_lateral',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_capo_abierto',
  
  'foto_maletero_abierto',
  'foto_volante',
  'foto_tablero_01',
  'foto_tablero_02',
  'foto_asientos_delanteros',
  'foto_asientos_traseros',
  'foto_caja_cambio',
  'foto_puerta_conductor_adentro',
  
  'foto_llanta_delantera_derecha',
  'foto_llanta_delantera_izquierda',
  'foto_llanta_trasera_derecha',
  'foto_llanta_trasera_izquierda',
  'foto_llanta_auxilio'
]

function extractProp(key: string) {
  const keySplited = key.split('/')
  return keySplited[keySplited.length - 1].replace('.jpeg', '')
}

// *-****************
export default function AutoCarousel(props: TpublicacionItem) {
  const { app } = useConfig()
  const navigate = useNavigate()
  const [ photos, setPhotos] = useState<string[]>([])
  const [ loadedImages, setLoadedImages] = useState<boolean>(false)
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ showImage, setShowImage ] = useState<string | undefined>(undefined)

  const [index, setIndex] = useState(0);

  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') || item.match('interior_'))) {
        return true
      } else return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)
    .sort((a,b) => {
      return order.indexOf(extractProp(a)) - order.indexOf(extractProp(b))
    })

    const loadAlPhotos = fotosUrls.map((picture) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() { resolve(img) }
        img.onerror = img.onabort = function() { reject(picture) }
        img.src = picture;  
      })
    });

    Promise.all(loadAlPhotos).then((values) => {
      setLoadedImages(true)
    })

    setPhotos([...fotosUrls])    
  }, [])

  useEffect(() => {    
    function keyNavigate(event: KeyboardEvent) {
      if (event.key === 'Escape') hiddeModal()
    }

    document.addEventListener('keydown', keyNavigate)
    return () => {
      document.removeEventListener('keydown', keyNavigate)
    }
  }, [])

  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };


  function carouselChange() {
    console.log('mounted')
  }

  return(
    <AutoCarouselContainer
      className='AutoCarousel'
      id='AutoCarouselID'
    >
      { !showModal &&
        <CloseCorner
        > 
          <CloseContainer onClick={() => navigate(-1)}>
            <CloseIcon src={close}/>
          </CloseContainer>

          { !isMobile &&
            <LogoContainer href='/'>
              <Logo src={logo} />
            </LogoContainer>
          }
        </CloseCorner>
      }

    { isMobile ?
        <CarouselBoot
          activeIndex={index} onSelect={handleSelect}
        >
          { loadedImages ? photos.map((photo, idx) => (
            <CarouselBoot.Item
              key={`pub-photo-${props.id_publicacion}-${idx}`}
            >
              <ImageSection
                isMobile={isMobile}
                className='wrapperImageFit'
                onClick={() => {
                  setShowImage(photo)
                  setShowModal(true)
                }}
              >
                <img src={photo} width={'100%'} height={'100%'} className='imageFit'/>
              </ImageSection>
            </CarouselBoot.Item>
          ))
          :
          <ImageSection isMobile={isMobile} className='wrapperImageFit'>
            <img src={loading2} width={'100%'} height={'100%'}/>
          </ImageSection>
        }
        </CarouselBoot>
      :
        <Carousel
          showThumbs={ isMobile ? false : true }
          thumbWidth={140}
          useKeyboardArrows={true}
          autoFocus={false}
          onChange={carouselChange}
        >
          { props && photos.map((photo) => (
            <ImageSection
              key={`pub-photo-${photo}`}
              isMobile={isMobile}
              onClick={() => {
                setShowImage(photo)
                setShowModal(true)
              }}
            >
              <img src={photo} />
              {/* <p className="legend">Legend 1</p> */}
            </ImageSection>
          ))}
        </Carousel>
    }

    { showImage && showModal &&
      <ImageViewer
        closeModal={hiddeModal}
        src={showImage}
        item={ props }
      />
    }

    </AutoCarouselContainer>
  )
}

interface isMobile { isMobile: boolean }
const AutoCarouselContainer = styled.div`
  
`
const ImageSection = styled.div<isMobile>`
  background-color: #cccccc;
  width: 100%;
  height: calc(85vh - 50px);
  min-height: calc(85vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${(args) => args.isMobile && `
    height: 400px;
    min-height: 400px;
  `}
`
const CloseCorner = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  top: 20px;
  left: 20px;
  z-index: 10;
`
const CloseContainer = styled.div`
  cursor: pointer;
`
const CloseIcon = styled.img`
  height: 40px;
`
const LogoContainer = styled.a`
  background-color: #30302c;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`
const Logo = styled.img`
  height: 20px;
`