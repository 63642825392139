import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'

import {
  escaneo_general,
  interior_vehiculo,
  seguridad_carretera,
  bajo_capo,
  datos_adicionales,
  estetica_vehiculo,
} from './autoTypes'

import check from '../../assets/icons/ui/check_green.png'
import warning from '../../assets/icons/ui/warning.png'
import useViewport from '../../hooks/useViewport'
import useConfig from '../../hooks/useConfig'
import ObsViewer from '../../components/imageViewer/ObsViewer'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface thisProps { inspeccion: any, tipo: string }
export default function EvaluacionModal(props: thisProps) {
  const { app } = useConfig()
  const [ properties, setProperties ] = useState<{ label: string, prop: string}[]>([]);
  const [ values, setValues ] = useState<boolean[]>([])
  const [ title, setTitle] = useState<string | undefined>(undefined)
  const [ showObsView, setShowObsView ] = useState<boolean>(false)
  const [ showImg, setShowImg ] = useState<string | undefined>(undefined)
  const [ showObs, setShowObs ] = useState<string | undefined>(undefined)
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const displayModal = () => setShowObsView(true)
  const hiddeModal = () => setShowObsView(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    let inspeccionProps: string[] = [];
    // console.log(props.tipo)

    switch(props.tipo) {
      case 'escaneo':
        inspeccionProps = escaneo_general
        setTitle('Escaneo de sensores')
        break;
      case 'interior':
        inspeccionProps = interior_vehiculo
        setTitle('Interior del vehículo')
        break;
      case 'seguridad':
        inspeccionProps = seguridad_carretera
        setTitle('Seguridad en carretera')
        break;
      case 'capo':
        inspeccionProps = bajo_capo
        setTitle('Bajo el capó')
        break;
      case 'adicionales':
        inspeccionProps = datos_adicionales
        setTitle('Datos Adicionales')
        break;
      case 'estetica':
        inspeccionProps = estetica_vehiculo
        setTitle('Estética del vehpiculo')
        break;     
    }

    const propertiesToSet = Object.entries(props.inspeccion).filter(([prop, value]) => inspeccionProps.includes(prop) && value !==null)

    const propsToSet = propertiesToSet.map(([prop,]) => {
      switch (prop) {
          case 'motor_sonido': return { label: 'Sonido del Motor', prop: 'motor_sonido' };
          case 'plastico_delantero': return { label: 'Plastico delantero', prop: 'plastico_delantero' };
          case 'correa': return { label: 'Correa', prop: 'correa' };
          case 'radiador_mangueras': return { label: 'Manguera del Radiador', prop: 'radiador_mangueras' };
          case 'bateria_agua': return { label: 'Agua de batería', prop: 'bateria_agua' };
          case 'bateria': return { label: 'Batería', prop: 'bateria_agua' };
          case 'alternador': return { label: 'Alternador', prop: 'alternador' };
          case 'motor_filtro_aire': return { label: 'Filtro de aire del motor', prop: 'motor_filtro_aire' };
          case 'motor_aceite': return { label: 'Aceite del motor', prop: 'motor_aceite' };
          case 'freno_liquido': return { label: 'Liquido de freno', prop: 'freno_liquido' };
          case 'liquido_refrigerante': return { label: 'Liquido refrigerante', prop: 'liquido_refrigerante' };
          case 'compresor_aire_acondicionado': return { label: 'Compresor del aire acondicionado', prop: 'compresor_aire_acondicionado' };
          case 'gas_refrigerante': return { label: 'Gas refrigerante', prop: 'gas_refrigerante' };
          case 'motor_aceite_espesor': return { label: 'Espesor del aceite', prop: 'motor_aceite_espesor' };

          case 'fugas_hidraulicas': return { label: 'Sin fugas hidráulicas', prop: 'fugas_hidraulicas' };

          case 'computadora_motor': return { label: 'Computadora del motor', prop: 'computadora_motor' };
          case 'computadora_caja': return { label: 'Computadora de la caja', prop: 'computadora_caja' };
          case 'airbags': return { label: 'Airbags', prop: 'airbags' };
          case 'aire_acondicionado': return { label: 'Aire acondicionado', prop: 'aire_acondicionado' };
          case 'panel_instrumento': return { label: 'Panel de Instrumentos', prop: 'panel_instrumento' };
          case 'inmovilizador_llave': return { label: 'Inmovilizador llave / Llave con chip', prop: 'inmovilizador_llave' };
          case 'eps': return { label: 'EPS (Dirección Asistida)', prop: 'eps' };
          case 'bcm': return { label: 'BCM (Body Control Module)', prop: 'bcm' };
          case 'awd_4wd': return { label: '4WD / AWD', prop: 'awd_4wd' };
          case 'tpms': return { label: 'TPMS (Sistema de monitoreo de presión de neumáticos)', prop: 'tpms' };
          case 'inyeccion_pareja': return { label: 'Inyeccion pareja de gasolina', prop: 'inyeccion_pareja' };
          case 'odometro_real': return { label: 'Kilometraje Real', prop: 'odometro_real' };

          
          case 'control_vidrios': return { label: 'Controles de vidrios eléctricos (delanteros y traseros)', prop: 'control_vidrios' }; 
          case 'controles_volante': return { label: 'Controles en el volante', prop: 'controles_volante' }; 
          case 'ac_interruptores': return { label: 'Interruptores del A/C', prop: 'ac_interruptores' };
          case 'ac_sistema': return { label: 'Sistema de A/C', prop: 'ac_sistema' };
          case 'ac_salida_delantera': return { label: 'Salida de A/C delantero', prop: 'ac_salida_delantera' };
          case 'ac_salida_trasera': return { label: 'Salida de A/C trasero', prop: 'ac_salida_trasera' };
          case 'radio': return { label: 'Radio', prop: 'radio' };
          case 'retrovisores_plegables': return { label: 'Retrovisores Plegables', prop: 'retrovisores_plegables' };
          case 'camara_retro': return { label: 'Cámara de retro', prop: 'camara_retro' };


          case 'direccion': return { label: 'Dirección', prop: 'direccion' };
          case 'terminales_direccion': return { label: 'Terminales de dirección', prop: 'terminales_direccion' };
          case 'cinturones_seguridad': return { label: 'Cinturones de seguridad', prop: 'cinturones_seguridad' };
          case 'luz_freno': return { label: 'Luz de freno', prop: 'luz_freno' };
          case 'pastillas_freno': return { label: 'Pastillas de freno', prop: 'pastillas_freno' };
          case 'llantas_neumaticos': return { label: 'Llantas / Neumáticos', prop: 'llantas_neumaticos' };
          case 'aros_originales': return { label: 'Aros originales', prop: 'aros_originales' };
          case 'limpia_parabrisas_delanteros': return { label: 'Limpia parabrisas delanteros', prop: 'limpia_parabrisas_delanteros' };
          case 'limpia_parabrisas_traseros': return { label: 'Limpia parabrisas traseros', prop: 'limpia_parabrisas_traseros' };


          case 'tapa_maletero': return { label: 'Tapa del maletero', prop: 'tapa_maletero' };
          case 'herramientas': return { label: 'Herramientas', prop: 'herramientas' };
          case 'llanta_auxilio': return { label: 'Llanta de auxilio', prop: 'llanta_auxilio' };
          //case 'numero_llaves': return { label: 'Número de Llaves con chip', prop: 'numero_llaves' }; // numero_llave es valor numérico no booleano. Revisar después como se va a mostrar


          case 'zonas_repintadas': return { label: 'Espesor de pintura', prop: 'zonas_repintadas' };
          case 'zonas_abolladuras': return { label: 'Sin abolladuras', prop: 'zonas_abolladuras' };
          case 'zonas_raspaduras': return { label: 'Sin raspaduras notorias', prop: 'zonas_raspaduras' };
          default: return { label: prop, prop };
      }
  });
  



    setProperties(propsToSet)
    const valueToSet = propertiesToSet.map(([,value]) => (value as boolean))
    setValues(valueToSet)

  }, [])

  // useEffect(() => {
  //   console.log('properties')
  //   console.log(props.inspeccion)
  // }, [properties])

  return(
    <EvaluacionModalContainer
      isMobile={isMobile}
    >
      { title &&
        <>
        <Title> { title } </Title>
        <HorizonatlDivisor/>
        </>
      }

      { properties.map(({ label, prop}, idx) => (
        props.inspeccion[prop] !== null ? ( // Se añade esta condición
          <Row>
            <Prop> { label } </Prop>
            {/*<Prop> { prop.replace(/\_/g, ' ') } </Prop>*/}
            <CheckIcon src={values[idx] ? check : warning} />

            { props.inspeccion[`${prop}_desc`] && 
              <ObsRow
                onClick={() => {
                  setShowObsView(true)
                  setShowImg(`${app.STATIC_FILES}inspecciones/${props.inspeccion.id_sub}/observaciones/${prop}.jpeg`)
                  setShowObs(props.inspeccion[`${prop}_desc`])
                }}
              >
                <ObsTex> *{ props.inspeccion[`${prop}_desc`] } </ObsTex>
                { props.inspeccion[`${prop}_img`] && props.inspeccion.id_sub &&
                  <ObsImg src={`${app.STATIC_FILES}inspecciones/${props.inspeccion.id_sub}/observaciones/${prop}.jpeg`}/>
                }
              </ObsRow>
            }

            <PropDivisor/>
          </Row>
        ) : null
      ))}

      { showObsView && showObs && showImg && 
        <ObsViewer
          closeModal={hiddeModal}
          src={showImg}
          text={showObs}
        />
      }
    </EvaluacionModalContainer>
  )
}

interface isMobile { isMobile: boolean }
const EvaluacionModalContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 20px;
  width: 450px;
  max-height: 520px;
  overflow: auto;
  ${(args) => args.isMobile && `
    width: 340px;
  `}
`

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  margin: auto;
  width: 90%;
`
const ObsRow = styled(Row)`
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #888;
    color: white;
  }
`

const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #b9b9b9;
  margin: 10px auto 15px auto;
`
const PropDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #b9b9b9;
  margin: 10px auto 15px auto;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
`
const Prop = styled.div`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  /*text-transform: capitalize;*/
`
const CheckIcon = styled.img`
  height: 20px;
`
const ObsTex = styled.div`
  font-family: Barlow;
  font-size: 13px;
  text-align: left;
  max-width: 170px;
`
const ObsImg = styled.img`
  height: 40px;
  border-radius: 5px;
  //margin-right: 40px;
`