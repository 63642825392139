import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Tagendar } from './agendar'
import useViewport from '../../hooks/useViewport'
import { Carousel } from "react-bootstrap";

import NavigatorComponent from './navigatorComponent'

import good from '../../assets/icons/ui/good2.png'
import check from '../../assets/icons/ui/check_white.png'

import car from '../../assets/icons/inspeccion/car.png'
import car_shiny from '../../assets/icons/inspeccion/car_shiny.png'
import documentos from '../../assets/icons/inspeccion/documentos.png'
import { TrootState, useAppSelector } from '../../app/store';

const data = [
  {
    image: car_shiny,
    text: 'El vehículo debe estar lavado'
  },
  {
    image: documentos,
    text: 'Tener todos los documentos del vehículos ese día para su revisión. RUAT, SOAT, Carnet de propietario, CI, póliza de importación'
  },
  {
    image: car,
    text: 'Procurar que haya un espacio dondes se pueda inspeccionar el vehículo, puede ser un garaje o en la calle'
  },
  {
    image: car,
    text: 'El lugar debe ser iluminado para poder tomar las fotos'
  }
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}


interface Tprops { changeStep: (step: Tagendar) => void }
export default function AgendarSuccess(props: Tprops) {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const inspeccion = useAppSelector((state: TrootState) => state.inspeccionPubli)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <AgendarSuccessContainer
      className='view'
    >
      <Content>
        <Group isMobile={isMobile}>
          <NavigatorComponent step={3}/>
          <Title isMobile={isMobile}>
            { inspeccion.tipo === 'agendado' ?
                'La Inspección ha sido agendada con éxito'
              :
                'Tu Solicitud ha sido enviada'
            }
            
          </Title>

          <BackIcon isMobile={isMobile}>
            <CheckIcon src={check} isMobile={isMobile}/>
          </BackIcon>
        </Group>

        <Group isMobile={isMobile}>
          <Subtitle isMobile={isMobile}>
            Recuerda tomar estas previsiones para el día de la inspección
          </Subtitle>

          { isMobile ? 
            <Carousel
              variant="dark"
              controls={false}
              interval={3500}
            >
              { data.map((requisito, idx) => (
                <Carousel.Item key={`requisito-${idx}`}>
                  <RequisitoCard>
                    <Icon src={requisito.image}/>
                    <Text> { requisito.text } </Text>
                  </RequisitoCard>
                </Carousel.Item>
              ))}
            </Carousel>
          :
            <RequisitosContainer>
              { data.map((requisito, idx) => (
                <RequisitoCard key={`requisito-${idx}`}>
                  <Icon src={requisito.image}/>
                  <Text> { requisito.text } </Text>
                </RequisitoCard>
              ))}
            </RequisitosContainer>
          }
        </Group>

      </Content>
    </AgendarSuccessContainer>
  )
}

const AgendarSuccessContainer = styled.div`
  width: 100%;  
`
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: max(calc(100vh - 150px), 567px);
  margin-top: 40px;
  gap: 15px;
`

interface Tgroup { isMobile: boolean }
const Group = styled.div<Tgroup>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${(args) => args.isMobile ? '15px' : '40px'};
`

interface Ttitle { isMobile: boolean }
const Title = styled.div<Ttitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 32px;
  color: black;
  ${(args) => args.isMobile && `
    font-size: 24px;
    width: 300px; 
  `}
`

interface TbackIcon { isMobile: boolean }
const BackIcon = styled.div<TbackIcon>`
  background-color: #FE7027;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(args) => args.isMobile && `
    width: 70px;
    height: 70px;
  `}
`
interface Tcheck { isMobile: boolean }
const CheckIcon = styled.img<Tcheck>`
  height: 50px;
  ${(args) => args.isMobile && `
    height: 30px; 
  `}
`
interface Tsubtitle { isMobile: boolean }
const Subtitle = styled.div<Tsubtitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #444444;
  ${(args) => args.isMobile && `
    font-size: 15px;
    width: 280px; 
  `}
`

const RequisitosContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const RequisitoCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;
  width: 300px;
  height: 220px;
`
  const Icon = styled.img`
    height: 50px;
    width: auto;
    object-fit: contain;
  `
  const Text = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    width: 200px;
    text-align: justify;
  `