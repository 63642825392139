import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import { useGetPublicacionQuery } from '../../services/apiSlice/apiSlice'
import { useParams } from 'react-router-dom'
import useViewport from '../../hooks/useViewport'
import './AutoView.css'

import AutoCarousel from './autoCarousel'

import loading2 from '../../assets/gifs/loading-car.svg'
import AutoCard from './autoCard'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function AutoView() {
  const { publicacionId } = useParams()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const { data: publicacion, isSuccess } = useGetPublicacionQuery(publicacionId)

  return(
    <AutoViewContainer isMobile={isMobile}>

      <FirstSide
        isMobile={isMobile}
      >
        { isSuccess && 
          <AutoCarousel { ...publicacion } />
        }
      </FirstSide>

      <SecondSide
        isMobile={isMobile}
      >
        { isSuccess && 
          <AutoCard {...publicacion}/>
        }
      </SecondSide>

    </AutoViewContainer>
  )
}

interface isMobile { isMobile: boolean }
const AutoViewContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  position: relative;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
  `}
`

const FirstSide = styled.div<isMobile>`
  //background-color: black;
  height: 100vh;
  width: 60%;
  //padding: 10px 10px;
  ${(args) => args.isMobile && `
    width: 100%;
    height: initial;
  `}
`

const SecondSide = styled.div<isMobile>`
  height: calc(100vh - 10px);
  width: 40%;
  padding: 10px 30px;
  //border: solid 2px #999;
  margin: 5px;
  ${(args) => args.isMobile && `
    width: calc(100% - 20px);
    padding: 0px 10px;
    border: none;
    margin: auto;
    height: auto;
    padding-bottom: 50px;
  `}
`
