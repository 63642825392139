import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Tview } from '../../app/types';
import { hiddeSidebar } from '../../app/AppUISlice';

import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TrootState, useAppDispatch } from '../../app/store';

interface thisProps { view: Tview, theme: string }
export default function ViewItem(props: thisProps) {
  const { view, theme } = props
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const { showSidebar } = useSelector((state: TrootState) => state.appUI)
  const userState = useSelector((state: TrootState) => state.user)

  function showThis(caso: string) {
    switch (caso) {
      case 'never':
          return false
      case 'ever':
          return true
      case 'noLogged':
        if (userState.data?.email) return false
        return true
      case 'logged':
        if (userState.data?.email) return true
        return false
      default:
        return true
    }
  }

  function specialStyle(text: string): ReactElement {
    if (text === 'Vender mi auto') {
      return(<Especial> Publica <Gratis>Gratis</Gratis> tu auto a la venta </Especial>)
    }
    if (text === 'Diagnóstico vehicular') {
      return(<Especial> Diagnóstico <br />Vehicular </Especial>)
    }
    /*
    if (text === 'Publicidad') {
      return(<Especial> Video <br />Publicitario</Especial>)
    }
    */
    return( <> {text} </> )
  }

  return(
    <ViewNavItem
      isActive={ pathname === view.path }
      isSidebar={showSidebar}
      theme={theme}
      show={showThis(view.show)}
    >
      <NavLink
        to={view.path}
        onClick={() => dispatch(hiddeSidebar())}
      >
        <ButtonLink>
          { specialStyle(view.title) }
        </ButtonLink>
      </NavLink>
    </ViewNavItem>
  )
}

interface TViewNavItem { isActive: boolean, isSidebar: boolean, theme: string, show: boolean }
const ViewNavItem = styled.div<TViewNavItem>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 10px 0px 10px;
  ${(args) => !args.show && `
    display: none;
  `};
  & > a {
    text-decoration: none;
    color: ${(props) => props.isSidebar ? `#999999` : props.theme === 'ligth' ? `#999999` : `white`};

    ${(props) => props.isActive && `
      color: white;
      
    `}
  }
`

const ButtonLink = styled.div`
  width: calc(min-content + 10px);
  margin: 10px auto 10px auto;
  padding: 0px 10px 0px 10px;
`

const Especial = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
  justify-content: center;
  white-space: pre;
  margin: auto;
`
  const Gratis = styled.div`
    background-color: #fe7027;
    padding: 0px 7px;
    border-radius: 2px;
    color: white;
    border: solid 1px white;
    font-size: 16px;
  `