import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { TloginUserPayload,
  TuserProfileResponse,
  TsimpleResponse,
  TresetPasswordEmailResponse,
  TsignUpRegister,
  TgoogleAuthReq } from './userApiTypes'


let BACKEND_URL = 'https://api.carp10.com'

export function getUserProfile() {
  const axiosOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/auth/myaccount`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to authenticate')
    })
  })
}

export function loginUser(props: TloginUserPayload) {
  const { username, password } = props

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      username,
      password
    }),
    url: `${BACKEND_URL}/auth/login`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function googleAuth(props: TgoogleAuthReq) {
  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      
      type: 'google',
      ...props,
    }),
    url: `${BACKEND_URL}/auth/google`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function logOut() {
  const axiosOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/auth/logout`
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to logout')
    })
  })
}


interface resetPassword {
  type: string,
  username?: string,
  verificationCode?: string,
  newPassword?: string,
}

export function resetPasswordFlow(payload: resetPassword) {

  const options = {
    method: 'POST',
    data: JSON.stringify({
      ...payload,
    }),
    url: `${BACKEND_URL}/auth/signup`
  };

  return new Promise<AxiosResponse<TresetPasswordEmailResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

interface resetPassword {
  type: string,
  phone_number?: string,
  email?: string,
  password?: string,
}

export function signUpRegister(payload: resetPassword) {

  const options = {
    method: 'POST',
    data: JSON.stringify({
      ...payload,
    }),
    url: `${BACKEND_URL}/auth/signup`
  };

  return new Promise<AxiosResponse<TsignUpRegister>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function updateUserData(payload: any) {

  const options = {
    method: 'PUT',
    withCredentials: true,
    data: JSON.stringify({
      ...payload,
    }),
    url: `${BACKEND_URL}/myaccount`
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function verifyUserExists(email: string) {

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'checUserExists',
      email
    }),
    url: `${BACKEND_URL}/auth/google`
  }

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })

}