import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { cleanAllFilters, setFilterTipo, setFilterMinPrice, setFilterMaxPrice, setSearch, setFilterPermutable, setFilterConDiagnostico } from '../../services/filterPublicaciones/filterSlice'
import useViewport from '../../hooks/useViewport'

import SortSelect from '../../components/selects/sortSelect'

import close from '../../assets/icons/ui/close-circle.png'
import sortIcon from '../../assets/icons/ui/sort.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface Tsticky { viewTop: number | undefined }
export default function CleanFilters(props: Tsticky) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const filters = useAppSelector((state: TrootState) => state.filter.filter)
  const [areFilters, setAreFilters] = useState(false)
  const [containerTop, setContainerTop] = useState(0)

  const refPosition = useRef<HTMLDivElement>(null)
  const stickyBar = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  // Scrolling hook
  useLayoutEffect(() => {
    const { current: containerCurrent } = refPosition;

    if (containerCurrent) {
      setContainerTop(containerCurrent.getBoundingClientRect().top);
    }

  }, [bodyTop])

  useEffect(() => {
    if (filters.tipos?.length === 0) {
      if (!filters.minPrice && !filters.maxPrice && !filters.search && !filters.inspeccionado && !filters.permutable) {
        setAreFilters(false)
      }
      else 
      setAreFilters(true)
    }
    else
    setAreFilters(true)
  }, [filters])

  return(
    <>
      <RefSticky
        ref={refPosition}
      />

      <CleanFilterSContainer
        ref={stickyBar}
        style={ containerTop <= 0 ? { position: `fixed`, top: '0px', left: '0px', right: '0px' }
            : { position: 'relative' } 
          }
      >

        { !isMobile &&
          <Row>
            <SortSelect/>
          </Row>
        }

        { areFilters &&
          <Content>
            <LimpiarFiltros
              onClick={() => dispatch(cleanAllFilters())}
            >
              Limpiar Filtros
            </LimpiarFiltros>

            <Filter
              showFilter={filters.search ? true : false}
              onClick={() => dispatch(setSearch(undefined))}
              isMobile={isMobile}
            >
              <FilterName>search:</FilterName> { filters.search }
              <CloseIcon src={close} />
            </Filter>

            <Filter
              showFilter={filters.minPrice ? true : false}
              onClick={() => dispatch(setFilterMinPrice(undefined))}
              isMobile={isMobile}
            >
              <FilterName>Min:</FilterName> { filters.minPrice }$
              <CloseIcon src={close} />
            </Filter>

            <Filter
              showFilter={filters.maxPrice ? true : false}
              onClick={() => dispatch(setFilterMaxPrice(undefined))}
              isMobile={isMobile}
            >
              <FilterName>Max:</FilterName> { filters.maxPrice }$
              <CloseIcon src={close} />
            </Filter>

            <Filter
              showFilter={filters.permutable ? true : false}
              onClick={() => dispatch(setFilterPermutable(false))}
              isMobile={isMobile}
            >
              <FilterName>Permutable</FilterName> { filters.permutable }
              <CloseIcon src={close} />
            </Filter>

            <Filter
              showFilter={filters.inspeccionado ? true : false}
              onClick={() => {dispatch(setFilterConDiagnostico(false));}}
              isMobile={isMobile}
            >
              <FilterName>Con diagnóstico</FilterName> { filters.inspeccionado }
              <CloseIcon src={close} />
            </Filter>

            { 
              filters.tipos.map((filter) => (
                <Filter
                  showFilter={true}
                  onClick={() => dispatch(setFilterTipo(filter))}
                  key={`filter-tipo-${filter}`}
                  isMobile={isMobile}
                >
                  { filter }
                  <CloseIcon src={close} />
                </Filter>   
              ))
            }

          </Content>
        }
      </CleanFilterSContainer>
    </>
  )
}

const RefSticky = styled.div``

interface showFilter { showFilter: boolean, isMobile: boolean }
interface isMobile { isMobile: boolean }
const CleanFilterSContainer = styled.div`
  width: 100%;
  background-color: white;
  z-index: 998;
  padding: 5px;
  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 70%;
  padding: 10px 0px;
  margin: auto; 
`
  const OrdenarButton = styled.select`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid gray;
    border-radius: 3px;
    width: 150px;
    padding: 5px 0px;
  `
  const OrdenarIcon = styled.img`
    width: 20px;
    margin-right: 5px;
  `

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  margin: auto;
  padding: 5px 15px;
`
  const LimpiarFiltros = styled.div`
    font-family: Montserrat;
    color: #fe7027;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  `

  const Filter = styled.div<showFilter>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;

    background-color: #e0e0e0;
    padding: 2px 3px 2px 8px;
    margin: ${(args) => args.isMobile ? `5px 0px 0px 5px` : `5px 0px 0px 25px`};
    border-radius: 10px;
    font-family: Montserrat;
    font-size: 14px;
    display: ${(args) => args.showFilter ? 'initial' : 'none'};
  `
    const FilterName = styled.span`
      font-weight: 600;
    `
    const CloseIcon = styled.img`
      height: 18px;
      margin-left: 5px;
    `