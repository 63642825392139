import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel as CarouselBoot } from "react-bootstrap"
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'
import './ImageViewer.css'

import closeIcon from '../../assets/icons/ui/closeGrey.png'
import closeIconSquare from '../../assets/icons/ui/close-square.png'


const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

const fotosExterior = [
  'exterior_1',
  'exterior_2',
  'exterior_3',
  'exterior_4',
  'exterior_5',
  'exterior_6',
  'exterior_7',
  'exterior_8',

  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_lateral',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_capo_abierto',

  'foto_llanta_delantera_derecha',
  'foto_llanta_delantera_izquierda',
  'foto_llanta_trasera_derecha',
  'foto_llanta_trasera_izquierda',
  'foto_llanta_auxilio'
]

const fotosInterior = [
  'interior_1',
  'interior_2',
  'interior_3',
  'interior_4',
  'interior_5',
  'interior_6',
  'interior_7',
  'interior_8',
  'interior_9',
  'interior_10',
  'interior_11',
  'interior_12',

  'foto_maletero_abierto',
  'foto_volante',
  'foto_tablero_01',
  'foto_tablero_02',
  'foto_asientos_delanteros',
  'foto_asientos_traseros',
  'foto_caja_cambio',
  'foto_puerta_conductor_adentro', 
]

const orderPhotos = [
  'exterior_1',
  'exterior_2',
  'exterior_3',
  'exterior_4',
  'exterior_5',
  'exterior_6',
  'exterior_7',
  'exterior_8',

  'interior_1',
  'interior_2',
  'interior_3',
  'interior_4',
  'interior_5',
  'interior_6',
  'interior_7',
  'interior_8',
  'interior_9',
  'interior_10',
  'interior_11',
  'interior_12',

  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_lateral',
  'foto_diagonal_trasera',
  'foto_trasera',
  'foto_capo_abierto',
  
  'foto_maletero_abierto',
  'foto_volante',
  'foto_tablero_01',
  'foto_tablero_02',
  'foto_asientos_delanteros',
  'foto_asientos_traseros',
  'foto_caja_cambio',
  'foto_puerta_conductor_adentro',

  'foto_llanta_delantera_derecha',
  'foto_llanta_delantera_izquierda',
  'foto_llanta_trasera_derecha',
  'foto_llanta_trasera_izquierda',
  'foto_llanta_auxilio',
]

function extractProp(key: string) {
  const keySplited = key.split('/')
  return keySplited[keySplited.length - 1].replace('.jpeg', '')
}

interface thisProps { closeModal: () => void, src: string, item: TpublicacionItem }
export default function ImageViewer(props: thisProps) {
  const { pathname } = useLocation()
  const { app } = useConfig()
  const [ photos, setPhotos] = useState<string[]>([])
  const [ group, setGroup ] = useState< 1 | 2 >(1)
  const [ index, setIndex ] = useState<number>(0)
  const [ loadedImages, setLoadedImages] = useState<boolean>(false)
  const [ loaded, setLoaded] = useState<boolean>(false)

  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      id: string
    }

    if(target.id.match(/modalBackground|closeModalIcon|ModalContainer/)) {
      props.closeModal()
    }
  }

  useEffect(() => {
    const filterPhoto = orderPhotos;

    const fotosUrls = Object.entries(props.item)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') || item.match('interior_'))) {
        return true
      } else return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)
    .sort((a,b) => {
      return filterPhoto.indexOf(extractProp(a)) - filterPhoto.indexOf(extractProp(b))
    }).filter((item) => {
      if (filterPhoto.includes(extractProp(item)))
        return true
      else
        return false
    })

    const loadAlPhotos = fotosUrls.map((picture) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() { resolve(img) }
        img.onerror = img.onabort = function() { reject(picture) }
        img.src = picture;  
      })
    });

    Promise.all(loadAlPhotos).then((values) => {
      setLoadedImages(true)
    })

    setPhotos([...fotosUrls])    
  }, [group])

  useEffect(() => {
    if (photos[index]) {
      const isGroup1 = fotosExterior.some((item) => photos[index].match(item))
      const isGroup2 = fotosInterior.some((item) => photos[index].match(item))

      if (isGroup1) setGroup(1)
      if (isGroup2) setGroup(2)
    }
  }, [index])

  useEffect(() => {
    if (!loaded) {
      const fotoIndex = photos.findIndex((item) =>  item.match(props.src) )

      if (fotoIndex > -1) { 
        setIndex(fotoIndex)
        setLoaded(true)
      } 
    }
  }, [photos])

  function getGroupFirstPhoto(group: number) {
    const groupItems = group === 1 ? fotosExterior : fotosInterior;

    const index = photos.findIndex((item) => {
      const match = groupItems.find((check) => item.match(check))

      if (match) return true
      else return false
    })

    return index
  }

  return(
    <ModalBackground
      id='modalBackground'
      style={{zIndex: 998 }}
      onClick={hiddeModalFn}
    >
        <CloseIconContainer
          id='closeModalIconContainer'
          location={pathname}
          style={{zIndex: 998 }}
        >
          <CloseIcon
            src={ pathname.match('/publicacion') ? closeIconSquare : closeIcon}
            id='closeModalIcon'
          />
        </CloseIconContainer>
      
        <Container className='FullImageSliderContainer'>
        { photos && isMobile ?
            <CarouselBoot
              indicators={false}
              onSelect={(event) => {console.log('event: ', event); setIndex(event)}}
              activeIndex={index}
            >
              { photos.map((photo) => (
                <CarouselBoot.Item
                  key={`pub-photo-${photo}`}
                >
                  <MobileImage
                    className='ImageSection'
                  >
                    {/* <img src={photo} width={'100%'} height={'100%'} className='imageFit'/> */}
                    <img src={photo} />
                  </MobileImage>
                </CarouselBoot.Item>
              ))}
            </CarouselBoot>
          :
            <Carousel
              // showThumbs={ isMobile ? false : true }
              centerMode={true}
              showThumbs={ true }
              thumbWidth={200}
              showIndicators={false}
              showStatus={false}
              selectedItem={index}
              onChange={(prop) => setIndex(prop)}
              useKeyboardArrows={true}
              autoFocus={true}
            >
              { photos.map((photo) => (
                <ImageSection
                  key={`pub-photo-${photo}`}
                  className='ImageSection'
                >
                  <img src={photo} />
                </ImageSection>
              ))}
            </Carousel>
        }
        </Container>

        <GroupSelector isMobile={isMobile} >
          <Group
            onClick={() => { setIndex(getGroupFirstPhoto(1)) }}
            active={group == 1}
          >
            Exterior
          </Group>
          <Group
            onClick={() => { setIndex(getGroupFirstPhoto(2)) }}
            active={group == 2}
          >
            Interior
          </Group>

        </GroupSelector>

        <Counter isMobile={isMobile} >
          { index + 1 } / { photos.length }
        </Counter>

    </ModalBackground>
  )
}

interface TmodalContainer { location: string }
const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
`

const CloseIconContainer = styled.div<TmodalContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  position: absolute;
  right: 10px;
  top: 15px;
  width: 12px;
  background-color: #FE7027;
  border-radius: 50px;
  ${(args) => args.location.match('/publicacion') && `
    right: 20px;
    top: 20px;
  `}
`

const CloseIcon = styled.img`
  width: 18px;
`

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > div {
    width: 100%;
  }
`
const ImageSection = styled.div`
  background-color: transparent;
  width: 80%;
  max-width: 80%;
  height: 70vh;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > img {
    width: 80%;
    max-width: 80%;
    height: 60vh;
    max-height: 60vh;
    object-fit: scale-down;
  }
`
const MobileImage = styled.div`
  & > img {
    width: 100%;
    max-width: 100%;
    height: 80vh;
    max-height: 80vh;
    object-fit: scale-down;
  }
`

interface TgroupSelector  { isMobile: boolean }
const GroupSelector = styled.div<TgroupSelector>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 40px;
  position: absolute;
  bottom: 130px;
  width: 100%;
  ${(args) => args.isMobile && `
    bottom: unset;
    top: 20px;
  `}
`

interface Tgroup { active: boolean }
const Group = styled.div<Tgroup>`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  ${(args) => args.active && `
    color: #FF7F2A;
    border-bottom: 2px solid #FF7F2A;
  `}
`

interface Tcounter { isMobile: boolean }
const Counter = styled.div<Tcounter>`
  position: absolute;
  bottom: 180px;
  width: 100%;
  font-family: Montserrat;
  font-size: 20px;
  color: white;
  ${(args) => args.isMobile && `
    bottom: 30px;
  `}
`