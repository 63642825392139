import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../../hooks/useViewport'



const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function ResumenMiPublicacion(props: TpublicacionItem) {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <ResumenContainer>
      <TopSide>
        <Data isMobile={isMobile}>
          <Title isMobile={isMobile}> Transmision </Title>
          <Value style={{textTransform: 'capitalize'}}> { props.transmision } </Value>
        </Data>

        <Data isMobile={isMobile}>
          <Title isMobile={isMobile}> Cilindrada </Title>
          <Value> { props.motor_cc ? `${props.motor_cc} cc.`: 'N/A' } </Value>
        </Data>

        <Data isMobile={isMobile}>
          <Title isMobile={isMobile}> Tracción </Title>
          <Value> { props.traccion ? `${props.traccion} cc.`: 'N/A' } </Value>
        </Data>

        <Data isMobile={isMobile}>
          <Title isMobile={isMobile}> Kilometaje </Title>
          <Value> { props.kilometraje } KM </Value>
        </Data>

        <HorizonatlDivisor/>

        <DocTitle>
          Documentación
        </DocTitle>

        <Row>
          <Data isMobile={isMobile}>
            <Title isMobile={isMobile}> Sacado de Concesionaria </Title>
            <Value> { props.de_agencia ? 'Si' : 'No'  } </Value>
          </Data>

          <Data isMobile={isMobile}>
            <Title isMobile={isMobile}> Dueños anteriores </Title>
            <Value> { props.propietarios_anteriores ? props.propietarios_anteriores : 'primero' } </Value>
          </Data>
        </Row>
      </TopSide>

      <BottomSide>
        { !isMobile 
          
        }
      </BottomSide>

    </ResumenContainer>
  )
}

interface isMobile { isMobile: boolean }
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`
const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #b9b9b9;
  margin: 20px auto 15px auto;
`

const ResumenContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
`
const TopSide = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 30px 10px 0px 10px;
`
const BottomSide = styled.div`
  display: flex;
  width: 100%;
`

const Data = styled.div<isMobile>`
  font-family: Montserrat;
  color: gray;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-bottom: 15px;
  ${(args) => args.isMobile && `
    width: 50%;
  `}
`

const Title = styled.div<isMobile>`
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  ${(args) => args.isMobile && `
    font-size: 11px;
  `}
`
const Value = styled.div`
  font-size: 15px;
  text-align: left;
`
const DocTitle = styled.div`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
  color: gray;
`