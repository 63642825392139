import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import useViewport from '../../hooks/useViewport'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { TstepPagoInspeccion } from './solicitarInspeccionModal'
import { getPresignedUrl, pagoInspeccion, uploadComprobante } from '../../services/pagoInspeccion/api'
import { setLogEvent } from '../popUp/popUpSlice'
import useConfig from '../../hooks/useConfig'

import { setPagoId, setResetInspeccion } from '../../services/pagoInspeccion/inspeccionPubliSlice'

import loading from '../../assets/gifs/loadingOrangeLittle.gif'
import upload from '../../assets/icons/inspeccion/upload.png'
import download from '../../assets/icons/inspeccion/download.png'

import ImageWithPlaceHolder from '../imageWithPlaceholder/imageWithPlaceHolder'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

async function downloadImage(imageSrc: string) {
  console.log(imageSrc)
  const image = await fetch(`${imageSrc}?mock=${Date.now()}`)
  console.log(image)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'image file name here'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

interface thisProps { publicacionData: TpublicacionItem, changeStep: (step: TstepPagoInspeccion) => void }
export default function Facturacion(props: thisProps) {
  const { publicacionData: pub } = props
  const { app } = useConfig()

  const dispatch = useAppDispatch()
  const [ searchParams, ] = useSearchParams()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const [ nombre, setNombre ] = useState<string|undefined>(undefined)
  const [ telf, setTelf ] = useState<string|undefined>(undefined)
  const [ email, setEmail ] = useState<string|undefined>(undefined)
  const [ instruccion, setInstruccion ] = useState<string|undefined>(undefined)
  const [ razon, setRazon ] = useState<string|undefined>(undefined)
  const [ nit, setNit ] = useState<string|undefined>(undefined)

  const [ terms, setTerms ] = useState<boolean>(false)

  const pagoInspeccionState = useAppSelector((state: TrootState) => state.inspeccionPubli)

  function inputChange(event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) {

    if (event.target.name === 'pago-nombre')
      setNombre(event.target.value)

    if (event.target.name === 'pago-telf')
      setTelf(event.target.value)

    if (event.target.name === 'pago-email')
      setEmail(event.target.value)

    if (event.target.name === 'pago-instruccion')
      setInstruccion(event.target.value)

    if (event.target.name === 'pub-razon')
      setRazon(event.target.value)

    if (event.target.name === 'pub-nit')
      setNit(event.target.value)
  }

  function aceptTerms (event: React.ChangeEvent <HTMLInputElement> ) {

    const target = event.target as typeof event.target & { checked: boolean}
    if (target.checked === true) {
      let noUndefined = true

      if (nombre === undefined) {
        dispatch(setLogEvent({ type: 'error', message: 'El campo de nombre no puede estar vacio' }))
        noUndefined = false
      }
      if (telf === undefined) {
        dispatch(setLogEvent({ type: 'error', message: 'El campo de telefono no puede estar vacio' }))
        noUndefined = false
      }
      if (email === undefined) {
        dispatch(setLogEvent({ type: 'error', message: 'El campo de email no puede estar vacio' }))
        noUndefined = false
      }

      if (noUndefined) {
        setTerms(true)

        if (!pagoInspeccionState.pagoId) {
          pagoInspeccion({
            nombre: nombre,
            phone: telf,
            email,
            vehiculo_id: props.publicacionData.id_publicacion,
            sonido_motor: pagoInspeccionState.conSonidoMotor ? true : false,
            instruccion_adicional: instruccion,
            razon_social: razon,
            nit: nit,

            address: pagoInspeccionState.data.direccion,
            fecha_inspeccion: pagoInspeccionState.data.fecha,
            hora_inspeccion: pagoInspeccionState.data.hora,

            requester: 'vendedor'
          }).then((resp) => {
            if (resp.data.result === 'success') {
              dispatch(setPagoId(resp.data.pagoInspeccionId))
            }
            console.log(resp)
          }).catch((err) => {
            dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
          })
        }
      }
    } else {
      setTerms(false)
    }
  }

  interface Tfile { file: File }
  async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as typeof event.target & {
      files: FileList;
    };

    const filesRaw: File[] = []

    for (let file in target.files ) {
      if (typeof target.files[file] === 'object') {
        filesRaw.push(target.files[file])
      }
    }

    console.log(filesRaw[0])
    const fileTypeMatch = filesRaw[0].name.match(/\..*/)
    
    try {
      if (filesRaw.length > 0 && pagoInspeccionState.pagoId && fileTypeMatch) {
        getPresignedUrl(pagoInspeccionState.pagoId, fileTypeMatch[0].replace('.', ''))
        .then((resp) => {
          if (resp.data.result) {
            uploadComprobante(filesRaw[0], resp.data.urlS3Image)
            .then((resp) => {
              dispatch(setLogEvent({ type: 'info', message: 'Comprobante cargado exitosamente' }))
              dispatch(setResetInspeccion())
              props.changeStep('success')
            })
            .catch((err) => {
              dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))    
            })
          }
          
        })
        .catch((err) => {
          dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
        })
      } else {
        dispatch(setLogEvent({ type: 'error', message: 'No se ha obtenido respuesta del servidor, recargue la pagina por favor' }))
      }

    } catch(error) {
      console.log(error)
    }
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <PagoInspeccionContainer isMobile={isMobile}>

      <Title
        isMobile={isMobile}
      >
        Diagnóstico vehícular para mi <Strong isMobile={isMobile}> { pub.marca } { pub.modelo } </Strong>
      </Title>

      <Content isMobile={isMobile}>
        <LeftSide>
          <Subtittle> Tu Información </Subtittle>

          <Input
            type='text'
            name='pago-nombre'
            placeholder="Nombre y Apellido"
            onChange={inputChange}
            value={ nombre ? nombre : ''}
          />

          <Input
            type='text'
            name='pago-telf'
            placeholder="Nro de teléfono"
            onChange={inputChange}
            value={ telf ? telf : ''}
          />

          <Input
            type='email'
            name='pago-email'
            placeholder="Correo Electronico"
            onChange={inputChange}
            value={ email ? email : ''}
          />

          <TextArea
            rows={4}
            cols={50}
            name='pago-instruccion'
            placeholder="Instrucción adicional?"
            onChange={inputChange}
            value={ instruccion ? instruccion : ''}
          />

          <Subtittle> Datos de facturación </Subtittle>

          <Input
            type='text'
            name='pub-razon'
            placeholder="Razón social / Nombre"
            onChange={inputChange}
            value={ razon ? razon : ''}
          />

          <Input
            type='text'
            name='pub-nit'
            placeholder="NIT"
            onChange={inputChange}
            value={ nit ? nit : ''}
          />

        </LeftSide>

        <RightSide>

          <Subtittle> Detalles del pago </Subtittle>

          <Row>
            <strong> Diagnostico vehícular para: </strong>
            { pub.tipo_vehiculo } { pub.marca } { pub.modelo } { pub.year }
          </Row>

          <Row>
            <strong> Metodo de pago: </strong>
            QR simple
          </Row>

          <Row>
            <strong> Servicio: </strong>
            Diagnostico vehicular
          </Row>

          <Importe>
            <Monto>
              <span> Subtotal: </span>
              <span> 328 Bs. </span>
            </Monto>

            <Divisor/>

            <Monto>
              <span> Total: </span>
              <span> 328 Bs. </span>
            </Monto>
          </Importe>

          <AceptarCondiciones
            QRVisible={ terms }
          >
            <Check
              type="checkbox"
              name='pago-acept'
              onChange={aceptTerms}
              checked={terms}
            />
            {/* <TextCheck>
              Al comprar el servicio de diagnostico vehicular aceptas los terminos y condiciones 
              de los diagnosticos vehiculares ofrecidas por Carp10,
              las políticas de privacidad y uso de tu iinformación
            </TextCheck> */}
            <TextCheck>
              Al pagar un diagnósitco acepta los <Link target="_blank" href="/diagnostico-terminoscondiciones" >términos y condiciones
              </Link> de las inspecciones ofrecidas por carp10, las <Link target='_blank' href='/politicasdeprivacidad'> políticas
              de privacidad</Link> y uso de tu información
            </TextCheck>
          </AceptarCondiciones>

        </RightSide>

        { terms &&
          <QRContainer>
            <Subtittle>
              Pagar diagnostico
            </Subtittle>

              <ImageWithPlaceHolder
                src={ app.QR_DIAGNOSTICO }
                placeHolder={loading}
              >
                {(src) => 
                  <QRIcon src={src} ></QRIcon>
                }
              </ImageWithPlaceHolder>


              <Download
                onClick={() => {
                  downloadImage( app.QR_DIAGNOSTICO )
                }}
              >
                <DownIcon src={download}/>
                <DownText> Guardar </DownText>
              </Download>

            
              <QRButton 
                onClick={() => {
                  document.getElementById(`file-upload-comprobante`)?.click()
                }}
              >
                Subir comprobante
                <UploadIcon src={upload}/>
              </QRButton>

              <FileUpload
                htmlFor={`file-upload-comprobante`}
                id={`label-pago-comprobante`}
              />

              <HiddenInput 
                id={`file-upload-comprobante`}
                type="file"
                name='comprobante'
                multiple={false}
                onChange={onFileChange}
                accept="application/pdf,image/*"
              />

          </QRContainer>
        }

      </Content>
    </PagoInspeccionContainer>
  )
}

interface isMobile { isMobile: boolean }
const PagoInspeccionContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 10px;
  //min-height: 100vh;
  padding: 40px 40px;
  ${(args) => args.isMobile && `
    width: 340px; 
    max-height: 600px;
    
    padding: 40px 10px;
    align-items: center;
    justify-content: unset;
  `}
`

interface Ttitle { isMobile: boolean }
const Title = styled.div<Ttitle>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 22px;
  width: 600px;
  color: black;
  ${(args) => args.isMobile && `
    font-size: 24px;
    width: 300px; 
  `}
`
  const Strong = styled.span<isMobile>`
    font-weight: 600;
    font-size: 24px;
    ${(args) => args.isMobile && `
      font-size: 24px;
      width: 300px; 
    `}
  `

const Subtittle = styled.div`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
`
const Content = styled.div<isMobile>`
  display: flex;
  justify-content: center;
  gap: 50px;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
    gap: 10px;
  `}
`

  const LeftSide = styled.div`
    display: flex;
    flex-flow: column nowrap;
  `
  const RightSide = styled.div``

const Input = styled.input`
  font-family: Montserrat;
  font-weight: 500;
  width: 300px;
  margin: 15px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px #999;
  border-radius: 5px;
  
  &:focus {
    outline: none;
  }
`
const TextArea = styled.textarea`
  font-family: Montserrat;
  font-weight: 500;
  width: 300px;
  margin: 15px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px #999;
  border-radius: 5px;
  
  &:focus {
    outline: none;
  }
`
const Row = styled.div`
  width: 300px;
  text-align: left;
  margin-top: 15px;
`

const Importe = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`

const Monto = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`

const Divisor = styled.div`
  border-bottom: 2px dashed gray;
  width: 250px;
  margin: 10px 0px;
`

interface QRVisible { QRVisible: boolean }
const AceptarCondiciones = styled.label<QRVisible>`
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  gap: 10px;
  ${(args) => !args.QRVisible && `
    margin-bottom: 150px;
  `}
`
  const TextCheck = styled.div`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    width: 250px;
    text-align: left;
    text-align: justify;
  `
    const Link = styled.a`
      color: #FE7027; 
      display: inline-block;
    `
  const Check = styled.input`
    margin-top: 3px;
  `
const QRContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  
`
  const QRIcon = styled.img`
    margin-top: 20px;
    width: 200px;
  `

  const QRButton = styled.button`
    margin-top: 10px;
    margin-bottom: 20px;
    border: none;
    background-color: #FE7027;
    border-radius: 5px;
    padding: 5px 20px;
    color: white;
    font-family: Montserrat;
    font-weight: 600;
    min-height: 50px;
  `
  const UploadIcon = styled.img`
    height: 20px;
    margin-left: 10px;
  `



const FileUpload = styled.label`
  font-family: Barlow;
  font-weight: 600;
  background: #61dafb;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  width: 240px;
  margin-top: 15px;
  display: none;
`

const HiddenInput = styled.input`
  display: none;
`

const Download = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`
const DownIcon = styled.img`
  width: 40px;
`
const DownText = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
`
