import { configureStore, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { ThunkAction } from "@reduxjs/toolkit"

import appUI from "./AppUISlice"
import user from "../services/auth/userSlice"
import publicar from "../services/publicar/publicarSlice"
//import inspeccion from "../services/inspeccion/inspeccionSlice"
import miCuenta from "../views/micuenta/miCuentaSlice"
import popUp from "../components/popUp/popUpSlice"
import inspeccionPubli from "../services/pagoInspeccion/inspeccionPubliSlice"

import filter from "../services/filterPublicaciones/filterSlice"
import publicaciones from "../services/publicaciones/publicacionesSlice"

import { publicacionesApi } from "../services/apiSlice/apiSlice"

import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"


//import rootReducer, { TrootState } from "./rootReducer"

const store = configureStore({
  reducer: {
    appUI,
    user,
    publicar,
    //inspeccion,
    miCuenta,
    filter,
    publicaciones,
    popUp,
    inspeccionPubli,
    [publicacionesApi.reducerPath]: publicacionesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(publicacionesApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

export type TrootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, TrootState, null, Action<string>>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TrootState> = useSelector

setupListeners(store.dispatch)

export default store