import React, { useMemo, useState, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { Carousel } from "react-bootstrap"
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useConfig from '../../hooks/useConfig'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import useViewport from '../../hooks/useViewport'
import { useNavigate, createSearchParams, NavLink } from 'react-router-dom'
import { clickedPublicacion } from '../../services/publicaciones/api'

import VendidoRibbon from '../vendidoRibbon/vendidoRibbon'

import loading2 from '../../assets/gifs/loading-car.svg'
import pin from '../../assets/icons/ui/map-pin.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function PublicacionCard(props: TpublicacionItem) {
  const navigate = useNavigate()
  const { app } = useConfig()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ photos, setPhotos] =  useState<string[]>([])

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const requiredPhotosUsuario = [
    'exterior_1', 'exterior_2', 'exterior_3', 'exterior_4', 
    'interior_1', 
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_asientos_delanteros',
  ]

  const requiredPhotosInspector = [
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_capo_abierto',
  ]

  useEffect(() => {
    const required = props.createdBy === 'usuario' ? requiredPhotosUsuario : requiredPhotosInspector;

    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') || item.match('interior_'))) {
        const fotoKeySplit = item.split('/')
        const fotoKey = fotoKeySplit[fotoKeySplit.length - 1].replace('fotos/', '').replace(/\..*/, '')

        if (required.includes(fotoKey)) {
          return true
        } else
          return false

      } else
        return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)

    setPhotos([...fotosUrls])
  }, [])

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading2}
      visibleByDefault={true}
    />
  ), [])

  function formatPrice(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return(
    <PublicacionCardContainer
      isMobile={isMobile}
      isSD={isSD}
      isInspeccionado={Boolean(props.inspeccionado)}
    >
      <Diagnosticado
       isInspeccionado={Boolean(props.inspeccionado)}>
        Diagnosticado
      </Diagnosticado>
      { props.vendido && <VendidoRibbon/>}

      <NavLinkk
        to={`/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}`}
        onClick={() => {
          clickedPublicacion(props.id_publicacion)
        }}
      >
      <Carousel>
        {photos.map((photo, index) => (
          <Carousel.Item
            key={`card-landing-${props.id_publicacion}-${index}`}
          >
            <ImageSection>
            <MyImage
              className='imageFit'
              alt="member image"
              src={photo}
              width={'100%'}
              height={'100%'}
            />
            </ImageSection>
          </Carousel.Item>
        ))}
      </Carousel>

   
        <DataSection>
          <DataFirstRow>
            { props.precio !== 0 ?
              <Price> $us { formatPrice(props.precio) } </Price>
              :
              <PrecioOculto> Precio Oculto </PrecioOculto>

            }
          </DataFirstRow>

          <DataSecondRow>
            {props.year} {props.marca} {props.modelo}
          </DataSecondRow>

          <DataThird>
            { props.kilometraje } Km | { props.transmision }
          </DataThird>

          <HorizonatlDivisor/>

          <DataFourth>
            <PinIcon src={pin}/>
            { props.ciudad }, Bolivia
          </DataFourth>

        </DataSection>
      </NavLinkk>

    </PublicacionCardContainer>
  )
}

interface Tgrid { isMobile: boolean, isSD: boolean }
const Grid = styled.div<Tgrid>`
  margin: 10px 0px 10px 0px;
  width: 33.3%;
  ${(args) => args.isSD && `
    width: 50%;
  `}

  ${(args) => args.isMobile && `
    width: 100%;
  `}
`

interface Tpublicacion { isMobile: boolean, isSD: boolean, isInspeccionado: boolean }
const PublicacionCardContainer = styled.div<Tpublicacion>`
  position: relative;
  background:none;
  width: min(450px, 100%);
  min-width: 430px;
  margin: 10px auto 10px auto;
  height: 400px;
  outline: solid 2px #ccc;
  flex: 0 0 30%;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
  `}
  ${(args) => args.isSD && `
    flex-flow: row nowrap;
  `}
  ${(args) => args.isInspeccionado &&`
    outline: solid 4px #fe7027;
  `}
`

interface TDiagnosticado {isInspeccionado: boolean }
const Diagnosticado = styled.label<TDiagnosticado>`
  display:none;
${(args) => args.isInspeccionado && `
  display:inline-flex;
  position: absolute;
  z-index:1;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Montserrat;
  background-color: #fe7027;
  font-weight: 600;
  color: #fff;
`}  
`

const ImageSection = styled.div`
  background-color: #cccccc;
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`
  const NoImage = styled.img`
    width: 100px;
    height: 100px;
  `
  const Price = styled.div`
    color: #FE7027;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
  `
  const PrecioOculto = styled.div`
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #777777;
    min-height: 30px;
  `

const DataSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &:link { text-decoration: none; }
  &:visited { text-decoration: none; }
  &:hover { text-decoration: none; color: black; }
  &:active { text-decoration: none; }
`
  const DataFirstRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
  `
  const DataSecondRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 17px;
  `
  const DataThird = styled.div`
    align-self: left;
    font-family: Montserrat;
    display: flex;
    font-weight: 500;
    font-size: 17px;
  `
  const DataFourth = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: Montserrat;
    align-self: flex-end;
    font-weight: 500;
    font-size: 12px;
    color: black;
    padding: 2px 10px;
    border-radius: 2px;
    border: 1px solid black;
  `
    const PinIcon = styled.img`
      height: 10px;
      margin-right: 5px;
    `

const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #999;
  margin: 15px auto 15px auto;
`

const NavLinkk = styled(NavLink)`
  text-decoration: none;
`