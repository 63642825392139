import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";

import AuthAdvice from "../../components/advice/authAdvice";

import Login from './login'
import ResetPassword from './resetPassword'
import ResetPasswordSuccess from "./resetPassSuccess";
import useViewport from "../../hooks/useViewport";

export type TsignInStep = 'login' | 'resetPassword' | 'resetPasswordSuccess' | 'signup' | 'verificacion' | 'signUpSucces'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function SignIn() {  
  const [step, setStep] = useState<TsignInStep>('login')
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const changeStep = (data: TsignInStep) => {
    setStep(data)
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return (
    <SignInContainer
      isMobile={isMobile}
      className='view'
    >
      <Card isMobile={isMobile}>
        { step === 'login' && <Login changeStep={changeStep} /> }
        { step === 'resetPassword' && <ResetPassword changeStep={changeStep} /> }
        { step === 'resetPasswordSuccess' && <ResetPasswordSuccess changeStep={changeStep}/> }
      </Card>

      <AuthAdvice/>
    </SignInContainer>
  );
}

interface TsignUpContainer { isMobile: boolean }
const SignInContainer = styled.div<TsignUpContainer>`
  display: flex;
  flex-flow: ${(args) => args.isMobile ? 'column nowrap' : 'row nowrap' };
  justify-content: ${(args) => args.isMobile ? 'initial' : 'center' };
  gap: ${(args) => args.isMobile ? '30px' : '100px' };

  padding-bottom: 30px;
  align-items: center;
  width: 100%;
`
interface Tcard { isMobile: boolean }
const Card = styled.div<Tcard>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 100%;
  min-width: 250px;
  max-width: 350px;
  border-radius: 10px;
  padding: ${(args) => args.isMobile ? '40px 0px' : '40px 45px' };
  margin: ${(args) => args.isMobile ? '30px 0px' : '0px' };
`
