import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import MainImageDesktop from '../../assets/images/landing.png'
import MainImageMob from '../../assets/images/landing_mob.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function WelcomeSection() {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <WelcomeContainer 
      className='WelcomeContainer'
      isMobile={isMobile}
    >
      <Row
        isMobile={isMobile}
      >
        <WelcomeMessage
          isMobile={isMobile}
        >
          <p>Compra un vehículo seminuevo <br/>
          <HighLight> revisado </HighLight> y
          <HighLight> verificado </HighLight> por carp10</p>
        </WelcomeMessage>

        <MainImage
          isMobile={isMobile}
          src={isMobile ? MainImageMob : MainImageDesktop}
        />
      </Row>

    </WelcomeContainer>
  )
}

interface TWelcomeContainer { isMobile: boolean }
const WelcomeContainer = styled.div<TWelcomeContainer>`
  background-color: #eeeeee;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 250px;
  width: 100%;
  ${(args) => !args.isMobile && `
    min-height: max(calc(60vh - 100px), 250px);
  `}
`

const WelcomeMessage = styled.div<isMobile>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 26px;
  padding: 20px 0px 20px 0px;
  width: 500px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  ${(args) => args.isMobile && `
    font-size: 18px;
    width: 100%;
  `}
`
  const HighLight = styled.span`
    color: #FE7027;
    font-weight: 800;
  `

interface isMobile { isMobile: boolean }
const Row = styled.div<isMobile>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: center;
  gap: 50px;
  ${(args) => args.isMobile && `
    flex-flow: column nowrap;
    gap: 10px;
  `}
`
const MainImage = styled.img<isMobile>`
  height: 250px;
  ${(args) => args.isMobile && `
    height: auto;
    width: 350px;
    margin: auto;
  `}
`
