import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Carousel } from "react-bootstrap"
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useViewport from '../../hooks/useViewport'
import useConfig from '../../hooks/useConfig'

import Modal from '../modal/modal'
import SolicitarInspeccionModal from '../inspeccionMiPublicacion/solicitarInspeccionModal'
import VendidoRibbon from '../vendidoRibbon/vendidoRibbon'

import noImage from '../../assets/icons/ui/no-image.png'
import loading2 from '../../assets/gifs/loading-car.svg'
import download from '../../assets/icons/micuenta/share.png'
import { useNavigate } from 'react-router-dom'

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `${date}/${d.getMonth() + 1}/${year}`
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function MiPublicacionMobileCard(props: TpublicacionItem) {
  const { app } = useConfig()
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  const [ photos, setPhotos] =  useState<string[]>([])
  const [ showModal, setShowModal] = useState<boolean>(false)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  const requiredPhotosUsuario = [
    'exterior_1', 'exterior_2', 'exterior_3', 'exterior_4', 
    'interior_1',
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_asientos_delanteros',
  ]

  const requiredPhotosInspector = [
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_capo_abierto',
  ]

  useEffect(() => {
    const required = props.createdBy === 'usuario' ? requiredPhotosUsuario : requiredPhotosInspector;

    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') ||  item.match('interior_'))) {
        const fotoKeySplit = item.split('/')
        const fotoKey = fotoKeySplit[fotoKeySplit.length - 1].replace('fotos/', '').replace(/\..*/, '')

        if (required.includes(fotoKey)) {
          return true
        } else
          return false

      } else
        return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)

    setPhotos([...fotosUrls])
  }, [])

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading2}
      visibleByDefault={true}
    />
  ), [])

  function formatPrice(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return(
    <PublicacionContainer>

      <Card>
      { props.vendido && <VendidoRibbon/>}
        <CarouselStyled>
          {photos.map((photo, index) => (
            <Carousel.Item
              key={`card-landing-${props.id_publicacion}-${index}`}
            >
              <ImageSection className='imageSection'>
                <MyImage
                  className='imageFit'
                  alt="member image"
                  src={photo}
                  width={'100%'}
                  height={'100%'}
                />
                </ImageSection>
            </Carousel.Item>
            
          ))}
        </CarouselStyled>

        <DataSection
          onClick={() => props.publishedAt && navigate(`/micuenta/publicacion/${props.id_publicacion}`) }
        >
          <DataFirstRow> {props.year} {props.marca}</DataFirstRow>
          <DataSecondRow>
            { props.version } { props.kilometraje ? `| ${props.kilometraje} km` : '' }
          </DataSecondRow>
          <DataThirdRow>
            Publicado el: { dateFormater(props.publishedAt) }
          </DataThirdRow>
        </DataSection>

      </Card>
      { props.publishedAt && !props.inspeccionado &&
        <Solicitar
          onClick={() => displayModal()}
        >
          Solicitar Inspeccion
        </Solicitar>
      }

      { props.publishedAt ?
        <Informe>
          <InformeIcon src={download} />
          Compartir
        </Informe>
        :
        <TerminarPublicacion
          onClick={()=> navigate('/vender')}
        >
          Terminar de publicar
        </TerminarPublicacion>
      }

      { showModal &&
        <Modal closeModal={hiddeModal} >
          <SolicitarInspeccionModal publicacionData={props} closeModal={hiddeModal}/>
        </Modal>
      }

    </PublicacionContainer>
  )
}

const PublicacionContainer = styled.div`
  position: relative;
  width: 350px;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
`

const CarouselStyled = styled(Carousel)`
  max-width: 100px;
  height: 100px;
`
const Card = styled.div`
  display: flex;
  border: solid 2px #ccc;
  border-radius: 2px;
`

const ImageSection = styled.div`
  background-color: #cccccc;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const DataSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 20px;
`
  const DataFirstRow = styled.div`
    align-self: left;
    text-align: left;
    
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
  `
  const DataSecondRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    margin-top: 0px;
  `
  const DataThirdRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 15px;
  `

const Button = styled.button`
  
  width: 230px;
  align-self: center;
  border: none;
  height: 40px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 0px;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
`
const TerminarPublicacion = styled(Button)`
  background-color: white;
  color: #FE7027;
  border: 2px solid #FE7027;
  align-self: flex-end;
`

const Solicitar = styled(Button)`
  background-color: #FE7027;
  color: white;
  align-self: flex-end;
`

const Informe = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #666;
  color: white;
  align-self: flex-end;
`
  const InformeIcon = styled.img`
    height: 30px;
    margin-right: 10px;
  `
