import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'


import Facturacion from './facturacion'
import SonidoMotor from './sonidoMotor'
import InspeccionPublicacion from './infoInspeccionPubli'
import SuccessPago from './succesPago'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export type TstepPagoInspeccion = 'info' | 'sonido' | 'facturacion' | 'success'
interface thisProps { publicacionData: TpublicacionItem, closeModal: () => void }
export default function PagoInspeccion(props: thisProps) {
  const [step, setStep] = useState<TstepPagoInspeccion>('info')
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const changeStep = (step: TstepPagoInspeccion) => setStep(step)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [])

  return(
    <PagoInspeccionContainer isMobile={isMobile}>

      { step === 'info' && <InspeccionPublicacion publicacionData={props.publicacionData} changeStep={changeStep} /> }
      { step === 'sonido' && <SonidoMotor publicacionData={props.publicacionData} changeStep={changeStep} /> }
      { step === 'facturacion' && <Facturacion publicacionData={props.publicacionData} changeStep={changeStep} /> }
      { step === 'success' && <SuccessPago closeModal={props.closeModal} /> }

    </PagoInspeccionContainer>
  )
}

interface isMobile { isMobile: boolean }
const PagoInspeccionContainer = styled.div<isMobile>`
  overflow: auto;
  max-height: 90vh;
`