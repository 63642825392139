import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Carousel } from "react-bootstrap"
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import useConfig from '../../hooks/useConfig'
import { useNavigate, createSearchParams, NavLink } from 'react-router-dom'
import { clickedPublicacion } from '../../services/publicaciones/api'
import VendidoRibbon from '../vendidoRibbon/vendidoRibbon'

import loading2 from '../../assets/gifs/loading-car.svg'

export default function PublicacionCardMobLanding(props: TpublicacionItem) {
  const { app } = useConfig()
  const navigate = useNavigate()
  const [ photos, setPhotos] =  useState<string[]>([])
  
  const requiredPhotosUsuario = [
    'exterior_1', 'exterior_2', 'exterior_3', 'exterior_4', 
    'interior_1', 
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_asientos_delanteros',
  ]

  const requiredPhotosInspector = [
    'foto_frontal',
    'foto_diagonal_frontal',
    'foto_diagonal_trasera',
    'foto_trasera',
    'foto_capo_abierto',
  ]

  useEffect(() => {
    const required = props.createdBy === 'usuario' ? requiredPhotosUsuario : requiredPhotosInspector;

    const fotosUrls = Object.entries(props)
    .map((item) => item[1])
    .filter((item) =>{
      if (typeof item === 'string' && (item.match('foto_') || item.match('exterior_') || item.match('interior_'))) {
        const fotoKeySplit = item.split('/')
        const fotoKey = fotoKeySplit[fotoKeySplit.length - 1].replace('fotos/', '').replace(/\..*/, '')

        if (required.includes(fotoKey))
          return true
        else
          return false

      } else
        return false
    })
    .map((item) => `${app.STATIC_FILES}${(item as string)}`)

    setPhotos([...fotosUrls])
  }, [])

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading2}
      visibleByDefault={true}
    />
  ), [])

  function formatPrice(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  return(
    <PublicacionCardContainer
    isInspeccionado={Boolean(props.inspeccionado)}>

      <Diagnosticado
        isInspeccionado={Boolean(props.inspeccionado)}>
        Diagnosticado
      </Diagnosticado>

      { props.vendido && <VendidoRibbon/>}
      <NavLinkk
        to={`/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}`}
        onClick={() => { clickedPublicacion(props.id_publicacion) }}
      >

      <Carousel>
        {photos.map((photo, idx) => (
          <Carousel.Item
            key={`card-landing-${props.id_publicacion}-${idx}`}
          >
            <ImageSection>
            <MyImage
              className='imageFit'
              alt="member image"
              src={photo}
              width={'100%'}
              height={'100%'}
            />
            </ImageSection>
          </Carousel.Item>
        ))}
      </Carousel>
        <DataSection
          // onClick={() => {
          //   clickedPublicacion(props.id_publicacion)
          //   navigate({
          //     pathname: `/publicacion/${props.id_publicacion}`,
          //     search: createSearchParams({
          //       modelo: props.modelo,
          //       marca: props.marca
          //     }).toString()
          //   })
          // }}
        >
          <DataFirstRow>
            <Price> $us { formatPrice(props.precio) } </Price>
          </DataFirstRow>

          <DataSecondRow>
            {props.year} {props.marca} {props.modelo}
          </DataSecondRow>

          <DataThird>
            { props.kilometraje } Km <Dot/> { props.transmision }
          </DataThird>

          <DataFourth>
            { props.ciudad }, Bolivia
          </DataFourth>
        </DataSection>
      </NavLinkk>  


    </PublicacionCardContainer>
  )
}

const Placeholder = styled.div`
  background-color: red;
  width: 150px;
  height: 150px;
`

interface Tpublicacion {isInspeccionado: boolean }
const PublicacionCardContainer = styled.div<Tpublicacion>`
  position: relative;
  width: min(450px, 100%);
  height: 340px;
  outline: solid 2px #ccc;
  margin-bottom: 20px;
  ${(args) => args.isInspeccionado &&`
  outline: solid 4px #fe7027;
`}
`

const ImageSection = styled.div`
  background-color: #cccccc;
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`

const NoImage = styled.img`
  width: 100px;
  height: 100px;
`

const Price = styled.div`
  color: #FE7027;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
`
const DataSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 20px;
  color: #777777;
  cursor: pointer;
`
const DataFirstRow = styled.div`
  align-self: left;
  text-align: left;
  
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
`

  const DataSecondRow = styled.div`
    align-self: left;
    text-align: left;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 17px;
  `

  const DataThird = styled.div`
    align-self: center;
    font-family: Montserrat;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 2px solid black;
    padding: 2px 10px;
    border-radius: 5px;
    margin-top: 5px;
  `

  const DataFourth = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: gray;
    margin-top: 5px;
    margin-bottom: 0px;
  `

  const Dot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: black;
    margin: 0px 10px;
  `

  const HorizonatlDivisor = styled.div`
    width: 100%;
    border-bottom: solid 2px #999;
    margin: 15px auto 25px auto;
  `

  const NavLinkk = styled(NavLink)`
    text-decoration: none;
    &:link { text-decoration: none; }
    &:visited { text-decoration: none; }
    &:hover { text-decoration: none; color: black; }
    &:active { text-decoration: none; }
  `

  const Diagnosticado = styled.label<Tpublicacion>`
    display:none;
  ${(args) => args.isInspeccionado && `
    display:inline-flex;
    position: absolute;
    z-index:1;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Montserrat;
    background-color: #fe7027;
    font-weight: 600;
    color: #fff;
  `}  
  `