import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { closeSession } from '../../services/auth/userSlice'
import { googleLogout } from '@react-oauth/google';


import menuDesktop from '../../assets/icons/ui/loginDesktop.png'

export default function DesktopMenu() {
  const navigate = useNavigate()
  const [active, setActive] = useState(false)
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: TrootState) => state.user)

  const dropMenu = useRef<HTMLDivElement |null>(null)

  function logOut() {
    console.log('dispath close session')
    dispatch(closeSession())
    .then((result) => {
      console.log('logout: ', result)
      if (result.meta.requestStatus === 'fulfilled') {
        googleLogout()
        navigate('/')
      }
    })
  }

  useLayoutEffect(() => {
    function clickBody(event: MouseEvent) {
      let target = event.target as Element
      const id = target.getAttribute('id')

      if (!target.closest('#carp10-login-dropmenu-button')) {
        if (!target.closest('#carp10-login-dropmenu') ) {
          setActive(false)
        }
      } 

      // if (id && !id.match(/foundx-user-dropdownmenu/)) {
      //   props.closeMenu()
      // }
      // if (!id) {
      //   props.closeMenu()
      // }
    }

    document.body.addEventListener('click', clickBody )

    return () => {
      document.body.removeEventListener('click', clickBody )
    }
  }, [])

  return(
    <DesktopMenuContainer>

      <IconContainer
        id='carp10-login-dropmenu-button'
      >
        <Icon
          src={menuDesktop}
          width={40}
          height={45}
          onClick={() => setActive(!active)}
        />
      </IconContainer>

      { active &&
        <DropMenu
          id='carp10-login-dropmenu'
          ref={dropMenu}
        >
          <FirstRow>
            { !user.data ?
                <>
                <NavLink to="/login" className='no-decoration'
                  onClick={() => setActive(false)}
                >
                  <InitLogin> Iniciar Sesión </InitLogin>
                </NavLink>

                <VerticalDivisor> | </VerticalDivisor>

                <NavLink to="/registro" className='no-decoration'
                  onClick={() => setActive(false)}
                >
                  <SignUp> Crear Cuenta </SignUp>
                </NavLink>    
                </>
              :
                <CloseSession
                  onClick={() => logOut()}
                >
                  Cerrar Sesión
                </CloseSession>
            }
          </FirstRow>

          <HorizonatlDivisor/>

          <SecondRow>
            <Inspecionar
              onClick={() => {
                navigate('/inspeccion');
                setActive(false);
              }}
            >
              Diagnóstico Vehicular
            </Inspecionar>
          </SecondRow>
        </DropMenu>
      }

    </DesktopMenuContainer>
  )
}

const DesktopMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`
  const IconContainer = styled.div`
    margin-left: 20px;
    cursor: pointer;
  `
  const Icon = styled.img`
    
  `
const DropMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 55px;
  right: 0px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  width: 230px;

  background-color: white;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.1);
`
  const FirstRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  `
  const HorizonatlDivisor = styled.div`
    width: 100px;
    border-bottom: solid 2px #fe7027;
    margin: 5px auto 5px auto;
  `
  const SecondRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  `
    const VerticalDivisor = styled.span`
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      margin: 0px 10px 0px 10px;
    `
    const InitLogin = styled.span`
      cursor: pointer;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    `
    const SignUp = styled.span`
      cursor: pointer;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    `
    const CloseSession = styled.div`
      cursor: pointer;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    `
    const Inspecionar = styled.span`
      cursor: pointer;
      font-family: Montserrat;
      align-self: center;
      background-color: #FE7027;
      border-radius: 15px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      width: 150px;
      padding: 5px 0px 5px 0px;
      margin-top: 5px;

      &:hover {
        background-color: #FF9800; #white
      }
    `