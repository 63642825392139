export const filterOnlyAllowedProps = (payload: any, requiredProps: string[]) => {
  const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([prop, value]) => {
      if (requiredProps.includes(prop)) {
          return true
      } else {
          return false
      }
  }))

  return filteredPayload;
}