import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import useViewport from '../../hooks/useViewport'
import { setFilterTipo, setFilterMinPrice, setFilterMaxPrice, setFilterConDiagnostico, setFilterPermutable } from '../../services/filterPublicaciones/filterSlice'

const prices = [
  0,
  10000,
  11000,
  12000,
  13000,
  14000,
  15000,
  16000,
  17000,
  18000,
  19000,
  20000,
  25000,
  30000,
  35000,
  40000
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function DesktopFilter() {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const filter = useAppSelector((state: TrootState) => state.filter.filter)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])  

  function inputChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.name === 'filter-precio-min') {
      dispatch(setFilterMinPrice(Number(event.target.value)))
    }
  
    if (event.target.name === 'filter-precio-max') {
      console.log(`${Number(event.target.value)} === ${prices[prices.length - 1]}`)
      if (Number(event.target.value) === prices[prices.length - 1]) {
        dispatch(setFilterMaxPrice(999999))
      } else {
        dispatch(setFilterMaxPrice(Number(event.target.value)))
      }
    }
  }
  useLayoutEffect(() => {}, [filter.tipos])

  function formatPrice(price: number) {
    if (price === 40000)
    return '$ ' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' +';
    else
    return '$ ' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleDiagnosticoChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setFilterConDiagnostico(event.target.checked));
  }
  
  function handlePermutableChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setFilterPermutable(event.target.checked));
  }  

  return(
    <DeskTopFilter
      isMobile={isMobile}
    >
      <Title> Filtrar búsqueda </Title>

      <PriceRango isMobile={isMobile}>
        <PriceTitle> Rango de Precio </PriceTitle>
        <RangoContainer>
          
          <SelectPrice
            name="filter-precio-min"
            isMobile={isMobile}
            onChange={inputChange}
            //defaultValue={filter.minPrice ? String(filter.minPrice) : 0}
            value={filter.minPrice ? String(filter.minPrice) : ''}
          >
            <Option value="" disabled> Precio Min </Option>
            {
              prices.map((price, index) => (

                filter.maxPrice ?
                  price < filter.maxPrice ?
                    <Option value={price} key={`price-${index}`}>
                      { formatPrice(price) }
                    </Option> : null
                :
                <Option value={price} key={`price-${index}`}>
                  { formatPrice(price) }
                </Option>
              ))
            }
          </SelectPrice>

          <HorizonatlDivisor isMobile={isMobile}/>

          <SelectPrice
            name="filter-precio-max"
            isMobile={isMobile}
            onChange={inputChange}
            //defaultValue={filter.maxPrice ? filter.maxPrice : ''}
            value={filter.maxPrice ? String(filter.maxPrice) : ''}
          >
            <Option value="" disabled> Precio Max </Option>
            {
              prices.map((price, index) => (

                filter.minPrice ?
                  price > filter.minPrice ? 
                  <Option value={price} key={`price-${index}`}>
                    { formatPrice(price) }
                  </Option> : null
                :
                <Option value={price} key={`price-${index}`}>
                  { formatPrice(price) }
                </Option>
              ))
            }
          </SelectPrice>

        </RangoContainer>
      </PriceRango>

      <TipoTitle>
        Tipo de vehículo
      </TipoTitle>

      <TipoButtons isMobile={isMobile}>

        <Tipo
          onClick={() => dispatch(setFilterTipo('automovil'))}
          selected={filter.tipos.includes('automovil')}
        >
          Automóviles
        </Tipo>

        <Tipo
          onClick={() => dispatch(setFilterTipo('camioneta'))}
          selected={filter.tipos.includes('camioneta')}
        >
          Camionetas
        </Tipo>

        <Tipo
          onClick={() => dispatch(setFilterTipo('vagoneta'))}
          selected={filter.tipos.includes('vagoneta')}
        >
          Vagonetas
        </Tipo>
      </TipoButtons>


      <CenteredHorizontally>
          <SwitchLabel>
          <SwitchText>Con diagnóstico</SwitchText>
              <SwitchInput 
                  type="checkbox" 
                  checked={filter.inspeccionado} 
                  onChange={handleDiagnosticoChange}
              /> 
              <Slider></Slider>
          </SwitchLabel>
          <SwitchLabel>
          <SwitchText>Permutable</SwitchText>
              <SwitchInput 
                  type="checkbox" 
                  checked={filter.permutable} 
                  onChange={handlePermutableChange}
              /> 
              <Slider></Slider>
          </SwitchLabel>
      </CenteredHorizontally>
    </DeskTopFilter>
  )
}

interface isMobile { isMobile: boolean }
const DeskTopFilter = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 30px;
  ${(args) => args.isMobile && `
    padding: 30px 0px;
    max-width: 350px;
  `}
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 15px;
`

const PriceRango = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  background-color: #30302C;
  width: 700px;
  margin: auto;
  border-radius: 70px;
  padding: 20px;
  ${(args) => args.isMobile && `
    background-color: transparent;
    padding: 10px 0px;
    width: 320px;
  `}
`
  const PriceTitle = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    color: #fe7027;
    margin-bottom: 10px;
  `
  const RangoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `
    const SelectPrice = styled.select<isMobile>`
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      width: 200px;
      border-radius: 70px;
      padding: 5px 5px 5px 15px;
      border: solid 1px gray;
      &:focus {
        outline: none;
      }
      ${(args) => args.isMobile && `
        width: 135px;
        border-radius: 0px;
      `}
    `
      const Option = styled.option`
        margin: 5px 0px 5px 0px;
        font-family: Montserrat;
      `


const HorizonatlDivisor = styled.div<isMobile>`
  width: 10px;
  border-bottom: solid 2px #fe7027;
  margin: 0px 10px;
  ${(args) => args.isMobile && `
    opacity: 0;
  `}
`
const TipoTitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: #fe7027;
  margin-top: 20px; 
`
const TipoButtons = styled.div<isMobile>`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px;
  ${(args) => args.isMobile && `
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 0px;
  `}
`
  interface Ttipo { selected: boolean}
  const Tipo = styled.button<Ttipo>`
    font-family: Montserrat;
    font-weight: 500;
    background-color: #30302C;
    color: white;
    padding: 10px 25px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    &:hover {
      //background-color: white;
    }
    ${(args) => args.selected && `
      background-color:#fe7027;
    `}
  `

  const CenteredHorizontally = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  `;

  const SwitchLabel = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 220px;
  height: 30px;
  margin: 0px 100px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: #fe7027;
    &:before {
      transform: translateX(26px);
    }
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  margin-left: 161px;
  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SwitchText = styled.span<{ color?: string, size?: string }>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #fe7027;
  min-width: 180px;  // Ancho mínimo que garantice que el texto no se rompa
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  ${(args) => args.color && `
    color: ${args.color};
  `} 
  ${(args) => args.size && `
    font-size: ${args.size};
  `}
`;