import React, { useEffect, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
import { createPartialPublicacion } from "../../services/publicar/api"  
import { setCurrentPublicacion, setMostrarPrecio } from "../../services/publicar/publicarSlice"
import useViewport from "../../hooks/useViewport"
import { setLogEvent } from "../../components/popUp/popUpSlice"
import { useGetMisPublicacionesQuery } from "../../services/apiSlice/apiSlice"
import { TpublicacionItem } from "../../services/publicaciones/publicacionesApiTypes"
import {   setUIStep,
  setMarca,
  setModelo,
  setYear,
  setVersion,
  setTraccion,
  setTransmision,
  setMotorCC,
  setKilometraje,
  setPropietariosAnteriores,
  setCiudad,
  setDeAgencia,
  setPermutable,
  setPrecio,
  addFotoToGroup1,
  addFotoToGroup2,
} from "../../services/publicar/publicarSlice"

import VenderDatosVehiculos from "./venderDatosVehiculos"
import VenderPrecio from "./venderPrecio"
import VenderFotosMultiples from "./venderFotosMultiples"
import useConfig from "../../hooks/useConfig"


const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Vender() {
  const dispatch = useAppDispatch()
  const { app } = useConfig()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const { data: misPublicaciones, error, isLoading, isSuccess } = useGetMisPublicacionesQuery({}, {pollingInterval: 0})
  const publicar = useAppSelector((state: TrootState) => state.publicar)

  const parcialPublicacion = (function() {
    const {marca, modelo, year, version, transmision, motor_cc, kilometraje,
      propietarios_anteriores, ciudad, de_agencia, permutable, precio, mostrar_precio, pubEnCurso, traccion} = publicar;
    
    marca && modelo && year && version && transmision && motor_cc && kilometraje &&
    propietarios_anteriores && ciudad && precio !== undefined && mostrar_precio !== undefined && traccion &&
    de_agencia !== undefined  && permutable !== undefined ?

    createPartialPublicacion({
        marca, modelo, year, version, transmision, motor_cc, kilometraje,
        propietarios_anteriores, ciudad, de_agencia, permutable, precio, traccion, mostrar_precio,
        id_publicacion: pubEnCurso ? pubEnCurso.publicacion_id : undefined 
      })
      .then((resp) => {
        //console.log('Datos guarados correctamente en el servidor')
        if (!pubEnCurso)
        dispatch(setCurrentPublicacion(resp.data.id_publicacion))
      })
      .catch((error) => {
        console.log(error)
      }) 
    :
      dispatch(setLogEvent({ type: 'error', message: 'No se han rellenado todos los campos, vuelve a los pasos anteriores' }))
  });

  useEffect(() => {
    if(publicar.ui.step === 'fotos') {
      parcialPublicacion()
    }
  }, [publicar.ui.step])

  useEffect(() => {   
    if (isSuccess && misPublicaciones.items && misPublicaciones.items.length > 0) {
      const publicacionPendiente: undefined | TpublicacionItem = misPublicaciones.items.slice().sort((x: TpublicacionItem, y: TpublicacionItem) => {
        return x.createdAt - y.createdAt;
      }).reverse().find((item: TpublicacionItem) => {
        if (!item.publishedAt) return true
      })

      if (publicacionPendiente) {
        if (publicacionPendiente.id_publicacion) dispatch(setCurrentPublicacion(publicacionPendiente.id_publicacion))
        if (publicacionPendiente.marca) dispatch(setMarca(publicacionPendiente.marca))
        if (publicacionPendiente.modelo) dispatch(setModelo(publicacionPendiente.modelo))
        if (publicacionPendiente.year) dispatch(setYear(publicacionPendiente.year))
        if (publicacionPendiente.version) dispatch(setVersion(publicacionPendiente.version))
        if (publicacionPendiente.traccion) dispatch(setTraccion(publicacionPendiente.traccion))
        if (publicacionPendiente.transmision) dispatch(setTransmision(publicacionPendiente.transmision))
        if (publicacionPendiente.motor_cc ) dispatch(setMotorCC(publicacionPendiente.motor_cc))
        if (publicacionPendiente.kilometraje) dispatch(setKilometraje(publicacionPendiente.kilometraje))
        if (publicacionPendiente.propietarios_anteriores) dispatch(setPropietariosAnteriores(publicacionPendiente.propietarios_anteriores))
        if (publicacionPendiente.ciudad) dispatch(setCiudad(publicacionPendiente.ciudad))
        if (publicacionPendiente.de_agencia) dispatch(setDeAgencia(publicacionPendiente.de_agencia))
        if (publicacionPendiente.permutable) dispatch(setPermutable(publicacionPendiente.permutable))
        if (publicacionPendiente.precio) dispatch(setPrecio(publicacionPendiente.precio))
        if (publicacionPendiente.mostrar_precio) dispatch(setMostrarPrecio(publicacionPendiente.mostrar_precio))

        publicacionPendiente.fotos?.fotosGroup1.forEach((foto, index) => {
          dispatch(addFotoToGroup1({imageURL: `${app.STATIC_FILES}${foto.imageURL}`, prediction1: foto.prediction1, prediction2: foto.prediction2, name: foto.name }));
        });
        
        publicacionPendiente.fotos?.fotosGroup2.forEach((foto, index) => {
          dispatch(addFotoToGroup2({imageURL: `${app.STATIC_FILES}${foto.imageURL}`, prediction1: foto.prediction1, prediction2: foto.prediction2, name: foto.name  }));
        });

      }
    }
    
  }, [misPublicaciones])


  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return (
    <SolcitarInspeccion
      isMobile={isMobile}
    >
      { publicar.ui.step === 'datosVehiculo' && <VenderDatosVehiculos/> }
      { publicar.ui.step === 'precio' && <VenderPrecio/> }
      { publicar.ui.step === 'fotos' && <VenderFotosMultiples/> }
    </SolcitarInspeccion>
  );
}

interface TsolicitarInspeccion { isMobile: boolean }
const SolcitarInspeccion = styled.div<TsolicitarInspeccion>`
  /* display: flex;
  flex-flow: column nowrap;
  align-items: center; */
  width: 100%;
`
 
