import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'

import touch from '../../../assets/icons/micuenta/touch-screen.png'
import chat from '../../../assets/icons/micuenta/chat.png'
import heart from '../../../assets/icons/micuenta/heart.png'
import share from '../../assets/icons/micuenta/share.png'
import useViewport from '../../../hooks/useViewport'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function StatsSectionMiPublicacion(props: TpublicacionItem) {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <StatsSectionContainer isMobile={isMobile}>

      <ContactStat>
        <IconContainer>
          <Icon src={chat} />
        </IconContainer>

        <Text>
          0 personas se han contactado contigo por esta publicacion
        </Text>
      </ContactStat>

      <TouchStat>
        <IconContainer>
          <Icon src={touch} />
        </IconContainer>
        
        <Text>
          { props.clicked } personas han hecho click en esta publicación
        </Text>
      </TouchStat>

      <LikeStat>
        <IconContainer>
          <Icon src={heart} />
        </IconContainer>
        <Text>
          { props.likes } personas han guardado este vehículo en favoritos
        </Text>
      </LikeStat>

      {/* <ShareStat>
        <IconContainer>
          <Icon src={share} />
        </IconContainer>
        <Text>
          0 personas ha compartido esta publicación
        </Text>
      </ShareStat> */}


    </StatsSectionContainer>
  )
}

interface isMobile { isMobile: boolean }
const StatsSectionContainer = styled.div<isMobile>`
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
  
  ${(args) => args.isMobile && `
    width: 90%;
    margin: auto;
  `}
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border: solid 2px gray;
  padding-right: 5px;
`
const ContactStat = styled(Container)``
const TouchStat = styled(Container)``
const ShareStat = styled(Container)``
const LikeStat = styled(Container)``

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin: 0px 20px 0px 10px;
`
  const Icon = styled.img`
    width: 30px;
  `
const Text = styled.div`
  flex-grow: 2;
  text-align: left;
`