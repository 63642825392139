import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useAppDispatch } from '../../app/store'
import styled, { CSSProperties } from 'styled-components'
import Select, { components, MultiValue, SingleValue, OptionProps, SingleValueProps, StylesConfig } from 'react-select'
import { setSort } from '../../services/filterPublicaciones/filterSlice'

import sortUp from '../../assets/icons/ui/sort-up.png'
import sortDown from '../../assets/icons/ui/sort-down.png'
import sortUpWhite from '../../assets/icons/ui/sort-up-white.png'
import sortDownWhite from '../../assets/icons/ui/sort-down-white.png'


type Toption = {
  value: string,
  label: string,
  icon: string,
  iconWhite: string,
}

const priceSortOps: Toption[] = [
  {
    value: 'high-price',
    label: 'Menor a mayor',
    icon: sortDown,
    iconWhite: sortDownWhite
  },
  {
    value: 'low-price',
    label: 'Mayor a menor',
    icon: sortUp,
    iconWhite: sortUpWhite
  }
]

type Tgroup = {
  label: string,
  options: Toption[],
}
const groupedOptions: Tgroup[] = [
  {
    label: 'Precio',
    options: priceSortOps,
  },
]

export default function SortSelect() {
  const dispatch = useAppDispatch()
  const [accountsState, setAccountsState] = useState(priceSortOps)
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (selectedOption) dispatch(setSort(selectedOption))
  }, [selectedOption])

  interface TsingleValue { data: Toption }
  const customSingleValue = (props: TsingleValue) => (
    <OptionContainer>
      <Icon src={props.data.icon}/>
      <OptionText>{ props.data.label }</OptionText>
    </OptionContainer>
  )

  const Option = (props: OptionProps<Toption, false, Tgroup> ) => (
    <components.Option {...props} className="react-select-option">
      <OptionContainer>
        <Icon src={ props.isSelected ? props.data.iconWhite : props.data.icon }/>

        <OptionText>{ props.data.label }</OptionText>
      </OptionContainer>
    </components.Option>
  )

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const formatGroupLabel = (data: Tgroup) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
    </div>
  )

  const customStyles: StylesConfig<Toption, false, Tgroup> = {
    menuList: (provided) => ({
      ...provided,
      padding: '0px',
      margin: '0px',
      borderRadius: '0px 0px 10px 10px',
      
    }),
    menu: (provided) => ({
      ...provided,
      padding: '0px',
      margin: '0px',
      borderRadius: '0px 0px 10px 10px',
      outline: 'solid 1px gray',
      top: '30px',
      width: 170,
      marginLeft: 1,
      overflow: 'hidden',
      zIndex: 999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      zIndex: 101,
      position: 'absolute',
      right: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      background: 'transparent',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 0px 10px 0px',
      backgroundColor: state.isSelected ? '#fe7027' :  state.isFocused ? '#6A4BFF55' : 'none',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      position: 'relative',
      display: 'flex',
      width: 170,
    }),
    valueContainer: () => ({
      zIndex: 100,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      height: 36,
      width: 500,
      border: 'solid 1px gray',
      borderRadius: 5,
    }),
    singleValue: (provided, state) => {
      const borderRadius = '5px';
      const backgroundColor = 'white';
      const display = 'flex';
      const flexFlow = 'row nowrap';
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition, display, flexFlow, backgroundColor };
    }
  }

  return(
    <SelectContainer>
      { accountsState.length > 0 &&
        <Select<Toption, false, Tgroup>
          className='select-react-component'
          //defaultValue={accountsState[0]}
          placeholder={' Ordenar por'}
          onChange={ selectedOption => {
            if (Array.isArray(selectedOption)) {
              throw new Error("Unexpected type passed to ReactSelect onChange handler");
            } else {
              if (selectedOption) setSelectedOption((selectedOption as Toption).value)
            }

          }}
          styles={customStyles}
          isSearchable={false}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          components={ {SingleValue: customSingleValue, Option} }
        />
      }
    </SelectContainer>
  )
}

const SelectContainer  = styled.div`
  
`

const OptionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 170px;
`
  const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 10px;
  `
  const OptionText = styled.span`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    display: inline-block;
  `
