import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'
import useConfig from '../../hooks/useConfig'
import { setLogEvent } from '../../components/popUp/popUpSlice'
import { likePublicacion, dislikePublicacion } from '../../services/publicaciones/api'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

import Resumen from './resumen'
import Equipamiento from './equipamiento'
import Evaluacion from './evaluacion'
import ContactSeller from './ContactSeller'
import Modal from '../../components/modal/modal'
import AuthModal from '../../components/modal/modalAuth'

//import bell from '../../assets/icons/ui/bell.png'
//import user from '../../assets/icons/ui/user.png'

import link from '../../assets/icons/ui/link.png'
import share from '../../assets/icons/ui/share.png'
import nonFavorite from '../../assets/icons/ui/favorite.png'
import favorite from '../../assets/icons/ui/favorite-like.png'
import permutacion from '../../assets/icons/ui/change.png'
import loading from '../../assets/gifs/loadingOrange.gif'
import { Helmet } from 'react-helmet'


const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function formatPrice(price: number) {
  if (price && typeof price === 'number') 
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else
    return null
}

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `el ${date} de ${month}, ${year}`
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function AutoCard(props: TpublicacionItem) {
  const { app } = useConfig()
  const dispatch = useAppDispatch()
  const [navigatorStep, setNavigatorStep] = useState<'resumen' | 'equipamiento' | 'evaluacion'>('resumen')
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [image, setImage] = useState<Blob|null>(null)
  const [imageSlug, setImageSlug] = useState<string|null>(null)
  const [ like, setLike ] = useState<boolean>(props.liked? props.liked : false )
  const [ showModal, setShowModal] = useState<boolean>(false)

  const user = useAppSelector((state: TrootState) => state.user)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  function FavPublicacion() {
    if (user.data) {
      if (like) {
        dislikePublicacion(props.id_publicacion)
        setLike(false)
      }
      else {
        likePublicacion(props.id_publicacion)
        setLike(true)
      }
    } else {
      displayModal()
    }
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  type ImageKeys = "exterior_1" | "exterior_2" | "exterior_3" | "exterior_4" | "exterior_5" | "exterior_6" | "exterior_7" | "exterior_7" | "interior_1" | "interior_2" | "interior_3" | "interior_4" | "interior_5" | "interior_6" | "interior_7" | "interior_8" | "interior_9" | "interior_10" | "interior_11" | "interior_12"
  | "foto_frontal" | "foto_diagonal_frontal" | "foto_lateral" | "foto_diagonal_trasera" | "foto_trasera" | "foto_capo_abierto" | "foto_maletero_abierto" | "foto_volante" | "foto_tablero_01" | "foto_tablero_02" | "foto_asientos_delanteros" | "foto_asientos_traseros" | "foto_caja_cambio" | "foto_puerta_conductor_adentro" | "foto_llanta_delantera_derecha" | "foto_llanta_delantera_izquierda" | "foto_llanta_trasera_derecha" | "foto_llanta_trasera_izquierda" | "foto_llanta_auxilio";

  useEffect(() => {   
    const fotoSlug = Object.entries(props).find(([prop, value]) => 
    (prop as ImageKeys) === 'foto_frontal' ||
    (prop as ImageKeys) === 'exterior_1');

    if (fotoSlug) {
      setImageSlug(`${app.STATIC_FILES}${fotoSlug[1]}`)

      fetch(`${app.STATIC_FILES}${fotoSlug}?date=${Date.now()}`)
      .then((res) => res.blob())
      .then((blob) => {
        setImage(blob)
      });  
    }
  }, [])

  return(
    <>
        <Helmet>
        <title>Detalle vehiculo</title>
          <meta name="description" content="%props.year%" />
          <meta name="keywords" content="palabra clave 1, palabra clave 2" />
          <meta property="og:title" content="Título de la Página" />
          <meta property="og:description" content="Descripción de la página Carp10" />
          <meta property="og:image" content="%props.foto_frontal%" />
          <meta property="og:url" content="%https://carp10.com/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}%" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Título de la Página" />
          <meta name="twitter:description" content="Descripción de la página" />
          <meta name="twitter:image" content="URL_de_la_imagen.png" />
        </Helmet>

    <AutoCardContainer>
      
      <HorizonatlDivisor/>

      <TitleSection>
        <Row>
          <Title> { props.year } { props.marca } { props.modelo } </Title>
        </Row>

        <Row>
          <Price> $us { formatPrice(props.precio) } </Price>

          { props.permutable &&
            <PermutableBadge isMobile={isMobile}>
              <PermutableIcon src={permutacion} isMobile={isMobile}/>
              <PermutableText isMobile={isMobile} > Permutable </PermutableText>
            </PermutableBadge>
          }

        </Row>

        <Row>
          <Published> Publicado { dateFormater(props.publishedAt) } </Published>
          <Location> { props.ciudad } </Location>
        </Row>

        <Links isMobile={isMobile} >

          <ActionLink
            isMobile={isMobile}
            onClick={() => { FavPublicacion() }}
          >
            <LinkIcon src={ like ? favorite : nonFavorite }/>
            Favoritos
          </ActionLink>

          <ActionLink
            isMobile={isMobile}
            onClick={() => {
              navigator.clipboard.writeText(`https://carp10.com/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}`);
              dispatch(setLogEvent({ type: 'info', message: 'Enlace copiado' }))
            }}
          >
            <LinkIcon src={link}/>
            Copiar enlace
          </ActionLink>

          <ActionLink isMobile={isMobile}
            onClick={async () => {
              if (image !== null) {
                const filesArray = [
                  new File(
                    [image],
                    `${app.STATIC_FILES}${imageSlug}`,
                    {
                      type: "image/jpeg",
                      lastModified: new Date().getTime()
                    }
                  )
                ];
    
                const shareData = {
                  title: `${props.marca} ${props.modelo} ${props.year} - Carp10`,
                  text: `Mira este vehículo a la venta en Carp10\n`,
                  files: filesArray,
                  url: `https://carp10.com/publicacion/${props.id_publicacion}?modelo=${props.modelo}&marca=${props.marca}`
                }

                // Share must be triggered by "user activation"
                try {
                  await navigator.share(shareData);
                } catch (err) {
                  console.log(err)
                }
              }
            }}
          >
            <LinkIcon src={ image ? share : loading }/>
            Compartir
          </ActionLink>
        </Links>

        {/* 
          { isMobile && 
            <ContactSeller {...props}/>
          } */
        }

        <Navigator
          className='noselect'
          isMobile={isMobile}
        >

          <NavCard
            selected={navigatorStep === 'resumen'}
            onClick={() => setNavigatorStep('resumen')}
            className='noselect'
          >
            Resumen
          </NavCard>

          <NavCard
            selected={navigatorStep === 'equipamiento'}
            onClick={() => setNavigatorStep('equipamiento')}
            className='noselect'
          >
            Equipamiento
          </NavCard>

          <NavCard
            selected={navigatorStep === 'evaluacion'}
            onClick={() => setNavigatorStep('evaluacion')}
            className='noselect'
          >
            { isMobile ? 'Eval.' : 'Evaluación' } 
            <Carp10>
              <span style={{color: 'white'}}>Car</span>
              <span style={{ color: '#FE7027' }}>p10</span>
            </Carp10>
          </NavCard>

        </Navigator>

        <Content isMobile={isMobile}>
          { navigatorStep === 'resumen' && <Resumen {...props} /> }
          { navigatorStep === 'equipamiento' && <Equipamiento {...props} /> }
          { navigatorStep === 'evaluacion' && <Evaluacion {...props} /> }
        </Content>

      </TitleSection>

      { !user.data && showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <AuthModal
            hiddeModal={hiddeModal}
          />
        </Modal>
      }

    </AutoCardContainer>
    </>
  )
}

interface isMobile { isMobile: boolean }
const HorizonatlDivisor = styled.div`
  width: 100%;
  //border-bottom: solid 2px #999;
  //margin: 10px auto 10px auto;
`
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
`

const AutoCardContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

const ActionIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-self: center;
`
  const ActionIcon = styled.img`
    height: 25px;
  `
const TitleSection= styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`
  const Title = styled.p`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    margin-bottom: 0px;
  `
  const Price = styled.p`
    font-size: 18px;
    font-weight: 600;
    color: gray;
    font-family: Montserrat;
    margin: 0px 0px 0px 0px;
  `
  const Published = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: gray;
    font-family: Montserrat;
    margin-top: 5px;
  `
  const Location = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    align-self: flex-end;
    color: gray;
  `
const Links = styled(Row)<isMobile>`
  background-color: #d9d9d9;
  padding: 7px 30px;
  border-radius: 5px;
  margin-top: 10px;
  ${(args) => args.isMobile && `
    background-color: transparent;
  `}
`
  const ActionLink = styled.div<isMobile>`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${(args) => args.isMobile && `
      flex-flow: column nowrap;
    `}
  `
    const LinkIcon = styled.img`
      height: 20px;
      margin-right: 5px;
    `

const Navigator = styled.div<isMobile>`
  display: flex;
  width: 100%;
  justify-content: center;

  margin-top: 15px;
  padding: 0px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  border: solid 2px #FE7027;
  background-color: #EEE;
  border-radius: 20px;
  overflow: hidden;
  ${(args) => args.isMobile && `
    font-size: 13px;
  `}
`
  interface TnavCard { selected: boolean }
  const NavCard = styled.div<TnavCard>`
    
    //padding-bottom: 5px;
    display: flex;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    color: gray;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    will-change: transform;
    ${(args) => args.selected && `
      color: white;
      background-color: #FE7027;
      box-shadow: 5px 5px 5px 5px #888888;
    `}
    &:hover {
      color: #FE7027;
      background-color: #EEE;
      border-radius: 20px;
      text-decoration: underline;
      }
    ${(args) => args.selected &&
      `
      transition: transform 0.3s ease;
      will-change: transform;
      transform: translateY(-2px);
      `}
  `;

const Content = styled.div<isMobile>`
  width: 100%;
  height: calc(100vh - 270px);
  ${(args) => args.isMobile && `
    height: auto;
  `}
`

const PermutableBadge = styled.div<isMobile>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  border: solid 2px #FF6600;
  border-radius: 5px;
  padding: 4px 10px;
  ${(args) => args.isMobile && `
    border: solid 2px #FF6600;
    padding: 2px 5px;
  `}
`
  const PermutableIcon = styled.img<isMobile>`
    height: 20px;
    margin-right: 15px;
    ${(args) => args.isMobile && `
      margin-right: 10px;
      height: 15px;
    `}
  `
  const PermutableText = styled.div<isMobile>`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    color: #FF6600;
    ${(args) => args.isMobile && `
      font-size: 11px;
    `}
  `

  const Carp10 = styled.div`
    background-color: black;
    font-weight: 700;
    padding: 0px 10px;
    border-radius: 5px;
    margin: 0px 0px 0px 8px;
  `