import React from 'react'
import styled from 'styled-components'

import check from '../../assets/icons/check.png'


export default function SuccessModal() {

  return(
    <SuccesModalContainer>
      <Title> Finalizado </Title>

      <Success>
        <SuccessIcon src={check} />
      </Success>

    </SuccesModalContainer>

  )
}

const SuccesModalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 20px 10px 50px 10px;
`
const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
`
const Success = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #fe7027;
`
  const SuccessIcon = styled.img`
    width: 50px;
  `