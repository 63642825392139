import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showSidebar: false,
}

const micuentaSlice = createSlice({
  name: 'micuenta',
  initialState,
  reducers: {
    hiddeSidebar(state) {
      state.showSidebar = false
    },
    displaySidebar(state) {
      state.showSidebar = true
    },
  }
})

export const {
  hiddeSidebar,
  displaySidebar
} = micuentaSlice.actions

export default micuentaSlice.reducer