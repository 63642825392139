import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { useGetMisPublicacionesQuery } from '../../services/apiSlice/apiSlice'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../hooks/useViewport'

import MiPublicacionCard from '../../components/publicacionCard/miPublicacionCard'
import MiPublicacionMobileCard from '../../components/publicacionCard/miPublicacionMobileCard'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function MisPublicaciones() {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)
  const { data: misPublicaciones, error, isLoading, isSuccess } = useGetMisPublicacionesQuery({})

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <MisPublicacionesContainer className='MisPublicacionesContainer'>
      <Title> Mis Publicaciones </Title>

      <PublicacionesContainer isMobile={isMobile} >
        { misPublicaciones &&
          misPublicaciones.items.map((publicacion: TpublicacionItem) => (
            isMobile ? 
            <MiPublicacionMobileCard {...publicacion} key={publicacion.id_publicacion}/>
            :
            <MiPublicacionCard {...publicacion} key={publicacion.id_publicacion}/>
          ))
        }
      </PublicacionesContainer>

    </MisPublicacionesContainer>
  )
}

interface isMobile { isMobile: boolean }
const MisPublicacionesContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.div`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 22px;
  text-align: left;
  margin: 10px 10px;
`
const PublicacionesContainer = styled.div<isMobile>`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding-left: 10px;
  ${(args) => args.isMobile && `
    gap: 15px;
    padding-left: 0px;
  `}
`