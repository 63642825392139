import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { displaySidebar } from '../miCuentaSlice'
import useViewport from '../../../hooks/useViewport'

//import SocialMedia from './socialMedia'

import menuIconLigth from '../../../assets/icons/ui/menu_ligth.png';
import userIcon from '../../../assets/icons/ui/user.png'
import menuIcon from '../../../assets/icons/ui/menu-micuenta.png'
import menuIconDark from '../../../assets/icons/ui/menu_dark.png';
import logo_ligth from '../../../assets/icons/ui/logo_ligth.png'
import logo_dark from '../../../assets/icons/ui/logo_dark.png'
import { NavLink } from 'react-router-dom'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function HeaderMiCuentaMobile() {
  const dispatch = useDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const user = useAppSelector((state: TrootState) => state.user)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  return(
    <HeaderElement
      isMobile={isMobile}
      style={{zIndex: 997}}
      className='Header'
    >

      <LeftSide>
        <MenuICon
          onClick={() => { dispatch(displaySidebar()) }}
          src={ menuIcon } 
        />
      </LeftSide>

      <CenterSide>
        Mis Publicaciones
      </CenterSide>

      <RightSide>
        { user.data &&
          <UserIcon src={user.data.profile_image ? user.data.profile_image : userIcon}/>
        }
      </RightSide>
      

    </HeaderElement>
  );
};

interface TheaderElement { isMobile: boolean }
const HeaderElement = styled.div<TheaderElement>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  //height: 100px;
  height: ${(args) => args.isMobile ? '70px' : '100px' };

  box-shadow: 0 5px 6px -6px #777;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CenterSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
`

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuICon = styled.img`
  width: 30px;
  cursor: pointer;
`;

const UserIcon = styled.img`
  border-radius: 35px;
  width: 35px;
  cursor: pointer;
`;

