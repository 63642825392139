
export type publicarSteps = 'datosVehiculo' | 'precio' | 'fotos'

export type Photo = {
  imageURL: string,
  prediction1: string,
  prediction2: string | null;
  name?: string;
};

export type fotos = {
  fotosGroup1: Photo[],
  fotosGroup2: Photo[],
}

export type publicacion = {

  ui: {
    step: publicarSteps,
    step1Complete: boolean,
    step2Complete: boolean,
    step3Complete: boolean,
  },

  pubEnCurso: {
    publicacion_id: string
  } | undefined,

  marca: string | undefined,
  modelo: string | undefined,
  year: number | undefined,

  version: string | undefined,
  traccion: '4x4'|'4x2'| undefined,

  transmision: 'mecanica' | 'automatica' | undefined,
  motor_cc: number | undefined,
  kilometraje: number | undefined,
  propietarios_anteriores: 'primero' | 'segundo' | 'tercero' | undefined,
  ciudad: string | undefined,
  de_agencia: boolean | undefined,
  permutable: boolean | undefined,
  mostrar_precio: boolean | undefined,
  precio: number | undefined,


  //fotos: fotos,

  fotos: {
    fotosGroup1: Photo[];
    fotosGroup2: Photo[];
  }
}

/*
export const grupo1Fotos = [
  'foto_frontal',
  'foto_diagonal_frontal',
  'foto_lateral',
  'foto_diagonal_trasera',
  'foto_trasera'
]

export const grupo2Fotos = [
  'foto_maletero_abierto',
  'foto_volante',
  'foto_tablero_01',
  'foto_tablero_02',
  'foto_asientos_delanteros',
  'foto_asientos_traseros',
  'foto_caja_cambio',
  'foto_puerta_conductor_adentro',
]
*/


export const datosVehiculos = [
  'marca',
  'modelo',
  'year',
  'version',
  'transmision',
  'motor_cc',
  'kilometraje',
  'propietarios_anteriores',
  'ciudad',
  'de_agencia',
  'permutable',
]

export const precio = [
  'precio'
]