import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'
import useViewport from '../../../hooks/useViewport'
import { useNavigate } from 'react-router-dom'
import { useUpdateEquipamientoMutation, useUpdateMiPublicacionMutation } from '../../../services/apiSlice/apiSlice'

import ResumenMiPublicacion from './details_resumen'
import EvaluacionMiPublicacion from '../../publicacion/evaluacion'
import MiEquipamiento from './details_equipamiento'
import Modal from '../../../components/modal/modal'
import ModalVendido from './modalVendido'

import closeIcon from '../../../assets/icons/ui/close-square.png'
import whatsapp from '../../../assets/icons/social/whatsapp.png'
import loadingGif from '../../../assets/gifs/loading.gif'

const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function formatPrice(price: number) {
  if (price && typeof price === 'number') 
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else
    return null
}

function dateFormater(dateStamp: number) {
  const d = new Date(dateStamp)

  const date = d.getDate()
  const month = meses[d.getMonth()]
  const year = d.getFullYear()

  return `el ${date} de ${month}, ${year}`
}

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function DetailsNav(props: TpublicacionItem) {
  const navigate = useNavigate()
  const [navigator, setNavigator] = useState<'resumen' | 'equipamiento' | 'evaluacion'>('resumen')
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ updateMiPublicacion, respUpdateMiPub ] = useUpdateMiPublicacionMutation()
  const [ showModal, setShowModal] = useState<boolean>(false)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  return(
    <AutoCardContainer>

      { !isMobile && 
        <CloseContainer
          onClick={() => navigate(-1)}
        >
          <CloseIcon src={closeIcon} />
        </CloseContainer>
      }

      <TitleSection>

        <Navigator
          className='noselect'
          isMobile={isMobile}
        >

          <NavCard
            selected={navigator === 'resumen'}
            onClick={() => setNavigator('resumen')}
            className='noselect'
          >
            Resumen
          </NavCard>

          <NavCard
            selected={navigator === 'equipamiento'}
            onClick={() => setNavigator('equipamiento')}
            className='noselect'
          >
            Equipamiento
          </NavCard>

          <NavCard
            selected={navigator === 'evaluacion'}
            onClick={() => setNavigator('evaluacion')}
            className='noselect'
          >
            Evaluación Mec.
          </NavCard>

        </Navigator>


        <Content isMobile={isMobile}>
          <>
            { navigator === 'resumen' && <ResumenMiPublicacion {...props} /> }
            { navigator === 'evaluacion' && <EvaluacionMiPublicacion {...props} /> }
            { navigator === 'equipamiento' && <MiEquipamiento {...props}/> }
          </>
          
          { !isMobile &&
            <>
              <ContacUs>
                <ContactText> Te Parece que hay un dato incorrecto, escríbenos </ContactText>
                <Link href={`https://wa.me/+59176000632`} target='_blank'>
                  <ContactIcon src={whatsapp}/>
                </Link>
              </ContacUs>

              <Botonera>
                <Solicitar> Solicitar inspección </Solicitar>
                <Vendido
                  onClick={() => {
                    if (props.vendido === false)
                      setShowModal(true)
                    else { 
                      updateMiPublicacion({
                        publicacionId: props.id_publicacion,
                        payload: {
                          vendido: false,
                        }
                      })
                    }
                  }}

                  disabled={respUpdateMiPub.isLoading}
                >
                  { respUpdateMiPub.isLoading ? '' : props.vendido === false ? 'Marcar como vendido' : 'Revertir vendido' }
                  <LoadingGif src={loadingGif} isLoading={respUpdateMiPub.isLoading}/>
                </Vendido>
              </Botonera>
            </>
          }

          { showModal &&
            <Modal
              closeModal={hiddeModal}
            >
              <ModalVendido
                publicacionId={props.id_publicacion}
                closeModal={hiddeModal}
              />
            </Modal>
          }

        </Content>

      </TitleSection>

    </AutoCardContainer>
  )
}

interface isMobile { isMobile: boolean }
const HorizonatlDivisor = styled.div`
  width: 100%;
  border-bottom: solid 2px #999;
  margin: 10px auto 10px auto;
`
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
`

const AutoCardContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 95%;
  margin: auto;
`

const TitleSection= styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`

const Navigator = styled.div<isMobile>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0px 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  //border-bottom: solid 2px #b9b9b9;
  ${(args) => args.isMobile && `
    font-size: 13px;
  `}
`
  interface TnavCard { selected: boolean }
  const NavCard = styled.div<TnavCard>`
    
    padding-bottom: 5px;
    
    color: gray;
    cursor: pointer;
    ${(args) => args.selected && `
      color: #FE7027;
      border-bottom: solid 2px #FE7027;
    `}
  `
const Content = styled.div<isMobile>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  ${(args) => args.isMobile && `
    min-height: 300px;
  `}
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  align-self: flex-end;
  width: 12px;
  background-color: #FE7027;
  border-radius: 50px;
  cursor: pointer;
`
const CloseIcon = styled.img`
  width: 18px;
`

const ContacUs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 40px;
  background-color: #999;
  width: 100%;
`
  const ContactText = styled.div`
    font-size: 14px;
    color: white;
  `
  const ContactIcon = styled.img`
    height: 30px;
    width: 30px;
  `

const Botonera = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`

const Button = styled.button`
  border: none;
  padding: 5px 20px;
  font-family: Montserrat;
  font-size: 13px;
  border-radius: 3px;
  color: white;
`

const Solicitar = styled(Button)`
  background-color: #FE7027;
  color: white;
`
const Vendido = styled(Button)`
  background-color: #777;
`
const Link = styled.a`
  text-decoration: none;
`

interface TloadingGif { isLoading: boolean }
const LoadingGif = styled.img<TloadingGif>`
  width: 23px;
  display: none;
  ${(args) => args.isLoading && `
    display: initial;
  `}
`