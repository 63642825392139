import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"
import useViewport from "../../hooks/useViewport"

import AgendarDatosVehiculo from "./agendarDatosVehiculo"
import AgendarFecha from "./agendarFecha"
import AgendarSuccess from "./agendarSuccess"
import Facturacion from "./facturacion"

export type Tagendar = 'datosVehiculo' | 'fecha' | 'facturacion' | 'success'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Agendar() {  
  const [step, setStep] = useState<Tagendar>('datosVehiculo')
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const changeStep = (data: Tagendar) => {
    setStep(data)
  }

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return (
    <SolcitarInspeccion
      
      isMobile={isMobile}
    >
      { step === 'datosVehiculo' && <AgendarDatosVehiculo changeStep={changeStep} /> }
      { step === 'fecha' && <AgendarFecha changeStep={changeStep} /> }
      { step === 'facturacion' && <Facturacion changeStep={changeStep} /> }
      { step === 'success' && <AgendarSuccess changeStep={changeStep} /> }
    </SolcitarInspeccion>
  );
}

interface TsolicitarInspeccion { isMobile: boolean }
const SolcitarInspeccion = styled.div<TsolicitarInspeccion>`
  /* display: flex;
  flex-flow: column nowrap;
  align-items: center; */
  width: 100%;
`
 
