import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import {
  publicacionParcialPayloadReq,
  publicacionParcialResponse,
  TresponseError,
  TuploadImageAuthorizationReq,
  TsimpleResponse,
  TfinishPublicacionReq,
  TuploadImageAuthorizationResp } from './apiTypes'

let BACKEND_URL = 'https://api.carp10.com'

export function createPartialPublicacion(payload: publicacionParcialPayloadReq) {

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'salvarDatos',
      ...payload
    }),
    url: `${BACKEND_URL}/vender/publicar`
  }

  return new Promise<AxiosResponse<publicacionParcialResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error: AxiosError<TresponseError>) {
      console.log("Error during axios request in createPartialPublicacion: ", error)
      reject(error);
    })
  })
}

export function finishPublicacion(payload: TfinishPublicacionReq, imageUrls: string[]) {

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'finishOferta',
      ...payload,
      imageUrls: imageUrls,
    }),
    url: `${BACKEND_URL}/vender/publicar`
  }

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error: AxiosError<TresponseError>) {
      console.log("Error during axios request in finishPublicacion:", error);
      reject(error);
    })
  })
}

export function uploadImageAuthorization(payload: TuploadImageAuthorizationReq) {

  const bodyData = {
    type: 'uploadImageAuth',
    ...payload
  };

  console.log("Cuerpo de la solicitud a uploadImageAuthorization: ", bodyData);  // Imprime el cuerpo de la solicitud

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify(bodyData),
    url: `${BACKEND_URL}/vender/publicar`
  };

  return new Promise<AxiosResponse<TuploadImageAuthorizationResp>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error: AxiosError<TresponseError>) {
      console.log("Error during axios request in uploadImageAuthorization",error);
      reject(error);
    });
  });
}

export function uploadImage(file: File|Blob, slug: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    axios.put(slug, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        //resolve({ result: 'success' });
        resolve(slug);
      } else {
        reject({ 
          result: 'fail', 
          message: 'Upload failed with status ' + response.status 
        });
      }
    })
    .catch((error) => {
      console.log('Error during image upload:', error)
      reject({ result: 'fail', message: error.message });
    });
  });
}
