import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import facebook from '../../assets/icons/social/facebook.png'
import instagram from '../../assets/icons/social/instagram.png'
import linkedin from '../../assets/icons/social/linkedin.png'
import { NavLink, Link } from 'react-router-dom'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Footer() {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <FooterElement
      isMobile={isMobile}
      className='Footer'
    >

      <AboutCarp10 isMobile={isMobile}>
        <Title> Sobre Carp10 </Title>
        <HorizonatlDivisor/>
        <Item> Sobre Nosotros </Item>
        <Item> Contacto </Item>
        <Item> Preguntas Frecuentes </Item>
        <Item> ¿Como funciona? </Item>
        <Item> Blog </Item>

        { !isMobile &&
          <>
            <br/>
            <Terms>
            <LinkCustom  to={'/terminos&condiciones'}>
              <ItemTerm> Términos y condiciones </ItemTerm>
            </LinkCustom>
            <LinkCustom  to={'/politicasdeprivacidad'}>
              <ItemTerm> Politicas de privacidad </ItemTerm>
              </LinkCustom>
            </Terms>
          </>
        }

      </AboutCarp10>

      <AtencionAlCliente isMobile={isMobile}>
        <Title> Atención Al Cliente: </Title>
        <HorizonatlDivisor/>
        <Item> telf: +(591) 76000632 </Item>
        <Item> correo: info@carp10.com </Item>
      </AtencionAlCliente>

      { isMobile &&
        <Terms>
          <LinkCustom  to={'/terminos&condiciones'}>
            <ItemTerm> Términos y condiciones </ItemTerm>
          </LinkCustom>

          <LinkCustom  to={'/politicasdeprivacidad'}>
            <ItemTerm> Politicas de privacidad </ItemTerm>
          </LinkCustom>
        </Terms>        
      }

      <Socials
        isMobile={isMobile}
      >
        <a href='https://www.facebook.com/carp10com' target='_blank'>
          <SocialIcon src={facebook}/>
        </a>

        <a href='https://www.instagram.com/carp10com' target='_blank'>
          <SocialIcon src={instagram} />
        </a>

        {/* <SocialIcon src={linkedin} /> */}

      </Socials>

    </FooterElement>
  )
}

interface TfooterElement { isMobile: boolean }
const FooterElement = styled.div<TfooterElement>`
  background-color: #30302C;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding-top: 50px;
  padding-bottom: 50px;
  gap: 20px;

  ${(args) => !args.isMobile && `
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
  `}
`
const Title = styled.div`
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  text-align: left;
`
const Item = styled.div`
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
`
interface Tcolumn { isMobile: boolean }
const AboutCarp10 = styled.div<Tcolumn>`
  display: flex;
  flex-flow: column nowrap;
  flex-direction: left;
  width: 70%;
  ${(args) => !args.isMobile && `
    width: 300px;
  `}
`

const AtencionAlCliente = styled.div<Tcolumn>`
  display: flex;
  flex-flow: column nowrap;
  flex-direction: left;
  width: 70%;
  ${(args) => !args.isMobile && `
    width: 300px;
  `}
`
const HorizonatlDivisor = styled.div`
  align-self: flex-start;
  width: 100px;
  border-bottom: solid 2px #FE7027;
  margin: 5px 0px 5px 0px;
`
const Terms = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 30px;
`

  const ItemTerm = styled(Item)`
    font-size: 10px;
    
  `

const Socials = styled.div<Tcolumn>`
  display: flex;
  flex-flow: row nowrap;
  gap: 30px;
  margin-top: 10px;
  ${(args) => !args.isMobile && `
    align-self: flex-end;
  `}
`
  const SocialIcon = styled.img`
    width: 50px; 
  `

const LinkCustom = styled(Link)`
  text-decoration: none
`