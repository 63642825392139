import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { publicacion, publicarSteps, Photo } from "./publicarTypes"


const initialState: publicacion = {
  ui: {
    step: 'datosVehiculo',
    step1Complete: false,
    step2Complete: false,
    step3Complete: false,
  },

  pubEnCurso: undefined,

  marca: undefined,
  modelo: undefined,
  year: undefined,

  version: undefined,
  traccion: undefined,

  transmision: undefined,
  motor_cc: undefined,
  kilometraje: undefined,
  propietarios_anteriores: undefined,
  ciudad: undefined,
  de_agencia: false,
  permutable: false,
  mostrar_precio: true,
  precio: undefined,
  fotos: {
    fotosGroup1: [],
    fotosGroup2: []
  }
}

const publicarSlice = createSlice({
  name: 'publicar',
  initialState,
  reducers: {
    setUIStep: (state, action: PayloadAction<publicarSteps>) => {
      state.ui.step = action.payload
    },
    setCurrentPublicacion: (state, action: PayloadAction<string>) => {
      state.pubEnCurso = {
        publicacion_id: action.payload
      }
    },
    setMarca(state, action: PayloadAction<string>) {
      state.marca =  action.payload
    },
    setModelo(state, action: PayloadAction<string>) {
      state.modelo = action.payload
    },
    setYear(state, action: PayloadAction<number>) {
      state.year = action.payload
    },
    setVersion(state, action: PayloadAction<string>) {
      state.version = action.payload
    },
    setTraccion(state, action: PayloadAction<'4x4'|'4x2'>) {
      state.traccion = action.payload
    },
    setTransmision(state, action: PayloadAction<'mecanica' | 'automatica'>) {
      state.transmision = action.payload
    },
    setMotorCC(state, action: PayloadAction<number>) {
      state.motor_cc = action.payload
    },
    setKilometraje(state, action: PayloadAction<number>) {
      state.kilometraje = action.payload
    },
    setPropietariosAnteriores(state, action: PayloadAction<'primero' | 'segundo' | 'tercero'>) {
      state.propietarios_anteriores = action.payload
    },
    setCiudad(state, action: PayloadAction<string>) {
      state.ciudad = action.payload
    },
    setDeAgencia(state, action: PayloadAction<boolean>) {
      state.de_agencia = action.payload
    },
    setPermutable(state, action: PayloadAction<boolean>) {
      state.permutable = action.payload
    },
    setPrecio(state, action: PayloadAction<number>) {
      state.precio = action.payload
    },
    setMostrarPrecio(state, action: PayloadAction<boolean>) {
      state.mostrar_precio = action.payload
    },
    clearFotos: (state) => {
      state.fotos.fotosGroup1 = [];
      state.fotos.fotosGroup2 = [];
    },
    updateFotos: (state, action: PayloadAction<{group1: Photo[], group2: Photo[]}>) => {
      state.fotos.fotosGroup1 = action.payload.group1;
      state.fotos.fotosGroup2 = action.payload.group2;
    },
    addFotoToGroup1: (state, action: PayloadAction<Photo>) => {
      state.fotos.fotosGroup1.push(action.payload);
    },
    addFotoToGroup2: (state, action: PayloadAction<Photo>) => {
      state.fotos.fotosGroup2.push(action.payload);
    },
    deleteFoto: (state, action: PayloadAction<{group: 'group1' | 'group2', index: number}>) => {
      const { group, index } = action.payload;
      
      if (group === 'group1') {
        state.fotos.fotosGroup1.splice(index, 1);
      } else if (group === 'group2') {
        state.fotos.fotosGroup2.splice(index, 1);
      }
    },
    

    setFinishedStep(state, action: PayloadAction<{ step: 1 | 2 | 3, finish: boolean }>) {
      const { payload } = action
      let toUpdate: any = { step1Complete: payload.finish }

      if (payload.step === 2) toUpdate = { step2Complete: payload.finish }
      if (payload.step === 3) toUpdate = { step3Complete: payload.finish }

      state.ui = {
        ...state.ui,
        ...toUpdate,
      }
    },
    setFinishPublicar: (state) => {
      Object.assign(state, initialState);
    }
  }
})

export const { 
  setUIStep,
  setCurrentPublicacion,
  setMarca,
  setModelo,
  setYear,
  setVersion,
  setTraccion,
  setTransmision,
  setMotorCC,
  setKilometraje,
  setPropietariosAnteriores,
  setCiudad,
  setDeAgencia,
  setPermutable,
  setPrecio,
  setMostrarPrecio,
  clearFotos,
  updateFotos,
  addFotoToGroup1,
  addFotoToGroup2,
  deleteFoto,
  setFinishedStep,
  setFinishPublicar,
 } = publicarSlice.actions

export default publicarSlice.reducer