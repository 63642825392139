import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useConfig from '../../hooks/useConfig'
import styled from 'styled-components'
import { TsignUpStep } from './SignUp'
import Cookies from 'universal-cookie'
import { AxiosError } from 'axios'
import { signUpRegister, verifyUserExists } from '../../services/auth/api'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import { googleLogin } from '../../services/auth/userSlice'
import { setLogEvent } from '../../components/popUp/popUpSlice'
import { useAppDispatch } from '../../app/store'
import Modal from '../../components/modal/modal'
import PhoneModal from '../../components/modal/phoneModal'


import loadingGif from '../../assets/gifs/loading.gif'
import emailIcon from '../../assets/icons/auth/email.png'
import { TresponseError } from '../../services/auth/userApiTypes'


const cities = ['Santa Cruz de la Sierra', 'Cochabamba', 'La Paz', 'Trinidad']

const cookies = new Cookies()

interface message {
  type: 'info' | 'error' | 'none',
  message: string | undefined,
}

interface Tregister { changeRegisterStep: (step: TsignUpStep) => void }
export default function Register(props: Tregister) {
  const config = useConfig()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [ message, setMessage ] = useState<message>({ type: 'none', message: '' })
  const [ emailSignUp, setEmailSignUp ] = useState(false)

  const [ enableSignUp, setEnableSignUp ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  const emailInput = useRef<HTMLInputElement>(null)
  const passInput = useRef<HTMLInputElement>(null)
  const phoneInput = useRef<HTMLInputElement>(null)
  const nombreInput = useRef<HTMLInputElement>(null)
  const apellidosInput = useRef<HTMLInputElement>(null)
  const citySelect = useRef<HTMLSelectElement>(null)

  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ phone, setPhone ] = useState<string|undefined>(undefined)
  const [ credential, setCrediental ] = useState<string|undefined>(undefined)
  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)


  function inputChange() {
    if (emailInput.current &&
        passInput.current &&
        phoneInput.current &&
        nombreInput.current &&
        apellidosInput.current &&
        citySelect.current) {

      if (emailInput.current.value !== '' && 
          passInput.current.value !== '' && 
          phoneInput.current.value !== '' &&
          nombreInput.current.value !== '' && 
          apellidosInput.current.value !== '' &&
          citySelect.current.value !== '')  {
        setEnableSignUp(true);
      } else {
        setEnableSignUp(false);
      }
    }
  }
  
  function registrarse(event: React.SyntheticEvent) {
    setMessage({ type: 'none', message: '' });
    setLoading(true);
    event.preventDefault();

    const target = event.target as typeof event.target & {
      phone: { value: string };
      email: { value: string };
      password: { value: string };
      given_name: { value: string };
      family_name: { value: string };
      city: { value: string };
    };
  
    const payload = {
      type: "signup",
      phone_number: target.phone.value,
      email: target.email.value,
      password: target.password.value,
      given_name: target.given_name.value,
      family_name: target.family_name.value,
      city: target.city.value,
    }

    console.log(payload)

    signUpRegister(payload)
    .then(resp => {
      console.log('Register: ', resp)
      setLoading(false);
      cookies.set(config.app.COOKIE_SIGNUP, target.email.value);
      props.changeRegisterStep('verificacion')
    })
    .catch(err => {
      let error: AxiosError<TresponseError> = err
      setLoading(false)
      setMessage({ type: 'error', message: error.response?.data.message })
      console.log('ResetPassNewPass Error: ', err)
    })
  }

  function googleAuth(props: { google_token: string, phone_number?: string }) {
    dispatch(googleLogin({...props}))
    .then((resp) => {
      navigate('/')
    })
    .catch((error: any) => {
      dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
    })
  }

  useEffect(() => {
    if (phone && credential) {
      googleAuth({ google_token: credential, phone_number: phone })
    }
  }, [phone])

  return (
    <FormContainer>

      <Title>Registrate</Title>

      { emailSignUp ?
        <Form onSubmit={registrarse} >
          

          <Input
            type="text"
            ref={nombreInput}
            onChange={() => { inputChange() }}
            placeholder="Nombres"
            name="given_name"
          />

          <Input
            type="text"
            ref={apellidosInput}
            onChange={() => { inputChange() }}
            placeholder="Apellidos"
            name="family_name"
          />

          <Input
            type="email"
            ref={emailInput}
            onChange={() => { inputChange() }}
            placeholder="Email"
            name="email"
          />

          <Input
            type="password"
            ref={passInput}
            onChange={() => { inputChange() }}

            placeholder="Contraseña"
            name="password"
          />

          <PhoneInputContainer>
            <CountryCodeInput
              type='text'
              placeholder='+591'
              disabled={true}
            />
            
            <PhoneInput
              type="tel"
              ref={phoneInput}
              onChange={() => { inputChange() }}
              placeholder="Telefono"
              name="phone"
            />
          </PhoneInputContainer>

          <SelectCity
            id="fast-input-marca"
            name="city"
            onChange={inputChange}
            ref={citySelect}
          >
            <option value="" disabled selected>Seleccione una ciudad</option>

            {
              cities.map((city, index) => (
                <option value={city} key={`marca${index}`}>
                  { city }
                </option>
              ))
            }

          </SelectCity>

          <p className={ message.type === 'error' ? 'auth-messageError' : 'auth-hidden' } >
              { message.message }
          </p>

          <SignUpButton
            type="submit"
            ready={enableSignUp}
            disabled={!enableSignUp || loading ? true : false}
          >
            { loading ? '' : 'Continuar' }
            <LoadingGif isLoading={loading} src={loadingGif}/>
          </SignUpButton>
          
        </Form>
        :
        <LoginContainer>

          <EmailSignUp
            onClick={() => setEmailSignUp(true)}
          >
            <EmailIcon src={emailIcon} />
            <EmailText> Continuar con Correo </EmailText>
          </EmailSignUp>

          <GoogleLogin
            width='250'
            locale='es_SP'
            text='continue_with'
            onSuccess={credentialResponse => {
              if (credentialResponse.credential) {
                const credential = credentialResponse.credential
                const segment = credential.split('.')
                const parsed = JSON.parse(atob(segment[1]))
        
                verifyUserExists(parsed.email)
                .then((resp: any) => {
                  if (resp.data.userExits === true) {
                    googleAuth({ google_token: credential })
                  } else {
                    setShowModal(true)
                    setCrediental(credential)
                  }
                })
              }
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            useOneTap
          />
        </LoginContainer>
      }

      <GoToLogin>
        Ya tienes una cuenta?
        
          <HighLighted
            onClick={() => {
              if (pathname === '/inspeccion')
              props.changeRegisterStep('login')
              else
              navigate('/login')
            }}
          >
            Inicia Sesión
          </HighLighted>
        
      </GoToLogin>

      
      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <PhoneModal
            hiddeModal={hiddeModal}
            setPhone={setPhone}
          />
        </Modal>
      }

        
    </FormContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const Title = styled.p`
  font-weight: 500;
  font-size:  25px;
  color: white;
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 0px auto 10px auto;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

interface TsignUpButton { ready: boolean }
const SignUpButton = styled.button<TsignUpButton>`
  width: 90%;
  background-color: #FF925C;
  
  border-radius: 10px;
  font-size: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  margin-top: 40px;
  ${(args) => args.ready && `
    background-color: #fe7027;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `

interface Tinput { error: boolean }
const Input = styled.input`
  width: 90%;
  border: none;
  border-radius: 5px;
  //border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0px 0px 0px;
  padding: 5px 5px 5px 15px;

  &:focus {
    outline: 1px solid black;
  }
`
const SelectCity = styled.select`
  width: 90%;
  border: none;
  border-radius: 5px;
  background-color: white;
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0px 0px 0px;
  padding: 10px 5px 10px 15px;
`
const PhoneInputContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 90%;
  gap: 10px;
`
  const CountryCodeInput = styled.input`
    flex-grow: 1;
    width: 50px;
    border: none;
    border-radius: 5px;
    background-color: white;
    font-size: 13px;
    line-height: 30px;
    margin: 15px 0px 0px 0px;
    padding: 5px 5px 5px 15px;
  `
  const PhoneInput = styled(Input)`
    width: auto;
    flex-grow: 4;
  `

const GoToLogin = styled.p`
  align-self: flex-end;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-align: right;
  text-decoration: none !important;
  color: white;

`
const HighLighted = styled.span`
  color: #fe7027;
  font-weight: 600;
  margin-left: 5px;
`

const LoginContainer = styled.div`
  align-self: center;
  margin-bottom: 20px;
`

const EmailSignUp = styled.div`
  background-color: #fe7027;
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 38px;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
`
  const EmailIcon = styled.img`
    height: 30px;
    margin-left: 5px;
  `
  const EmailText = styled.div`
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    margin-left: 30px;
  `