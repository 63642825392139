import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'

import Login from '../../views/sign-in/login'
import ResetPassword from '../../views/sign-in/resetPassword'
import ResetPasswordSuccess from '../../views/sign-in/resetPassSuccess'

import Register from '../../views/sign-up/register'
import Verificacion from '../../views/sign-up/verification'
import SignUpSuccess from '../../views/sign-up/signUpSuccess'
import AuthAdvice from '../advice/authAdvice'

import logo from '../../assets/icons/ui/logo_ligth.png'


export type TsignInStep = 'login' | 'resetPassword' | 'resetPasswordSuccess' | 'signup' | 'verificacion' | 'signUpSucces'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface thisProps { hiddeModal: () => void }
export default function AuthModal(props: thisProps) {
  const [step, setStep] = useState<TsignInStep>('login')
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)
  }, [bodyWidth, bodyTop])

  const changeStep = (data: TsignInStep) => {
    setStep(data)
  }

  return(
    <AuthModalContainer
      isMobile={isMobile}
    >
      
      <Card>
        { isMobile && <Logo src={logo} /> }
        { step === 'login' && <Login changeStep={changeStep} /> }
        { step === 'resetPassword' && <ResetPassword changeStep={changeStep} /> }
        { step === 'resetPasswordSuccess' && <ResetPasswordSuccess changeStep={changeStep}/> }
        { step === 'signup' && <Register changeRegisterStep={changeStep}/>}
        { step === 'verificacion' &&  <Verificacion changeRegisterStep={changeStep}/> }
        { step === 'signUpSucces' &&  <SignUpSuccess changeRegisterStep={changeStep}/> }
      </Card>

      { !isMobile &&
        <AuthAdvice/>
      }

    </AuthModalContainer>
  )
}

interface TauthModalContainer { isMobile: boolean }
const AuthModalContainer = styled.div<TauthModalContainer>`
  border-radius: 10px;
  padding: 40px 20px;
  background-color: #282c34;
  ${(args) => !args.isMobile && `
    display: flex;
    flex-flow: row nowrap;  
    background-color: white;
    gap: 30px;
  `}
`

const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 250px;
  max-width: 350px;
  margin: 0px;
  background-color: #282c34;
  border-radius: 10px;
`
const Logo = styled.img`
  width: 200px;
  margin-top: 10px;
` 