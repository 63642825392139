import React, { useLayoutEffect, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
import { setInspeccionFecha, setInspeccionHora, setInspeccionDireccion } from '../../services/pagoInspeccion/inspeccionPubliSlice';
import { Tagendar } from './agendar'
import useViewport from "../../hooks/useViewport"

import NavigatorComponent from './navigatorComponent';


const horaCita = [
  '08:00 - 10:00',
  '10:00 - 12:00',
  '12:00 - 14:00',
  '14:00 - 16:00'
]

const year = [
  2010, 2011, 2012, 2013, 2014,
  2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface Tprops { changeStep: (step: Tagendar) => void }
export default function AgendarFecha(props: Tprops) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(new Date());
  const date = new Date();
  const fromDate = `${date.getMonth() + 1}/${date.getDate() + 1}/${date.getFullYear()}`;

  const [complete, setComplete] = useState(false)


  const inspeccion = useAppSelector((state: TrootState) => state.inspeccionPubli)


  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'inspeccion-hora') {
      dispatch(setInspeccionHora(event.target.value))
    }

    if (event.target.name === 'inspeccion-address') {
      dispatch(setInspeccionDireccion(event.target.value))
    }
  }

  useEffect(() => {
    dispatch(setInspeccionFecha(startDate.getTime()))
  }, [startDate])


  useEffect(() => {
    if (inspeccion.data.fecha && inspeccion.data.hora && inspeccion.data.direccion)
    setComplete(true)
    else
    setComplete(false)
  }, [inspeccion])

  return(
    <DatosVehiculoContainer
      className='view'
      isMobile={isMobile}
    >
      <Centered>

        <NavigatorComponent step={2}/>

        <Title> Agendar Diagnóstico </Title>

        <FirstRow
          isMobile={isMobile}
          isSD={isSD}
        >
          <DatePickerStyled
            selected={startDate}
            onChange={(date:Date) => setStartDate(date)}
            locale={es}
            minDate={new Date(fromDate)}
          />

          <SelectPublicar 
            name='inspeccion-hora'
            onChange={inputChange}
            defaultValue={inspeccion.data.hora ? inspeccion.data.hora : ''}
          >
            <Option value="" disabled> Horario </Option>
            { horaCita.map((item, index) => (
              <Option value={item} key={`inspeccion-hora-${index}`}>
                { item }
              </Option>
            ))}
          </SelectPublicar>

          <Input
            type='text'
            name='inspeccion-address'
            placeholder={ inspeccion.data.direccion ? inspeccion.data.direccion  : 'Dirección' }
            onChange={inputChange}
          />

        </FirstRow>

        <Bottom isMobile={isMobile}>
          <Volver
            onClick={() => props.changeStep('datosVehiculo')}
          >
            Volver
          </Volver>

          <Continue
            onClick={() => props.changeStep('facturacion')}
            disabled={!complete || loading ? true : false }
            ready={complete}
          >
            Solicitar Diagnóstico
          </Continue>
        </Bottom>

      </Centered>
    </DatosVehiculoContainer>
  )
}

interface TdatosVehiculoContainer { isMobile: boolean }
const DatosVehiculoContainer = styled.div<TdatosVehiculoContainer>`
  width: 100%;
`
const Centered = styled.div`
  max-width: 850px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: max(calc(100vh - 150px), 567px);
  margin: 20px auto 0px auto;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 30px;
  align-self: flex-start;
  margin: 0px 0px 0px 30px;
`

const DatePickerStyled = styled(DatePicker)`
  font-family: Montserrat;
  font-weight: 600;
  width: 180px;
  padding: 6px 5px 6px 15px;
  border: solid 1px gray;
  border-radius: 5px;
`

const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 600;
  width: 200px;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`
  const Option = styled.option`
    margin: 5px 0px 5px 0px;
    font-family: Montserrat;
  `

const Input = styled.input`
  font-family: Montserrat;
  font-weight: 600;
  width: 180px;
  margin: 5px 0px 0px 0px;
  padding: 6px 5px 6px 15px;
  border: solid 1px gray;
  border-radius: 5px;
`

interface TfirstRow { isMobile: boolean, isSD: boolean }
const FirstRow = styled.div<TfirstRow>`
  display: flex;
  flex-flow: ${(args) => args.isMobile  ? 'column nowrap' : 'row nowrap'};
  gap: ${(args) => args.isMobile  ? '30px' : '100px'};
`
interface Tbottom { isMobile: boolean }
const Bottom = styled.div<Tbottom>`
  margin-top: 30px;
  display: flex;
  flex-flow: ${(args) => args.isMobile ? 'column nowrap' : 'row nowrap' };
  gap: 30px;
`

interface Tcontinue { ready: boolean }
const Continue = styled.button<Tcontinue>`
  cursor: pointer;
  align-self: center;
  margin: 0px 0px 0px 0px;
  background-color: #FF925C;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 190px;
  ${(args) => args.ready && `
    background-color: #fe7027;
  `}
`
const Volver = styled.button`
  cursor: pointer;
  align-self: center;
  margin: 0px 0px 0px 0px;
  background-color: gray;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 190px;
`