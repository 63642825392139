import React from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../../services/publicaciones/publicacionesApiTypes'

import trash from '../../../assets/icons/ui/trash.png'
import share from '../../../assets/icons/micuenta/share.png'

// const meses = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 
// 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

// function dateFormater(dateStamp: number) {
//   const d = new Date(dateStamp)

//   const date = d.getDate()
//   const month = meses[d.getMonth()]
//   const year = d.getFullYear()

//   return `el ${date} de ${month}, ${year}`
// }

export default function TitleMobile(props: TpublicacionItem) {

  return(
    <TitleSectionContainer>

      <Title> { props.year } { props.marca } </Title>
      <Title>
        { props.modelo } <Version> { props.version } </Version>
      </Title>

      <MarcarVendido> Marcar como vendido </MarcarVendido>
      <SolicitarInspeccion> Solicitar Inspeccion </SolicitarInspeccion>

      <Row>
        <Action>
          <Icon src={share}/>
          <Text> Compartir </Text>
        </Action>

        <Action>
          <Icon src={trash}/>
          <Text> Eliminar </Text>
        </Action>
      </Row>

    </TitleSectionContainer>
  )
}

const TitleSectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 10px 0px;
`

  const Title = styled.p`
    font-size: 22px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0px;
    margin-top: 5px;
  `
  // const Published = styled.div`
  //   font-size: 14px;
  //   font-weight: 400;
  //   color: gray;
  //   font-family: Montserrat;
  //   margin-top: 5px;
  // `

const Version = styled.span`
  font-weight: 500;
`
const MarcarVendido = styled.button`
  width: 250px;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #999;
  font-weight: 500;
`
const SolicitarInspeccion = styled.button`
  width: 250px;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #FE7027;
  font-size: 18px;
  font-weight: 500;
`

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  gap: 40px;
`

const Action = styled.div`
  
`
const Icon = styled.img`
  height: 25px;
`
const Text = styled.div``