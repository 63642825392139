import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TpublicacionItem } from '../../services/publicaciones/publicacionesApiTypes';
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store';
import { setPagarInspeccion } from '../../services/pagoInspeccion/inspeccionPubliSlice';
import { TstepPagoInspeccion } from './PagoInspeccion';

import useViewport from '../../hooks/useViewport'
import { Carousel } from "react-bootstrap";

import good from '../../assets/icons/ui/good2.png'
import check from '../../assets/icons/ui/check_white.png'

import inspector from '../../assets/icons/inspeccion/mechanic.png'
import list from '../../assets/icons/inspeccion/list-check.png'
import contact from '../../assets/icons/inspeccion/smartphone-contact.png'
import documentos from '../../assets/icons/inspeccion/documentos.png'

import whatsappIcon from '../../assets/icons/social/whatsapp.png'

const data = [
  {
    image: inspector,
    text: 'El diagnóstico vehicular será realizada por un experto técnico automotriz de carp10, y te dará su opinión respecto al vehículo.'
  },
  {
    image: list,
    text: 'Diagnóstico vehicular de +50 puntos del vehículo con prueba en marcha.'
  },
  {
    image: contact,
    text: 'El inspector se contactará contigo antes y después del diagnóstico vehicular para que puedas hacerle algunas preguntas sobre el diagnostico y sobre el vehículo.'
  },
  {
    image: documentos,
    text: 'Te enviaremos el reporte del diagnóstico vehicular a tu whatsapp.'
  }
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}


interface thisProps { publicacionData: TpublicacionItem, changeStep: (step: TstepPagoInspeccion) => void }
export default function InspeccionPublicacion(props: thisProps) {
  const { publicacionData: pub } = props
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <AgendarSuccessContainer
      isMobile={isMobile}
    >
      <Content>
        <Group isMobile={isMobile}>
          
          <Title isMobile={isMobile}>
            Compra este <Strong isMobile={isMobile}> { pub.marca } { pub.modelo } </Strong> con confianza
          </Title>

        </Group>

        <Group isMobile={isMobile}>

          { isMobile ? 
            <Carousel
              variant="dark"
              controls={false}
              interval={3500}
            >
              { data.map((requisito, idx) => (
                <Carousel.Item key={`requisito-${idx}`}>
                  <RequisitoCard>
                    <Icon src={requisito.image}/>
                    <Text> { requisito.text } </Text>
                  </RequisitoCard>
                </Carousel.Item>
              ))}
            </Carousel>
          :
            <RequisitosContainer>
              { data.map((requisito, idx) => (
                <RequisitoCard key={`requisito-${idx}`}>
                  <Icon src={requisito.image}/>
                  <Text> { requisito.text } </Text>
                </RequisitoCard>
              ))}
            </RequisitosContainer>
          }
        </Group>

        <Footer>
          <Subtitle isMobile={isMobile}>
            Precio <span style={{ fontSize: 'smaller' }}>sin factura</span>: <strong> 300 Bs.</strong> 
          </Subtitle>
{/*
          <Advice>
            Tambien recibirás gratis un reporte de las deudas fiscales que pudiese tener el vehículo
          </Advice>
*/}
          <AgendarButton
            onClick={() => {
              dispatch(setPagarInspeccion({
                publicacionId: pub.id_publicacion,
                marca: pub.marca,
                modelo: pub.modelo
              }))

              props.changeStep('sonido')
            }}
          >
            Agendar diagnostico vehicular
          </AgendarButton>
          
          <PhoneNumber href="https://wa.me/59176604123" isMobile={isMobile} target="_blank" rel="noopener noreferrer">
          <WhatsappLogo src={whatsappIcon} alt="WhatsApp" /> Mayor información: +591 76604123
          </PhoneNumber>
        
        </Footer>

      </Content>
    </AgendarSuccessContainer>
  )
}

interface isMobile { isMobile: boolean }
const AgendarSuccessContainer = styled.div<isMobile>`
  width: 1100px;
  padding: 10px 0px;
  ${(args) => args.isMobile && `
    width: 350px;
  `}
`
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: max(calc(80vh - 150px), 567px);
  gap: 5px;
`

interface Tgroup { isMobile: boolean }
const Group = styled.div<Tgroup>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  
  gap: ${(args) => args.isMobile ? '5px' : '20px'};
`

interface Ttitle { isMobile: boolean }
const Title = styled.div<Ttitle>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 32px;
  color: black;
  ${(args) => args.isMobile && `
    font-size: 24px;
    width: 300px; 
  `}
`

const Strong = styled.span<isMobile>`
  font-weight: 600;
  font-size: 32px;
  ${(args) => args.isMobile && `
    font-size: 24px;
    width: 300px; 
  `}
`


interface Tsubtitle { isMobile: boolean }
const Subtitle = styled.div<Tsubtitle>`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #444444;
  ${(args) => args.isMobile && `
    font-size: 15px;
    width: 280px; 
  `}
`

const RequisitosContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 1000px;
  gap: 0px;
`
const RequisitoCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;
  width: 300px;
  height: 250px;
`
  const Icon = styled.img`
    height: 50px;
    width: auto;
    object-fit: contain;
  `
  const Text = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    width: 200px;
    min-height: 140px;
    text-align: justify;
    display: flex;
    align-items: center;
  `
const Footer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  align-items: center;
`
const Advice = styled.div`
  font-family: Montserrat;
  font-size: 13px;
  width: 250px;
`

const AgendarButton = styled.button`
  margin-top: 10px;
  border: none;
  background-color: #FE7027;
  color: white;
  border-radius: 5px;
  padding: 5px 20px;
` 

const PhoneNumber = styled.a<isMobile>`
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-decoration: none; 
    display: inline-block; 

    &:hover {
        background-color: #f1f1f1;
        color: #555; 
    }

    ${(args) => args.isMobile && `
        font-size: 16px;
        padding: 8px 12px;
    `}
`;

const WhatsappLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 4px;  // o cualquier otro valor de espacio que desees
    vertical-align: middle;  // para alinear el ícono con el texto
`;