import React from "react"
import styled from "styled-components"

import notification from '../../assets/icons/notification/notification.png'

export default function Notificactions() {
  
  return (
    <Container>
      <Icon src={notification}/>
    </Container>
  )
}

const Container = styled.div``

const Icon = styled.img`
  height: 40px;
  margin: 0px 0px 0px 20px;
`
