/**
 * Configuration
 */
// import { deepEqual } from "assert";
import manifest from "./manifest.json";

/** Whether we're running on a local desktop or on AWS Lambda */
const isLocal = process.env.IS_LOCAL || process.env.IS_OFFLINE;
const isProduction = process.env.PROJECT_STAGE && process.env.PROJECT_STAGE === 'prod' ? true : false;

/**
 * Configuration Options
 *
 * IMPORTANT:
 * The config is injected into the client (browser) and accessible through the {@link useConfig}
 * hook. However, due to this behavior, it is important NOT to expose any sensitive information
 * such as passwords or tokens through the config.
 */

const config = {
    /** Application Config */
    app: {
        /** Name of the app is loaded from the `manifest.json` */
        TITLE: manifest.short_name,
        /** Theme is also loaded from the `manifest.json` */
        THEME_COLOR: manifest.theme_color,
        /** URL to our public API Gateway endpoint */
        URL: isLocal ? `http://localhost:3000` : String(process.env.APIGATEWAY_URL),
        /** Where the bundled distribution files (`main.js`, `main.css`) are hosted */
        DIST_URL: isLocal ? "http://localhost:8080" : String(process.env.APP_DIST_URL),
        /** Where the contents of the `public` folder are hosted (might be the same as `config.app.DIST_URL`) */
        PUBLIC_URL: isLocal ? "http://localhost:8080" : String(process.env.APP_PUBLIC_URL),
        /** AWS Productos or SmartSSI  */
        BACKEND_URL: isProduction ? "" : "https://api.carp10.com",
        CHAT_BACKEND_URL: isProduction ? "wss://ws.carp10.com" : "wss://ws.carp10.com",
        COOKIE_USER: "carp10_user",
        COOKIE_SIGNUP: "carp10_signup",
        STATIC_FILES: 'https://carp10-archivos.s3.amazonaws.com/',

        IMAGES_PREFIX_SLUG: 'inspecciones/',
        IMAGES_SUFFIX_SLUG: 'fotos/',

        FRONTAL_IMAGE: 'foto_frontal.jpeg',
        TRASERA_IMAGE: 'foto_trasera.jpeg',
        DIAGONAL_FRONTAL_IMAGE: 'foto_diagonal_frontal.jpeg',
        DIAGONAL_TRASERA_IMAGE: 'foto_diagonal_trasera.jpeg',
        CAPO_ABIERTO_IMAGE: 'foto_capo_abierto.jpeg',

        QR_DIAGNOSTICO: 'https://carp10-archivos.s3.amazonaws.com/qr/QR-diagnostico&Sonido-348bs.jpeg',
        QR_DIAGNOSTICO_SONIDO: 'https://carp10-archivos.s3.amazonaws.com/qr/QR-diagnostico&Sonido-348bs.jpeg',
        QR_PUBLICAR: 'https://carp10-archivos.s3.amazonaws.com/qr/QR-diagnostico-328bs.jpeg',
    },
};

export type Config = typeof config;
export default config;
