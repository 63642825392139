import React from 'react'
import styled from 'styled-components'

import Footer from '../../components/footer/footer'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function Terminos() {
  
  return(
    <TerminosContainer>
      <Doc>
        <Titulo> Políticas de privacidad </Titulo>

        <TextoParrafo> Última actualización de las Políticas de Privacidad: 17 de febrero de 2023 </TextoParrafo>

        <Seccion>
          <TituloSeccion>1. Identificación</TituloSeccion>
          <TextoParrafo> Caramba S.R.L. (en adelante “Carp10”, “nosotros”, “nos”, “nuestro” o la “Compañía”) es una empresa constituida bajo las leyes del Estado Plurinacional de Bolivia. Carp10 opera en el sitio web ubicado en www.carp10.com (en adelante el “Sitio”). </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>2. Objeto</TituloSeccion>
          <TextoParrafo> La presente Política de Privacidad tiene por objeto establecer el tratamiento de Datos Personales del Usuario, mismos que son proporcionados a Carp10 durante la utilización de los Servicios prestados a través del Sitio. </TextoParrafo>
          <TextoParrafo> Carp10 garantiza la protección de los Datos Personales de los Usuarios en cumplimiento a lo establecido en la Constitución Política del Estado Plurinacional de Bolivia en el artículo 21, inciso 2) y las normas internacionales de protección de datos personales. </TextoParrafo>
          <TextoParrafo> Los Datos Personales proporcionados serán utilizados y tratados de la manera que se indica en la presente Política de Privacidad. </TextoParrafo>
          <TextoParrafo> El Usuario, al momento del uso del Sitio, acepta el tratamiento y uso de sus Datos Personales, así como garantiza la veracidad de estos. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>3. Definiciones</TituloSeccion>
          <TextoParrafo> 3.1 Políticas de Privacidad: Es el documento legal que explica el manejo de los datos de los Usuarios dentro del Sitio. </TextoParrafo>
          <TextoParrafo> 3.2 Sitio: Es la página web que permite el acceso a los Servicios prestados, a través del cual los Usuarios podrán conocer ofertas para la compra y venta de bienes. </TextoParrafo>
          <TextoParrafo> 3.3 Usuario o Usuarios: Son las personas naturales que ingresan al Sitio, pudiendo acceder a los Servicios que se prestan. De igual manera, los términos “usted” y “su” se refieren a cada individuo que accede, navega o utiliza los Servicios. </TextoParrafo>
          <TextoParrafo> 3.4 Servicios: Los Servicios consisten en la puesta en acceso a diferentes ofertas de compra y venta de bienes, así como la posibilidad de brindar el servicio de inspección sobre los bienes ofertados. </TextoParrafo>
          <TextoParrafo> 3.5 Datos Personales: Es aquella información brindada por el Usuario para el uso del Sitio. </TextoParrafo>
          <TextoParrafo> 3.6 Cookies: Es aquella información enviada por los Usuarios que tiene como fin identificar cuando el Usuario usa el Sitio y lleva un registro de la actividad del mismo. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>4. Datos Personales</TituloSeccion>
          <TextoParrafo> Los Datos Personales que podrán ser manejados y tratados por Carp10 se detallan a continuación: </TextoParrafo>
          <TextoParrafo> 4.1. Datos de identificación del Usuario, como ser: nombre, apellidos, correo electrónico, domicilio, ciudad donde reside, teléfono, número de cédula de identidad, fecha de nacimiento, número de pasaporte, estado civil y/o parentesco con personas menores de edad o discapacitados solicitantes de nuestros servicios y fecha de vencimiento y/o género. </TextoParrafo>
          <TextoParrafo> 4.2. Toda aquella información que forme parte de comunicaciones, quejas, reclamos, comentarios, consultas, sugerencias y/o cualquier tipo de comunicación que realice el Usuario a través del correo electrónico, atención telefónica al cliente o redes sociales. </TextoParrafo>
          <TextoParrafo> 4.3. Datos de cómo llega el usuario al Sitio, si es que llega a través de una fuente externa, tales como páginas web o publicaciones en redes sociales. </TextoParrafo>
          <TextoParrafo> 4.4. Datos derivados del uso de Cookies, mismos que son usados para facilitar la navegación de los Usuarios y con fines estadísticos. </TextoParrafo>
          <TextoParrafo> El Usuario, cuando ingresa al Sitio, autoriza y consiente el tratamiento que recibirán sus datos personales según las presentes Políticas de Privacidad. El Usuario certifica que es mayor de 18 años y que por lo tanto posee la capacidad legal necesaria para la prestación del consentimiento inequívoco en cuanto al tratamiento de sus datos de carácter personal y todo ello, de conformidad con lo establecido en la presente Política de Privacidad. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>5. Finalidad de Recopilación de los Datos Personales</TituloSeccion>
          <TextoParrafo> Los Datos Personales recopilados por Carp10 a partir del uso del Sitio son utilizados con las siguientes finalidades: </TextoParrafo>
          <TextoParrafo> 5.1. Son utilizados para registrar y proporcionar los Usuarios que son registrados para el uso del Sitio.  </TextoParrafo>
          <TextoParrafo> 5.2. Cumplir con las obligaciones legales, contables, impositivas, administrativas y contractuales relacionadas con la prestación de los Servicios solicitados por parte del Usuario. </TextoParrafo>
          <TextoParrafo> 5.3. Envío de comunicaciones a través de correo electrónico, llamadas telefónicas, comunicaciones automatizadas sin intervención humana, notificaciones consistentes en el envío de mensajes a un teléfono móvil o cualquier otro medio de comunicación. </TextoParrafo>
          <TextoParrafo> 5.4. Todo tipo de envío de promociones u ofertas a través de los medios de comunicación establecidos.  </TextoParrafo>
          <TextoParrafo> 5.5. Dar respuesta a reclamos, quejas, incidencias y/o consultas. </TextoParrafo>
          <TextoParrafo> 5.6. Análisis estadístico del comportamiento del Usuario para determinar preferencias y mejoras.  </TextoParrafo>
          <TextoParrafo> 5.7. Facilitar información a los Usuarios sobre los Servicios ofrecidos. </TextoParrafo>
          <TextoParrafo> 5.8. Analizar, optimizar y mejorar el uso del Servicio. </TextoParrafo>
          <TextoParrafo> Carp10 no utilizará los datos otorgados por el Usuario para Servicios distintos a los mencionados anteriormente. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>6. Confidencialidad</TituloSeccion>
          <TextoParrafo> Carp10 se compromete a guardar absoluta reserva, confidencialidad y secreto sobre la información privada y personal a la que se tenga acceso a través de todos los medios anteriormente mencionados. Carp10 utiliza medidas de seguridad para evitar que los Datos Personales de los Usuarios puedan ser manipulados o ultrajados por terceros ajenos a la Compañía. </TextoParrafo>
          <TextoParrafo> Todos los Usuarios tienen el derecho de conocer, objetar u obtener la eliminación o rectificación de los datos registrados, prevaleciendo los derechos a la privacidad, intimidad, honra, honor, propia imagen y dignidad establecidos en el artículo 21 de la Constitución Política del Estado Plurinacional de Bolivia. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion>7. Cesión de Datos</TituloSeccion>
          <TextoParrafo> Carp10 no cede, transfiere o vende tus Datos Personales a terceros ajenos a la Compañía. </TextoParrafo>
          <TextoParrafo> No obstante, para el buen manejo y desenvolvimiento del Sitio es necesario comunicar datos específicos a nuestros proveedores, mismos que de igual manera garantizan guardar absoluta confidencialidad de los datos otorgados. </TextoParrafo>
          <TextoParrafo> A continuación, detallamos los posibles destinatarios de los Datos Personales de los Usuarios:</TextoParrafo>
          <TextoParrafo> 7.1. Empresas especializadas en la gestión de información comercial.</TextoParrafo>
          <TextoParrafo> 7.2. Empresas vinculadas contractualmente con Carp10 que llevan a cabo los Servicios.</TextoParrafo>
          <TextoParrafo> 7.3. Proveedores de servicios de pago. </TextoParrafo>
          <TextoParrafo> 7.4. Empresas de servicios de sistemas informáticos. </TextoParrafo>
          <TextoParrafo> 7.5. Todas las empresas que sean o puedan llegar a formar parte de la Compañía. </TextoParrafo>
          <TextoParrafo> Todos los datos recabados por parte de Carp10 están almacenados en un Data Center, mismos que solo serán utilizados para efectos de almacenamiento, seguridad y control. Dichos datos serán almacenados durante el término máximo necesario para el cumplimiento de las obligaciones legales, administrativas y contractuales de la Compañía. </TextoParrafo>
          <TextoParrafo> La lista de destinatarios mencionados es enunciativa mas no limitativa, pudiendo Carp10 ceder los Datos Personales de los Usuarios para fines del buen otorgamiento del Servicio. </TextoParrafo>
          <TextoParrafo> Carp10 podrá vender en cualquier momento la Compañía a personas jurídicas o naturales, transfiriendo con ella todos los Datos Personales de los Usuarios que se hayan recabado durante el uso de los Servicios y/o cualquier otro medio. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 8. Derechos de los Usuarios </TituloSeccion>
          <TextoParrafo> Como Usuario, puedes ejercer los siguientes derechos relacionados con tus Datos Personales: </TextoParrafo>
          <TextoParrafo> 8.1. Derecho de Acceso a los Datos: Todo Usuario tiene derecho a obtener una copia de los Datos Personales que son objeto de tratamiento, así como ciertos detalles sobre la manera en que los utilizamos. Podremos solicitarle, antes de facilitar la información, que especifique los Datos Personales o actividades de tratamiento a los que se refiere su solicitud. </TextoParrafo>
          <TextoParrafo> 8.2. Derecho de Rectificación: Todo Usuario tiene la potestad de solicitar que se rectifiquen los datos que cuenta Carp10 si considera que estos no son exactos y/o completos, debiendo enviar una notificación y justificar el hecho. </TextoParrafo>
          <TextoParrafo> 8.3. Derecho de Eliminación: Todo Usuario tiene derecho a solicitar la eliminación de los Datos Personales brindados a Carp10. Sin embargo, esto podría conllevar la imposibilidad de continuar con la prestación del Servicio. </TextoParrafo>
          <TextoParrafo> 8.4. Derecho de Objeción: Todo Usuario tiene derecho a oponerse al tratamiento de sus Datos Personales. En caso de requerimiento, pondremos fin al tratamiento indicado, salvo acreditación de motivos legítimos para continuar con el mismo. Es posible que el ejercicio del derecho de objeción puede suponer la imposibilidad de prestar el Servicio. </TextoParrafo>
          <TextoParrafo> Para ejercer cualquiera de estos derechos, el Usuario puede contactar a Carp10 en cualquier momento a través de los medios establecidos en el apartado “Contacto”. </TextoParrafo>
          <TextoParrafo> Todos los derechos otorgados son acordes a la Constitución Política del Estado Plurinacional de Bolivia. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 9. Cookies </TituloSeccion>
          <TextoParrafo> Carp10 utiliza cookies estrictamente necesarias y esenciales para que los Usuarios utilicen el Sitio y les permita moverse libremente, utilizar áreas seguras, opciones personalizadas, entre otros. Asimismo, Carp10 utiliza cookies que recogen datos relativos al análisis de uso de la Web. </TextoParrafo>
          <TextoParrafo> Las Cookies son utilizadas para mejorar el Servicio al cliente, midiendo el uso y el rendimiento de la página, para optimizarla y personalizarla. Las Cookies que se encuentran en los enlaces de redes sociales no son utilizadas ni manipuladas por Carp10. </TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 10. Modificación de las Políticas de Privacidad </TituloSeccion>
          <TextoParrafo> Por decisión propia de Carp10 o a causa de la promulgación de nuevas normas en materia de seguridad y de protección de datos, la Compañía podrá modificar la presente Política de Privacidad. De haber cualquier modificación, se notificará al Usuario al momento de iniciar sesión en el Sitio. En ningún caso las modificaciones supondrán una pérdida de eficacia en la protección de tus Datos Personales.</TextoParrafo>
        </Seccion>

        <Seccion>
          <TituloSeccion> 11. Contacto </TituloSeccion>
          <TextoParrafo> A efectos de notificaciones, Carp10 designa la siguiente dirección y formas de comunicación:  </TextoParrafo>
          <TextoParrafo> Correo electrónico: info@carp10.com </TextoParrafo>
          <TextoParrafo> Teléfono: +(591) 76000632 </TextoParrafo>
        </Seccion>
      </Doc>

      <Footer/>
    </TerminosContainer>
  )
}

const TerminosContainer = styled.div`
  font-family: Montserrat;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`

const Doc = styled.div`
  width: min(70%, 800px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Titulo = styled.p`
  font-size: 28px;
  font-weight: 500;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 22px;
  }
`

const Seccion = styled.div`
  
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`

const TituloSeccion = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 16px;
  }
`

const TextoParrafo = styled.p`
  font-size: 16px;
  text-align: left;
  margin-left: 40px;
  @media (max-width: 950px) {
    margin-left: 0px;
    font-size: 12px;
  }
`
const Inciso = styled.div`
  display: flex;
  margin-left: 40px;
`
const TituloInciso = styled.div`
  min-width: 30px;
`

const TextoInciso = styled.div``