import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import useViewport from '../../../hooks/useViewport'
import { setGivenName, setFamilyName, setMobilePhone } from '../../../services/auth/userSlice'
import { updateUserData } from '../../../services/auth/api'
import { fetchUserData } from '../../../services/auth/userSlice'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

const ciudades = [
  'Santa Cruz de la Sierra',
  'Warnes',
  'La Guardia'
]

type form = {
  given_name: string | undefined,
  family_name: string | undefined,
  mobile_phone: string | undefined,
  ciudad: string | undefined,
}

interface thisProps { hiddeModal: () => void }
export default function UserEditModal(props: thisProps) {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: TrootState) => state.user )
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const [ form, setForm ] = useState<form>({
    given_name: user.data?.given_name ? user.data.given_name : undefined,
    family_name: user.data?.family_name ? user.data.family_name : undefined,
    mobile_phone: user.data?.mobile_phone ? user.data.mobile_phone : undefined,
    ciudad: user.data?.ciudad ? user.data.ciudad : undefined,
  })


  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  async function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'user-name')
    setForm((state) => ({ ...state, given_name: event.target.value }))

    if (event.target.name === 'user-family-name')
    setForm((state) => ({ ...state, family_name: event.target.value }))

    if (event.target.name === 'user-ciudad') 
    setForm((state) => ({ ...state, ciudad: event.target.value }))

    if (event.target.name === 'user-phone') 
    setForm((state) => ({ ...state, mobile_phone: event.target.value }))
  }

  function updateUser() {

    const cleanPayload = Object.fromEntries(Object.entries(form).filter(([prop, value]) => {
      if (value === undefined) return false
      return true
    }))

    if (Object.keys(cleanPayload).length !== 0) {
      console.log('guardar')
      console.log(cleanPayload)

      updateUserData(cleanPayload).then((resp: any) => {
        if (resp.data.result === 'success') {
          dispatch(fetchUserData())
          props.hiddeModal()
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
    }
  }

  

  return(
    <UserEditModalContainer>

      <InputsContainer>
        <Title>
          Mi Perfil
        </Title>

        <RowName>
          <InputProp>
            <InputTitle> Nombre(s) </InputTitle>

            <Row>
              <Input
                type={'text'}
                name='user-name'
                placeholder={ form.given_name}
                //disabled={editKm ? false : true}
                onChange={inputChange}
                value={ form.given_name ? form.given_name : '' }
              />
            </Row>

          </InputProp>

          <InputProp>
            <InputTitle> Apellido(s) </InputTitle>

            <Row>
              <Input
                type={'text'}
                name='user-family-name'
                placeholder={ form.family_name}
                //disabled={editKm ? false : true}
                onChange={inputChange}
                value={ form.family_name ? form.family_name : '' }
              />
            </Row>

          </InputProp>
        </RowName>

        <InputProp>
          <InputTitle> Ciudad </InputTitle>

          <SelectPublicar
            name='user-ciudad'
            onChange={inputChange}
            value={ form.ciudad ? form.ciudad : '' }
          >
            <option value="" disabled> Selecione Ciudad </option>
            {
              ciudades.map((ciudad, index) => (
                <option value={ciudad} key={`vender-cc-${ciudad}`}>
                  { ciudad }
                </option>
              ))
            }
          </SelectPublicar>

        </InputProp>

        <InputProp>
          <InputTitle> Celular </InputTitle>

          <PhoneInputContainer>
            <CountryCodeInput
              type='text'
              placeholder='+591'
              disabled={true}
            />

            <PhoneInput
              type={'number'}
              name='user-phone'
              placeholder={ form.mobile_phone }
              //disabled={editKm ? false : true}
              onChange={inputChange}
              value={ form.mobile_phone ? form.mobile_phone : '' }
            />
          </PhoneInputContainer>
        </InputProp>

        <InputProp>
          <InputTitle> email </InputTitle>

          <Row>
            <Input
              type={'text'}
              name='user-family-name'
              placeholder={ user.data?.email}
              disabled={true}
            />
          </Row>

        </InputProp>

      </InputsContainer>

      <Bottom>
        <Eliminar> Eliminar mi cuenta </Eliminar>

        <Buttons>
          <Cerrar> Cerrar </Cerrar>
          <Guardar
            onClick={() => updateUser()}
          >
            Guardar Cambios
          </Guardar>
        </Buttons>
      </Bottom>

    </UserEditModalContainer>
  )
}

const UserEditModalContainer = styled.div`
  background-color: white;
  width: 500px;
  height: 600px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`
const Title = styled.div`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0px 10px 0px;
`

const InputsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0px 10px 0px 40px;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  //width: 100%;
  //align-items: space-between;
`
const RowName = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

const InputProp = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;
`
  const InputTitle = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: black;
  `
  const Input = styled.input`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    margin-top: 15px;
    width: 200px;
    line-height: 25px;
  `
  const Edit = styled.div`
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
    text-decoration: underline;
    color: #0088AA;
    font-weight: 600;
    cursor: pointer;
    margin-left: 30px;
  `
const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13px;

  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  border: solid 1px gray;
  border-radius: 5px;
  width: 205px;
  

  &:focus {
    outline: none;
  }
`
const PhoneInputContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 150px;
  gap: 10px;
  margin-top: 10px;
`
  const CountryCodeInput = styled.input`
    flex-grow: 1;
    width: 50px;
    border: none;
    
    border-radius: 5px;
    background-color: white;
    font-size: 13px;
    line-height: 0px;
    margin: 0px 10px 0px 0px;
    padding: 0px 0px 0px 15px;
  `
  const PhoneInput = styled(Input)`
    width: 125px;
    flex-grow: 4;
    margin: 0px;
  `

const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  margin-bottom: 30px;
`
  const Eliminar = styled.span`
    text-decoration: underline;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  `

  const Buttons = styled.div`
    display: flex;
    gap: 10px;
  `
  const Button = styled.button`
    display: flex;
    border: none;
    width: 130px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    padding: 5px 0px;
  `
  const Cerrar = styled(Button)`
    background-color: white;
    color: #fe7027;
    border: 2px solid #fe7027;
    border-radius: 2px;
  `
  const Guardar = styled(Button)`
    background-color: #5FD35F;
    border: 2px solid #5FD35F;
    color: white;
  `