import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from "../../app/store"
import { setInspeccionMarca, setInspeccionModelo, setInspeccionYear } from '../../services/pagoInspeccion/inspeccionPubliSlice'
import { Tagendar } from './agendar'
import useViewport from "../../hooks/useViewport"
import { setPorAgendar, setYoLoAgendo } from '../../services/pagoInspeccion/inspeccionPubliSlice'

import NavigatorComponent from './navigatorComponent'

import { vehicles } from '../../assets/data/VehiclesData2'

const marcas = Object.keys(vehicles)

const year = [
  2010, 2011, 2012, 2013, 2014,
  2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
]


const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

type ObjectKey = keyof typeof vehicles;

interface Tprops { changeStep: (step: Tagendar) => void }
export default function AgendarDatosVehiculo(props: Tprops) {
  const dispatch = useAppDispatch()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [complete, setComplete] = useState(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const inspeccion = useAppSelector((state: TrootState) => state.inspeccionPubli)
  const publicacion = useAppSelector((state: TrootState) => state.publicar)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  function inputChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.name === 'inspeccion-marca') {
      dispatch(setInspeccionMarca(event.target.value))
    }

    if (event.target.name === 'inspeccion-modelo') {
      dispatch(setInspeccionModelo(event.target.value))
    }

    if (event.target.name === 'inspeccion-ano') {
      dispatch(setInspeccionYear(Number(event.target.value)))
    }
  }

  useEffect(() => {
    if (inspeccion.data.marca && inspeccion.data.modelo && inspeccion.data.year)
    setComplete(true)
    else
    setComplete(false)
  }, [inspeccion])

  function AgendarYoMismo(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked)
    dispatch(setYoLoAgendo())
    else
    dispatch(setPorAgendar())
  }

  return(
    <DatosVehiculoContainer
      //className='view'
      isMobile={isMobile}
    >
      <Centered>

        { inspeccion.tipo === 'agendado' &&
          <NavigatorComponent step={1} />
        }

        <Title> Datos del vehiculo </Title>

        <FirstRow
          isMobile={isMobile}
          isSD={isSD}
        >
          <SelectPublicar
            name="inspeccion-marca"
            onChange={inputChange}
            defaultValue={inspeccion.data.marca ? inspeccion.data.marca : ''}
          >
            <Option value="" disabled> Marca </Option>
            {
              marcas.map((marca, index) => (
                <Option value={marca} key={`marca${index}`}>
                  { marca }
                </Option>
              ))
            }
          </SelectPublicar>

          <SelectPublicar 
            name='inspeccion-modelo'
            onChange={inputChange}
            disabled={inspeccion.data.marca ? false : true}
            defaultValue={inspeccion.data.modelo ? inspeccion.data.modelo : ''}
          >
            <Option value="" disabled> Modelo </Option>
            {
              inspeccion.data.marca ? vehicles[inspeccion.data.marca as ObjectKey].map((vehicle, index) => (
                <Option value={vehicle} key={`vehicle${vehicle}`}>
                  { vehicle }
                </Option>
              ))
              :
              null
            }
          </SelectPublicar>

          <SelectPublicar
            name='inspeccion-ano'
            onChange={inputChange}
            disabled={inspeccion.data.modelo ? false : true}
            defaultValue={inspeccion.data.year ? inspeccion.data.year : 0}
          >
            <Option value={0} disabled> Año </Option>
            {
              year.map((year, index) => (
                <Option value={year} key={`year${index}`}>
                  { year }
                </Option>
              ))
            }
          </SelectPublicar>
        </FirstRow>

        <Bottom>
          <AgendarContainer>
            <AgendarCheck
              type='checkbox'
              id='agendar-inspeccion-myself'
              onChange={AgendarYoMismo}
              checked={ inspeccion.tipo === 'agendado'}
            />
            <AgendarLabel
              htmlFor='agendar-inspeccion-myself'
            >
              {`Agendar la inspección yo mismo\n en este momento`}
            </AgendarLabel>
          </AgendarContainer>

          <Continue
            onClick={() => {
              if (inspeccion.tipo === 'agendado')
              props.changeStep('fecha')
              else
              props.changeStep('facturacion')
            }}
            disabled={!complete}
            ready={complete}
          >
            { inspeccion.tipo === 'agendado' ? 'Agendar Fecha' : 'Solicitar Diagnóstico' }
          </Continue>
        </Bottom>

      </Centered>
    </DatosVehiculoContainer>
  )
}

interface TdatosVehiculoContainer { isMobile: boolean }
const DatosVehiculoContainer = styled.div<TdatosVehiculoContainer>`
  width: 100%;
`
const Centered = styled.div`
  max-width: 800px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: max(calc(100vh - 150px), 467px);
  margin: 20px auto 0px auto;
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 30px;
  align-self: flex-start;
  margin: 0px 0px 0px 30px;
`

const SelectPublicar = styled.select`
  font-family: Montserrat;
  font-weight: 600;
  width: 200px;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 5px 15px;
  border: solid 1px gray;
  border-radius: 5px;
  
  &:focus {
    outline: none;
  }
`
  const Option = styled.option`
    margin: 5px 0px 5px 0px;
    font-family: Montserrat;
  `

interface TfirstRow { isMobile: boolean, isSD: boolean }
const FirstRow = styled.div<TfirstRow>`
  display: flex;
  flex-flow: ${(args) => args.isMobile  ? 'column nowrap' : 'row nowrap'};
  gap: ${(args) => args.isMobile  ? '30px' : '100px'};
`
const Bottom = styled.div`
  margin-top: 30px;
`
const AgendarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
  const AgendarCheck = styled.input`
    width: 15px;
    height: 15px;
    font-size: 100px;
    accent-color: gray;
  `
  const AgendarLabel = styled.label`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    color: gray;
    white-space: pre-wrap;
  `


interface Tcontinue { ready: boolean }
const Continue = styled.button<Tcontinue>`
  align-self: center;
  margin: 30px 0px 0px 0px;
  background-color: #FF925C;
  
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
  width: 200px;
  ${(args) => args.ready && `
    background-color: #fe7027;
  `}
`