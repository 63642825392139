import React from "react"
import styled from "styled-components"

export default function VendidoRibbon() {

  return (
    <Container className="VendidoRibbon">
      <span>Vendido</span>
    </Container>
  )
}

const Container = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  z-index: 10;

  top: -3px;
  right: -3px;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 3px solid #1eb5ff;
    border: 3px solid #000;
    border-top-color: transparent;
    border-right-color: transparent;
  };
  &::before {
    top: 0;
    left: 0;
  };
  &::after {
    bottom: 0;
    right: 0;
  };
  & span {
    position: absolute;
    display: block;
    width: 165px;
    padding: 5px 0;
    background-color: #000;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: white;
    font-family: montserrat, sans-serif;

    left: -22px;
    top: 30px;
    transform: rotate(45deg);
    
  };
`