import React from 'react'
import styled from 'styled-components'

import good from '../../assets/icons/ui/good2.png'

interface Tnavigator { step: 1 | 2 | 3 }
export default function NavigatorComponent(props: Tnavigator) {

  return(
    <AgendarNavigator>
      <DatosVehiculoStep>
        <Circle
          isCurrentStep={props.step === 1 ? true : false}
        >
          1
        </Circle>
        <TextStep> {`Datos del\n vehículo`} </TextStep>
      </DatosVehiculoStep>

      <Ruler/>

      <FechaStep>
        <Circle
          isCurrentStep={props.step === 2 ? true : false}
        >
          2
        </Circle>
        <TextStep> {`Agendar\n Inspección`} </TextStep>
      </FechaStep>

      <Ruler/>

      <SuccessStep>
        <BackIcon
          isCurrentStep={props.step === 3 ? true : false}
        >
          <GoodIcon src={good}/>
        </BackIcon>
      </SuccessStep>
    </AgendarNavigator>

  )
}

const AgendarNavigator = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-top: -40px;
`
  const Step = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
  `
    interface Tcircle { isCurrentStep: boolean }
    const Circle = styled.div<Tcircle>`
      font-family: Montserrat;
      font-weight: 600;
      width: 40px;
      height: 40px;
      
      background-color: gray;
      color: white;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      ${(args) => args.isCurrentStep && `
        background-color: #30302C;
      `}
    `
    const TextStep = styled.div`
      font-family: Montserrat;
      font-size: 10px;
      white-space: pre-wrap;
    `
    const DatosVehiculoStep = styled(Step)``
    const FechaStep = styled(Step)``
    const SuccessStep = styled(Step)``

    const BackIcon = styled(Circle)`
      background-color: transparent;
      ${(args) => args.isCurrentStep && `
        background-color: #ff9955;
      `}
    `

    const GoodIcon = styled.img`
      width: 40px;
      height: 40px;
    `

    const Ruler = styled.div`
      width: 8px;
      border-bottom: solid 2px gray;
      align-self: center;
    `