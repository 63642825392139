export const escaneo_general = [
  'sen_oxigeno_escape',
  'fuel_system',
  'temp_motor',
  'sen_map',
  'rpm',
  'temp_escape',
  'transmision_check',
  'airbags',
  'sistema_AWD',

  'computadora_motor',
  'computadora_caja',
  'aire_acondicionado',
  'panel_instrumento',
  'inmovilizador_llave',
  'eps',
  'bcm',
  'awd_4wd',
  'tpms',
  'odometro_real',
  'inyeccion_pareja'
]

export const interior_vehiculo = [
  'bomba_hidraulica',
  'luces_alta_baja',
  'camara_reversa',
  'ctrl_vidrio_izq_delantero',
  'ctrl_vidrio_der_delantero',
  'ctrl_vidrio_izq_trasero',
  'ctrl_vidrio_der_trasero',
  'ac_interruptores',
  'ac_sistema',
  'ac_salida_delantera',
  'ac_salida_trasera',
  'radio',
  'limpia_parabrisas',
  'guiñadores',

  'control_vidrios',
  'controles_volante',
  'retrovisores_plegables',
  'camara_retro'
]

export const seguridad_carretera = [
  'encendido_motor',
  'motor_ralenti',
  'retro',
  'secuencial',
  'suspension',
  'suspension_amortig',
  'frenos',


  'direccion',
  'terminales_direccion',
  'cinturones_seguridad',
  'luz_freno',
  'pastillas_freno',
  'llantas_neumaticos',
  'aros_originales',
  'limpia_parabrisas_delanteros',
  'limpia_parabrisas_traseros'
]

export  const bajo_capo = [
  'motor_sonido',
  'correa',
  'radiador_mangueras',
  'motor_soporte',
  'bateria_agua',
  'bateria',
  'alternador',
  'motor_filtro_aire',
  'motor_aceite',
  'motor_aceite_color',
  'freno_liquido',
  'liquido_refrigerante',


  'plastico_delantero',
  'motor_aceite_espesor',
  'compresor_aire_acondicionado',
  'gas_refrigerante'
]

export const datos_adicionales = [
  'llanta_delantera_izquierda',
  'llanta_trasera_izquierda',
  'llanta_trasera_derecha',
  'llanta_delantera_derecha',
  
  
  'tapa_maletero',
  'herramientas',
  'llanta_auxilio',
  //'numero_llaves'
]

export const estetica_vehiculo = [
  'parachoque_trasero',
  'freno_disco',
  'puerta_frontal_izquierda',
  'puerta_trasera_izquierda',
  'puerta_trasera_derecha',
  'puerta_frontal_derecha',
  'parachoque_frontal',
  'capo',
  'techo',
  'fugas_hidraulicas',


  'zonas_repintadas',
  'zonas_abolladuras',
  'zonas_raspaduras'
]